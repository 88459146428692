import React from 'react';
import Slide from './Slide';
import RelationPage from '../../../common/RelationPage';

function Performance() {
  return (
    <div className="performance">
      <div className="inner">
        <div className="visual">
          <h2 className="tlt">성능 및 제원</h2>
        </div>
        <div className="con">
          <Slide />
          <div className="table_wrap th_lf performance_table">
            <table>
              <colgroup>
                <col width="40%" />
                <col width="60%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>전장 (mm)</th>
                  <td>5,310</td>
                </tr>
                <tr>
                  <th>전폭 (mm)</th>
                  <td>1,770</td>
                </tr>
                <tr>
                  <th>전고 (mm)</th>
                  <td>2,100</td>
                </tr>
                <tr>
                  <th>축간거리 (mm)</th>
                  <td>2,850</td>
                </tr>
                <tr>
                  <th>공차 중량 (kg)</th>
                  <td>2,320</td>
                </tr>
                <tr>
                  <th>배터리 용량 (kWh)</th>
                  <td>82</td>
                </tr>
                <tr>
                  <th>타이어 (inch)</th>
                  <td>15</td>
                </tr>
                <tr>
                  <th>최대적재량 (kg)</th>
                  <td>1,000</td>
                </tr>
                <tr>
                  <th>최고출력 (ps/rpm)</th>
                  <td>190/4,200</td>
                </tr>
                <tr>
                  <th>에너지 소비효율(전비) (km/kWh)</th>
                  <td>
                    도심 3.0 / 복합 2.6 / 고속도로 2.3
                    <small>*산자부 인증 기준</small>
                  </td>
                </tr>
                <tr>
                  <th>1회 충전 주행거리 (km)</th>
                  <td>
                    상온주행 최대 246km 저온주행 최대 209km
                    <small>* 환경부 누리집 등재 기준</small>
                  </td>
                </tr>
                {/* <tr>
                  <th>충전시간</th>
                  <td>5.310</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Performance;
