import React, { useEffect, useState } from 'react';
import AlartPopUp from '../../../../components/common/AlartPopUp';
import { useAppDispatch, useAppSelector } from '../../../../store';
import useTcoFormHook from '../../../../utile/hook/useTcoFormHook';
import { autoComma, isDecimal, unComma } from '../../../../utile/regExp';
import { actCb1LPopUp } from '../../../../utile/slice/layerPopUpSlice';
import RelationPage from '../../../common/RelationPage';
import Visual from '../../../common/Visual';
import CalcPopup from './CalcPopup';

const periodOptions = [
  { name: '1', value: '1' },
  { name: '2', value: '2' },
  { name: '3', value: '3' },
  { name: '4', value: '4' },
  { name: '5', value: '5' },
  { name: '6', value: '6' },
  { name: '7', value: '7' },
  { name: '8', value: '8' },
  { name: '9', value: '9' },
  { name: '10', value: '10' },
];

function Tco() {
  function delTxt(input) {
    input.target.closest('.input_wrap').querySelector('input').value = '';
    input.target.closest('.input_wrap').querySelector('.input_del').classList.remove('focus');
  }

  const checkedCss = (e) => {
    const labelTagList = Array.from(document.getElementsByTagName('label'));

    labelTagList.forEach((labelTag) => {
      labelTag.classList.remove('checked');
    });

    e.target.parentNode.classList.add('checked');
  };

  const dispatch = useAppDispatch();
  const { isShowAPopUp } = useAppSelector((state) => state.alartPopUp);
  const { depth1LPopUpName: tcoForm, isCb1LPopUp } = useAppSelector((state) => state.layerPopUp);

  const { register, handleSubmit, onCalculate, onError, setValue, getValues, reset } =
    useTcoFormHook();

  //최초로딩시 필수값 팝업등록
  useEffect(() => {
    register('dieselFuelCost', {
      required: '1회 주유비를 입력해 주세요.',
    });
    register('electricVehicleChargingCost', {
      required: '1회 충전비를 입력해 주세요.',
    });
    register('combinedFuelEconomy', {
      required: '보유하신 차량 표준 연비를 입력해 주세요.',
    });
    register('dailyDrivingDistance', {
      required: '하루 주행거리를 입력해 주세요.',
    });
  }, []);

  useEffect(() => {
    if (isCb1LPopUp) {
      reset();
      setDieselFuelCost('');
      setElectricVehicleChargingCost('');
      setCombinedFuelEconomy('');
      setDailyDrivingDistance('');

      //폼리셋된후 다시 필수값 팝업 등록
      register('dieselFuelCost', {
        required: '1회 주유비를 입력해 주세요.',
      });
      register('electricVehicleChargingCost', {
        required: '1회 충전비를 입력해 주세요.',
      });
      register('combinedFuelEconomy', {
        required: '보유하신 차량 표준 연비를 입력해 주세요.',
      });
      register('dailyDrivingDistance', {
        required: '하루 주행거리를 입력해 주세요.',
      });
      const labelTagList = Array.from(document.getElementsByTagName('label'));

      labelTagList.forEach((labelTag) => {
        labelTag.classList.remove('checked');
      });
    }
  }, [isCb1LPopUp]);

  const [dieselFuelCost, setDieselFuelCost] = useState('');
  const [electricVehicleChargingCost, setElectricVehicleChargingCost] = useState('');
  const [combinedFuelEconomy, setCombinedFuelEconomy] = useState('');
  const [dailyDrivingDistance, setDailyDrivingDistance] = useState('');

  useEffect(() => {
    setValue('dieselFuelCost', unComma(dieselFuelCost));
  }, [dieselFuelCost]);

  useEffect(() => {
    setValue('electricVehicleChargingCost', unComma(electricVehicleChargingCost));
  }, [electricVehicleChargingCost]);

  useEffect(() => {
    setValue('combinedFuelEconomy', combinedFuelEconomy);
  }, [combinedFuelEconomy]);

  useEffect(() => {
    setValue('dailyDrivingDistance', dailyDrivingDistance);
  }, [dailyDrivingDistance]);

  const resetCb1LPopUp = () => {
    dispatch(actCb1LPopUp(''));
  };

  console.log('getValues', getValues);

  return (
    <div className="tco">
      <Visual
        tlt="EV 절약계산기"
        sub={'전기차 운행 시, 디젤차 보다 \n 절약되는 금액을 미리 계산해보세요.'}
      />
      <div className="inner">
        <div className="cont">
          <div className="tlt_wrap">
            <strong className="tlt">전기차와 디젤 차량을 비교해 보세요.</strong>
            <p className="desc">
              장기간 운행할 수록 낮아지는비용으로 절약되는 금액을 확인하실 수 있습니다.
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit(onCalculate, onError)}>
          <div className="calc_tco">
            <dl className="item">
              <dt>디젤 주유비</dt>
              <dd>
                <div className="form_wrap line_ty">
                  <div className="input_wrap">
                    <input
                      id="input1"
                      name="input1"
                      placeholder="1 회 주유비 입력"
                      className="input_txt"
                      value={dieselFuelCost}
                      onChange={(e) => {
                        setDieselFuelCost(autoComma(e.target.value));
                      }}
                    ></input>
                    <button
                      type="button"
                      title="내용 지우기"
                      className="input_del"
                      onClick={delTxt}
                    >
                      <i className="ico_18_del"></i>
                    </button>
                  </div>
                  <span className="err_msg">{}</span>
                </div>
                <span>원/L</span>
              </dd>
            </dl>
            <dl className="item">
              <dt>보유 차량 복합연비</dt>
              <dd>
                <div className="form_wrap line_ty">
                  <div className="input_wrap">
                    <input
                      id="input2"
                      name="input2"
                      placeholder="보유 차량의 표준연비 입력"
                      className="input_txt"
                      value={combinedFuelEconomy}
                      onChange={(e) => {
                        setCombinedFuelEconomy(isDecimal(e.target.value));
                      }}
                    ></input>
                    <button
                      type="button"
                      title="내용 지우기"
                      className="input_del"
                      onClick={delTxt}
                    >
                      <i className="ico_18_del"></i>
                    </button>
                  </div>
                </div>
                <span>km/L</span>
              </dd>
            </dl>
            <dl className="item">
              <dt>하루 주행거리</dt>
              <dd>
                <div className="form_wrap line_ty">
                  <div className="input_wrap">
                    <input
                      id="input3"
                      name="input3"
                      placeholder="하루 주행거리 입력"
                      className="input_txt"
                      value={dailyDrivingDistance}
                      onChange={(e) => {
                        setDailyDrivingDistance(isDecimal(e.target.value));
                      }}
                    ></input>
                    <button
                      type="button"
                      title="내용 지우기"
                      className="input_del"
                      onClick={delTxt}
                    >
                      <i className="ico_18_del"></i>
                    </button>
                  </div>
                  <span className="err_msg">{}</span>
                </div>
                <span>km</span>
              </dd>
            </dl>
            <dl className="item ext1">
              <dt>한달 주행 횟수</dt>
              <dd>
                <div className="form_wrap">
                  <div className="select">
                    <select
                      id="input1"
                      className="input_select"
                      {...register('numberOfTripsPerMonth', {
                        required: '한 달 주행 횟수를 선택해주세요.',
                      })}
                    >
                      <option value="">한 달 주행 횟수 선택</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                    </select>
                  </div>
                </div>
                <span>건</span>
              </dd>
            </dl>
            <dl className="item ext1">
              <dt>차량 운행 기간</dt>
              <dd>
                <div className="form_wrap">
                  <div className="select">
                    <select
                      id="input1"
                      className="input_select"
                      {...register('vehicleOperationPeriod', {
                        required: '차량 운행 기간을 선택해 주세요.',
                      })}
                    >
                      <option value="">차량 운행 기간 선택</option>
                      {periodOptions.map((option) => (
                        <option key={option.name} value={option.value}>
                          {option.value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <span>년</span>
              </dd>
            </dl>
            <dl className="item charge">
              <dt>전기차 충전비</dt>
              <dd>
                <div className="form_wrap line_ty">
                  <div className="input_wrap">
                    <input
                      id="input3"
                      name="input3"
                      placeholder="1 회 충전비 입력"
                      className="input_txt"
                      value={electricVehicleChargingCost}
                      onChange={(e) => {
                        setElectricVehicleChargingCost(autoComma(e.target.value));
                      }}
                    ></input>
                    <button
                      type="button"
                      title="내용 지우기"
                      className="input_del"
                      onClick={delTxt}
                    >
                      <i className="ico_18_del"></i>
                    </button>
                  </div>
                </div>
                <span>원/kWh</span>
              </dd>
            </dl>
            <button className="btn_primary_l" onClick={resetCb1LPopUp}>
              <span>계산하기</span>
            </button>
          </div>
        </form>

        <div className="box_links">
          <a
            href="https://www.opinet.co.kr/user/dopospdrg/dopOsPdrgSelect.do"
            target="_blank"
            className="infor1"
            rel="noreferrer"
          >
            디젤 주유비 안내 바로가기 <i title="페이지이동" className="ico_move"></i>
          </a>
          <a
            href="https://evc.kepco.co.kr:4445/service/service11.do"
            target="_blank"
            className="infor2"
            rel="noreferrer"
          >
            전기 충전요금 안내 바로가기 <i title="페이지이동" className="ico_move"></i>
          </a>
        </div>

        <div className="box_information">
          <dl className="info">
            <dt>
              <i className="ico_24_noti_msg"></i>확인해 주세요.
            </dt>
            <dd>
              - EV 절약계산기는 차량 총소유비용(Total cost of ownership) 중 연간 주유비용과
              충전비용에 대하여 산정한 금액입니다
            </dd>
            <dd>- 실제 디젤 가격은 디젤 공급 사업자 및 구매 날짜에 따라 달라질 수 있습니다.</dd>
            <dd>
              - 실제 전기 충전 요금은 충전 서비스 제공 사업자, 충전속도(급속/완속), 전기 충전 날짜
              등에 따라 달라질 수 있습니다.
            </dd>
          </dl>
        </div>
      </div>
      <RelationPage />
      {isShowAPopUp && <AlartPopUp />}
      {tcoForm && <CalcPopup tcoForm={tcoForm} />}
    </div>
  );
}

export default Tco;
