import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { staticAutoComma, unComma } from '../../../../../utile/regExp';
import { actAddInputEstimateForm } from '../../../../../utile/slice/tempFormSlice';
import cargoWhImg from '../../../../../assets/images/img/img_model_type1_wh.png';
import cargoSbImg from '../../../../../assets/images/img/img_model_type1_sb.png';
import teugjangWhImg from '../../../../../assets/images/img/img_model_type2_wh.png';
import teugjangSbImg from '../../../../../assets/images/img/img_model_type2_sb.png';
import PDF from '../document/Document';
import '../document/document.css';

function MyOption() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function barOpen(e) {
    const detail = document.querySelector('.detail');
    const btn = e.target.closest('button');
    btn.classList.toggle('active');
    detail.classList.toggle('active');
  }
  function accOnOff(e) {
    const accCont = e.target.closest('li');
    accCont.classList.toggle('active');
  }
  window.addEventListener('load', () => {
    posBar();
  });

  window.addEventListener('resize', () => {
    posBar();
  });

  function posBar() {
    const wrap = document.querySelector('.my_option_wrap');
    const moveDiv = document.querySelector('.my_option');
    const btnWrap = document.querySelector('[data-toggle]');

    if (window.innerWidth < 1024) {
      wrap?.classList.add('estimatebar');
      wrap?.appendChild(btnWrap);
    } else {
      wrap?.classList.remove('estimatebar');
      moveDiv?.appendChild(btnWrap);
    }
  }

  const subsidyPrice = useAppSelector((state) => state.subsidyPrice.subsidyPrice); // 구매보조금
  const consignmentCharge = useAppSelector((state) => state.consignment.consignmentCharge); // 탁송비
  const regFeeTotal = useAppSelector((state) => state.regFee.regFeeTotal); // 등록비용

  const { estimateForm } = useAppSelector((state) => state.tempForm);

  const ref = useRef();

  const goDocReserv = () => {
    //!템프폼에 디스패치 해야함.
    navigate('/buy/document/docreserv');
  };

  useEffect(() => {
    posBar();
    dispatch(
      actAddInputEstimateForm({
        ...estimateForm,
        mandInsurPrice: Number(unComma(document.querySelector('#mandInsurPrice').innerHTML)),
      }),
    );
  }, []);

  return (
    <div className="my_option_wrap">
      <button
        type="button"
        title="펼치기"
        className="bar_open active"
        data-tablet
        onClick={barOpen}
      ></button>
      <div className="my_option detail">
        <div className="option_visual">
          {estimateForm.type === 'T4K 카고' ? (
            <img src={estimateForm.COLOR_TYPE === 'WHITE' ? cargoWhImg : cargoSbImg} alt="" />
          ) : (
            <img src={estimateForm.COLOR_TYPE === 'WHITE' ? teugjangWhImg : teugjangSbImg} alt="" />
          )}
          <dl>
            <dt>
              <span>{estimateForm.mldNm}</span>
              {estimateForm.trimNm}
            </dt>
            <dd>-{estimateForm.colorNm}</dd>
            <dd>-{estimateForm.packageNm}</dd>
          </dl>
        </div>
        <ul className="price_info">
          <li className="active" data-accordion>
            <div className="tlt_wrap active">
              <strong className="tlt">
                총 차량 구매 금액
                <button type="button" title="컨텐츠 열기" onClick={accOnOff}>
                  <i className="ico_32_accoup"></i>
                </button>
              </strong>
              <span className="price">
                <span>
                  {staticAutoComma(
                    estimateForm.totalPrice +
                      estimateForm.consignmentPrice -
                      estimateForm.subsidyPrice,
                  )}
                </span>
                원
              </span>
            </div>
            <dl className="acco_desc active">
              <dt>차량 가격</dt>
              <dd>
                <span>{staticAutoComma(estimateForm.totalPrice)}</span>원
              </dd>
              <dt>구매 보조금</dt>
              {/* 마이너스인 경우 클래스 추가 */}
              <dd className="minus">
                (-) <span>{staticAutoComma(subsidyPrice)}</span>원
              </dd>
              <dt>탁송</dt>
              <dd>
                <span>{staticAutoComma(consignmentCharge)}</span>원
              </dd>
            </dl>
          </li>
          <li>
            <div className="tlt_wrap">
              <strong className="tlt">임시운행 의무보험료</strong>
              <span className="price">
                <span id="mandInsurPrice">2,000</span>원
              </span>
            </div>
          </li>
          <li>
            <div className="tlt_wrap total">
              <strong className="tlt">총 견적 합계</strong>
              <span className="price">
                <span>
                  {staticAutoComma(
                    estimateForm.totalPrice +
                      estimateForm.consignmentPrice -
                      estimateForm.subsidyPrice +
                      estimateForm.mandInsurPrice,
                  )}
                </span>
                원
              </span>
            </div>
          </li>
          {estimateForm.paymentType === 'INST' && (
            <li className="active" data-accordion>
              <div className="tlt_wrap active">
                <strong className="tlt">
                  월 납입 금액
                  <button type="button" title="컨텐츠 열기" onClick={accOnOff}>
                    <i className="ico_32_accoup"></i>
                  </button>
                </strong>
                <span className="price">
                  <span>
                    {staticAutoComma(parseInt(estimateForm?.monthly_payment?.totalMonthlyPayment))}
                  </span>
                  원
                </span>
              </div>
              <dl className="acco_desc">
                <dt>할부원금</dt>
                <dd>
                  <span>{staticAutoComma(estimateForm?.installmentPrincipal)}</span>원
                </dd>
                <dt>총 할부이자</dt>
                <dd>
                  <span>
                    {staticAutoComma(
                      parseInt(estimateForm?.monthly_payment?.totalInstallmenInterest),
                    )}
                  </span>
                  원
                </dd>
                <dt>할부기간 / 금리</dt>
                <dd>
                  <span>
                    {estimateForm?.monthly_payment?.installmentPeriod}개월 /&nbsp;
                    {estimateForm?.monthly_payment?.interestRate}%
                  </span>
                </dd>
              </dl>
            </li>
          )}
          {estimateForm.paymentType === 'INST' && (
            <li>
              <div className="tlt_wrap">
                <strong className="tlt">출고 전 납입 금액</strong>
                <span className="price">
                  <span>
                    {staticAutoComma(
                      parseInt(
                        estimateForm.totalPrice -
                          estimateForm.subsidyPrice +
                          estimateForm.consignmentPrice +
                          estimateForm.mandInsurPrice -
                          estimateForm?.installmentPrincipal,
                      ),
                    )}
                  </span>
                  원
                </span>
              </div>
            </li>
          )}
          <li>
            <div className="tlt_wrap">
              <strong className="tlt">등록 비용 (별도 납부)</strong>
              <span className="price">
                <span>{staticAutoComma(regFeeTotal)}</span>원
              </span>
            </div>
          </li>
        </ul>
      </div>
      <span className="sub_tlt" data-tablet>
        예상 가격(VAT 포함)
      </span>
      <em className="price" data-tablet>
        <span>{staticAutoComma(estimateForm.totalPrice)}</span>원
      </em>
      <div className="btn_layout" data-toggle>
        <ReactToPrint
          trigger={() => (
            <a download className="btn_ghost_l" style={{ cursor: 'pointer' }}>
              <i className="ico_24_download_bk"></i>
              견적서 다운로드
            </a>
          )}
          content={() => ref.current}
        />
        <button type="button" className="btn_primary_l" onClick={goDocReserv}>
          <span>구매상담 신청</span>
        </button>
      </div>
      <div style={{ display: 'none' }}>
        <div ref={ref}>
          <PDF />
        </div>
      </div>
    </div>
  );
}

export default MyOption;
