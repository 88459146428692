import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getCustomerList } from '../../../../utile/slice/boardSlice';
import { resetResultCode } from '../../../../utile/slice/postBoardFormSlice';
import { actIsLoading } from '../../../../utile/slice/loadingSlice';
import PaginationList from '../../../common/pagination/BoardPagination';

function QnaList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const qnaList = useAppSelector((state) => state.board.customerList);
  const Page = useAppSelector((state) => state.board.paginationData);
  const { resultCode } = useAppSelector((state) => state.boardForm);
  const [pageNo, setPageNo] = useState(state ? state : 1);

  useEffect(() => {
    dispatch(getCustomerList(pageNo));
  }, [pageNo]);

  useEffect(() => {
    dispatch(resetResultCode());
  }, [resultCode, resetResultCode, dispatch]);

  const onChangePageNo = (pageNum) => {
    setPageNo(pageNum);
  };

  const onClickList = () => {
    dispatch(actIsLoading(true));
    setTimeout(() => {
      dispatch(actIsLoading(false));
    }, 2000);
  };

  const goToWritePage = () => {
    navigate('/customer/qna/0/write', { state: { mode: "insert", bbsSeq: 0 } });
  };

  return (
    <>
      <div className="inner">
        <ul className="list_wrap1">
        {qnaList.map((qna) => (
          <li key={qna.bbsSeq}>
            <Link
              to={'/customer/qna/' + qna.bbsSeq}
              state={{bbsSeq:qna.bbsSeq, password:''}}
              title="페이지 이동" className="list"
              onClick={onClickList}
            >
              <div className="tlt_wrap">
                <strong className="tlt">
                  {qna.bbsTitNm}
                </strong>
                <span className="date">{qna.createDate}</span>
              </div>
            </Link>
          </li>
        ))}
        </ul>
        <PaginationList paginationData={Page} currentNum={pageNo} setCurrentNum={onChangePageNo} />
        <div className='btn_layout right'>
            <button className="btn_primary_m" onClick={goToWritePage}><span>글쓰기</span></button>
        </div>
      </div>
    </>
  );
}

export default QnaList;