import React, { useEffect, useState } from 'react';
// import ScaleLoader from 'react-spinners/ScaleLoader';

const Loading = () => {
  // todo pageloader
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        left: '0',
        right: '0',
        top: '80px',
        bottom: '200px',
      }}
    >
      <Loader />
    </div>
  );
};

function Loader() {
  // const [isLoaded, setIsLoaded] = useState(false);
  // useEffect(() => {
  //   setIsLoaded(true);
  // }, []);

  return (
    <div className="spinner1212">
      <div className="rect1"></div>
      <div className="rect2"></div>
      <div className="rect3"></div>
      <div className="rect4"></div>
      <div className="rect5"></div>
    </div>
  );
  // return <div className="after-loader" data-load={isLoaded}></div>;
}

export default Loading;
