import React from 'react';
import RelationPage from '../../../common/RelationPage';
import Visual from '../../../common/Visual';

function Subven() {
  return (
    <div className="subven">
      <Visual tlt="구매보조금" sub={'국비, 지방자치단체 전기차 지원 \n 보조금이 궁금하신가요?'} />
      <div className="inner">
        <div className="cont">
          <div className="tlt_wrap">
            <strong className="tlt">구매보조금 현황 안내</strong>
            <div className="desc">
              저공해차 통합 누리집 홈페이지에서 구매보조금 지원금액 및 지급현황을 확인하실 수
              있습니다.
              <div className="btn_wrap">
                <a
                  href="https://www.ev.or.kr/portal/buyersGuide/incenTive"
                  target="_blank"
                  title="페이지이동"
                  className="btn_text"
                  rel="noreferrer"
                >
                  보조금 지원금액 바로가기
                  <i title="페이지이동" className="ico_move"></i>
                </a>
                <a
                  href="https://www.ev.or.kr/portal/localInfo"
                  target="_blank"
                  title="페이지이동"
                  className="btn_text"
                  rel="noreferrer"
                >
                  보조금 지급현황 바로가기
                  <i title="페이지이동" className="ico_move"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="contain_subven">
          <h4 className="tit">T4K 전기트럭 구매 보조금</h4>

          <div className="box_diagram">
            <div className="diagram_item">
              국비
              <p className="point_txt">
                최대 <strong>1200</strong> 만원
              </p>
            </div>
            <i className="ico_diagram_plus"> </i>
            <div className="diagram_item">
              지방자치단체
              <p className="point_txt">
                최대 <strong>400</strong> 만원
              </p>
            </div>
            <i className="ico_diagram_equal"> </i>
            <div className="diagram_item">
              <div className="item_txt">
                총 최대
                <br /> <strong>1,600</strong> 만원 지원
              </div>
              <p className="point_txt">(서울시 기준)</p>
            </div>
          </div>
        </div>

        <div className="contain_subven">
          <h4 className="tit">2023년도 지자체별 구매 보조금</h4>
          <div className="table_wrap">
            <div className="table_scroll_wrap">
              <table className="txt_center">
                <colgroup>
                  <col width="25%" />
                  <col width="25%" />
                  <col width="16.6%" />
                  <col width="16.6%" />
                  <col width="16.6%" />
                </colgroup>
                <caption className="blind">2022년도 전기차 지자체 차종별 보조금 표</caption>
                <thead>
                  <tr>
                    <th rowSpan={2}>시/도</th>
                    <th rowSpan={2}>지역구분</th>
                    <th colSpan={3}>T4K 구매 보조금/만원</th>
                  </tr>
                  <tr>
                    <th>국비</th>
                    <th>지자체</th>
                    <th>합계</th>
                  </tr>
                </thead>
                <tbody className="txt_center">
                  <tr>
                    <td>서울</td>
                    <td>서울특별시</td>
                    <td>1,200</td>
                    <td>400</td>
                    <td>1,600</td>
                  </tr>
                  <tr>
                    <td>부산</td>
                    <td>부산광역시</td>
                    <td>1,200</td>
                    <td>400</td>
                    <td>1,600</td>
                  </tr>
                  <tr>
                    <td>대구</td>
                    <td>대구광역시</td>
                    <td>1,200</td>
                    <td>400</td>
                    <td>1,600</td>
                  </tr>
                  <tr>
                    <td>인천</td>
                    <td>인천광역시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>광주</td>
                    <td>광주광역시</td>
                    <td>1,200</td>
                    <td>350</td>
                    <td>1,550</td>
                  </tr>
                  <tr>
                    <td>대전</td>
                    <td>대전광역시</td>
                    <td>1,200</td>
                    <td>450</td>
                    <td>1,650</td>
                  </tr>
                  <tr>
                    <td>울산</td>
                    <td>울산광역시</td>
                    <td>1,200</td>
                    <td>360</td>
                    <td>1,560</td>
                  </tr>
                  <tr>
                    <td>세종</td>
                    <td>세종특별자치시</td>
                    <td>1,200</td>
                    <td>400</td>
                    <td>1,600</td>
                  </tr>

                  <tr>
                    <td>경기</td>
                    <td>수원시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>성남시</td>
                    <td>1,200</td>
                    <td>690</td>
                    <td>1,890</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>의정부시</td>
                    <td>1,200</td>
                    <td>500</td>
                    <td>1,700</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>안양시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>부천시</td>
                    <td>1,200</td>
                    <td>550</td>
                    <td>1,750</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>광명시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>평택시</td>
                    <td>1,200</td>
                    <td>830</td>
                    <td>2,030</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>동두천시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>안산시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>고양시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>과천시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>구리시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>남양주시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>오산시</td>
                    <td>1,200</td>
                    <td>800</td>
                    <td>2,000</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>시흥시</td>
                    <td>1,200</td>
                    <td>500</td>
                    <td>1,700</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>군포시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>의왕시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>하남시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>용인시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>파주시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>이천시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>안성시</td>
                    <td>1,200</td>
                    <td>800</td>
                    <td>2,000</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>김포시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>화성시</td>
                    <td>1,200</td>
                    <td>800</td>
                    <td>2,000</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>광주시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>양주시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>포천시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>여주시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>연천군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>가평군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>경기</td>
                    <td>양평군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>

                  <tr>
                    <td>강원</td>
                    <td>춘천시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>원주시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>강릉시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>동해시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>태백시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>속초시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>삼척시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>홍천군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>횡성군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>영월군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>평창군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>정선군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>철원군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>화천군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>양구군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>인제군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>고성군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>강원</td>
                    <td>양양군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>

                  <tr>
                    <td>충북</td>
                    <td>청주시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>충북</td>
                    <td>충주시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>충북</td>
                    <td>제천시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>충북</td>
                    <td>보은군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>충북</td>
                    <td>옥천군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>충북</td>
                    <td>영동군</td>
                    <td>1,200</td>
                    <td>800</td>
                    <td>2,000</td>
                  </tr>
                  <tr>
                    <td>충북</td>
                    <td>증평군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>충북</td>
                    <td>진천군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>충북</td>
                    <td>괴산군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>충북</td>
                    <td>음성군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>충북</td>
                    <td>단양군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>

                  <tr>
                    <td>충남</td>
                    <td>천안시</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>
                  <tr>
                    <td>충남</td>
                    <td>공주시</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>
                  <tr>
                    <td>충남</td>
                    <td>보령시</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>
                  <tr>
                    <td>충남</td>
                    <td>아산시</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>
                  <tr>
                    <td>충남</td>
                    <td>서산시</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>
                  <tr>
                    <td>충남</td>
                    <td>논산시</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>
                  <tr>
                    <td>충남</td>
                    <td>계룡시</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>
                  <tr>
                    <td>충남</td>
                    <td>당진시</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>
                  <tr>
                    <td>충남</td>
                    <td>금산군</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>
                  <tr>
                    <td>충남</td>
                    <td>부여군</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>
                  <tr>
                    <td>충남</td>
                    <td>서천군</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>
                  <tr>
                    <td>충남</td>
                    <td>청양군</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>
                  <tr>
                    <td>충남</td>
                    <td>홍성군</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>
                  <tr>
                    <td>충남</td>
                    <td>예산군</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>
                  <tr>
                    <td>충남</td>
                    <td>태안군</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>2,100</td>
                  </tr>

                  <tr>
                    <td>전북</td>
                    <td>전주시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>전북</td>
                    <td>군산시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>전북</td>
                    <td>익산시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>전북</td>
                    <td>정읍시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>전북</td>
                    <td>남원시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>전북</td>
                    <td>김제시</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>전북</td>
                    <td>완주군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>전북</td>
                    <td>진안군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>전북</td>
                    <td>무주군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>전북</td>
                    <td>장수군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>전북</td>
                    <td>임실군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>전북</td>
                    <td>순창군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>전북</td>
                    <td>고창군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>전북</td>
                    <td>부안군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>

                  <tr>
                    <td>전남</td>
                    <td>목포시</td>
                    <td>1,200</td>
                    <td>750</td>
                    <td>1,950</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>여수시</td>
                    <td>1,200</td>
                    <td>529</td>
                    <td>1,729</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>순천시</td>
                    <td>1,200</td>
                    <td>650</td>
                    <td>1,850</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>나주시</td>
                    <td>1,200</td>
                    <td>550</td>
                    <td>1,750</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>광양시</td>
                    <td>1,200</td>
                    <td>850</td>
                    <td>2,050</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>담양군</td>
                    <td>1,200</td>
                    <td>750</td>
                    <td>1,950</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>곡성군</td>
                    <td>1,200</td>
                    <td>950</td>
                    <td>2,150</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>구례군</td>
                    <td>1,200</td>
                    <td>750</td>
                    <td>1,950</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>고흥군</td>
                    <td>1,200</td>
                    <td>750</td>
                    <td>1,950</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>보성군</td>
                    <td>1,200</td>
                    <td>850</td>
                    <td>2,050</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>화순군</td>
                    <td>1,200</td>
                    <td>750</td>
                    <td>1,950</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>장흥군</td>
                    <td>1,200</td>
                    <td>750</td>
                    <td>1,950</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>강진군</td>
                    <td>1,200</td>
                    <td>750</td>
                    <td>1,950</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>해남군</td>
                    <td>1,200</td>
                    <td>850</td>
                    <td>2,050</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>영암군</td>
                    <td>1,200</td>
                    <td>850</td>
                    <td>2,050</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>무안군</td>
                    <td>1,200</td>
                    <td>750</td>
                    <td>1,950</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>함평군</td>
                    <td>1,200</td>
                    <td>750</td>
                    <td>1,950</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>영광군</td>
                    <td>1,200</td>
                    <td>750</td>
                    <td>1,950</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>장성군</td>
                    <td>1,200</td>
                    <td>750</td>
                    <td>1,950</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>완도군</td>
                    <td>1,200</td>
                    <td>750</td>
                    <td>1,950</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>진도군</td>
                    <td>1,200</td>
                    <td>750</td>
                    <td>1,950</td>
                  </tr>
                  <tr>
                    <td>전남</td>
                    <td>신안군</td>
                    <td>1,200</td>
                    <td>750</td>
                    <td>1,950</td>
                  </tr>

                  <tr>
                    <td>경북</td>
                    <td>포항시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>경주시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>김천시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>안동시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>구미시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>영주시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>영천시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>상주시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>문경시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>경산시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>군위군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>의성군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>청송군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>영양군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>영덕군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>청도군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>고령군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>성주군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>칠곡군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>예천군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>봉화군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>울진군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경북</td>
                    <td>울릉군</td>
                    <td>1,200</td>
                    <td>1,100</td>
                    <td>2,300</td>
                  </tr>

                  <tr>
                    <td>경남</td>
                    <td>창원시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>진주시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>통영시</td>
                    <td>1,200</td>
                    <td>900</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>사천시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>김해시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>밀양시</td>
                    <td>1,200</td>
                    <td>1,000</td>
                    <td>2,200</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>거제시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>양산시</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>의령군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>함안군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>창녕군</td>
                    <td>1,200</td>
                    <td>705</td>
                    <td>1,905</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>고성군</td>
                    <td>1,200</td>
                    <td>700</td>
                    <td>1,900</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>남해군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>하동군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>산청군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>함양군</td>
                    <td>1,200</td>
                    <td>600</td>
                    <td>1,800</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>거창군</td>
                    <td>1,200</td>
                    <td>1,150</td>
                    <td>2,350</td>
                  </tr>
                  <tr>
                    <td>경남</td>
                    <td>합천군</td>
                    <td>1,200</td>
                    <td>800</td>
                    <td>2,000</td>
                  </tr>
                  <tr>
                    <td>제주</td>
                    <td>제주특별자치도</td>
                    <td>1,200</td>
                    <td>500</td>
                    <td>1,700</td>
                  </tr>
                  <tr>
                    <td>공단</td>
                    <td>한국환경공단</td>
                    <td>1,200</td>
                    <td>0</td>
                    <td>1,200</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ul className="info">
              <li className="sub">※ 2023년 전기차 보급정책 기준 금액입니다.</li>
              <li className="sub">
                ※ 구매 보조금은 실시간 현황과 상이할 수 있으므로, 자세한 사항은 판매센터 또는 각
                지방자치단체 담당자에게 확인하시기 바랍니다.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <RelationPage />
    </div>
  );
}

export default Subven;
