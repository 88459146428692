import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../store';
import { actShowAPopUp } from '../slice/alartPopUpSlice';

import { actShowDepth1LPopUp } from '../slice/layerPopUpSlice';

const useTcoFormHook = () => {
  const dispatch = useAppDispatch();
  const { register, handleSubmit, setValue, getValues, reset } = useForm();

  const onCalculate = (Tcoform) => {
    dispatch(actShowDepth1LPopUp(Tcoform));
  };

  const onError = (error) => {
    const sortErr = {
      dieselFuelCost: error.dieselFuelCost?.message,
      combinedFuelEconomy: error.combinedFuelEconomy?.message,
      dailyDrivingDistance: error.dailyDrivingDistance?.message,
      numberOfTripsPerMonth: error.numberOfTripsPerMonth?.message,
      vehicleOperationPeriod: error.vehicleOperationPeriod?.message,
      electricVehicleChargingCost: error.electricVehicleChargingCost?.message,
    };

    const errorMessage = Object.values(sortErr).filter((v) => {
      return v;
    });

    if (errorMessage.length) {
      dispatch(
        actShowAPopUp({
          message: errorMessage[0],
          cntBtn: 1,
          img: '',
          btnName: {},
        }),
      );
    }
  };

  return {
    register,
    handleSubmit,
    onCalculate,
    onError,
    setValue,
    getValues,
    reset,
  };
};

export default useTcoFormHook;
