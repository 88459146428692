const overviewData = [
  {
    title: 'Blade Battery',
    message: `배터리와 운전자\n모두를 보호해요`,
    keywords: [
      '높은 배터리 지상고',
      '배터리 하부 프로텍터 설치',
      '화재 안전성이 뛰어난 블레이드 배터리',
    ],
    videoUrlmo: './media/main/mo/video_main_blade_battery.mp4',
    videoUrlta: './media/main/ta/video_main_blade_battery.mp4',
    videoUrlpc: './media/main/pc/video_main_blade_battery.mp4',
  },
  {
    title: 'V2L (Vehicle to Load)',
    message: '내 트럭의 전기로\n다양한 외부기기 사용이 가능해요',
    keywords: ['실외 V2L', '실내 V2L'],
    videoUrlmo: './media/main/mo/video_main_v2l_tech.mp4',
    videoUrlta: './media/main/ta/video_main_v2l_tech.mp4',
    videoUrlpc: './media/main/pc/video_main_v2l_tech.mp4',
  },
  {
    title: 'Premium Feature',
    message: '편의기능은 장거리 \n운전에도 피로를 덜어줘요',
    keywords: ['핸드폰 무선충전기', '운전석 통풍/열선 시트', '좌석 리클라이닝 & 전동식 럼버서포트'],
    videoUrlmo: './media/main/mo/video_main_v2l_220v.mp4',
    videoUrlta: './media/main/ta/video_main_v2l_220v.mp4',
    videoUrlpc: './media/main/pc/video_main_v2l_220v.mp4',
  },
];

const newsListMock = [
  {
    bbsSeq: 85,
    bbsTitNm: '中 최대 전기차업체 BYD, 한국시장에 1t 전기트럭 출시',
    bbsCont:
      '<p>(서울=연합뉴스) 임기창 기자 = 중국 최대 전기차 제조업체 비야디(BYD)가 전기버스에 이어 한국 시장에 1t(톤) 전기트럭을 선보인다.</p>\r\n\r\n<p>&nbsp;</p>\r\n\r\n<p>[GS글로벌 제공. 재판매 및 DB금지]</p>\r\n\r\n<p>BYD의 한국 공식 수업업체 GS글로벌[001250]은 내달 BYD의 1t 전기트럭 T4K를 출시한다고 15일 밝혔다.</p>\r\n\r\n<p>BYD는 배터리부터 파워트레인(엔진&middot;동력), 반도체까지 모두 직접 제조하는 기술력을 보유했고 지난해 1~3분기 세계 친환경차 판매 1위를 달성한 업체다. 2016년에는 아우디 총괄디자이너 출신 볼프강 예거를 영입해 디자인 수준 등 전기차 제조 기술 완성도를 꾸준히 높여 왔다.</p>\r\n\r\n<p>이번에 국내 출시되는 T4K는 82kWh 배터리를 탑재해 1회 충전에 환경부 공인 246㎞의 상온 주행거리 능력을 확보했다.</p>\r\n\r\n<p>&nbsp;</p>\r\n\r\n<p>GS글로벌 모빌리티 사업부 관계자는 "이번에 출시하는 T4K는 1t 트럭의 본질에 충실하기 위해 실용성, 내구성, 편의성에 초점을 맞춰 개발한 한국형 트럭"이라며 "전기버스에 이어 1t 전기트럭을 출시하며 상용 전기차 라인업을 구축해 보급에 적극적으로 나서겠다"고 말했다.</p>\r\n\r\n<p>T4K는 내달 사전예약을 거쳐 국내에 정식 출시될 예정이다.</p>\r\n\r\n<p>pulse@yna.co.kr</p>\r\n',
    fileFullPath: '/file/news/20230317/5a24a6b9-7c92-47cf-98e5-b7972da54df5.jpg',
    createDate: '2023.03.17',
  },
  {
    bbsSeq: 84,
    bbsTitNm: '中 전기차 BYD, 1톤 트럭 한국 상륙…수입사는 GS글로벌',
    bbsCont:
      '<p>4월 출시 예정&hellip;상온 246㎞ 주행거리 인증<br />\r\n<br />\r\nBYD 1톤 전기트럭 T4K 티저 이미지. 사진=GS글로벌 제공<br />\r\n[데일리한국 안효문 기자]&nbsp;GS글로벌은 BYD(비야디) 1톤 전기트럭을 한국에 공식 수입한다고 15일 밝혔다.<br />\r\n<br />\r\nGS글로벌은 BYD 공식 수입사(임포터)로 전기버스에 이어 1톤 전기트럭으로 국내 판매 라인업을 확장한다.<br />\r\n<br />\r\n국내 도입 차량은 1톤 전기트럭 티포케이(T4K)로, 82㎾h 용량의 배터리를 탑재해 환경부로부터 1회 충전 최장 주행가능거리 246㎞(상온 기준)를 인증 받았다. GS글로벌은 오는 4월 T4K 사전 예약에 이어 공식 출시할 예정이다.<br />\r\n<br />\r\n<br />\r\nBYD는 중국 최대 전기차 생산 브랜드로 손꼽힌다. 25년 이상의 배터리 생산, 15년 이상의 전기차 생산 노하우를 축적해 배터리부터 파워트레인과 전기차용 반도체까지 직접 제조하는 기술력을 보유했다. 이를 바탕으로 2022년 1~3분기 글로벌 친환경차 판매 1위를 차지하기도 했다.<br />\r\n<br />\r\nGS글로벌 모빌리티 사업부 관계자는 &ldquo;T4K는 1톤 트럭의 본질에 충실하기 위해 실용성, 내구성, 편의성에 초점을 맞춰 개발한 한국형 트럭&rdquo;이라며 &ldquo;전기버스에 이어 1톤 전기트럭을 출시하며 상용 전기차 라인업을 구축해 상용 전기차량 보급에 적극적으로 나서겠다&rdquo;고 전했다.<br />\r\n<br />\r\n한편, GS글로벌은 이날 공식 유튜브 채널을 개설하고 T4K의 티저 영상을 공개했다. 티저에서는 T4K의 실루엣과 전면 디자인 등을 확인할 수 있다.<br />\r\n<br />\r\n출처 : 한국아이닷컴(https://www.hankooki.com)</p>\r\n',
    fileFullPath: '/file/news/20230317/2bbf1ba0-96ab-43a3-9081-bdee7fd7857c.jpg',
    createDate: '2023.03.17',
  },
  {
    bbsSeq: 80,
    bbsTitNm: '미디어에서 만나는 T4K',
    bbsCont: '<p>뉴스 본문 내용입니다.</p>\r\n',
    fileFullPath: '/file/news/20230224/c8126d1a-02ca-44d4-8513-3599475defac.png',
    createDate: '2023.02.24',
  },
  {
    bbsSeq: 79,
    bbsTitNm: 'LFP 앞세운 中 배터리 돌풍…BYD, LG에너지 솔루션 눌렀다 ',
    bbsCont: '<p>뉴스 본문 내용입니다.</p>\r\n',
    fileFullPath: '/file/news/20230224/e307f304-38e1-48e2-b14f-f383f0076a99.png',
    createDate: '2023.02.24',
  },
  {
    bbsSeq: 74,
    bbsTitNm: '전기차 기업 BYD, GSG와 한국 진출 임박 사무소 열고 딜러사 물색 중',
    bbsCont: '<p>뉴스 본문 내용입니다.</p>\r\n',
    fileFullPath: '/file/news/20230224/e314dd3a-c5d9-4627-9591-7821c79bae01.png',
    createDate: '2023.02.22',
  },
];

const bbsList = [
  {
    bbsSeq: '68',
    bbsTitNm: '123',
    bbsCont: '<p>123</p>\n',
    createDate: '2023.02.20',
  },
  {
    bbsSeq: '65',
    bbsTitNm: '공지사항입니다.',
    bbsCont: '<p>공지사항 준비중 입니다</p>\n',
    createDate: '2023.02.19',
  },
];

const noticeListMock = [
  {
    bbsSeq: '83',
    bbsTitNm: '공지사항입니다2',
    bbsCont: '<p>공지사항입니다.2</p>\n',
    createDate: '2023.03.02',
  },
  {
    bbsSeq: '82',
    bbsTitNm: '공지사항입니다.',
    bbsCont: '<p>공지사항입니다.</p>\n',
    createDate: '2023.03.02',
  },
  {
    bbsSeq: '81',
    bbsTitNm: 'T4K 사전 예약 OPEN, 사전 예약시 웰컴기프트 증정',
    bbsCont:
      '<p>사전 예약시 웰컴기프트를 증정합니다. 자세한내용은 사전예약 페이지를 확인하세요!&nbsp;</p>\n',
    createDate: '2023.02.27',
  },
];

export { overviewData, newsListMock, bbsList, noticeListMock };
