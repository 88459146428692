import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { actShowAPopUp } from '../slice/alartPopUpSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import { postForm } from '../slice/postFormSlice';
import { actResetEstimateForm } from '../slice/tempFormSlice';
import { actShowVerify, actHideVerify } from '../slice/verifyMessageSlice';
import {
  actChContLPopUp,
  actCb1LPopUp,
  actCb2LPopUp,
  actCb3LPopUp,
  actShowDepth1LPopUp,
} from '../slice/layerPopUpSlice';
import { personalInfo, terms, marketing } from '../sendHtml';
import { autoHypen } from '../regExp';

const useVerifyMessageFormHook = (alartInfo = {}, reqUrl) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { resultCode } = useAppSelector((state) => state.form);

  const { estimateForm } = useAppSelector((state) => state.tempForm);

  const { register, handleSubmit, reset, setValue, getValues } = useForm();

  const onSubmit = (reservform) => {
    const { reserContactNum, reserNm, reserContactMail, sidoCd, sigunguCd, vehlNm, zipNum, basicAddr, dtlAddr } = reservform;

    const fommatNum = autoHypen(reserContactNum?.replace(/-/g, ''));

    const form =
      window.location.pathname !== '/buy/document/docreserv'
        ? 
        window.location.pathname !== '/drive/reservation'
          ? //사전예약,구매예약
          {
            reserContactNum: fommatNum,
            reserNm,
            sidoCd,
            sigunguCd,
            prsInfoCltAgrCont: personalInfo,
            prsInfo3cProAgrCont: terms,
            vehlNm: vehlNm ? vehlNm : 'T4K',
          }
          :
          { // 시승예약
            reserContactNum: fommatNum,
            reserNm,
            reserContactMail,
            zipNum,
            basicAddr,
            dtlAddr,
            prsInfoCltAgrCont: personalInfo,
            prsInfo3cProAgrCont: terms,
            prsInfoMktgAgrCont: marketing,
            vehlNm: vehlNm ? vehlNm : 'T4K',
          }
        : {
            //견적예약
            vehlNm: estimateForm.vehlNm,
            reserContactNum: fommatNum,
            reserNm,
            sidoCd,
            sigunguCd,
            prsInfoCltAgrCont: personalInfo,
            prsInfo3cProAgrCont: terms,
            mldNm: estimateForm.mldNm,
            trimNm: estimateForm.trimNm,
            colorNm: estimateForm.colorNm,
            packageNm: estimateForm.packageNm,
            mandInsurPrice: estimateForm.mandInsurPrice,
            subsidyPrice: estimateForm.subsidyPrice,
            subsidyAddrCdL: estimateForm.subsidyAddrCdL,
            totQuorPrice:
              estimateForm.totalPrice +
              estimateForm.consignmentPrice -
              estimateForm.subsidyPrice +
              estimateForm.mandInsurPrice,
            vehlPrice: estimateForm.totalPrice,
            packageDtlNm: estimateForm.packageDtlNm,
          };

    const formData = { form, reqUrl };

    dispatch(postForm(formData));
    onResult(alartInfo);
  };

  const onResult = (alartInfo) => {
    const { fail } = alartInfo;

    if (window.location.pathname === '/ad') {
      //사전예약폼전송결과
      if (resultCode) {
        reset();
        dispatch(actChContLPopUp(true));
      } else {
        dispatch(actShowAPopUp(fail));
        dispatch(actShowVerify(false));
      }
    } else {
      //구매예약폼전송결과
      //견적예약폼 전송결과
      if (resultCode) {
        reset();
        dispatch(actHideVerify());
        dispatch(actCb1LPopUp(''));
        dispatch(actCb2LPopUp(false));

        if (window.location.pathname === '/buy/reservation') {
          dispatch(actShowDepth1LPopUp('reservation'));
          dispatch(actResetEstimateForm({}));
        } else if (window.location.pathname === '/drive/reservation') {
          dispatch(actCb3LPopUp(false));
          dispatch(actShowDepth1LPopUp('reservation'));
          dispatch(actResetEstimateForm({}));
        } else {
          navigate('/buy/document/comp');
          return;
        }
      } else {
        dispatch(actShowAPopUp(fail));
        dispatch(actShowVerify(false));
      }
    }
  };

  const onError = (error) => {
    //requiredErr: APopUp 에러
    // stringErr : 문자열 빨간색 에러 메세지
    const requiredErr = Object.entries(error)
      .map((v) => {
        return v[1];
      })
      .filter((v) => {
        return v.type === 'required';
      });

    let stringErr = {};

    Object.entries(error).forEach((v) => {
      if (v[1].type === 'pattern') {
        stringErr[v[0]] = v[1].message;
      }
    });

    if (Object.keys(stringErr)) {
      dispatch(actShowVerify(stringErr));
    }

    if (requiredErr.length) {
      dispatch(
        actShowAPopUp({
          message: requiredErr[0].message,
          cntBtn: 1,
          img: '',
          btnName: {},
        }),
      );
    }
  };

  return { register, handleSubmit, onSubmit, onError, setValue, getValues, reset };
};

export default useVerifyMessageFormHook;
