import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReserBtn from '../common/ReserBtn';

function Footer() {
  const [activedMenu, setActivedMenu] = useState(null);

  const moSubOpen = (event) => {
    console.log(event.target.name, activedMenu);
    if (event.target.name === activedMenu) {
      setActivedMenu(null);
      return;
    }

    setActivedMenu(event.target.name);
  };

  const { pathname } = window.location;
  if (
    pathname === '/buy/estimate/model' ||
    pathname === '/buy/estimate/color' ||
    pathname === '/buy/estimate/option' ||
    pathname === '/buy/estimate/detail' ||
    pathname === '/buy/document/docreserv'
  )
    return null;

  return (
    <footer>
      <div className="inner">
        <div className="ft_top">
          <ul>
            <li data-active={activedMenu === '1'}>
              <strong className="ft_mu_tlt">
                브랜드
                <button
                  type="button"
                  className="mo_view ft_mu_more"
                  title="메뉴 열기"
                  name="1"
                  onClick={moSubOpen}
                ></button>
              </strong>
              <Link to="/brand/byd" title="BYD 소개">
                BYD 소개
              </Link>
              <Link to="/brand/gsg" title="GSG 소개">
                GSG 소개
              </Link>
            </li>
            <li data-active={activedMenu === '2'}>
              <strong className="ft_mu_tlt">
                모델
                <button
                  type="button"
                  className="mo_view ft_mu_more"
                  title="메뉴 열기"
                  name="2"
                  onClick={moSubOpen}
                ></button>
              </strong>
              <Link to="/model/introduce" title="T4K">
                T4K
              </Link>
              <Link to="/customer/gallery" title="T4K 특장">
                T4K 특장
              </Link>
              <Link to="/model/price" title="가격">
                가격
              </Link>
            </li>
            <li data-active={activedMenu === '3'}>
              <strong className="ft_mu_tlt">
                구매
                <button
                  type="button"
                  className="mo_view ft_mu_more"
                  title="메뉴 열기"
                  name="3"
                  onClick={moSubOpen}
                ></button>
              </strong>
              <Link to="/buy/reservation" title="구매상담 신청">
                구매상담 신청
              </Link>
              <Link to="/drive/reservation" title="시승 신청">
                시승 신청
              </Link>
              <Link to="/buy/estimate/select" title="견적">
                견적
              </Link>
              <Link to="/service/network" title="판매 네트워크">
                판매 네트워크
              </Link>
              <Link to="/buy/guide" title="구매가이드">
                구매가이드
              </Link>
              <Link to="/buy/tco" title="EV 절약계산기">
                EV 절약계산기
              </Link>
              <Link to="/buy/subven" title="구매보조금">
                구매보조금
              </Link>
            </li>
            <li data-active={activedMenu === '4'}>
              <strong className="ft_mu_tlt">
                서비스
                <button
                  type="button"
                  className="mo_view ft_mu_more"
                  title="메뉴 열기"
                  name="4"
                  onClick={moSubOpen}
                ></button>
              </strong>
              <Link to="/service/info" title="서비스 안내">
                서비스 안내
              </Link>
              <Link to="/service/network" title="서비스 네트워크">
                서비스 네트워크
              </Link>
              <Link to="/service/part" title="부품정보">
                부품정보
              </Link>
              <Link to="/service/guide" title="차량관리 가이드">
                차량관리 가이드
              </Link>
            </li>
            <li data-active={activedMenu === '5'}>
              <strong className="ft_mu_tlt">
                고객지원
                <button
                  type="button"
                  className="mo_view ft_mu_more"
                  title="메뉴 열기"
                  name="5"
                  onClick={moSubOpen}
                ></button>
              </strong>
              <Link to="/customer/notice" title="소식">
                소식
              </Link>
              <Link to="/customer/contact" title="문의하기">
                문의하기
              </Link>
              <Link to="/customer/community" title="커뮤니티">
                커뮤니티
              </Link>
              <Link to="/customer/condition" title="이달의 판매조건">
                이달의 판매조건
              </Link>
              <Link to="/customer/event" title="제휴이벤트">
                제휴이벤트
              </Link>
            </li>
          </ul>
          <div className="pc_view">
            <ReserBtn type="wh" />
          </div>
        </div>
        <div className="ft_btm">
          <div className="logo">
            <div className="ft_logo_gs">
              <span className="blind">gs clobal</span>
            </div>
            <div className="ft_logo_byd">
              <span className="blind">byd</span>
            </div>
          </div>
          <div class="companyInfo">
            <span>회사명: ㈜GS글로벌</span>
            <span>사업소재지: 06141 서울시 강남구 논현로 508 GS감남타워</span>            
            <span>고객센터: 1600-8251</span>
            <span>사업자등록번호: 202-81-33028</span>
            <span>통신판매업신고번호: 제2021-서울강남-01438호</span>
        </div>
          <div className="info">
            <Link to="/personal-info" className="link_b" title="개인정보처리방침">
              개인정보처리방침
            </Link>
            {/* <Link to="/terms" className="link_s" title="사이트 이용약관">
              사이트 이용약관
            </Link> */}
            <div className="link_s">GS Global &copy; 2023 All rights reserved</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
