import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ax from '../../api/ax';
import requests from '../../api/requests';

const initialState = {
  searchBoardList: [],
  paginationInfo: {},
  totalPageCount: 0,
  currentPageNo: 1,
  totalRecordCount: 0,
};

export const getPartsInfoList = createAsyncThunk(
  'searchBoard/getPartsInfoList',
  async (formData) => {
    const { form, currentPageNo } = formData;

    if (!form || Object.keys(form).length === 0) {
      //초기렌더링 or 전체 게시물 페이저클릭. (form:{} 상태)
      const res = await ax.get(requests.SERVICE_PART + '?currentPageNo=' + currentPageNo);
      let partInfoData = res.data.data;
      return partInfoData;
    }
    if (!formData.form.srchModel) {
      // 모델명 전체
      const { srchType, srchCont, srchModel } = form;
      const res =
        srchType === 'partName'
          ? await ax.get(
              requests.SERVICE_PART +
                '?currentPageNo=' +
                currentPageNo +
                '&srchMdlNm=' +
                srchModel +
                '&srchPartNmTxt=' +
                srchCont,
            )
          : await ax.get(
              requests.SERVICE_PART +
                '?currentPageNo=' +
                currentPageNo +
                '&srchMdlNm=' +
                srchModel +
                '&srchPartNumTxt=' +
                srchCont,
            );

      let partInfoData = res.data.data;
      return partInfoData;
    } else {
      // 모델명 선택
      const { srchType, srchCont, srchModel } = form;

      let res;

      if (!Object.values(form)[1]) {
        //모델명선택 + 페이저
        res = await ax.get(
          requests.SERVICE_PART + '?currentPageNo=' + currentPageNo + '&srchMdlNm=' + srchModel,
        );
      }

      if (srchType === 'partName') {
        //모델명선택 + 부품명써치 +페이저
        res = await ax.get(
          requests.SERVICE_PART +
            '?currentPageNo=' +
            currentPageNo +
            '&srchPartNmTxt=' +
            srchCont +
            '&srchMdlNm=' +
            srchModel,
        );
      }

      if (srchType === 'partNum') {
        //모델명선택 + 부품번호써치 + 페이저
        res = await ax.get(
          requests.SERVICE_PART +
            '?currentPageNo=' +
            currentPageNo +
            '&srchPartNumTxt=' +
            srchCont +
            '&srchMdlNm=' +
            srchModel,
        );
      }
      let data = res.data.data;
      return data;
    }
  },
);

const searchBoardSlice = createSlice({
  name: 'searchBoard',
  initialState,
  reducers: {
    actSetCurrentNo: (state, { payload }) => {
      state.currentPageNo = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPartsInfoList.pending, (state) => {
      state.searchBoardList = [];
      state.totalPageCount = 0;
      //state.currentPageNo = 0;
      state.totalRecordCount = 0;
    });

    builder.addCase(getPartsInfoList.fulfilled, (state, { payload }) => {
      state.searchBoardList = payload.partsInfoList;
      state.paginationInfo = payload.paginationInfo;
      state.totalPageCount = payload.paginationInfo.totalPageCount;
      //state.currentPageNo = payload.paginationInfo.currentPageNo;
      state.totalRecordCount = payload.paginationInfo.totalRecordCount;
    });
  },
});

export const {
  actSetCurrentNo,
} = searchBoardSlice.actions;

export default searchBoardSlice;
