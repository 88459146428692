import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../api/ax';
import requests from '../../api/requests';

const initialState = {
    noticeList: [],
    newsList: [],
    snsList: [],
    eventList: [],
    conditionList: [],
    reviewList: [],
    galleryList: [],
    modelGalleryList: [],
    customerList: [],
    mainVisualList: [],
    popupList: [],
    listFixed: [],
    paginationData: {},
    noticeDetail: [],
    newsDetail: [],
    snsDetail: [],
    eventDetail: [],
    conditionDetail: [],
    reviewDetail: [],
    galleryDetail: [],
    modelGalleryDetail: [],
    customerDetail: [],
    mainBanner: [],
    mainVisualDetail: [],
    resultCode: ''
};

// 공지사항
export const getNoticeList = createAsyncThunk('board/getNoticeList', async currentPageNo => {
    const res = await axios.get(requests.NOTICE + '?currentPageNo=' + currentPageNo);
    let noticeListData = res.data.data;
    return noticeListData;
});

export const getNoticeDetail = createAsyncThunk('board/getNoticeDetail', async seqNum => {
    const res = await axios.get(requests.NOTICE + '/' + seqNum);
    let noticeDetailData = res.data.data;
    return noticeDetailData;
});

export const getNoticeFixDetail = createAsyncThunk('board/getNoticeFixDetail', async seqNum => {
    const res = await axios.get(requests.NOTICE + '/' + seqNum + '?topFixYn=y');
    let noticeFixDetailData = res.data.data;
    return noticeFixDetailData;
});

// 뉴스
export const getNewsList = createAsyncThunk('board/getNewsList', async currentPageNo => {
    const res = await axios.get(requests.NEWS + '?currentPageNo=' + currentPageNo);
    let newsListData = res.data.data;
    return newsListData;
});

export const getNewsDetail = createAsyncThunk('board/getNewsDetail', async seqNum => {
    const res = await axios.get(requests.NEWS + '/' + seqNum);
    let newsDetailData = res.data.data;
    return newsDetailData;
});

// SNS
export const getSnsList = createAsyncThunk('board/getSnsList', async reqParams => {
    const res = await axios.get(requests.SNS + '?currentPageNo=' + reqParams.currentPageNo + "&cateCd=" + reqParams.cateCd);
    let snsListData = res.data.data;
    return snsListData;
});

export const getSnsDetail = createAsyncThunk('board/getSnsDetail', async seqNum => {
    const res = await axios.get(requests.SNS + '/' + seqNum);
    let snsDetailData = res.data.data;
    return snsDetailData;
});

// 출고 후기
export const getReviewList = createAsyncThunk('board/getReviewList', async currentPageNo => {
    const res = await axios.get(requests.REVIEW + '?currentPageNo=' + currentPageNo);
    let reviewListData = res.data.data;
    return reviewListData;
});

export const getReviewDetail = createAsyncThunk('board/getReviewDetail', async seqNum => {
    const res = await axios.get(requests.REVIEW + '/' + seqNum);
    let reviewDetailData = res.data.data;
    return reviewDetailData;
});

// 갤러리
export const getGalleryList = createAsyncThunk('board/getGalleryList', async currentPageNo => {
    const res = await axios.get(requests.GALLERY + '?currentPageNo=' + currentPageNo);
    let galleryListData = res.data.data;
    return galleryListData;
});

export const getGalleryDetail = createAsyncThunk('board/getGalleryDetail', async seqNum => {
    const res = await axios.get(requests.GALLERY + '/' + seqNum);
    let galleryDetailData = res.data.data;
    return galleryDetailData;
});

export const getCustomerList = createAsyncThunk('board/getCustomerList', async currentPageNo => {
    const res = await axios.get(requests.CUSTOMER + '?currentPageNo=' + currentPageNo);
    let customerListData = res.data.data;
    return customerListData;
});

export const getCustomerDetail = createAsyncThunk('board/getCustomerDetail', async reqParams => {
    var basicAuth = 'Basic ' + window.btoa(reqParams.bbsSeq+':'+reqParams.password);
    const res = await axios.get(requests.CUSTOMER + '/' + reqParams.bbsSeq, {
        headers: { 'Authorization': basicAuth }
    });
    let customerDetailData = res.data.data;
    return customerDetailData;
});

// T4K 특장
export const getModelGalleryList = createAsyncThunk('board/getModelGalleryList', async () => {
    const res = await axios.get(requests.MODEL_GALLERY);
    let modelGalleryListData = res.data.data;
    return modelGalleryListData;
});

export const getModelGalleryDetail = createAsyncThunk('board/getModelGalleryDetail', async seqNum => {
    const res = await axios.get(requests.MODEL_GALLERY + '/' + seqNum);
    let modelGalleryDetailData = res.data.data;
    return modelGalleryDetailData;
});

export const getMainBanner = createAsyncThunk('board/getMainBanner', async () => {
    const res = await axios.get(requests.MAIN_BANNER);
    let mainBannerData = res.data.data;
    return mainBannerData;
});

export const getMainVisualList = createAsyncThunk('board/getMainVisualList', async currentPageNo => {
    const res = await axios.get(requests.MAIN_VISUAL);
    let mainVisualListData = res.data.data;
    return mainVisualListData;
});

export const getMainVisualDetail = createAsyncThunk('board/getmainVisualDetail', async seqNum => {
    const res = await axios.get(requests.MAIN_VISUAL + '/' + seqNum);
    let mainVisualDetailData = res.data.data;
    return mainVisualDetailData;
});

export const getPopupList = createAsyncThunk('board/getPopupList', async () => {
    const res = await axios.get(requests.POPUP);
    let popupListData = res.data.data;
    return popupListData;
});

// 이벤트
export const getEventList = createAsyncThunk('board/getEventList', async reqParams => {
    const res = await axios.get(requests.EVENT + '?currentPageNo=' + reqParams.currentPageNo + '&onGoingEventYn=' + reqParams.tab);
    let eventListData = res.data.data;
    return eventListData;
});

export const getEventDetail = createAsyncThunk('board/getEventDetail', async seqNum => {
    const res = await axios.get(requests.EVENT + '/' + seqNum);
    let eventDetailData = res.data.data;
    return eventDetailData;
});

//이달의 판매조건
export const getConditionList = createAsyncThunk('board/getConditionList', async reqParams => {
    const res = await axios.get(requests.SALES_TERMS + '?currentPageNo=' + reqParams.currentPageNo + '&onGoingEventYn=' + reqParams.tab);
    let conditionListData = res.data.data;
    return conditionListData;
});

export const getConditionDetail = createAsyncThunk('board/getConditionDetail', async seqNum => {
    const res = await axios.get(requests.SALES_TERMS + '/' + seqNum);
    let conditionDetailData = res.data.data;
    return conditionDetailData;
});

const boardSlice = createSlice({
    name: 'board',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getNoticeList.fulfilled, (state, { payload }) => {
            state.listFixed = payload.topFixedNoticeList;
            state.noticeList = payload.noticeList;
            state.paginationData = payload.paginationInfo;
        });
        builder.addCase(getNoticeDetail.fulfilled, (state, { payload }) => {
            state.noticeDetail = payload.noticeApiDetailData;
        });
        builder.addCase(getNoticeFixDetail.fulfilled, (state, { payload }) => {
            state.noticeDetail = payload.noticeApiDetailData;
        });
        builder.addCase(getNewsList.fulfilled, (state, { payload }) => {
            state.newsList = payload.newsList;
            state.paginationData = payload.paginationInfo;
        });
        builder.addCase(getNewsDetail.fulfilled, (state, { payload }) => {
            state.newsDetail = payload.newsDetailApiData;
        });
        builder.addCase(getSnsList.fulfilled, (state, { payload }) => {
            state.snsList = payload.snsList;
            state.paginationData = payload.paginationInfo;
        });
        builder.addCase(getSnsDetail.fulfilled, (state, { payload }) => {
            state.snsDetail = payload.snsDetailApiData;
        });
        builder.addCase(getEventList.fulfilled, (state, { payload }) => {
            state.eventList = payload.eventList;
            state.paginationData = payload.paginationInfo;
        });
        builder.addCase(getEventDetail.fulfilled, (state, { payload }) => {
            state.eventDetail = payload.eventDetailInfo;
        });
        builder.addCase(getReviewList.fulfilled, (state, { payload }) => {
            state.reviewList = payload.reviewList;
            state.paginationData = payload.paginationInfo;
        });
        builder.addCase(getReviewDetail.fulfilled, (state, { payload }) => {
            state.reviewDetail = payload.reviewApiDetailData;
        });
        builder.addCase(getGalleryList.fulfilled, (state, { payload }) => {
            state.galleryList = payload.galleryList;
            state.paginationData = payload.paginationInfo;
        });
        builder.addCase(getGalleryDetail.fulfilled, (state, { payload }) => {
            state.galleryDetail = payload.galleryDetailApiData;
        });
        builder.addCase(getCustomerDetail.fulfilled, (state, { payload }) => {
            state.customerDetail = payload.customerApiDetailData;
            state.resultCode = payload.resultCode;
        });
        builder.addCase(getCustomerList.fulfilled, (state, { payload }) => {
            state.customerList = payload.customerList;
            state.paginationData = payload.paginationInfo;
        });
        builder.addCase(getConditionList.fulfilled, (state, { payload }) => {
            state.conditionList = payload.termsList;
            state.paginationData = payload.paginationInfo;
        });
        builder.addCase(getConditionDetail.fulfilled, (state, { payload }) => {
            state.conditionDetail = payload.termsDetailInfo;
        });
        builder.addCase(getModelGalleryList.fulfilled, (state, { payload }) => {
            state.modelGalleryList = payload.forteList;
            state.paginationData = payload.paginationInfo;
        });
        builder.addCase(getModelGalleryDetail.fulfilled, (state, { payload }) => {
            state.modelGalleryDetail = payload.modelGalleryDetailInfo;
        });
        builder.addCase(getMainBanner.fulfilled, (state, { payload }) => {
            state.mainBanner = payload.mainBannerDetailInfo;
        });
        builder.addCase(getMainVisualList.fulfilled, (state, { payload }) => {
            state.mainVisualList = payload.mainVisualList;
        });
        builder.addCase(getMainVisualDetail.fulfilled, (state, { payload }) => {
            state.mainVisualDetail = payload.mainVisualDetailInfo;
        });
        builder.addCase(getPopupList.fulfilled, (state, { payload }) => {
            state.popupList = payload.popupList;
        });
    },
});

export default boardSlice;