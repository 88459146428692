import React, { useLayoutEffect } from 'react';
import Input from './Input';
import RelationPage from '../../../common/RelationPage';
import ReservePopup from './ReservePopup';
import { useAppSelector } from '../../../../store';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

function Reservation() {
  const { depth1LPopUpName } = useAppSelector((state) => state.layerPopUp);

  const handleGsap = () => {
    const target = document.querySelectorAll('[data-trigger]');

    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 769px)': function () {
        target.forEach((el) => {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: el,
                start: 'bottom bottom',
                end: 'center 55%',
                // markers: true,
              },
            })
            .to(el, {
              duration: 1.4,
              opacity: 1,
              y: 0,
            });
        });
      },

      //   mobile
      '(max-width: 769px)': function () {
        target.forEach((el) => {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: el,
                start: 'center bottom',
                end: 'center 55%',
                // scrub: 1,
                // markers: true,
              },
            })
            .to(el, {
              duration: 0.8,
              opacity: 1,
              y: 0,
            });
        });
      },
    });
  };

  useLayoutEffect(() => {
    handleGsap();
  }, []);

  return (
    <div className="drive_reservation">
      <div className="visual img_bg" data-scale>
        <div className="inner">
          <div className="txt_wrap">
            <h2 className="tlt" data-trigger>
              시승 신청
            </h2>
            <p className="sub_tlt" data-trigger>
              앞으로의 전기트럭 표준이 될 <br /> T4K를 지금 만나보세요.
            </p>
          </div>
        </div>
      </div>
      <div className="contain-temppos">
        <div className="contain-reservation">
          <Input />
        </div>
      </div>
      {depth1LPopUpName === 'reservation' && <ReservePopup />}

      {/* <div className="cont_wrap">
        <div className="txt-slogun">
          앞으로의 전기트럭 표준이 될,
          <strong>T4K를 지금 만나보세요.</strong>
        </div>
        
      </div> */}

      {/* <div className="inner">
        <div className="visual">
          <h2 className="tlt">구매상담 신청</h2>
        </div>
        
      </div> */}
      <RelationPage />
    </div>
  );
}

export default Reservation;
