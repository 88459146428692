import React from 'react';
import { actCb1APopUp, actCb2APopUp } from '../../../../../utile/slice/alartPopUpSlice';
import { useAppDispatch } from '../../../../../store';

const ChAlartPopUp = (props) => {
  const dispatch = useAppDispatch();

  const closeAlartPopUp = (btn) => {
    if (btn === 'Y') {
      dispatch(actCb1APopUp(true));
    } else {
      dispatch(actCb2APopUp(true));
    }
    props.setIsChangeAlartPopUp(false);
  };

  return (
    <div className="AlartPopUp_wrap popup_wrap">
      <div className="dim"></div>
      {/* START : 기본 alert */}
      <div className="conf_inner">
        <div className="conf_header">
          {/* <button
            type="button"
            title="팝업 닫기"
            className="ico_22_pop_close"
            onClick={closeAlartPopUp}
          ></button> */}
        </div>
        <div className="conf_cont">
          <strong className="tlt">차종을 변경하시겠습니까?</strong>
          <p className="desc">현재까지의 견적내용은 저장되지 않습니다.</p>
        </div>
        <div className="conf_footer" style={{ marginTop: '0' }}>
          <div className="btn_layout">
            <button
              onClick={() => {
                closeAlartPopUp('N');
              }}
              type="button"
              className="btn_ghost_m"
            >
              <span>아니요</span>
            </button>
            <button
              ype="button"
              className="btn_primary_m"
              onClick={() => {
                closeAlartPopUp('Y');
              }}
            >
              <span>예</span>
            </button>
          </div>
        </div>
      </div>
      {/* END : 기본 alert */}
    </div>
  );
};

export default ChAlartPopUp;
