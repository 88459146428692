import React from 'react';

import reserveLogo from '../../../../../assets/images/ico/ico_logo_bk.svg';
import { useAppSelector } from '../../../../../store';
import { staticAutoComma } from '../../../../../utile/regExp';

import cargoWhImg from '../../../../../assets/images/img/img_model_type1_wh.png';
import cargoSbImg from '../../../../../assets/images/img/img_model_type1_sb.png';
import teugjangWhImg from '../../../../../assets/images/img/img_model_type2_wh.png';
import teugjangSbImg from '../../../../../assets/images/img/img_model_type2_sb.png';

const Reservdoc = () => {
  const { estimateForm } = useAppSelector((state) => state.tempForm);
  const regFeeTotal = useAppSelector((state) => state.regFee.regFeeTotal);
  const regTax = useAppSelector((state) => state.regFee.regTax); // 취득세
  const bondTax = useAppSelector((state) => state.regFee.bondTax); // 공채
  const localTax = useAppSelector((state) => state.regFee.localTax); // 증지대
  const plateFee = useAppSelector((state) => state.regFee.plateFee); // 차량번호판
  const charge = useAppSelector((state) => state.regFee.charge); // 대행

  let today = new Date();
  let year = today.getFullYear();
  let month = ('0' + (today.getMonth() + 1)).slice(-2);
  let day = ('0' + today.getDate()).slice(-2);

  return (
    <div className="reservdoc">
      <div className="page">
        <div className="inner">
          <div className="doc_content">
            <div className="doc_header">
              <h3 className="tit">
                <img src={reserveLogo} alt="logo" className="logo" />
              </h3>
              <p className="txt_date">
                견적일 : {year}년 {month}월 {day}일
              </p>
            </div>
            <div className="summary">
              <strong className="doc_tlt">견적서</strong>
              {estimateForm.type === 'T4K 카고' ? (
                <img
                  src={estimateForm.COLOR_TYPE === 'WHITE' ? cargoWhImg : cargoSbImg}
                  alt=""
                  className="model_img"
                />
              ) : (
                <img
                  src={estimateForm.COLOR_TYPE === 'WHITE' ? teugjangWhImg : teugjangSbImg}
                  alt=""
                  className="model_img"
                />
              )}
              <div className="total">
                <div className="model">
                  <p className="model_name">
                    <strong>{estimateForm.mldNm}</strong>
                    {estimateForm.trimNm}
                  </p>
                </div>
                <div className="price">
                  총 견적 합계
                  <strong>
                    {staticAutoComma(
                      estimateForm.totalPrice +
                        estimateForm.consignmentPrice -
                        estimateForm.subsidyPrice +
                        estimateForm.mandInsurPrice,
                    )}
                    원
                  </strong>
                </div>
              </div>
            </div>
            <div className="section_detail1">
              <p className="txt_tit">차량정보</p>
              <div className="list_option_wrap">
                <dl className="list_option">
                  <dt>모델</dt>
                  <dd
                    className="name"
                    dangerouslySetInnerHTML={{
                      __html: `${estimateForm.mldNm}<br/>${estimateForm.trimNm}`,
                    }}
                  />
                  <dd>
                    {/* <span className="condition1">(VAT 포함)</span>
                    <strong>{staticAutoComma(estimateForm.model_price)}원</strong> */}
                  </dd>
                </dl>
                <dl className="list_option">
                  <dt>컬러</dt>
                  <dd className="name">{estimateForm.colorNm}</dd>
                  <dd>{/* <strong>{staticAutoComma(estimateForm.color_price)}원</strong> */}</dd>
                </dl>
                <dl className="list_option">
                  <dt>옵션&패키지</dt>
                  <dd className="name">
                    {estimateForm.packageNm}
                    <ul>
                      {estimateForm.packageNm === '풀옵션 패키지'
                        ? estimateForm.package_data.optionPackageData?.map((option, i) => (
                            <li key={i}>{option.optionNm}</li>
                          ))
                        : estimateForm.option_package_checked_list?.map((option, i) => (
                            <li key={i}>{option.optionNm}</li>
                          ))}
                    </ul>
                  </dd>
                  <dd>
                    {/* <span className="condition2">패키지 할인 (-) 250,000원</span>
                    <strong>{staticAutoComma(estimateForm.primium_package_price - 250000)}</strong> */}
                  </dd>
                </dl>
              </div>
              <dl className="price">
                <dt>차량가격</dt>
                <dd>{staticAutoComma(estimateForm.totalPrice)}</dd>
              </dl>
            </div>
            <div className="doc_footer">
              <ul className="foot_list">
                {/* <li className="item">
                  <span className="tit">영업담당자</span> 고상진팀장
                </li> */}
                <li className="item">
                  <span className="tit">고객센터</span> 1600-8251
                </li>
                {/* <li className="item">
                  <span className="tit">이메일</span> sjko@gsgt4k.co.kr
                </li> */}
              </ul>
              <span className="txt_copy">GS Global &copy; 2023 All rights reserved</span>
            </div>
          </div>
          <div className="doc_content">
            {/* <div className="doc_header">
              <h3 className="tit">
                <img src={reserveLogo} alt="logo" className="logo" />
              </h3>
              <p className="txt_date">견적일 : 0000년 00월 00일</p>
            </div> */}
            <div className="section_detail2">
              <p className="txt_tit">구매금액</p>
              <div className="list_option_wrap">
                <dl className="list_option">
                  <dt>보조금 (국비 및 지방비)</dt>
                  <dd>
                    (-)
                    {staticAutoComma(estimateForm.subsidyPrice)}원
                  </dd>
                </dl>
                <dl className="list_option">
                  <dt>탁송</dt>
                  <dd>{staticAutoComma(estimateForm.consignmentPrice)}원</dd>
                </dl>
                {/* <dl className="list_option">
                  <dt>등록비용</dt>
                  <dd>{staticAutoComma(estimateForm.reg_fee_total)}원</dd>
                </dl> */}
                <dl className="list_option">
                  <dt>임시운행 의무보험료</dt>
                  <dd>{staticAutoComma(estimateForm.mandInsurPrice)}원</dd>
                </dl>
              </div>
              <dl className="price">
                <dt>총 견적 합계</dt>
                <dd>
                  {staticAutoComma(
                    estimateForm.totalPrice +
                      estimateForm.consignmentPrice -
                      estimateForm.subsidyPrice +
                      estimateForm.mandInsurPrice,
                  )}
                  원
                </dd>
              </dl>
            </div>

            <div className="section_detail3">
              <p className="txt_tit">예상비용</p>

              <div className="flex_box">
                <dl className="list_option head">
                  <dt>결제방법</dt>
                  <dd>할부</dd>
                </dl>
              </div>

              <div className="flex_box">
                <dl className="list_option head">
                  <dt>월 납입 금액</dt>
                  <dd>
                    월 {staticAutoComma(estimateForm?.monthly_payment?.totalMonthlyPayment)}원
                  </dd>
                </dl>
                <div className="list_option_wrap">
                  <dl className="list_option">
                    <dt>할부원금</dt>
                    <dd>{staticAutoComma(estimateForm?.installmentPrincipal)}원</dd>
                  </dl>
                  <dl className="list_option">
                    <dt>총 할부이자</dt>
                    <dd>
                      {staticAutoComma(
                        parseInt(estimateForm?.monthly_payment?.totalInstallmenInterest),
                      )}
                      원
                    </dd>
                  </dl>
                  <dl className="list_option">
                    <dt>할부 기간 / 금리</dt>
                    <dd>
                      {estimateForm?.monthly_payment?.installmentPeriod}개월/
                      {estimateForm?.monthly_payment?.interestRate}%
                    </dd>
                  </dl>
                </div>
              </div>

              <div className="flex_box">
                <dl className="list_option head">
                  <dt>출고 전 납입 금액</dt>
                  <dd>
                    {staticAutoComma(
                      estimateForm.totalPrice -
                        estimateForm.subsidyPrice +
                        estimateForm.consignmentPrice +
                        estimateForm.mandInsurPrice -
                        estimateForm?.installmentPrincipal,
                    )}
                    원
                  </dd>
                </dl>
                <div className="list_option_wrap">
                  <dl className="list_option">
                    <dt>총 차량 구매 금액</dt>
                    <dd>
                      {staticAutoComma(
                        estimateForm.totalPrice -
                          estimateForm.subsidyPrice +
                          estimateForm.consignmentPrice,
                      )}
                      원
                    </dd>
                  </dl>
                  <dl className="list_option">
                    <dt>임시운행 의무보험료</dt>
                    <dd>{staticAutoComma(estimateForm?.mandInsurPrice)}원</dd>
                  </dl>
                  <dl className="list_option">
                    <dt>할부 원금</dt>
                    <dd>(-) {staticAutoComma(estimateForm?.installmentPrincipal)}원</dd>
                  </dl>
                </div>
              </div>

              <div className="flex_box">
                <dl className="list_option head">
                  <dt>등록비용(별도납부)</dt>
                  <dd>{staticAutoComma(regFeeTotal)}원</dd>
                </dl>
                <div className="list_option_wrap">
                  <dl className="list_option">
                    <dt>취득세</dt>
                    <dd>{staticAutoComma(regTax)}원</dd>
                  </dl>
                  <dl className="list_option">
                    <dt>공채</dt>
                    <dd>{staticAutoComma(bondTax)}원</dd>
                  </dl>
                  <dl className="list_option">
                    <dt>증지대</dt>
                    <dd>{staticAutoComma(localTax)}원</dd>
                  </dl>
                  <dl className="list_option">
                    <dt>차량번호판</dt>
                    <dd>{staticAutoComma(plateFee)}원</dd>
                  </dl>
                  <dl className="list_option">
                    <dt>등록 대행 수수료</dt>
                    <dd>{staticAutoComma(charge)}원</dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className="box_information">
              <dl className="info">
                <dt>
                  <i alt="logo" className="ico_info" />
                  확인해 주세요.
                </dt>
                <dd>
                  &middot; 본 견적서는 고객님의 차량 구입(청약) 의사 결정에 도움을 드리고자 작성된
                  것으로 법적인 효력은 없으며, 계약을 원하실 경우 별도로 청약 의사 표시를 하셔야
                  합니다.
                </dd>
                <dd>
                  &middot; 실제 계약 내용은 고객께서 계약하실 시점의 판매조건이나 대출 조건에 따라
                  본 견적 내용과 달라질 수 있으며, 본 견적은 법적구속력이 없습니다.
                </dd>
                <dd>
                  &middot; 자동차 취등록세 및 부대비용, 등록비용은 고객님의 이해를 돕기 위한
                  부분으로 정확한 금액은 반드시 판매센터에 확인하시기 바랍니다.
                </dd>
                <dd>
                  &middot; 탁송료 및 공채할인률은 출고지, 등록일에 따라서 변경될 수 있습니다.{' '}
                </dd>
                <dd>
                  &middot; 등록대행 수수료는 당사에 등록을 의뢰하실 경우 발생되는 항목이며 의뢰지역,
                  의뢰방법에 따라 달라질 수 있습니다.
                </dd>
                <dd>
                  &middot; 공채 금액은 매일매일 변경되는 부분으로 자세한 내용은 판매센터로 문의
                  부탁드립니다.
                </dd>
              </dl>
            </div>

            <div className="doc_footer">
              <ul className="foot_list">
                {/* <li className="item">
                  <span className="tit">영업담당자</span> 고상진팀장
                </li> */}
                <li className="item">
                  <span className="tit">고객센터</span> 1600-8251
                </li>
                {/* <li className="item">
                  <span className="tit">이메일</span> sjko@gsgt4k.co.kr
                </li> */}
              </ul>
              <span className="txt_copy">GS Global &copy; 2023 All rights reserved</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Reservdoc);
