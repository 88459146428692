import React, { useState, useEffect } from 'react';
import ProgressBar from '../common/ProgressBar';
import { CircularProgressbar } from 'react-circular-progressbar';
import MyDesc from './MyDesc';
import MyOption from './MyOption';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { useNavigate } from 'react-router';
import AlartPopUp from '../../../../common/AlartPopUp';
import { actResetEstimateForm } from '../../../../../utile/slice/tempFormSlice';

function Application() {
  const [state, setState] = useState({
    complete: 0,
  });
  setTimeout(() => {
    const circle = document.querySelector('.circle');

    setState({ ...state, complete: 100 });
    circle.classList.add('finish');
  }, 300);

  // window.addEventListener('scroll', () => {
  //   const floating = document.querySelector('.my_option');
  //   const floatingDesc = document.querySelector('.my_desc');
  //   const floatingScroll = floating.offsetTop;

  //   if (window.innerWidth > 1024) {
  //     if (window.scrollY >= floatingDesc.offsetTop - 90) {
  //       floating?.classList.add('fixed');
  //     } else {
  //       floating?.classList.remove('fixed');
  //     }
  //   }
  // });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { estimateForm } = useAppSelector((state) => state.tempForm);
  const { isShowAPopUp, isEffectToParentsAPopUp } = useAppSelector((state) => state.alartPopUp);

  useEffect(() => {
    if (isEffectToParentsAPopUp) {
      navigate('/buy/estimate/select');
      dispatch(actResetEstimateForm({}));
    }
  }, [isEffectToParentsAPopUp]);

  return (
    <div className="estimate">
      <ProgressBar />
      {isShowAPopUp && <AlartPopUp />}
      <div className="inner complet">
        <div className="compl_msg">
          <div className="circle">
            <CircularProgressbar value={state.complete} maxValue={100} />
          </div>
          <strong className="tlt">견적이 완성되었습니다.</strong>
          <p className="sub_tlt">{`${estimateForm.mldNm}  
          ${estimateForm.trimNm}`}</p>
        </div>
        <div className="checkout-container">
          <MyDesc />
          <MyOption />
        </div>
      </div>
    </div>
  );
}

export default Application;
