import React from 'react';
import ReservePopup from '../../pages/advance/ReservePopup';
import { useAppDispatch, useAppSelector } from '../../../store';
import { actIsFixLPopUp } from '../../../utile/slice/layerPopUpSlice';

function ReserveBtn() {
  const dispatch = useAppDispatch();

  const openLayerPopUp = () => {
    dispatch(actIsFixLPopUp(true));
  };

  const { isFixLPopUp } = useAppSelector((state) => state.layerPopUp);

  return (
    <div className="btn_wrap">
      <button type="button" className="btn_radius" onClick={openLayerPopUp}>
        <span>사전예약 상담 신청</span>
      </button>
      {isFixLPopUp && <ReservePopup />}
    </div>
  );
}

export default ReserveBtn;
