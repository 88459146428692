import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../../api/ax';
import requests from '../../../../../api/requests';

const initialState = {
  cityList: [], // 시/도 리스트
  townList: [], // 시/군/구 리스트
  consignmentCharge: '0', // 탁송비
  selectSido: '', // 시/도
	selectSigungu: '' // 시/군/구
};


export const getCityList = createAsyncThunk('consignment/getCityList', async () => {
  const res = await axios.get(requests.CONSIGNMENT_CHARGE_LIST);
  return res.data.data;
});

export const getTownList = createAsyncThunk('consignment/getTownList', async (cateSeq) => {
  const res = await axios.get(requests.CONSIGNMENT_CHARGE_LIST + '?cateSeq=' + cateSeq);
  return res.data.data;
});

const consignmentSlice = createSlice({
  name: 'consignment',
  initialState,
  reducers: {
    actSetSido: (state, { payload }) => {
      state.selectSido = payload;
    },
    actSetSigungu: (state, { payload }) => {
      state.selectSigungu = payload;
    },
    actSetConsignmentCharge: (state, { payload }) => {
      state.consignmentCharge = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCityList.fulfilled, (state, { payload }) => {
      state.cityList = payload.cateList;
    });
    builder.addCase(getTownList.fulfilled, (state, { payload }) => {
      state.townList = payload.cateList;
    });
  },
});

export const { actSetSido, actSetSigungu, actSetConsignmentCharge } = consignmentSlice.actions;
export default consignmentSlice;
