import React, { useEffect, useState } from 'react';
import DaumPostcode from "react-daum-postcode";
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  actShowDepth1LPopUp,
  actCb1LPopUp,
  actCb2LPopUp,
  actCb3LPopUp,
} from '../../../../utile/slice/layerPopUpSlice';
import AlartPopUp from '../../../common/AlartPopUp';
import useVerifyMessageFormHook from '../../../../utile/hook/useVerifyMessageFormHook';
import requests from '../../../../api/requests';
import { autoHypen } from '../../../../utile/regExp';
import { actEffectToParentsAPopUp } from '../../../../utile/slice/alartPopUpSlice';

// 개인정보, 제3자동의 팝업
import PersonalInfoPop from '../../../common/popup/PersonalInfo';
import TermsPop from '../../../common/popup/Terms';
import MarketingPop from '../../../common/popup/Marketing';
import { ScrollLock, ScrollUnlock } from '../../../../utile/slice/layerPopUpSlice';

function Input() {
  const dispatch = useAppDispatch();

  //팝업창관련이벤트 시작//
  const alartInfo = {
    fail: {
      message: `시승 예약에 실패했습니다.<br>잠시 후 다시 시도해 주세요.`,
      cntBtn: 1,
    },
  };
  const { isShowAPopUp, isEffectToParentsAPopUp } = useAppSelector((state) => state.alartPopUp);
  const { isCb1LPopUp, isCb2LPopUp, isCb3LPopUp, depth1LPopUpName } = useAppSelector(
    (state) => state.layerPopUp,
  );
  const [syncIsCbAPopUp, setSyncIsCbAPopUp] = useState(isEffectToParentsAPopUp);
  useEffect(() => {
    setSyncIsCbAPopUp(true);
    if (syncIsCbAPopUp) {
      setSyncIsCbAPopUp(false);
      dispatch(actEffectToParentsAPopUp(false));
    }
  }, [isEffectToParentsAPopUp]);

  //팝업창관련이벤트 끝//

  //전화번호 하이픈 자동입력 이벤트시작//
  useEffect(() => {
    register('reserContactNum', {
      required: '신청 정보를 모두 입력해 주세요.',
      pattern: {
        value: /[0-9-]$/,
        message: '번호가 형식에 맞지 않습니다.',
      },
    });
    register('reserNm', {
      required: '신청 정보를 모두 입력해 주세요.',
      pattern: {
        value: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/,
        message: '이름이 형식에 맞지 않습니다.',
      },
    });
    register('reserContactMail', {
      required: '신청 정보를 모두 입력해 주세요.',
      pattern: {
        value: /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/,
        message: '이메일이 형식에 맞지 않습니다.',
      },
    });
    register('zipNum', {
      required: '신청 정보를 모두 입력해 주세요.',
    });
    register('basicAddr', {
      required: '신청 정보를 모두 입력해 주세요.',
    });
    register('dtlAddr', {
      required: '신청 정보를 모두 입력해 주세요.',
    });
  }, []);
  const [AddHyphen, setAddHyphen] = useState('');

  useEffect(() => {
    setValue('reserContactNum', AddHyphen);
  }, [AddHyphen]);

  //전화번호 하이픈 자동입력 이벤트 끝//

  // 예약자이름 onChange 이벤트 시작
  const [reserName, setReserName] = useState('');

  useEffect(() => {
    setValue('reserNm', reserName);
  }, [reserName]);

  //예약자이름 onChange 이벤트 끝

  // 예약자이메일 onChange 이벤트 시작

  const [reserContactMail, setReserContactMail] = useState('');

  useEffect(() => {
    setValue('reserContactMail', reserContactMail);
  }, [reserContactMail]);

  //예약자이메일 onChange 이벤트 끝

  //거주지역 이벤트 시작//
  const [zipNum, setZipNum] = useState('');
  const [basicAddr, setBasicAddr] = useState('');
  const [dtlAddr, setDtlAddr] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setValue('zipNum', zipNum);
  }, [zipNum]);
  useEffect(() => {
    setValue('basicAddr', basicAddr);
  }, [basicAddr]);
  useEffect(() => {
    setValue('dtlAddr', dtlAddr);
  }, [dtlAddr]);
  const completeHandler = (data) => {
    console.log(data);
    setZipNum(data.zonecode);
    setBasicAddr(data.roadAddress);
  };

  const closeHandler = (state) => {
    if (state === 'FORCE_CLOSE') {
      setIsOpen(false);
    } else if (state === 'COMPLETE_CLOSE') {
      setIsOpen(false);
    }
  };

  const toggleHandler = () => {
    if(isOpen){
      ScrollUnlock();
    }else {
      ScrollLock();
    }
    setIsOpen((prevOpenState) => !prevOpenState);
  };

  const inputChangeHandler = (event) => {
    setDtlAddr(event.target.value);
  };
  //거주지역 이벤트 끝//

  //폼관련 이벤트 시작//
  const { checkName, checkNumber, checkEmail } = useAppSelector((state) => state.verifyMessage);

  const { register, handleSubmit, onSubmit, onError, setValue, getValues, reset } =
    useVerifyMessageFormHook(alartInfo, requests.SEND_DRIVE_FORM);

  const { resultCode } = useAppSelector((state) => state.form);

  useEffect(() => {
    if (resultCode === true) {
      reset();
      setAddHyphen('');
      setReserName('');
      setReserContactMail('');
      setZipNum('');
      setBasicAddr('');
      setDtlAddr('');
      setIsOpen(false);
    } else return;
  }, [resultCode]);
  //폼관련 이벤트 끝//

  //약관이벤트 시작
  const onClickPersonalInfo = () => {
    let prsInfoCltAgrCont = getValues('prsInfoCltAgrCont');

    if (prsInfoCltAgrCont) {
      setValue('prsInfoCltAgrCont', false);
      dispatch(actCb1LPopUp('unChecked'));
    } else {
      dispatch(actCb1LPopUp('checked'));
      dispatch(actShowDepth1LPopUp('personal'));
    }
  };

  const onClickTerms = () => {
    let prsInfo3cProAgrCont = getValues('prsInfo3cProAgrCont');

    if (prsInfo3cProAgrCont) {
      setValue('prsInfo3cProAgrCont', false);
      dispatch(actCb2LPopUp('unChecked'));
    } else {
      dispatch(actCb2LPopUp('checked'));
      dispatch(actShowDepth1LPopUp('terms'));
    }
  };

  const onClickMarketing = () => {
    let prsInfoMktgAgrCont = getValues('prsInfoMktgAgrCont');

    if (prsInfoMktgAgrCont) {
      setValue('prsInfoMktgAgrCont', false);
      dispatch(actCb3LPopUp('unChecked'));
    } else {
      dispatch(actCb3LPopUp('checked'));
      dispatch(actShowDepth1LPopUp('marketing'));
    }
  };

  useEffect(() => {
    if (isCb1LPopUp === 'unChecked') {
      setValue('prsInfoCltAgrCont', false);
    } else if (isCb1LPopUp === 'checked') {
      setValue('prsInfoCltAgrCont', true);
    } else return;
  }, [isCb1LPopUp]);

  useEffect(() => {
    if (isCb2LPopUp === 'unChecked') {
      setValue('prsInfo3cProAgrCont', false);
    } else if (isCb2LPopUp === 'checked') {
      setValue('prsInfo3cProAgrCont', true);
    } else return;
  }, [isCb2LPopUp]);

  useEffect(() => {
    if (isCb3LPopUp === 'unChecked') {
      setValue('prsInfoMktgAgrCont', false);
    } else if (isCb3LPopUp === 'checked') {
      setValue('prsInfoMktgAgrCont', true);
    } else return;
  }, [isCb3LPopUp]);
  //약관이벤트 끝

  function inputFocus(input) {
    input.target.closest('.input_wrap').querySelector('.input_del').classList.add('focus');
  }
  function inputFocusOut(input) {
    setTimeout(() => {
      input.target.closest('.input_wrap').querySelector('.input_del').classList.remove('focus');
    }, 200);
  }
  function delTxt(input) {
    input.target.closest('.input_wrap').querySelector('input').value = '';
    input.target.closest('.input_wrap').querySelector('.input_del').classList.remove('focus');
  }

  return (
    <div className="reserv_input">
      {isShowAPopUp && <AlartPopUp />}
      {depth1LPopUpName === 'personal' && <PersonalInfoPop />}
      {depth1LPopUpName === 'terms' && <TermsPop />}
      {depth1LPopUpName === 'marketing' && <MarketingPop />}
      <form className="form" onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="form_wrap line_ty">
          <div className="input_wrap">
            <label htmlFor="input1" className="label2">
              이름
            </label>
            <input
              id="input1"
              name="input1"
              type="text"
              placeholder="이름을 입력하세요."
              className="input_txt"
              onFocus={inputFocus}
              onBlur={inputFocusOut}
              maxLength={50}
              value={reserName}
              onChange={(e) => {
                setReserName(e.target.value);
              }}
            ></input>
            <button type="button" title="내용 지우기" className="input_del" onClick={delTxt}>
              <i className="ico_18_del"></i>
            </button>
          </div>
          <span className="err_msg">{checkName}</span>
        </div>
        <div className="form_wrap line_ty">
          <div className="input_wrap">
            <label htmlFor="input1" className="label2">
              연락처
            </label>
            <input
              id="input1"
              name="input1"
              type="text"
              placeholder="010-1234-5678"
              className="input_txt"
              onFocus={inputFocus}
              onBlur={inputFocusOut}
              maxLength={13}
              value={autoHypen(AddHyphen)}
              onChange={(e) => {
                setAddHyphen(e.target.value);
              }}
            />
            <button type="button" title="내용 지우기" className="input_del" onClick={delTxt}>
              <i className="ico_18_del"></i>
            </button>
          </div>
          <span className="err_msg">{checkNumber}</span>
        </div>
        <div className="form_wrap line_ty">
          <div className="input_wrap">
            <label htmlFor="input1" className="label2">
              이메일
            </label>
            <input
              id="input1"
              name="input1"
              type="text"
              placeholder="이메일을 입력하세요."
              className="input_txt"
              onFocus={inputFocus}
              onBlur={inputFocusOut}
              value={reserContactMail}
              onChange={(e) => {
                setReserContactMail(e.target.value);
              }}
            ></input>
            <button type="button" title="내용 지우기" className="input_del" onClick={delTxt}>
              <i className="ico_18_del"></i>
            </button>
          </div>
          <span className="err_msg">{checkEmail}</span>
        </div>
        <div className="form_wrap line_ty">
          <div className="input_wrap noboard">
            <label htmlFor="input3" className="label2">
              거주지역
            </label>
            <input
              type="text"
              className="input_txt input_txt2"
              value={zipNum}
              disabled
            />
            <button
              type="button"
              className="button postBtn"
              onClick={toggleHandler}
            >주소찾기</button>
          </div>
        </div>
        {isOpen && (
        <div className="post_wrap popup_wrap">
          <div class="dim"></div>
          <div class="pop_l_inner"> 
          <div class="pop_header">
            <h2>우편번호검색</h2>
            <button type="button" title="팝업 닫기" class="ico_22_pop_close" onClick={toggleHandler}></button>            
          </div>        
          <DaumPostcode
            onComplete={completeHandler}
            onClose={closeHandler}
          />
          </div>
        </div>
      )}
        <div className="form_wrap line_ty addInput">
          <div className="input_wrap">
            <label className="label2"></label>
            <input
              type="text"
              className="input_txt"
              value={basicAddr}
              disabled
            />
          </div>
        </div>
        <div className="form_wrap line_ty">
          <div className="input_wrap">
          <label className="label2"></label>
            <input
              id="input3"
              name="input3"
              type="text"
              placeholder="상세주소를 입력하세요."
              className="input_txt"
              value={dtlAddr}
              onFocus={inputFocus}
              onBlur={inputFocusOut}
              onChange={inputChangeHandler}
            />
          </div>
        </div>
        <div className="input_checkbox_basic">
          <div className="box_checkbox">
            <input
              type="checkbox"
              id="agree"
              name="item1"
              checked={getValues('prsInfoCltAgrCont') && isCb1LPopUp === 'checked' ? 'checked' : ''}
              onChange={() => {
                return;
              }}
            />
            <label
              className="input_checkbox"
              {...register('prsInfoCltAgrCont', {
                required: '약관에 동의하셔야 시승 신청이 가능합니다.',
              })}
              onClick={onClickPersonalInfo}
            >
              개인정보 수집과 이용에 동의합니다.
            </label>
          </div>
        </div>    

        <div className="input_checkbox_basic">
          <div className="box_checkbox">
            <input
              type="checkbox"
              id="checkbox_basic_1"
              name="item1"
              checked={
                getValues('prsInfo3cProAgrCont') && isCb2LPopUp === 'checked' ? 'checked' : ''
              }
              onChange={() => {
                return;
              }}
            />
            <label
              className="input_checkbox"
              onClick={onClickTerms}
              {...register('prsInfo3cProAgrCont', {
                required: '약관에 동의하셔야 시승 신청이 가능합니다.',
              })}
            >
              개인정보 제3자 제공에 동의합니다.
            </label>
          </div>
        </div>
        <div className="input_checkbox_basic">
          <div className="box_checkbox">
            <input
              type="checkbox"
              id="agree"
              name="item1"
              checked={
                getValues('prsInfoMktgAgrCont') && isCb3LPopUp === 'checked' ? 'checked' : ''
              }
              onChange={() => {
                return;
              }}
            />
            <label
              className="input_checkbox"
              onClick={onClickMarketing}
            >
              마케팅 활용에 동의합니다.
            </label>
          </div>
        </div>
        <button className="btn_primary_l">
          <span>시승 신청</span>
        </button>
      </form>
    </div>
  );
}

export default React.memo(Input);
