import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import requests from '../../../../../../api/requests';
import { getReviewDetail } from '../../../../../../utile/slice/boardSlice';
import PaginationBoard from '../../../../../common/PaginationBoard';

function Board() {
  const navigate = useNavigate();
  const seqNum = useParams();

  const dispatch = useAppDispatch();

  const reviewDetail = useAppSelector(state => state.board.reviewDetail);
  
  useEffect(() => {
    dispatch(getReviewDetail(seqNum.bbsSeq));
  }, [seqNum]);

  const goDetail = (seq) => {
    navigate(`/customer/review/${seq}`);
  }

  const goList = () => {
    navigate('/customer/review', {state: reviewDetail.currentPageNo});
  }

  const goModify = (base64pw) => {
    navigate(`/customer/review/${reviewDetail.bbsSeq}/write`, {
      state: {
        bbsSeq :reviewDetail.bbsSeq, 
        password:base64pw, 
        mode :'modify'
      }});
  }

  const [toModify, setToModify] = useState(false);
  
  // 팝업을 닫는 함수
  const handleClosePopup = () => {
    setToModify(false); // toModify 상태를 false로 설정하여 팝업을 숨깁니다.
  };

  const setPassword = (event) => {
    const parentNode = event.target.parentNode;
    const input = parentNode.querySelector('[type=password]').value;
    if(input === ""){
      alert("비밀번호를 입력해주세요.");
      return false;
    }

    verifyPassword(input);
  }

  const verifyPassword = (input) => {
    fetch(`${requests.REVIEW}/${seqNum.bbsSeq}${requests.CHECK_PASSWORD}`, {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          bbsSeq: seqNum.bbsSeq,
          password: input
      })
    })
    .then(response => {
      if (response.ok) {
        return response;
      } else {
        throw Error(`Request rejected with status ${response.status}`);
      }
    })
    .then(res => res.json())
    .then(res => {
      if (res.data.sameYn) {
        if(toModify){
          goModify(input);
        }
      } else{
        alert("비밀번호가 일치하지 않습니다.");
      }
    })
    .catch(err => {
      alert("오류가 발생했습니다. 잠시후 다시 시도해주세요.");
      navigate('/customer/review');
    });
  }
  
  return (
    <>
    <div className="board" key={seqNum.bbsSeq}>
      <span className="date">{reviewDetail.createDate}</span>
      <strong className="tlt">{reviewDetail.bbsTitNm}</strong>
      <div className="cont">
          <p className="desc" dangerouslySetInnerHTML={{ __html: reviewDetail.bbsCont }} />
      </div>
      <PaginationBoard detailData={reviewDetail} goDetail={goDetail} goList={goList} />
      <div className='btn_layout right'>
        <button className="btn_primary_m" onClick={() => setToModify(true)}><span>수정하기</span></button>
      </div>
    </div>
      {toModify &&
        <div className="boardpop_wrap">
          <button type="button" title="팝업 닫기" class="ico_22_pop_close pwPopClose" onClick={handleClosePopup}></button>
          <div className="conf_inner">
            <div className="tlt">비밀번호 입력</div>
            <input type="password" className="input_wrap"/>
            <input type="button" className="btn_wrap" value="확인" onClick={setPassword}/>
          </div>
        </div>
      }
    </>
  );
}

export default Board;
