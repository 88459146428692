import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { staticAutoComma } from '../../../../../utile/regExp';
import { actAddInputEstimateForm } from '../../../../../utile/slice/tempFormSlice';
import {
  actSetConsignmentCharge,
  actSetSido,
  actSetSigungu,
  getCityList,
  getTownList,
} from './consignmentSlice';

function Consignment({ accOnOff }) {
  const dispatch = useAppDispatch();
  const cityList = useAppSelector((state) => state.consignment.cityList); // 시/도 리스트
  const townList = useAppSelector((state) => state.consignment.townList); // 시/군/구 리스트
  const consignmentCharge = useAppSelector((state) => state.consignment.consignmentCharge); // 탁송비
  const selectSido = useAppSelector((state) => state.consignment.citselectSidoyList); //  시/도
  const selectSigungu = useAppSelector((state) => state.consignment.selectSigungu); // 시/군/구

  const { estimateForm } = useAppSelector((state) => state.tempForm);

  useEffect(() => {
    dispatch(
      actAddInputEstimateForm({
        ...estimateForm,
        consignmentPrice: Number(consignmentCharge),
        consignmentAddrSeq: selectSigungu,
      }),
    );
  }, [consignmentCharge, selectSigungu]);

  useEffect(() => {
    dispatch(actSetSido(''));
    dispatch(actSetSigungu('s'));
    dispatch(actSetConsignmentCharge('0'));
    dispatch(getCityList());
    dispatch(
      actAddInputEstimateForm({
        ...estimateForm,
        consignmentPrice: 0,
        consignmentAddrSeq: '',
      }),
    );
    dispatch(getTownList('37'));
  }, []);

  useEffect(() => {
    if (townList.length > 0) {
      dispatch(actSetConsignmentCharge(townList[0].consignmentPrice));
    } else {
      dispatch(actSetConsignmentCharge('0'));
    }
  }, [townList]);

  const onChangeSelectSido = (e) => {
    let value = e.target.value;
    dispatch(actSetSido(value));
    dispatch(actSetSigungu(''));
    dispatch(getTownList(value));
    dispatch(actSetConsignmentCharge('0'));
  };

  const onChangeSelectSigungu = (e) => {
    let value = e.target.value;
    let dataVal = e.target[e.target.selectedIndex].dataset.price;
    dispatch(actSetSigungu(value));
    if (e.target.selectedIndex) {
      dispatch(actSetConsignmentCharge(dataVal));
    } else {
      dispatch(actSetConsignmentCharge(dataVal));
    }
  };

  return (
    <>
      <li className="section active">
        <div className="tlt_wrap" onClick={accOnOff}>
          <strong className="tlt">탁송</strong>
          <span className="price">
            <span className="num">{staticAutoComma(consignmentCharge)}</span>원
            <button type="button" title="항목 닫기">
              <i className="ico_32_accoup"></i>
            </button>
          </span>
        </div>
        <div className="sec_cont">
          <div className="form_wrap line_ty">
            <div className="input_wrap">
              <label htmlFor="input1" className="label">
                탁송지 선택
              </label>
              <div className="select_layout">
                <div className="select">
                  <select
                    id="selectSido"
                    name="selectSido"
                    className="input_select"
                    onChange={onChangeSelectSido}
                    value={selectSido}
                  >
                    {cityList.map((city) => (
                      <option value={city.cateSeq} key={city.cateSeq}>
                        {city.cateNm}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="select">
                  <select
                    id="selectSigungu"
                    name="selectSigungu"
                    className="input_select"
                    onChange={onChangeSelectSigungu}
                    value={selectSigungu}
                  >
                    {townList.map((city) => (
                      <option
                        value={city.cateSeq}
                        key={city.cateSeq}
                        data-price={city.consignmentPrice}
                      >
                        {city.cateNm}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <ul className="info">
            <li>
              - 급격한 유가 변동 대비하여 6개월 주기로 기적용 유가 대비 ±10% 유가 변동이 발생할
              경우, 배송비를 조정(인상/인하)하고 있습니다.
            </li>
            <li>- 견적 시 확인하신 탁송비와 결제 시 실제 탁송비가 달라질 수 있습니다.</li>
          </ul>
        </div>
      </li>
    </>
  );
}

export default Consignment;
