import React from 'react';
import { actCb1LPopUp, actHideDepth1LPopUp } from '../../../utile/slice/layerPopUpSlice';
import { useAppDispatch } from '../../../store';

function PersonalInfoPop() {
  const dispatch = useAppDispatch();

  const closeLayerPopUp = () => {
    dispatch(actHideDepth1LPopUp());
    dispatch(actCb1LPopUp('unChecked'));
  };

  const confirmAgree = () => {
    dispatch(actHideDepth1LPopUp());
    dispatch(actCb1LPopUp('checked'));
  };

  return (
    <div className="popup_wrap">
      <div className="dim"></div>
      <div className="pop_l_inner">
        <div className="pop_header">
          <strong
            className="tlt"
            style={{ display: 'block', margin: '20px 0 0 0', fontSize: '28px' }}
          >
            개인정보 수집/이용 동의
          </strong>
          <button
            type="button"
            title="팝업 닫기"
            className="ico_22_pop_close"
            onClick={closeLayerPopUp}
          ></button>
        </div>
        <div className="pop_cont replace_page">
          <div className="cont"></div>
          <b style={{ display: 'block', margin: '0 0 8px', fontWeight: 'bold' }}>
            1. 개인정보 수집 및 처리 목적
          </b>
          <p style={{ marginLeft: ' 14px' }}>구매상담 신청 확인 용도로 사용</p>

          <b style={{ display: 'block', margin: '20px 0 8px', fontWeight: 'bold' }}>
            2. 개인정보 수집 항목
          </b>
          <p style={{ marginLeft: ' 14px' }}>
            성명, 휴대전화 번호, 연락처, 거주지역 등 구매상담 안내 시 필요 항목
          </p>

          <b style={{ display: 'block', margin: '20px 0 8px', fontWeight: 'bold' }}>
            3. 개인정보 보유기간
          </b>
          <p style={{ marginLeft: ' 14px' }}>
            법령상 의무 보조 기간, 관련 목적 달성 또는 동의 철회 시 까지
          </p>

          <div style={{ marginTop: '44px' }}>
            <p style={{ fontWeight: 'bold' }}>
              ※ 개인정보 수집·이용 동의 시, 원활한 구매상담을 위하여 영업담당자가 연락을 드릴
              예정입니다.
            </p>
            <p style={{ margin: '4px 0', fontWeight: 'bold' }}>
              ※ 개인정보 수집·이용에 동의를 하지 않을 시, 구매상담 이용에 제한을 받을 수 있습니다.
            </p>
            <p style={{ fontWeight: 'bold' }}>
              ※ 세부 항목은 개인정보 처리방침 본문을 확인하시기 바랍니다.
            </p>
          </div>
          {/* <div className="cont">
            <strong className="tlt">2. 개인정보의 수집 및 이용목적, 수집하는 개인정보 항목</strong>
            <p className="sm_tlt">
              회사는 귀하의 문의에 대한 정확한 답변, 입사지원 및 임직원 정보 관리 등의 업무처리를
              위하여 다음과 같이 개인정보를 수집하고 있습니다.
            </p>
            <dl className="list_dl3">
              <dt>(1) 수집 및 이용목적</dt>
              <dd>
                회사는 귀하의 개인정보를 본인확인, 채용전형, 제휴문의 및 업무연락, 윤리경영 제보,
                근로계약 체결(채용) 및 고용관계 유지 등을 위한 목적으로 사용합니다.
              </dd>
              <dt>(2) 수집하는 개인정보 항목</dt>
              <dd>
                - 개인식별정보 : 성명, 사진, 이메일, 직업, 자택 또는 직장 주소 및 연락처, 핸드폰
                번호, IP, 쿠키 등
              </dd>
              <dd>- 고유식별정보 : 주민등록번호, 외국인등록번호, 여권번호 등</dd>
              <dd>- 민감정보 : 병력사항, 건강정보</dd>
              <dd>
                - 선택 및 기타 정보 : 가족사항, 결혼여부, 외국어 능력, 인턴경험, 학력사항, 경력 및
                자격사항, 병역사항, 보훈대상여부 등
              </dd>
              <dt>(3) 개인정보 수집방법</dt>
              <dd>
                - 개인식별정보 : 성명, 사진, 이메일, 직업, 자택 또는 직장 주소 및 연락처, 핸드폰
                번호, IP, 쿠키 등
              </dd>
              <dd>- 고유식별정보 : 주민등록번호, 외국인등록번호, 여권번호 등</dd>
              <dd>- 민감정보 : 병력사항, 건강정보</dd>
              <dd>
                - 선택 및 기타 정보 : 가족사항, 결혼여부, 외국어 능력, 인턴경험, 학력사항, 경력 및
                자격사항, 병역사항, 보훈대상여부 등
              </dd>
            </dl>
          </div> */}
        </div>
        <div className="pop_footer">
          <div className="btn_layout">
            <button type="button" className="btn_primary_m" onClick={confirmAgree}>
              <span>동의하기</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(PersonalInfoPop);
