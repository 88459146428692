import React from 'react';

const AlartPopUp = (props) => {
  const closeAlartPopUp = () => {
    props.setOpenAlartPopUp(false);
  };

  return (
    <div className="AlartPopUp_wrap popup_wrap">
      <div className="dim"></div>
      {/* START : 기본 alert */}
      <div className="conf_inner">
        <div className="conf_header">
          {/* <button
            type="button"
            title="팝업 닫기"
            className="ico_22_pop_close"
            onClick={closeAlartPopUp}
          ></button> */}
        </div>
        <div className="conf_cont">
          <strong className="tlt">풀옵션 패키지를 선택해 주세요.</strong>
          <p className="desc">
            2023년은 제조사의 사정으로
            <br /> '풀옵션 패키지'만 선택이 가능합니다.
          </p>
        </div>
        <div className="conf_footer">
          <button onClick={closeAlartPopUp} type="button" className="btn_primary_m">
            <span>확인</span>
          </button>
        </div>
      </div>
      {/* END : 기본 alert */}
    </div>
  );
};

export default React.memo(AlartPopUp);
