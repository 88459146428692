import React from 'react';

function SnsBtn(props) {
    const youtubeLink = "https://www.youtube.com/@bydetruck";
    const blogLink = "https://blog.naver.com/gsgev";
    const instagramLink = "https://www.instagram.com/bydetruck_official/";
    const kakaoplusLink = "https://pf.kakao.com/_gSNcxj";
    return (
        <>
            <a
                href={youtubeLink}
                target="_blank"
                className={props.type === 'wh' ? 'wh' : 'youtube snsIcon'}
                rel="noreferrer"
            >유튜브</a>            
            <a
                href={instagramLink}
                target="_blank"
                className={props.type === 'wh' ? 'wh' : 'insta snsIcon'}
                rel="noreferrer"
            >인스타그램</a>
            <a
                href={blogLink}
                target="_blank"
                className={props.type === 'wh' ? 'wh' : 'blog snsIcon'}
                rel="noreferrer"
            >블로그</a>
            <a
                href={kakaoplusLink}
                target="_blank"
                className={props.type === 'wh' ? 'wh' : 'kakao snsIcon'}
                rel="noreferrer"
            >카카오</a>
        </>
    );
}

export default SnsBtn;
