import React from 'react';

// 이미지 : 구매예약완료
// import CarComplete from '../../../assets/images/ico/ico_car_complete.png';
import MainPop1 from '../../../assets/images/img/img_main_pop1.png';
import MainPop2 from '../../../assets/images/img/img_main_pop_a.png';
import MainPop3 from '../../../assets/images/img/img_main_pop_b.png';
import MainPop4 from '../../../assets/images/img/img_main_pop_c.png';
//import MainPop2 from '../../../assets/images/img/img_main_pop2.png';
// import dummyImg2 from '../../../assets/images/img/mo_img_visual_guide.jpg';
// import dummyImg3 from '../../../assets/images/img/mo_img_visual_faq.jpg';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../store';
import { actHideDepth1LPopUp } from '../../../utile/slice/layerPopUpSlice';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { getPopupList } from 'utile/slice/boardSlice';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const NoticePopUp = () => {
  const dispatch = useAppDispatch();

  const [appCookies, setAppCookies] = useCookies();
  const popupList = useAppSelector((state) => state.board.popupList);

  const getExpiredDate = (days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    // 테스트용
    // date.setSeconds(date.getSeconds() + days);

    return date;
  };

  const closeLayerPopUp = () => dispatch(actHideDepth1LPopUp());

  const closeFor7days = () => {
    if (!appCookies.expire1DayNew) return;

    const expires = getExpiredDate(1);
    setAppCookies('expire1DayNew', true, { path: '/', expires });

    closeLayerPopUp();
  };

  useEffect(() => {
    dispatch(getPopupList());
  }, []);

  return (
    <div className="NoticePopUp_wrap popup_wrap">
      <div className="dim"></div>
      <div className="conf_inner notice">
        <Swiper
          navigation
          slidesPerView={1}
          scrollbar={{ draggable: true }}
          pagination={{ clickable: true }}
          autoplay={{ delay: 4500 }}
          loop={true}
          duration
        >
         {/* <SwiperSlide>
            <a href="/customer/event/120" className="conf_cont">
              <img src={MainPop2} alt="" />
            </a>
          </SwiperSlide>*/}
          {/* <SwiperSlide>
            <a href="/customer/event/138" className="conf_cont">
              <img src={MainPop2} alt="" />
            </a>
          </SwiperSlide> */}
          {/* <SwiperSlide>
            <a href="/customer/event/137" className="conf_cont">
              <img src={MainPop3} alt="" />
            </a>
          </SwiperSlide> */}
           {/*
          <SwiperSlide>
            <a href="/customer/event/136" className="conf_cont">
              <img src={MainPop4} alt="" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="/buy/reservation" className="conf_cont">
              <img src={MainPop1} alt="" />
            </a>
          </SwiperSlide>
          */}
          {/* <SwiperSlide>
            <div className="conf_cont">
              <img src={dummyImg3} alt="" />
            </div>
          </SwiperSlide> */}
          {popupList.map((popup, index) => (
            <SwiperSlide key={index}>
              <a href={popup.bbsBtnUrl} className="conf_cont">
                <img src={popup.fileFullPath} alt={popup.bbsTitNm} />
              </a>
            </SwiperSlide>
            ))}
        </Swiper>

        <div className="conf_footer">
          <div className="input_checkbox_basic">
            <div className="box_checkbox">
              <input type="checkbox" id="agree" value="" name="item1" onClick={closeFor7days} />
              <label htmlFor="agree" className="input_checkbox">
                오늘 하루 창 보지 않기
              </label>
            </div>
          </div>
          <button
            type="button"
            className="btn_txt_bk"
            onClick={closeLayerPopUp}
            style={{ marginLeft: 'auto' }}
          >
            <span>닫기</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(NoticePopUp);
