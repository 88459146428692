import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import consignmentSlice from './components/pages/buy/estimate/part4/consignmentSlice';
import planPriceSlice from './components/pages/buy/estimate/part4/planPriceSlice';
import regFeeSlice from './components/pages/buy/estimate/part4/regFeeSlice';
import subsidyPriceSlice from './components/pages/buy/estimate/part4/subsidyPriceSlice';
import mapCitySlice from './components/pages/service/network/mapCitySlice';
import mapSlice from './components/pages/service/network/mapSlice';
import repairTypeSlice from './components/pages/service/network/RepairTypeSlice';
import sortingSlice from './components/pages/service/network/sortingSlice';
import { scrollSlice } from './utile/ScrollToTop';
import alartPopUpSlice from './utile/slice/alartPopUpSlice';
import boardSlice from './utile/slice/boardSlice';
import citySlice from './utile/slice/citySlice';
import layerPopUpSlice from './utile/slice/layerPopUpSlice';
import loadingSlice from './utile/slice/loadingSlice';
import postFormSlice from './utile/slice/postFormSlice';
import postBoardFormSlice from './utile/slice/postBoardFormSlice';
import searchBoardSlice from './utile/slice/searchBoardSlice';
import tempFormSlice from './utile/slice/tempFormSlice';
import verifyMessageSlice from './utile/slice/verifyMessageSlice';

const rootReducer = combineReducers({
  alartPopUp: alartPopUpSlice.reducer,
  layerPopUp: layerPopUpSlice.reducer,
  loading: loadingSlice.reducer,
  scroll: scrollSlice.reducer,
  form: postFormSlice.reducer,
  tempForm: tempFormSlice.reducer,
  verifyMessage: verifyMessageSlice.reducer,
  city: citySlice.reducer,
  board: boardSlice.reducer,
  boardForm: postBoardFormSlice.reducer,
  sorting: sortingSlice.reducer,
  serviceNetwork: mapSlice.reducer,
  mapCity: mapCitySlice.reducer,
  repairType: repairTypeSlice.reducer,
  searchBoard: searchBoardSlice.reducer,
  regFee: regFeeSlice.reducer,
  subsidyPrice: subsidyPriceSlice.reducer,
  consignment: consignmentSlice.reducer,
  planPrice: planPriceSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  //middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
  // preloadedState: initialState,
  //enhancers: defaultEnhancers => [...defaultEnhancers],
});

export const useAppSelector = useSelector;
export const useAppDispatch = () => useDispatch();

export default store;
