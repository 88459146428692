import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getReviewList } from '../../../../../utile/slice/boardSlice';
import { resetResultCode } from '../../../../../utile/slice/postBoardFormSlice';
import { actIsLoading } from '../../../../../utile/slice/loadingSlice';
import PaginationList from '../../../../common/pagination/BoardPagination';

import EmptyImage from '../../../../../assets/images/list_empty.png';

function ReviewList({category}) {
  const THIS_CATE = "review";
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const reviewList = useAppSelector(state => state.board.reviewList);
  const Page = useAppSelector(state => state.board.paginationData);
  const { resultCode } = useAppSelector((state) => state.boardForm);
  const [pageNo, setPageNo] = useState(state ? state : 1);

  useEffect(() => {
    if(category===THIS_CATE){
      setPageNo(1);
      dispatch(getReviewList(1));
    }
  }, [category]);

  useEffect(() => {
    if(category===THIS_CATE){
      dispatch(getReviewList(pageNo));
    }
  }, [pageNo]);

  useEffect(() => {
    dispatch(resetResultCode());
  }, [resultCode, resetResultCode, dispatch]);

  const onChangePageNo = (pageNum) => {
    setPageNo(pageNum);
  }

  const onClickList = () => {
    dispatch(actIsLoading(true));
    setTimeout(() => {
      dispatch(actIsLoading(false));
    }, 2000);
  }

  return (
    <>
      <ul className="list_wrap1">
        {reviewList.map((review) => (
          <li key={review.bbsSeq}>
            <Link
              to={'/customer/review/' + review.bbsSeq}
              title="페이지 이동"
              className="list"
              onClick={onClickList}
            >
              <div className="tlt_wrap">
                <strong className="tlt">{review.bbsTitNm}</strong>
                <span className="date">{review.createDate}</span>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      <PaginationList paginationData={Page} currentNum={pageNo} setCurrentNum={onChangePageNo} />
      <div className='btn_layout right'>
        <Link
          to={'/customer/review/0/write'}
          state={{mode:"insert", bbsSeq:0}}
          title="페이지 이동" >
          <button className="btn_primary_m"><span>글쓰기</span></button>
        </Link>
      </div>
    </>
  );
}

export default ReviewList;
