import React from 'react';
import Page from './Board';
import PaginationBoard from '../../../../../common/PaginationBoard';

function NewsDetail() {
  return (
    <div className="news_detail inner">
      <Page />
      {/* <PaginationBoard /> */}
    </div>
  );
}

export default NewsDetail;
