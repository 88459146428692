import React from 'react';
import { actCb3LPopUp, actHideDepth1LPopUp } from '../../../utile/slice/layerPopUpSlice';
import { useAppDispatch } from '../../../store';

function MarketingPop() {
  const dispatch = useAppDispatch();

  const closeLayerPopUp = () => {
    dispatch(actHideDepth1LPopUp());
    dispatch(actCb3LPopUp('unChecked'));
  };

  const confirmAgree = () => {
    dispatch(actHideDepth1LPopUp());
    dispatch(actCb3LPopUp('checked'));
  };

  return (
    <div className="popup_wrap">
      <div className="dim"></div>
      <div className="pop_l_inner">
        <div className="pop_header">
          <strong
            className="tlt"
            style={{ display: 'block', margin: '20px 0 0 0', fontSize: '28px' }}
          >
            마케팅 활용 동의
          </strong>
          <button
            type="button"
            title="팝업 닫기"
            className="ico_22_pop_close"
            onClick={closeLayerPopUp}
          ></button>
        </div>
        <div className="pop_cont replace_page">
          <div className="privacy">

            <b style={{ display: 'block', margin: '0 0 8px', fontWeight: 'bold' }}>
              1. 상품및서비스안내등마케팅활용을위한개인정보수집·이용및정보수신에관한동의
            </b>
            <div className="table_wrap">
              <div className="table_scroll_wrap">
                <table summary='수집·이용항목, 목적, 보유기간'>
                  <thead>
                    <tr>
                      <th>수집·이용항목</th>
                      <th>수집·이용목적</th>
                      <th>보유기간</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>성명, 연락처(집전화번호/휴대폰번호), 이메일,주소</td>
                      <td>신규서비스(상품) 안내, 전자적전송매체를이용한영리목적의광고성정보전송,
                        우편(DM) 발송등마케팅활용, 시장조사, 이벤트안내
                      </td>
                      <td>법령상의무보존기간이없는한관련목적달성시까지</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div style={{ marginTop: '44px' }}>
              <p style={{ fontWeight: 'normal' }}>
                ※ 귀하는위동의를거부할수있으나, 거부시정보수신및이벤트참여등이제한됩니다.
              </p>
              <p style={{ fontWeight: 'noamal' }}>
                ※ SMS, 이메일, DM을통한신규서비스관련정보, 차량정기점검및이벤트안내등을위한개인정보수집∙이용및마케팅정보수신에동의합니다.       
              </p>
            </div>

          </div>
        </div>
        <div className="pop_footer">
          <div className="btn_layout">
            <button type="button" className="btn_primary_m" onClick={confirmAgree}>
              <span>동의하기</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(MarketingPop);
