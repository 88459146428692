import React from 'react';
import { useAppDispatch } from '../../.././../store';
import { actHideDepth1LPopUp, actCb1LPopUp } from '../../../../utile/slice/layerPopUpSlice';
import { staticAutoComma } from '../../../../utile/regExp';

function CalPopup({ tcoForm }) {
  const dispatch = useAppDispatch();

  // dailyDrivingDistance - 하루주행거리
  // numberOfTripsPerMonth - 한달 주행 횟수
  // vehicleOperationPeriod - 차량운행기간
  // combinedFuelEconomy - 보유차량복합연비
  // dieselFuelCost - 디젤주유비
  // electricVehicleChargingCost - 전기차 충전비

  // totalMileage - 총주행거리
  //StandardElectricVehicleFuelEconomy - 전기차 표준연비

  let totalMileage =
    tcoForm.dailyDrivingDistance *
    tcoForm.numberOfTripsPerMonth *
    tcoForm.vehicleOperationPeriod *
    12;

  let StandardElectricVehicleFuelEconomy = 2.6;

  let fuelCost = Math.round((totalMileage / tcoForm.combinedFuelEconomy) * tcoForm.dieselFuelCost);

  let EVChargingCost = Math.round(
    (totalMileage / StandardElectricVehicleFuelEconomy) * tcoForm.electricVehicleChargingCost,
  );

  let savings = fuelCost - EVChargingCost;

  const closeCalcPopUp = () => {
    dispatch(actHideDepth1LPopUp());
  };

  const resetTcoForm = () => {
    dispatch(actHideDepth1LPopUp());
    dispatch(actCb1LPopUp(true));
  };

  return (
    <div className="popup_wrap">
      <div className="dim"></div>
      <div className="pop_m_inner">
        <div className="pop_header">
          <strong className="tlt"></strong>
          <button
            type="button"
            title="팝업 닫기"
            className="ico_22_pop_close"
            onClick={closeCalcPopUp}
          ></button>
        </div>
        <div className="pop_cont calctco">
          <p className="text_infor">
            전기차 운행으로 {tcoForm.vehicleOperationPeriod}년 동안 <br />약
            <strong>{staticAutoComma(savings)}</strong>
            원을 절약할 수 있어요!
          </p>
          <div className="img_block"></div>
          <dl className="result_calc">
            <dt>
              {/* <i className="ico_24_res_won"></i> */}
              주유비
            </dt>
            <dd className="result_value">
              <span>약</span>
              {staticAutoComma(fuelCost)} 원
            </dd>
            <dd className="result_infor">
              <ul>
                <li>· 계산 공식 : (총 주행거리 ÷ 표준연비) X 1회 주유비</li>
              </ul>
            </dd>
          </dl>
          <dl className="result_calc">
            <dt>
              {/* <i className="ico_24_res_won"></i> */}
              전기차 충전비
            </dt>
            <dd className="result_value">
              <span>약</span>
              {staticAutoComma(Math.floor(EVChargingCost))} 원
            </dd>
            <dd className="result_infor">
              <ul>
                <li>· 계산 공식 : (총 주행거리 ÷ 표준연비) x 1회 충전비</li>
                <li>· T4K 복합 전비 기준 : 2.6km/kWh</li>
              </ul>
            </dd>
          </dl>
        </div>
        <div className="pop_footer">
          <button type="button" className="btn_primary_m" onClick={resetTcoForm}>
            <span>다시 계산하기</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CalPopup;
