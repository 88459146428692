import { useEffect } from 'react';

function PaginationList({paginationData, currentNum, setCurrentNum}) {

  const Page = paginationData;
  let firstPageNoOnPageList = Page.firstPageNoOnPageList;
  let lastPageNoOnPageList = Page.lastPageNoOnPageList;
  let firstPageNo = Page.firstPageNo;
  let lastPageNo = Page.lastPageNo;
  
  useEffect(() => {
  }, [currentNum]);

  const pageNumber = [];
  
  for (let i = firstPageNoOnPageList; i <= lastPageNoOnPageList; i++) {
    pageNumber.push(i);
  }

  const onClickPrev = () => {
    if(currentNum > firstPageNo){
      setCurrentNum(currentNum -1)
    } 
  }

  const onClickNext = () => {
    if(currentNum < lastPageNo){
      setCurrentNum(currentNum +1)
    }
  }

  return (
    <div className="pagination">
      <button type="button" title="처음으로" className="page_ctrl" onClick={() => setCurrentNum(firstPageNo)}>
        <i className="ico_24_first"></i>
      </button>
      <button type="button" title="이전으로" className="page_ctrl" onClick={onClickPrev}>
        <i className="ico_24_prev"></i>
      </button>
      {pageNumber.map((item, i) => (
        <button key={i} type="button" title="페이지이동" className={item == currentNum ? "page_item active" : "page_item"} onClick={() => setCurrentNum(item)}>
          {item}
        </button>
      ))}
      <button type="button" title="다음으로" className="page_ctrl" onClick={onClickNext}>
        <i className="ico_24_next"></i>
      </button>
      <button type="button" title="끝으로" className="page_ctrl" onClick={() => setCurrentNum(lastPageNo)}>
        <i className="ico_24_last"></i>
      </button>
    </div>
  );
}

export default PaginationList;
