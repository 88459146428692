import React from 'react';

import CarComplete from '../../assets/images/ico/ico_car_complete.png';

import { useAppDispatch, useAppSelector } from '../../store';
import { actEffectToParentsAPopUp, actHideAPopUp } from '../../utile/slice/alartPopUpSlice';

const AlartPopUp = () => {
  const dispatch = useAppDispatch();
  const { message, cntBtn, img, btnName, title } = useAppSelector((state) => state.alartPopUp);

  const closeAlartPopUp = () => {
    dispatch(actHideAPopUp());
  };

  const effectToParents = () => {
    dispatch(actEffectToParentsAPopUp(true));
    dispatch(actHideAPopUp());
  };

  return (
    <div className="AlartPopUp_wrap popup_wrap">
      <div className="dim"></div>
      {/* START : 기본 alert */}
      <div className="conf_inner">
        <div className="conf_header">
          {/* <button
            type="button"
            title="팝업 닫기"
            className="ico_22_pop_close"
            onClick={closeAlartPopUp}
          ></button> */}
        </div>
        <div className="conf_cont">
          {img && (
            <div className="sub_visual">
              {img ? <img src={img === 'buy/reservation' ? CarComplete : null} alt="img" /> : null}
            </div>
          )}
          <strong className="tlt">{title}</strong>

          <p
            className="desc"
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />
        </div>
        <div className="conf_footer">
          {cntBtn === 1 ? (
            <button onClick={closeAlartPopUp} type="button" className="btn_primary_m">
              <span>확인</span>
            </button>
          ) : (
            <div className="btn_layout">
              <button
                onClick={() => {
                  closeAlartPopUp();
                }}
                type="button"
                className="btn_ghost_m"
              >
                <span>{btnName.first}</span>
              </button>
              <button onClick={effectToParents} type="button" className="btn_primary_m">
                <span>{btnName.second}</span>
              </button>
            </div>
          )}
        </div>
      </div>
      {/* END : 기본 alert */}
    </div>
  );
};

export default React.memo(AlartPopUp);
