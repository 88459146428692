export let personalInfo = `
       <div className="pop_cont replace_page">
          <div className="cont">
            <strong className="tlt">1. 총칙</strong>
            <ul className="list_wrap3">
              <li>
                (1) “개인정보”란 생존하는 개인에 관한 정보로서 성명, 주민등록번호 및 영상 등에
                의하여 당해 개인을 알아볼 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수
                없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함합니다)를 말합니다.
              </li>
              <li>
                (2) 주식회사 GS글로벌(이하 “회사”라 함)은 귀하의 개인정보보호를 매우 중요시하며,
                개인정보보호법 등 관련 법령상의 개인정보보호규정을 준수하고 있습니다. 회사는 본
                개인정보처리방침을 통하여 귀하의 개인정보가 어떠한 목적과 방식으로 수집·이용되고
                있으며, 귀하의 개인정보보호를 위해 회사가 어떠한 조치를 취하고 있는지 알려드립니다.
              </li>
              <li>
                (3) 회사는 개인정보처리방침을 홈페이지(www.gsgcorp.com) 첫 화면에 공개함으로써
                귀하께서 언제나 용이하게 보실 수 있도록 조치하고 있습니다. 회사의 개인정보처리방침은
                관련 법령의 개정이나 회사의 정책에 의해 변경될 수 있으며, 이 경우 변경사항은 회사
                홈페이지에 게시됩니다.
              </li>
            </ul>
          </div>
          <div className="cont">
            <strong className="tlt">2. 개인정보의 수집 및 이용목적, 수집하는 개인정보 항목</strong>
            <p className="sm_tlt">
              회사는 귀하의 문의에 대한 정확한 답변, 입사지원 및 임직원 정보 관리 등의 업무처리를
              위하여 다음과 같이 개인정보를 수집하고 있습니다.
            </p>
            <dl className="list_dl3">
              <dt>(1) 수집 및 이용목적</dt>
              <dd>
                회사는 귀하의 개인정보를 본인확인, 채용전형, 제휴문의 및 업무연락, 윤리경영 제보,
                근로계약 체결(채용) 및 고용관계 유지 등을 위한 목적으로 사용합니다.
              </dd>
              <dt>(2) 수집하는 개인정보 항목</dt>
              <dd>
                - 개인식별정보 : 성명, 사진, 이메일, 직업, 자택 또는 직장 주소 및 연락처, 핸드폰
                번호, IP, 쿠키 등
              </dd>
              <dd>- 고유식별정보 : 주민등록번호, 외국인등록번호, 여권번호 등</dd>
              <dd>- 민감정보 : 병력사항, 건강정보</dd>
              <dd>
                - 선택 및 기타 정보 : 가족사항, 결혼여부, 외국어 능력, 인턴경험, 학력사항, 경력 및
                자격사항, 병역사항, 보훈대상여부 등
              </dd>
              <dt>(3) 개인정보 수집방법</dt>
              <dd>
                - 개인식별정보 : 성명, 사진, 이메일, 직업, 자택 또는 직장 주소 및 연락처, 핸드폰
                번호, IP, 쿠키 등
              </dd>
              <dd>- 고유식별정보 : 주민등록번호, 외국인등록번호, 여권번호 등</dd>
              <dd>- 민감정보 : 병력사항, 건강정보</dd>
              <dd>
                - 선택 및 기타 정보 : 가족사항, 결혼여부, 외국어 능력, 인턴경험, 학력사항, 경력 및
                자격사항, 병역사항, 보훈대상여부 등
              </dd>
            </dl>
          </div>
        </div>`;
export let terms = `
        <div className="pop_cont replace_page">
          <div className="cont">
            <strong className="tlt">1. 총칙</strong>
            <ul className="list_wrap3">
              <li>
                (1) “개인정보”란 생존하는 개인에 관한 정보로서 성명, 주민등록번호 및 영상 등에
                의하여 당해 개인을 알아볼 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수
                없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함합니다)를 말합니다.
              </li>
              <li>
                (2) 주식회사 GS글로벌(이하 “회사”라 함)은 귀하의 개인정보보호를 매우 중요시하며,
                개인정보보호법 등 관련 법령상의 개인정보보호규정을 준수하고 있습니다. 회사는 본
                개인정보처리방침을 통하여 귀하의 개인정보가 어떠한 목적과 방식으로 수집·이용되고
                있으며, 귀하의 개인정보보호를 위해 회사가 어떠한 조치를 취하고 있는지 알려드립니다.
              </li>
              <li>
                (3) 회사는 개인정보처리방침을 홈페이지(www.gsgcorp.com) 첫 화면에 공개함으로써
                귀하께서 언제나 용이하게 보실 수 있도록 조치하고 있습니다. 회사의 개인정보처리방침은
                관련 법령의 개정이나 회사의 정책에 의해 변경될 수 있으며, 이 경우 변경사항은 회사
                홈페이지에 게시됩니다.
              </li>
            </ul>
          </div>
          <div className="cont">
            <strong className="tlt">2. 개인정보의 수집 및 이용목적, 수집하는 개인정보 항목</strong>
            <p className="sm_tlt">
              회사는 귀하의 문의에 대한 정확한 답변, 입사지원 및 임직원 정보 관리 등의 업무처리를
              위하여 다음과 같이 개인정보를 수집하고 있습니다.
            </p>
            <dl className="list_dl3">
              <dt>(1) 수집 및 이용목적</dt>
              <dd>
                회사는 귀하의 개인정보를 본인확인, 채용전형, 제휴문의 및 업무연락, 윤리경영 제보,
                근로계약 체결(채용) 및 고용관계 유지 등을 위한 목적으로 사용합니다.
              </dd>
              <dt>(2) 수집하는 개인정보 항목</dt>
              <dd>
                - 개인식별정보 : 성명, 사진, 이메일, 직업, 자택 또는 직장 주소 및 연락처, 핸드폰
                번호, IP, 쿠키 등
              </dd>
              <dd>- 고유식별정보 : 주민등록번호, 외국인등록번호, 여권번호 등</dd>
              <dd>- 민감정보 : 병력사항, 건강정보</dd>
              <dd>
                - 선택 및 기타 정보 : 가족사항, 결혼여부, 외국어 능력, 인턴경험, 학력사항, 경력 및
                자격사항, 병역사항, 보훈대상여부 등
              </dd>
              <dt>(3) 개인정보 수집방법</dt>
              <dd>
                - 개인식별정보 : 성명, 사진, 이메일, 직업, 자택 또는 직장 주소 및 연락처, 핸드폰
                번호, IP, 쿠키 등
              </dd>
              <dd>- 고유식별정보 : 주민등록번호, 외국인등록번호, 여권번호 등</dd>
              <dd>- 민감정보 : 병력사항, 건강정보</dd>
              <dd>
                - 선택 및 기타 정보 : 가족사항, 결혼여부, 외국어 능력, 인턴경험, 학력사항, 경력 및
                자격사항, 병역사항, 보훈대상여부 등
              </dd>
            </dl>
          </div>
        </div>`;
export let marketing = `
        <div className="pop_cont replace_page">
          <div className="cont">
            <strong className="tlt">1. </strong>
            <ul className="list_wrap3">
              <li>
              </li>
            </ul>
          </div>
          <div className="cont">
            <strong className="tlt">2. </strong>
            <p className="sm_tlt">
            </p>
            <dl className="list_dl3">
              <dt></dt>
              <dd>
              </dd>
            </dl>
          </div>
          </div>`;