import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import RelationPage from '../../../common/RelationPage';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getModelGalleryList } from '../../../../utile/slice/boardSlice';
import { actIsLoading } from '../../../../utile/slice/loadingSlice';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay]);

function Gallery() {
  const dispatch = useAppDispatch();
  const gallList = useAppSelector((state) => state.board.modelGalleryList);
  const [media, setMedia] = useState({
    bbsTitNm: '', 
    bbsCont: '', 
    fileFullPath: '', 
  });
  const [mainIndex, setMainIndex] = useState(0);

  useEffect(() => {
    if(gallList.length > 0){
      setMedia({
        bbsTitNm: gallList[0].bbsTitNm, 
        bbsCont: gallList[0].bbsCont, 
        fileFullPath: gallList[0].fileFullPath, 
      });
    }
  }, [gallList]);

  useEffect(() => {
    dispatch(getModelGalleryList());
    
    const video = document.querySelectorAll('video');

    for (let i = 0; i < video.length; i++) {
      video[i].addEventListener('timeupdate', function () {
        setState({
          ...state,
          playedSeconds: video[i].currentTime,
          loadedSeconds: video[i].duration,
        });
      });
    }
  }, []);

  const [state, setState] = useState({
    playedSeconds: 0, // 재생 진행률
    loadedSeconds: 0, // 전체 재생
  });
  

  function gallChange(index) {
    setMainIndex(index);
    setMedia({
      ...media,
      bbsTitNm: gallList[index].bbsTitNm, 
      bbsCont: gallList[index].bbsCont, 
      fileFullPath: gallList[index].fileFullPath, 
    });
  }

  return (
    <div className="gallery">
      <div className="inner">
        <div className="visual">
          <h2 className="tlt">T4K 특장</h2>
        </div>
        <div className="gallery_wrap pc_view">
          {media && 
            <div className="gall_main type01">
              <div className="player-wrapper" dangerouslySetInnerHTML={{ __html : media.bbsCont}}></div>
              <strong className="tlt">{media.bbsTitNm}</strong>
            </div>
          }
          <div className="gall_list">
            <Swiper
              slidesPerView="auto"
              scrollbar={{ draggable: true }}
              pagination={{ clickable: true }}
            >
              {gallList.map((item, index) => (
                <SwiperSlide>
                  <button key={index} type="button" className={'item_' + item.bbsSeq} onClick={() => gallChange(index)}
                    style={{background: `url('${item.fileFullPath}') no-repeat center center`}}>
                    <em className="tlt">{item.bbsTitNm}</em>
                  </button>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <div className="gallery_wrap mo_view">
          <Swiper
            slidesPerView={1}
            scrollbar={{ draggable: true }}
            navigation
            pagination={{ type: 'fraction' }}
          >
            {gallList.map((item, index) =>
              <SwiperSlide key={index}>
              <div className="gall_main">
                <div className="player-wrapper" dangerouslySetInnerHTML={{ __html : item.bbsCont}}></div>
                <strong className="tlt">{item.bbsTitNm}</strong>
              </div>
            </SwiperSlide>
            )}
          </Swiper>
        </div>
      </div>
      <RelationPage />
    </div>
  );
}

export default Gallery;
