import { useState, useEffect } from 'react';
import { useScroll } from '../../utile/hook/useScrollHook';

const ScrollTopBtn = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScrollTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const { scrollY } = useScroll();

  useEffect(() => {
    const elemOffset = window.innerHeight / 2;
    if (scrollY > elemOffset) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [scrollY]);

  return <button className={isVisible ? 'btnTop visible' : 'btnTop'} onClick={handleScrollTop} />;
};

export default ScrollTopBtn;
