import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { getGalleryDetail } from '../../../../../../utile/slice/boardSlice';
import PaginationBoard from '../../../../../common/PaginationBoard';

function Board() {
  const navigate = useNavigate();
  const seqNum = useParams();

  const dispatch = useAppDispatch();

  const galleryDetail = useAppSelector(state => state.board.galleryDetail);
  
  useEffect(() => {
    dispatch(getGalleryDetail(seqNum.bbsSeq));
  }, [seqNum]);

  const goDetail = (seq) => {
    navigate(`/customer/gallery/${seq}`);
  }

  const goList = () => {
    navigate('/customer/gallery', {state: galleryDetail.currentPageNo});
  }
  
  return (
    <>
      <div className="board" key={galleryDetail.bbsSeq}>
        <span className="date">{galleryDetail.createDate}</span>
        <strong className="tlt">{galleryDetail.bbsTitNm}</strong>
        <div className="cont">
          <p className="desc" dangerouslySetInnerHTML={{ __html: galleryDetail.bbsCont }} />
        </div>
      </div>
      <PaginationBoard detailData={galleryDetail} goDetail={goDetail} goList={goList} />
    </>
  );
}

export default Board;
