import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getGalleryList } from '../../../../../utile/slice/boardSlice';
import { actIsLoading } from '../../../../../utile/slice/loadingSlice';
import PaginationList from '../../../../common/pagination/BoardPagination';

import EmptyImage from '../../../../../assets/images/list_empty.png';

function GalleryList({category}) {
  const THIS_CATE = "gallery";
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const galleryList = useAppSelector(state => state.board.galleryList);
  const Page = useAppSelector(state => state.board.paginationData);
  const [pageNo, setPageNo] = useState(state ? state : 1);

  useEffect(() => {
    if(category===THIS_CATE){
      setPageNo(1);
      dispatch(getGalleryList(1));
    }
  }, [category]);

  useEffect(() => {
    if(category===THIS_CATE){
      dispatch(getGalleryList(pageNo));
    }
  }, [pageNo]);

  const onChangePageNo = (pageNum) => {
    setPageNo(pageNum);
  }

  const onClickList = () => {
    dispatch(actIsLoading(true));
    setTimeout(() => {
      dispatch(actIsLoading(false));
    }, 2000);
  }

  return (
    <>
      <ul className="list_wrap2">
        {galleryList.map((gallery) => (
          <li key={gallery.bbsSeq}>
            <Link
              to={'/customer/gallery/' + gallery.bbsSeq}
              title="페이지 이동"
              className="card_cont1"
              onClick={onClickList}
            >
              <div className="img_wrap">
                {gallery.fileFullPath && <img src={gallery.fileFullPath} alt="" />}
              </div>
              <strong className="tlt">{gallery.bbsTitNm}</strong>
              <span className="date">{gallery.createDate}</span>
            </Link>
          </li>
        ))}
      </ul>
      <PaginationList paginationData={Page} currentNum={pageNo} setCurrentNum={onChangePageNo} />
    </>
  );
}

export default GalleryList;
