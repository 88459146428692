import React from 'react';
import { actHideDepth1LPopUp } from '../../../../utile/slice/layerPopUpSlice';
import { useAppDispatch } from '../../.././../store';

function Alli() {
  const dispatch = useAppDispatch();

  const closeAlliPopUp = () => {
    dispatch(actHideDepth1LPopUp());
  };

  return (
    <p>
      <div class="AlartPopUp_wrap popup_wrap" style={{ display: 'flex', alignItems: 'center' }}>
        <div class="dim"></div>
        <div class="conf_inner">
          <div class="conf_header">
            <button
              type="button"
              title="팝업 닫기"
              class="ico_22_pop_close"
              onClick={closeAlliPopUp}
            ></button>
          </div>
          <div class="conf_cont">
            <strong class="tlt" style={{ fontSize: '18px' }}>
              서비스 준비 중입니다
            </strong>
            <p class="desc" style={{ fontSize: '15px', lineHeight: '150%' }}>
              조금만 기다려 주세요.
              <br />
              빠른 시일 내에 준비하겠습니다.
            </p>
          </div>
          {/* <div class="conf_footer">
              <div class="btn_layout">
                <button type="button" class="btn_primary_m" onClick={() => setIsOpen(false)}>
                  <span>예</span>
                </button>
              </div>
            </div> */}
        </div>
      </div>
      {/* <div className="popup_wrap">
        <div className="dim"></div>
        <div className="pop_l_inner">
          <div className="pop_header">
            <strong className="tlt">제휴 보험사</strong>
            <button
              type="button"
              title="팝업 닫기"
              className="ico_22_pop_close"
              onClick={closeAlliPopUp}
            ></button>
          </div>
          <div className="pop_cont" style={{ minHeight: '100px', paddingBottom: '0' }}>
            <p>제휴 보험사 준비중입니다.</p>
            <ul className="aff_list">
              <li>
                <a href="/" target="_blank" className="card_cont">
                  <strong className="name">
                    <i className="ico_40_samsung"></i>
                    삼성화재 다이렉트
                  </strong>
                  <button type="button">
                    <i className="ico_24_link"></i>
                  </button>
                </a>
              </li>
            </ul>
            <span className="desc">
              정확한 금액 및 상담은 할부금융 제휴금융사, 제휴리스사, 제휴보험사를 통해 직접 문의하시길
              바랍니다.
            </span>
          </div>
        </div>
      </div> */}
    </p>
  );
}

export default Alli;
