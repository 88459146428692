import React from 'react';
import { actHideDepth1LPopUp } from '../../../../utile/slice/layerPopUpSlice';
import { useAppDispatch } from '../../../../store';
import ImgReservation from '../../../../assets/images/img/img_done.png';

function ReservePopup() {
  const dispatch = useAppDispatch();

  const closeLayerPopUp = () => {
    dispatch(actHideDepth1LPopUp());
  };

  return (
    <div>
      <div className="popup_wrap">
        <div className="dim"></div>
        <div className="pop_m_inner reserve_inner">
          <div className="pop_header">
            <strong className="tlt"></strong>
            <button
              type="button"
              title="팝업 닫기"
              className="ico_22_pop_close"
              onClick={closeLayerPopUp}
            ></button>
          </div>
          <div className="logo_wrap">
            <h4 className="logo">
              <span className="blind">t4k</span>
            </h4>
          </div>
          <div className="pop_cont complete">
            {/* 2023-03-01 14:51:38 구매예약 신청 완료 이미지 추가 */}
            <img src={ImgReservation} alt="T4K" />
            <strong className="tlt">
              구매상담 신청이 <br />
              완료되었습니다.
            </strong>
            <p className="desc">
              신청 완료 문자가 발송되며,
              <br />
              영업담당자가 빠르게 연락드립니다!
            </p>
            <div className="conf_footer">
              <button
                type="button"
                title="버튼"
                className="btn_primary_l"
                onClick={closeLayerPopUp}
              >
                <span>확인</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReservePopup;
