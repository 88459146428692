import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getEventDetail } from '../../../../../utile/slice/boardSlice';
import PaginationBoard from '../../../../common/PaginationBoard';

function Board() {
  const navigate = useNavigate();
  const seqNum = useParams();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const eventDetail = useAppSelector((state) => state.board.eventDetail);

  useEffect(() => {
    dispatch(getEventDetail(seqNum.bbsSeq));
  }, [seqNum]);

  const goDetail = (seq) => {
    navigate(`/customer/event/${seq}`);
  };

  const goList = () => {
    navigate('/customer/event', { state: { ...state, currentPageNo: eventDetail.currentPageNo, tab: eventDetail.eventProgressCode === 'ongoing' ? 'y' : 'n' } });
  };
  
  return (
    <>
      <div className="board" key={eventDetail.bbsSeq}>
        <span className="date">
          {eventDetail.eventStartDt} ~ {eventDetail.eventEndDt}
        </span>
        <strong className="tlt">{eventDetail.bbsTitNm}</strong>
        <div className="cont">
          <p className="desc" dangerouslySetInnerHTML={{ __html: eventDetail.bbsCont }} />
        </div>
      </div>
      <PaginationBoard detailData={eventDetail} goDetail={goDetail} goList={goList} />
    </>
  );
}

export default Board;
