import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getConditionDetail } from '../../../../../utile/slice/boardSlice';
import PaginationBoard from '../../../../common/PaginationBoard';

function Board() {
  const navigate = useNavigate();
  const seqNum = useParams();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const conditionDetail = useAppSelector((state) => state.board.conditionDetail);

  useEffect(() => {
    dispatch(getConditionDetail(seqNum.bbsSeq));
  }, [seqNum]);

  const goDetail = (seq) => {
    navigate(`/customer/condition/${seq}`);
  };

  const goList = () => {
    navigate('/customer/condition', {state: conditionDetail.currentPageNo});
  };
  
  return (
    <>
      <div className="board" key={conditionDetail.bbsSeq}>
        <span className="date">
          {conditionDetail.eventStartDt} ~ {conditionDetail.eventEndDt}
        </span>
        <strong className="tlt">{conditionDetail.bbsTitNm}</strong>
        <div className="cont">
          <p className="desc" dangerouslySetInnerHTML={{ __html: conditionDetail.bbsCont }} />
        </div>
      </div>
      <PaginationBoard detailData={conditionDetail} goDetail={goDetail} goList={goList} />
    </>
  );
}

export default Board;
