import React, { useRef, useState } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';
import RelationPage from '../../../common/RelationPage';
import Visual from '../../../common/Visual';

import BenefitsPage from './Benefits';
import ChargePage from './Charge';
import FundPage from './Fund';
import InformationPage from './Information';
import RegistrationPage from './Registration';

function Buyguide() {
  const ref = useRef();
  const { events } = useDraggable(ref);

  const [activeIndex, setActiveIndex] = useState(0);

  const tabClickHandler = (index) => {
    setActiveIndex(index);
  };

  const tabContArr = [
    {
      tabList: (
        <button
          key={0}
          type="button"
          className={activeIndex === 0 ? 'active' : ''}
          onClick={() => tabClickHandler(0)}
        >
          구매절차 및 보조금
        </button>
      ),
      tabCont: (
        <div className="tab-cont">
          <InformationPage tabClickHandler={tabClickHandler} />
        </div>
      ),
    },
    {
      tabList: (
        <button
          key={1}
          type="button"
          className={activeIndex === 1 ? 'active' : ''}
          onClick={() => tabClickHandler(1)}
        >
          차량등록
        </button>
      ),
      tabCont: (
        <div className="tab-cont">
          <RegistrationPage />
        </div>
      ),
    },
    {
      tabList: (
        <button
          key={2}
          type="button"
          className={activeIndex === 2 ? 'active' : ''}
          onClick={() => tabClickHandler(2)}
        >
          세제혜택
        </button>
      ),
      tabCont: (
        <div className="tab-cont">
          <BenefitsPage />
        </div>
      ),
    },
    {
      tabList: (
        <button
          key={3}
          type="button"
          className={activeIndex === 3 ? 'active' : ''}
          onClick={() => tabClickHandler(3)}
        >
          공채
        </button>
      ),
      tabCont: (
        <div className="tab-cont">
          <FundPage />
        </div>
      ),
    },
    {
      tabList: (
        <button
          key={4}
          type="button"
          className={activeIndex === 4 ? 'active' : ''}
          onClick={() => tabClickHandler(4)}
        >
          충전카드
        </button>
      ),
      tabCont: (
        <div className="tab-cont">
          <ChargePage />
        </div>
      ),
    },
  ];

  return (
    <div className="buyguide">
      <Visual
        tlt="구매가이드"
        sub={'전기차 구매 시 구매절차, 차량등록, 세금 혜택 등 \n 다양한 안내를 확인해보세요.'}
      />
      <div className="tab-head" {...events} ref={ref}>
        <div className="tab-inner">
          {tabContArr.map((section, index) => {
            return section.tabList;
          })}
        </div>
      </div>
      <div className="inner with-tab">{tabContArr[activeIndex].tabCont}</div>
      <RelationPage />
    </div>
  );
}

export default Buyguide;
