import React from 'react';
import Page from './Board';

function NoticeDetail() {
  return (
    <div className="notice_detail inner">
      <Page />
    </div>
  );
}

export default NoticeDetail;
