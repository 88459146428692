import { useForm } from 'react-hook-form';
import { getPartsInfoList } from 'utile/slice/searchBoardSlice';
import { useAppDispatch, useAppSelector } from '../../store';

const useSearchFormHook = () => {
  const dispatch = useAppDispatch();
  const { register, handleSubmit, getValues } = useForm();

  const { searchForm } = useAppSelector((state) => state.tempForm);
  const currentPageNo = useAppSelector(state => state.searchBoard.currentPageNo);
  

  const onSearch = (searchInfo) => {
    let searchData;

    if (typeof searchInfo === 'number') {
      //페이저 클릭
      searchData = {};
      searchData.form = searchForm;
      searchData.currentPageNo = currentPageNo;
      //dispatch(getPartsInfoList(searchData));
      //dispatch(actSetCurrentNo(currentPageNo));
      dispatch(getPartsInfoList(searchData));
    } else {
      //써치바검색
      if (searchInfo) {
        //dispatch(actSaveSearchForm(searchInfo));
      }
      //dispatch(actSetCurrentNo(1));
      searchData = {};
      searchData.form = searchInfo;
      searchData.currentPageNo = currentPageNo;
      dispatch(getPartsInfoList(searchData));
    }
  };

  const onError = (error) => {
    return;
  };

  return {
    onError,
    onSearch,
    register,
    handleSubmit,
    getValues,
  };
};

export default useSearchFormHook;
