import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import rColor from '../../../../../assets/images/img/img_buy_color.jpg';
import wColor from '../../../../../assets/images/img/img_buy_color2.jpg';
import Img1 from '../../../../../assets/images/img/img_buy_package1.jpg';
import Img from '../../../../../assets/images/img/img_t4k_01.png';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { staticAutoComma } from '../../../../../utile/regExp';
import { actShowAPopUp } from '../../../../../utile/slice/alartPopUpSlice';
import { actAddInputEstimateForm } from '../../../../../utile/slice/tempFormSlice';
import Consignment from './Consignment';
import PlanPopup from './PlanPopup';
import { actionToReset } from './planPriceSlice';
import RegFee from './RegFee';
import SubsidyPrice from './SubsidyPrice';
import { actShowDepth1LPopUp } from '../../../../../utile/slice/layerPopUpSlice';
import ModelDetail1 from '../common/PopupDetail1';
import ModelDetail2 from '../common/PopupDetail2';
import ModelDetail3 from '../common/PopupDetail3';
import ModelDetail4 from '../common/PopupDetail4';
import ModelDetail5 from '../common/PopupDetail5';

function MyDesc() {
  const PAYMENT_TYPE = {
    CASH: 'CASH',
    INST: 'INST',
  };
  useEffect(() => {
    // const accList = document.querySelectorAll('.section');
    // accList.forEach((list) => {
    //   list.classList.add('active');
    // });
    dispatch(actionToReset());
  }, []);

  const [state, setState] = useState({
    popOpen: false,
  });

  function accOnOff(e) {
    const accCont = e.target.closest('.section');

    accCont.classList.toggle('active');
  }

  function contView(e) {
    const btnAll = e.target.closest('.sec_cont').querySelectorAll('button');
    const btnValue = e.target.closest('button');
    const cont = e.target.closest('.sec_cont').querySelectorAll('.acco_desc');

    btnAll.forEach((btn) => {
      btn.classList.remove('active');
    });
    btnValue.classList.add('active');
    if (btnValue.innerText === '할부') {
      setState({ ...state, popOpen: !state.popOpen });
    } else {
      cont.forEach((con) => {
        if (con.classList.contains('active')) {
          con.classList.remove('active');
        }
      });
      dispatch(
        actAddInputEstimateForm({
          ...estimateForm,
          paymentType: PAYMENT_TYPE.CASH,
        }),
      );
    }
  }

  const planPrice = useSelector((state) => state.planPrice);
  const dispatch = useAppDispatch();

  const onClickPopupOpen = () => {
    setState({ ...state, popOpen: true });
  };

  const popupClose = () => {
    setState({ ...state, popOpen: false });
    dispatch(actionToReset());
    dispatch(
      actAddInputEstimateForm({
        ...estimateForm,
        paymentType: PAYMENT_TYPE.CASH,
      }),
    );
    cancelFn();
  };

  function cancelFn() {
    const btnAll = document.querySelectorAll('.btn_radius');
    const btnPayment = document.getElementById('btnPayment');
    const cont = document.querySelectorAll('.disAccodian');

    btnAll.forEach((btn) => {
      btn.classList.remove('active');
    });
    btnPayment.classList.add('active');

    cont.forEach((con) => {
      con.classList.remove('active');
    });
  }

  const onClickApply = () => {
    const cont = document.querySelectorAll('.disAccodian');

    cont.forEach((con) => {
      con.classList.add('active');
    });

    setState({ ...state, popOpen: false });
    dispatch(
      actAddInputEstimateForm({
        ...estimateForm,
        paymentType: PAYMENT_TYPE.INST,
      }),
    );
  };

  const { estimateForm } = useAppSelector((state) => state.tempForm);

  useEffect(() => {
    // 보조금 변경 시 할부 선택 초기화
    popupClose();
  }, [estimateForm.totalPrice, estimateForm.subsidyPrice]);

  const changeAlartInfo = {
    title: '선택 차량을 변경하시겠습니까?',
    message: '지금까지 입력하신 상세정보가 모두 초기화됩니다.',
    cntBtn: 2,
    btnName: { first: '아니요', second: '예' },
  };

  const changeModel = () => {
    dispatch(actShowAPopUp(changeAlartInfo));
    //!리셋 셀렉트박스
  };

  useEffect(() => {
    dispatch(
      actAddInputEstimateForm({
        ...estimateForm,
        installmentPrincipal: planPrice.figuresPrice.shareVal,
        monthly_payment: {
          totalMonthlyPayment: Number(planPrice.monthPrice),
          totalInstallmenInterest: planPrice.interestPrice,
          installmentPeriod: planPrice.figuresPrice.restVal,
          interestRate: planPrice.figuresInterestPrice.interestVal,
        },
        before_payment: {
          totalBeforePayment: planPrice.buyPrice,
          totalVehiclePurchaseAmount: planPrice.buyPrice,
        },
      }),
    );
  }, [planPrice]);

  // 팝업호출
  const openDetailedItem = (mldNm, trimNm) => {
    dispatch(actShowDepth1LPopUp(mldNm.replace(/ /g, '') + trimNm.replace(/ /g, '')));
  };

  const { depth1LPopUpName } = useAppSelector((state) => state.layerPopUp);

  return (
    <div className="my_desc">
      <ul className="cho_check">
        {/* 선택 차량 */}
        <li className="section">
          <div className="tlt_wrap">
            {/* <div className="tlt_wrap" onClick={accOnOff}> */}
            <strong className="tlt">선택 차량</strong>
            <span className="price">
              <span className="category">차량 가격</span>
              <span className="num">{staticAutoComma(estimateForm.totalPrice)}</span>원
              {/* <button type="button" title="항목 닫기">
                <i className="ico_32_accoup"></i>
              </button> */}
            </span>
          </div>
          {/* <div className="cho_car_wrap">
            <ul className="cho_car_list">
              <li>
                <div className="img_cont">
                  <div className="img_wrap">
                    <img src={Img} alt="" />
                  </div>
                  <div className="txt_wrap">
                    <span className="category">모델</span>
                    <em className="tlt">{`${estimateForm.mldNm}  
          ${estimateForm.trimNm}`}</em>
                    <button
                      type="button"
                      className="btn_txt"
                      onClick={() => openDetailedItem(estimateForm.mldNm, estimateForm.trimNm)}
                    >
                      상세보기
                      <i className="ico_20_next"></i>
                    </button>
                  </div>
                </div>
                <div className="price_cont">
                  <span className="price">
                    <span>{staticAutoComma(estimateForm.model_price)}</span>원
                  </span>
                </div>
              </li>
              <li>
                <div className="img_cont">
                  <div className="img_wrap">
                    <img src={estimateForm.COLOR_TYPE === 'WHITE' ? wColor : rColor} alt="color" />
                  </div>
                  <div className="txt_wrap">
                    <span className="category">컬러</span>
                    <em className="tlt">{estimateForm.colorNm}</em>
                  </div>
                </div>
                <div className="price_cont">
                  <span className="price">
                    <span>{staticAutoComma(estimateForm.color_price)}</span>원
                  </span>
                </div>
              </li>
              <li>
                <div className="img_cont">
                  <div className="img_wrap">
                    <img src={Img1} alt="" />
                  </div>
                  <div className="txt_wrap">
                    <span className="category">옵션&amp;패키지</span>
                    <em className="tlt">풀옵션 패키지</em>
                  </div>
                </div>
                <div className="price_cont">
                  <span className="discount">
                    패키지 할인 (-) <span>250,000</span>원
                  </span>
                  <span className="price">
                    <span>{staticAutoComma(estimateForm.primium_package_price - 250000)}</span>원
                  </span>
                </div>
              </li>
            </ul>
            <button type="button" className="btn_ghost_s" onClick={changeModel}>
              <span>다시 선택하기</span>
            </button>
          </div> */}
        </li>

        {/* 구매 보조금 */}
        <SubsidyPrice accOnOff={accOnOff} />

        {/* 탁송 */}
        <Consignment accOnOff={accOnOff} />

        {/* 결제방법 */}
        <li className="section active">
          <div className="tlt_wrap" onClick={accOnOff}>
            <strong className="tlt">결제방법</strong>
            <span className="price">
              <span className="category">총 차량 구매 금액</span>
              <span className="num">
                {staticAutoComma(
                  estimateForm.totalPrice +
                    estimateForm.consignmentPrice -
                    estimateForm.subsidyPrice,
                )}
              </span>
              원
              <button type="button" title="항목 닫기">
                <i className="ico_32_accoup"></i>
              </button>
            </span>
          </div>
          <div className="sec_cont">
            <div className="btn_layout">
              <button
                type="button"
                id="btnPayment"
                className="btn_radius active"
                onClick={contView}
              >
                현금
              </button>
              <button type="button" className="btn_radius" onClick={contView}>
                할부
              </button>
            </div>
            <dl className="acco_desc disAccodian">
              <dt className="bold">월 납입 금액</dt>
              <dd>
                월 <span>{staticAutoComma(planPrice.monthPrice)}</span>원
              </dd>
              <dt>할부 원금</dt>
              <dd>
                <span>{staticAutoComma(planPrice.figuresPrice.shareVal)}</span>원
              </dd>
              <dt>총 할부이자</dt>
              <dd>
                <span>{staticAutoComma(planPrice.interestPrice)}</span>원
              </dd>
              <dt>할부 기간 / 금리</dt>
              <dd>
                <span>{planPrice.figuresPrice.restVal} 개월</span> /
                <span> {planPrice.figuresInterestPrice.interestVal} %</span>
              </dd>
            </dl>
            <dl className="acco_desc disAccodian">
              <dt className="bold">출고 전 납입 금액</dt>
              <dd>
                <span>
                  {staticAutoComma(
                    parseInt(
                      estimateForm.totalPrice -
                        estimateForm.subsidyPrice +
                        estimateForm.consignmentPrice +
                        estimateForm.mandInsurPrice -
                        estimateForm?.installmentPrincipal,
                    ),
                  )}
                </span>
                원
              </dd>
              <dt>총 차량 구매 금액</dt>
              <dd>
                <span>{staticAutoComma(planPrice.buyPrice)}</span>원
              </dd>
              <dt>임시운행 의무보험료</dt>
              <dd>
                <span>2,000</span>원
              </dd>
              <dt>할부 원금</dt>
              <dd>
                (-) <span>{staticAutoComma(planPrice.figuresPrice.shareVal)}</span>원
              </dd>
            </dl>
            <button type="button" className="btn_ghost_s" onClick={onClickPopupOpen}>
              <span>할부조건 변경하기</span>
            </button>
            <div className="affiliate">
              <div className="aff_tlt">
                <strong className="tlt">제휴사 문의</strong>
                <span className="desc">
                  정확한 금액 및 상담은 할부금융 제휴금융사, 제휴리스사, 제휴보험사를 통해 직접
                  문의하시길 바랍니다.
                </span>
              </div>
              <strong className="tlt">제휴금융사</strong>
              <ul className="aff_list">
                <li>
                  <a
                    href="https://www.shinhancard.com"
                    target="_blank"
                    className="card_cont"
                    rel="noreferrer"
                  >
                    <strong className="name">
                      <i className="ico_40_shinhan"></i>
                      신한카드
                    </strong>
                    <button type="button">
                      <i className="ico_24_link"></i>
                    </button>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.woorifcapital.com"
                    target="_blank"
                    className="card_cont"
                    rel="noreferrer"
                  >
                    <strong className="name">
                      <i className="ico_40_woori"></i>
                      우리금융캐피탈
                    </strong>
                    <button type="button">
                      <i className="ico_24_link"></i>
                    </button>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.nhcapital.co.kr"
                    target="_blank"
                    className="card_cont"
                    rel="noreferrer"
                  >
                    <strong className="name">
                      <i className="ico_40_nonghyb"></i>
                      NH농협캐피탈
                    </strong>
                    <button type="button">
                      <i className="ico_24_link"></i>
                    </button>
                  </a>
                </li>
                <li>
                  <strong className="tlt">제휴리스사</strong>
                  {/* 임시 - 준비되면 삭제하고, 아래코드 주석해제 */}
                  <div className="card_cont tem">
                    <strong className="name">제휴 준비 중입니다</strong>
                  </div>
                  {/* <a
                    href="https://www.lotte-autolease.net"
                    target="_blank"
                    className="card_cont"
                    rel="noreferrer"
                  >
                    <strong className="name">
                      <i className="ico_40_lotte"></i>
                      롯데오토리스
                    </strong>
                    <button type="button">
                      <i className="ico_24_link"></i>
                    </button>
                  </a> */}
                </li>
                <li>
                  <strong className="tlt">제휴보험사</strong>
                  {/* 임시 - 준비되면 삭제하고, 아래코드 주석해제 */}
                  <div className="card_cont tem">
                    <strong className="name">제휴 준비 중입니다</strong>
                  </div>
                  {/* <a
                    href="https://direct.samsungfire.com"
                    target="_blank"
                    className="card_cont"
                    rel="noreferrer"
                  >
                    <strong className="name">
                      <i className="ico_40_samsung"></i>
                      삼성화재 다이렉트
                    </strong>
                    <button type="button">
                      <i className="ico_24_link"></i>
                    </button>
                  </a> */}
                </li>
              </ul>
            </div>
          </div>
        </li>

        {/* 등록비용 */}
        <RegFee accOnOff={accOnOff} />
      </ul>
      <dl className="info">
        <dt>
          <i className="ico_24_noti_msg"></i>확인해 주세요.
        </dt>
        <dd>
          - 본 견적서는 고객님의 차량 구입(청약) 의사 결정에 도움을 드리고자 작성된 것으로 법적인
          효력은 없으며, 계약을 원하실 경우 별도로 청약 의사 표시를 하셔야 합니다.
        </dd>
        <dd>
          - 실제 계약 내용은 고객께서 계약하실 시점의 판매조건이나 대출 조건에 따라 본 견적 내용과
          달라질 수 있으며, 본 견적은 법적구속력이 없습니다.
        </dd>
        <dd>
          - 자동차 취등록세 및 부대비용, 등록비용은 고객님의 이해를 돕기 위한 부분으로 정확한 금액은
          반드시 판매센터에 확인하시기 바랍니다.
        </dd>
        <dd>- 탁송료 및 공채할인률은 출고지, 등록일에 따라서 변경될 수 있습니다. </dd>
        <dd>
          - 등록대행 수수료는 당사에 등록을 의뢰하실 경우 발생되는 항목이며 의뢰지역, 의뢰방법에
          따라 달라질 수 있습니다.
        </dd>
        <dd>
          - 공채 금액은 매일매일 변경되는 부분으로 자세한 내용은 판매센터로 문의 부탁드립니다.
        </dd>
      </dl>

      {/* 할부선택 팝업 */}
      {state.popOpen && <PlanPopup popupClose={popupClose} onClickApply={onClickApply} />}

      {/* 상세보기팝업 */}
      {depth1LPopUpName === 'T4K내장탑차슈퍼캡롱레인지프리미엄' && <ModelDetail1 />}
      {depth1LPopUpName === 'T4K냉동탑차슈퍼캡롱레인지프리미엄' && <ModelDetail2 />}
      {depth1LPopUpName === 'T4K윙바디슈퍼캡롱레인지프리미엄' && <ModelDetail3 />}
      {depth1LPopUpName === 'T4K카고슈퍼캡롱레인지프리미엄' && <ModelDetail4 />}
      {depth1LPopUpName === 'T4K카고슈퍼캡롱레인지' && <ModelDetail5 />}
    </div>
  );
}

export default React.memo(MyDesc);
