import React from 'react';
import ProgressBar from '../common/ProgressBar';
import ModelDetail1 from '../common/PopupDetail1';
import ModelDetail2 from '../common/PopupDetail2';
import ModelDetail3 from '../common/PopupDetail3';
import ModelDetail4 from '../common/PopupDetail4';
import ModelDetail5 from '../common/PopupDetail5';
import ModelImg1 from '../../../../../assets/images/img/img_t4k_sample.png';
import ModelImg2 from '../../../../../assets/images/img/img_t4k_sample2.png';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { actShowDepth1LPopUp } from '../../../../../utile/slice/layerPopUpSlice';
import { actAddInputEstimateForm } from '../../../../../utile/slice/tempFormSlice';
import { useNavigate } from 'react-router-dom';
import { unComma } from '../../../../../utile/regExp';

export const models = [
  {
    name: 'T4K 카고',
    subName: '슈퍼캡 롱레인지 프리미엄',
    description: '넓은 적재 공간에 EV 경제성까지! \n 안전한 상품 수송능력을 갖춘 운송수단입니다.',
    modelPrice: 43690000,
  },
  // {
  //   name: 'T4K 카고',
  //   subName: '슈퍼캡 롱레인지',
  //   description:
  //     '슬림형 고성능 냉동기와 단열성이 뛰어난 냉동탑으로 식품 수송에 최적의 효율을 자랑합니다.',
  //   modelPrice: 43690000,
  // },
];

function Model() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { estimateForm } = useAppSelector((state) => state.tempForm);
  const { depth1LPopUpName } = useAppSelector((state) => state.layerPopUp);

  const openDetailedItem = (LpopUpName) => {
    dispatch(actShowDepth1LPopUp(LpopUpName));
  };

  const goColor = (modelData) => {
    if (estimateForm.mldNm === modelData.mldNm) {
      // 이전 트림 선택되어 있는 지 확인
      dispatch(actAddInputEstimateForm({ ...modelData, vehlNm: 'T4K' }));
    } else {
      // 다른 트림 선택 시 컬러 및 옵션 초기화
      dispatch(
        actAddInputEstimateForm({ ...modelData, COLOR_TYPE: '', OPTION_TYPE: '', vehlNm: 'T4K' }),
      );
    }
    navigate('/buy/estimate/color');
  };

  return (
    <div className="estimate">
      <ProgressBar />
      {estimateForm.type !== 'T4K 카고' ? (
        <div className="inner">
          <div className="model_cho">
            <div className="model">
              <div className="img_wrap">
                <img src={ModelImg2} alt=""></img>
              </div>
              <strong className="category mldNm">T4K 내장탑차</strong>
              <em className="tlt trimNm">슈퍼캡 롱레인지 프리미엄</em>
              <p className="caption">
                넓은 적재 공간에 EV 경제성까지!
                <br />
                안전한 상품 수송능력을 갖춘 운송수단입니다.
              </p>
              <button type="button" className="btn_txt" onClick={() => openDetailedItem('detail1')}>
                <span>상세품목</span>
                <i className="ico_20_next"></i>
              </button>
              <em className="price">
                <span className="model_price">43,690,000</span>원
              </em>
              <button
                type="button"
                className="btn_primary_l"
                onClick={() =>
                  goColor({
                    mldNm: document.querySelectorAll('.mldNm')[0].innerHTML,
                    trimNm: document.querySelectorAll('.trimNm')[0].innerHTML,
                    model_price: Number(
                      unComma(document.querySelectorAll('.model_price')[0].innerHTML),
                    ),
                  })
                }
              >
                <span>모델선택</span>
              </button>
            </div>
            <div className="model">
              <div className="img_wrap">
                <img src={ModelImg2} alt=""></img>
              </div>
              <strong className="category mldNm">T4K 냉동탑차</strong>
              <em className="tlt trimNm">슈퍼캡 롱레인지 프리미엄</em>
              <p className="caption">
                슬림형 고성능 냉동기와 단열성이 뛰어난 냉동탑으로 식품 수송에 최적의 효율을
                자랑합니다.
              </p>
              <button type="button" className="btn_txt" onClick={() => openDetailedItem('detail2')}>
                <span>상세품목</span>
                <i className="ico_20_next"></i>
              </button>
              <em className="price">
                <span className="model_price">43,690,000</span>원
              </em>
              <button
                type="button"
                className="btn_primary_l"
                onClick={() =>
                  goColor({
                    mldNm: document.querySelectorAll('.mldNm')[1].innerHTML,
                    trimNm: document.querySelectorAll('.trimNm')[1].innerHTML,
                    model_price: Number(
                      unComma(document.querySelectorAll('.model_price')[1].innerHTML),
                    ),
                  })
                }
              >
                <span>모델선택</span>
              </button>
            </div>
            <div className="model">
              <div className="img_wrap">
                <img src={ModelImg2} alt=""></img>
              </div>
              <strong className="category mldNm">T4K 윙바디</strong>
              <em className="tlt trimNm">슈퍼캡 롱레인지 프리미엄</em>
              <p className="caption">
                화물 운송 뿐 아니라 무대, 전시까지 활용 가능한 고효율, 다기능의 스마트한 차량입니다.
              </p>
              <button type="button" className="btn_txt" onClick={() => openDetailedItem('detail3')}>
                <span>상세품목</span>
                <i className="ico_20_next"></i>
              </button>
              <em className="price">
                <span className="model_price">43,690,000</span>원
              </em>
              <button
                type="button"
                className="btn_primary_l"
                onClick={() =>
                  goColor({
                    mldNm: document.querySelectorAll('.mldNm')[2].innerHTML,
                    trimNm: document.querySelectorAll('.trimNm')[2].innerHTML,
                    model_price: Number(
                      unComma(document.querySelectorAll('.model_price')[2].innerHTML),
                    ),
                  })
                }
              >
                <span
                  onClick={() =>
                    goColor({
                      mldNm: document.querySelectorAll('.mldNm')[2].innerHTML,
                      trimNm: document.querySelectorAll('.trimNm')[2].innerHTML,
                      model_price: Number(
                        unComma(document.querySelectorAll('.model_price')[2].innerHTML),
                      ),
                    })
                  }
                >
                  모델선택
                </span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="inner">
          <div className="model_cho">
            {models.map((model, index) => {
              return (
                <div key={index} className="model">
                  <div className="img_wrap">
                    <img src={ModelImg1} alt="" />
                  </div>
                  <strong className="category">{model.name}</strong>
                  <em className="tlt">{model.subName}</em>
                  <p className="caption">{model.description}</p>
                  <button
                    type="button"
                    className="btn_txt"
                    onClick={() =>
                      // todo
                      openDetailedItem(model.subName === '슈퍼캡 롱레인지' ? 'detail5' : 'detail4')
                    }
                  >
                    <span>상세품목</span>
                    <i className="ico_20_next"></i>
                  </button>
                  <em className="price" style={{ visibility: 'hidden' }}>
                    <span className="model_price">{model.modelPrice.toLocaleString()}</span>원
                  </em>
                  <button
                    type="button"
                    className="btn_primary_l"
                    onClick={() =>
                      goColor({
                        mldNm: model.name,
                        trimNm: model.subName,
                        model_price: model.modelPrice,
                      })
                    }
                  >
                    <span>모델선택</span>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {depth1LPopUpName === 'detail1' && <ModelDetail1 />}
      {depth1LPopUpName === 'detail2' && <ModelDetail2 />}
      {depth1LPopUpName === 'detail3' && <ModelDetail3 />}
      {depth1LPopUpName === 'detail4' && <ModelDetail4 />}
      {depth1LPopUpName === 'detail5' && <ModelDetail5 />}
    </div>
  );
}

export default Model;
