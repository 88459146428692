import React, {useEffect} from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

function Visual(props) {

  const handleGsap = () => {
    const target = document.querySelectorAll('[data-trigger]');

    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 769px)': function () {
        target.forEach((el) => {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: el,
                start: 'bottom bottom',
                end: 'center 55%',
                // markers: true,
              },
            })
            .to(el, {
              duration: 1.4,
              opacity: 1,
              y: 0,
            });
        });
      },

      //   mobile
      '(max-width: 769px)': function () {
        target.forEach((el) => {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: el,
                start: 'center bottom',
                end: 'center 55%',
                // scrub: 1,
                // markers: true,
              },
            })
            .to(el, {
              duration: 0.8,
              opacity: 1,
              y: 0,
            });
        });
      },
    });

  };

  useEffect(() => {
    handleGsap();
  });

  return (
    <div className="visual img_bg">
      <div className="bg_img" data-scale></div>
      <div className="inner">
        <h2 className="tlt" data-trigger>{props.tlt}</h2>
        <p className="sub_tlt" data-trigger>{props.sub}</p>
      </div>
    </div>
  );
}

export default Visual;
