import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useDraggable } from 'react-use-draggable-scroll';
import ImgSample from '../../../assets/images/img/img_sample1.jpg';
// import MovieItem1 from '../../../assets/media/gsglobal_teaser.mp4';
import MovieItem2 from '../../../assets/media/gsclobal_section1.mp4';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useAppDispatch } from '../../../store';

// 디바이스별 영상 설정
import { useMediaQuery } from 'react-responsive';
import MovieHeroPc from '../../../assets/media/pc/gsglobal_teaser.mp4';
import MovieHeroTa from '../../../assets/media/ta/gsglobal_teaser.mp4';
import MovieHeroMo from '../../../assets/media/mo/gsglobal_teaser.mp4';

// 사전예약 버튼
import ReserveBtn from './ReserveBtn';
import { createSerializableStateInvariantMiddleware } from '@reduxjs/toolkit';

function Advance() {
  const dispatch = useAppDispatch();

  const getHTML = document.querySelector('html')?.classList.add('noscroll');
  const getbody = document.querySelector('body')?.classList.add('noscroll');

  const ref = useRef();
  const { events } = useDraggable(ref, { applyRubberBandEffect: true });

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const target = document.querySelectorAll('[data-trigger]');
    ScrollTrigger.matchMedia({
      scroller: '[data-snap]',
      // desktop
      '(min-width: 769px)': function () {
        target.forEach((el) => {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: el,
                start: 'bottom bottom',
                end: 'bottom bottom',
                //scrub: 1,
                //markers: true,
              },
            })
            .to(el, {
              duration: 1.4,
              opacity: 1,
              y: 0,
            });
        });
      },

      //   mobile
      '(max-width: 769px)': function () {
        target.forEach((el) => {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: el,
                start: 'center bottom',
                end: 'center top',
                scrub: 1,
                // markers: true,
              },
            })
            .to(el, {
              duration: 0.8,
              opacity: 1,
              y: 0,
            });
        });
      },
    });

    const video = document.querySelector('.react-player');

    function handleProgress(e) {
      setState({ ...state, playedSeconds: video.currentTime, loadedSeconds: video.duration });
    }
    video.addEventListener('timeupdate', handleProgress);
  }, []);

  const [state, setState] = useState({
    playing: true, // 재생중인지
    muted: false, // 음소거인지
    controls: false, // 기본으로 제공되는 컨트롤러 사용할건지
    volume: 0.5, // 볼륨크기
    playbackRate: 1.0, // 배속
    played: 0, // 재생의 정도 (value)
    seeking: false, // 재생바를 움직이고 있는지
    duration: 0, // 전체 시간
    playedSeconds: 0, // 재생 진행률
    loadedSeconds: 0, // 전체 재생
  });

  const video = document.querySelector('.react-player');
  function togglePlay(e) {
    e.target.classList.toggle('stop');
    const method = video.paused ? 'play' : 'pause';
    video[method]();
  }

  // 디바이스별 영상 설정
  const viewportPc = useMediaQuery({ query: '(min-width: 1368px)' });
  const viewportTa = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1367px)' });
  const viewportMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const movie = viewportPc ? MovieHeroPc : viewportTa ? MovieHeroTa : MovieHeroMo;
  return (
    <div className="advance">
      <header>
        <div className="logo_wrap">
          <h1 className="logo">
            <span className="blind">T4K</span>
          </h1>
          <h1 className="logo">
            <span className="blind">GS Global</span>
          </h1>
        </div>
        <div className="pc_view">
          <ReserveBtn />
        </div>
      </header>
      <section className="intro" data-snap>
        <div className="player-wrapper">
          <video
            id="video"
            className="react-player"
            autoPlay={state.playing}
            playsInline={true}
            muted={true}
            loop={true}
            controls={false}
            style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
            src={movie}
          >
            {/* <source src={MovieItem1} type="video/mp4"></source> */}
          </video>
        </div>
        <div className="circle_progress_wrap">
          <CircularProgressbar value={state.playedSeconds} maxValue={state.loadedSeconds} />
          <button
            type="button"
            title="비디오 컨트롤"
            onClick={togglePlay}
            className="ctrl"
          ></button>
        </div>
        {/* <h2 className="tlt" data-trigger>
          2022.02.24
          <br />
          Grand open
        </h2> */}
        <div className="event_line pc_view">
          <div className="item">
            <div className="txt_wrap">
              <strong className="tlt">사전 예약 후 T4K 구매하면 웰컴기프트 증정</strong>
              <span className="info">
                재고 소진시 자동 종료되며, 사은품교환 및 반품은 제한될 수 있습니다.
              </span>
            </div>
          </div>
        </div>
      </section>
      <div className="wrap">
        <section className="detail" data-snap>
          <div className="item full">
            <div className="player-wrapper insection">
              <video
                id="video"
                className="react-player"
                autoPlay={state.playing}
                playsInline={true}
                muted={true}
                loop={true}
                controls={false}
                style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
                src={MovieItem2}
              >
                <source src={MovieItem2} type="video/mp4"></source>
              </video>
            </div>
            <div className="box-desc">
              <h3 className="item_tlt" data-trigger>
                82kWh
                <br />
                Blade-LFP Battery
              </h3>
              <div className="desc" data-trigger>
                <p className="desc_txt">
                  하이엔드 전기차에 장착하는 블레이드 LFP배터리로 화재에 덜 취약합니다.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="detail" data-snap>
          <div className="item full">
            <div className="box-desc">
              <h3 className="item_tlt" data-trigger>
                한번 충전으로 부족할까
                <br />
                걱정하지 마세요.
              </h3>
              <div className="desc" data-trigger>
                <p className="desc_txt">
                  1회 충전으로 최대 246km 주행이 가능합니다. <br />
                  (상온 주행 시, 저온 주행 209km)
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="detail" data-snap>
          <div className="item full">
            <div className="box-desc">
              <h3 className="item_tlt" data-trigger>
                겨울에도 충전걱정 없어요
              </h3>
              <div className="desc" data-trigger>
                <p className="desc_txt">
                  배터리 히팅시스템으로 겨울철 극한저온도에서 발생할 수 있는 차량구동문제, 급격한
                  충전속도저하 문제를 최소화시켜줍니다.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="detail" data-snap>
          <div className="item full">
            <div className="box-desc">
              <h3 className="item_tlt" data-trigger>
                내 트럭에서 <br />
                전기를 끌어다 쓸 수 있어요
              </h3>
              <div className="desc" data-trigger>
                <p className="desc_txt">
                  V2L 커넥터를 활용하여 차량 외부에도 전력 공급이 가능합니다.(3.3kW급)
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="detail" data-snap>
          <div className="item full">
            <div className="box-desc">
              <h3 className="item_tlt" data-trigger>
                차량까지 컨트롤 하는 <br />
                스마트한 패드에요
              </h3>
              <div className="desc" data-trigger>
                <p className="desc_txt">
                  12.8인치의 대형 화면의 스마트패드가 탑재되어 차량 정보 확인, 공조시스템 제어가
                  가능합니다.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="detail slide" data-snap>
        <div className="item full">
          <ul {...events} ref={ref}>
            <li className="item">
              <img src={ImgSample} alt="" />
            </li>
          </ul>
        </div>
      </section>
      {/* <section className="inner" data-snap>
        <div className="logo_wrap">
          <h4 className="logo">
            <span className="blind">BYD</span>
          </h4>
        </div>
      </section> */}
      <div className="reserve_mo mo_view">
        <ReserveBtn />
      </div>
    </div>
  );
}

export default Advance;
