export function headerScrollAction(scrollPosition, lastScrollPosition) {
  // scrollPosition : 현재 스크롤 값, lastScrollPosition : 이전 스크롤 값
  const DELTA = 10; // 스크롤 시 변경 가능 수치
  const headerWrap = document.querySelector('header');
  const headerHeight = headerWrap.offsetHeight;

  if (scrollPosition === 0) {
    scrollPosition += DELTA;
  }

  // 이전 스크롤 값과 현재 스크롤 값이 10 이상 차이날 경우만 실행
  if (Math.abs(lastScrollPosition - scrollPosition) < DELTA) return;
  // Scroll
  if (scrollPosition > lastScrollPosition && lastScrollPosition > headerHeight) {
    // headerWrap.classList.add('active');
    if (headerWrap.classList.contains('bg_tr')) {
      headerWrap.classList.remove('bg_tr');
    }
  } else {
    // headerWrap.classList.remove('active');
    if (lastScrollPosition < headerHeight) {
      headerWrap.classList.add('bg_tr');
    }
  }
}
