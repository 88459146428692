import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../api/ax';
import requests from '../../../../api/requests';

const initialState = {
  cityList: [],
  townList: [],
};

export const getCityList = createAsyncThunk('mapCity/getCityList', async () => {
  const res = await axios.get(requests.CITY_LIST);
  let cityListData = res.data.data;
  return cityListData;
});

export const getTownList = createAsyncThunk('mapCity/getTownList', async (seqNum) => {
  const res = await axios.get(requests.CITY_LIST + '?cd=' + seqNum);
  let townListData = res.data.data;
  return townListData;
});

const mapCitySlice = createSlice({
  name: 'mapCity',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCityList.fulfilled, (state, { payload }) => {
      state.cityList = payload.cityList;
    });
    builder.addCase(getTownList.fulfilled, (state, { payload }) => {
      state.townList = payload.cityList;
    });
  },
});

export default mapCitySlice;
