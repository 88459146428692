import { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AxiosInterceptor } from './api/ax';
import { headerScrollAction } from './assets/js/header';
import BlockUrlAccess from './BlockUrlAccess';
import CookieAgreeLayOut from './CookieAgreeLayOut';
import { useAppDispatch } from './store';
import { actChangeDepth1LPopUp } from './utile/slice/layerPopUpSlice';

// style
import './assets/styles/style.scss';
import RouteChangeTracker from './utile/RouteChangeTracker';

import Advance from './components/pages/advance/Index';

import Loading from './components/common/Loading';
import ScrollTopBtn from './components/common/ScrollTopBtn';
import WideLayout from './components/layouts/WideLayout';

import Common from './components/common/Common';
import Introduce from './components/pages/model/introduce/Index';

// pages
import Reservdoc from './components/pages/buy/estimate/document/Document';
import CompEstimate from './components/pages/buy/estimate/document/ReservaComp';
import Docreserv from './components/pages/buy/estimate/document/Reservation';
import SelecEstimate from './components/pages/buy/estimate/part0/Index';
import Model from './components/pages/buy/estimate/part1/Index';
import Color from './components/pages/buy/estimate/part2/Index';
import Option from './components/pages/buy/estimate/part3/Index';
import Detail from './components/pages/buy/estimate/part4/Index';
import { default as Buyguide } from './components/pages/buy/guide/Index';
import Reservation from './components/pages/buy/reservation/Reservation';
import DriveReservation from './components/pages/buy/drive/Reservation';
import Subven from './components/pages/buy/subven/Index';
import Tco from './components/pages/buy/tco/Tco';
import Contact from './components/pages/customer/contact/Index';
import Community from './components/pages/customer/community/Index';
import ConditionDetail from 'components/pages/customer/condition/detail/Index';
import Condition from './components/pages/customer/condition/Index';
import EventDetail from './components/pages/customer/event/detail/Index';
import Event from './components/pages/customer/event/Index';
import Qna from './components/pages/customer/qna/Index';
import QnaDetail from './components/pages/customer/qna/detail/Index';
import QnaWrite from './components/pages/customer/qna/Write';
import Faq from './components/pages/customer/faq/Index';
import NewsDetail from './components/pages/customer/notice/news/detail/Index';
import News from './components/pages/customer/notice/Index';
import SnsDetail from './components/pages/customer/notice/sns/detail/Index';
import Sns from './components/pages/customer/notice/Index';
import NoticeDetail from './components/pages/customer/notice/notice/detail/Index';
import Notice from './components/pages/customer/notice/Index';
import ReviewDetail from './components/pages/customer/community/review/detail/Index';
import ReviewWrite from './components/pages/customer/community/review/Write';
import Review from './components/pages/customer/community/Index';
import GalleryDetail from './components/pages/customer/community/gallery/detail/Index';
import Gallery from './components/pages/customer/community/Index01';
import ModelGallery from './components/pages/model/gallery/Index';
import Performance from './components/pages/model/performance/Index';
import Price from './components/pages/model/price/Index';
import Guide from './components/pages/service/guide/Index';
import ServiceInfo from './components/pages/service/info/Index';
import Network from './components/pages/service/network/Index';
import PartInfo from './components/pages/service/partInfo/Index';

// 대체페이지
import AccessError from './components/common/replacementPage/AccessError';
import Nopage from './components/common/replacementPage/NoPage';
import PersonalInfo from './components/common/replacementPage/PersonalInfo';
import SystemFix from './components/common/replacementPage/SystemFix';
import Terms from './components/common/replacementPage/Terms';

// main page
import MainPage from './components/pages/mainPage/Index';

// pagelist
import PageList from './static/PageList';

//임시
import ScrollToTop from 'utile/ScrollToTop';
import BrandByd from './components/pages/brand/Byd';
import BrandGsg from './components/pages/brand/Gsg';

// code splitting으로 인한 swiper 오류...
//임시
// const BrandByd = lazy(() => import('./components/pages/brand/Byd'));
// const BrandGsg = lazy(() => import('./components/pages/brand/Gsg'));

// // 대체페이지
// const AccessError = lazy(() => import('./components/common/replacementPage/AccessError'));
// const Nopage = lazy(() => import('./components/common/replacementPage/NoPage'));
// const Open = lazy(() => import('./components/common/replacementPage/Open'));
// const PersonalInfo = lazy(() => import('./components/common/replacementPage/PersonalInfo'));
// const SystemFix = lazy(() => import('./components/common/replacementPage/SystemFix'));
// const Terms = lazy(() => import('./components/common/replacementPage/Terms'));

// // 사전예약
// const Buyguide = lazy(() => import('./components/pages/buy/guide/Index'));
// const Advance = lazy(() => import('./components/pages/advance/Index'));

// const PageList = lazy(() => import('./static/PageList'));

// const Reservdoc = lazy(() => import('./components/pages/buy/estimate/document/Document'));
// const CompEstimate = lazy(() => import('./components/pages/buy/estimate/document/ReservaComp'));
// const Docreserv = lazy(() => import('./components/pages/buy/estimate/document/Reservation'));
// const SelecEstimate = lazy(() => import('./components/pages/buy/estimate/part0/Index'));

// const Model = lazy(() => import('./components/pages/buy/estimate/part1/Index'));
// const Color = lazy(() => import('./components/pages/buy/estimate/part2/Index'));
// const Option = lazy(() => import('./components/pages/buy/estimate/part3/Index'));
// const Detail = lazy(() => import('./components/pages/buy/estimate/part4/Index'));

// const Reservation = lazy(() => import('./components/pages/buy/reservation/Reservation'));
// const Subven = lazy(() => import('./components/pages/buy/subven/Index'));
// const Tco = lazy(() => import('./components/pages/buy/tco/Tco'));
// const Contact = lazy(() => import('./components/pages/customer/contact/Index'));
// const Event = lazy(() => import('./components/pages/customer/event/Index'));
// const EventDetail = lazy(() => import('./components/pages/customer/event/detail/Index'));
// const Faq = lazy(() => import('./components/pages/customer/faq/Index'));
// const NewsDetail = lazy(() => import('./components/pages/customer/news/detail/Index'));
// const News = lazy(() => import('./components/pages/customer/news/Index'));
// const NoticeDetail = lazy(() => import('./components/pages/customer/notice/detail/Index'));
// const Notice = lazy(() => import('./components/pages/customer/notice/Index'));

// const Gallery = lazy(() => import('./components/pages/model/gallery/Index'));
// const Performance = lazy(() => import('./components/pages/model/performance/Index'));
// const Price = lazy(() => import('./components/pages/model/price/Index'));
// const Guide = lazy(() => import('./components/pages/service/guide/Index'));
// const ServiceInfo = lazy(() => import('./components/pages/service/info/Index'));
// const Network = lazy(() => import('./components/pages/service/network/Index'));
// const PartInfo = lazy(() => import('./components/pages/service/partInfo/Index'));

// const Common = lazy(() => import('./components/common/Common'));

// const MainPage = lazy(() => import('./components/pages/mainPage/Index'));

// const Introduce = lazy(() => import('./components/pages/model/introduce/Index'));

function App() {
  RouteChangeTracker();

  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const header = document.querySelector('header');
    document.body.style.cssText = '';

    dispatch(actChangeDepth1LPopUp());

    // main header
    if (location.pathname === '/') {
      let scrollPosition = window.pageYOffset;
      let scrollPrev = 0;
      // 헤더스크롤 로드
      window.addEventListener('scroll', function () {
        scrollPosition = window.pageYOffset;
        // Header 인터랙션
        !!header && headerScrollAction(scrollPosition, scrollPrev);
        scrollPrev = scrollPosition;
      });

      // 모바일 높이값계산
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }, [location]);

  return (
    <div className="wrap">
      <div className="content">
        <AxiosInterceptor>
          <ScrollToTop />
          {/* <Suspense fallback={<Loading />}> */}

          {/* custom loading을 위한 테스트 라우팅 */}
          <Suspense fallback={<Loading />}>
            <Routes>
              {/* <Route exact path="/" element={<Open />} /> */}
              <Route exact path="/ad" element={<Advance />} />
              <Route exact path="/access-error" element={<AccessError />} />
              <Route exact path="/system-fix" element={<SystemFix />} />
              <Route element={<WideLayout />}>
                <Route exact path="/model/introduce" element={<Introduce />} />

                <Route exact path="/pagelist" element={<PageList />} />
                {/* 공통요소 */}
                <Route exact path="/common" element={<Common />} />
                <Route exact path="/personal-info" element={<PersonalInfo />} />
                <Route exact path="/terms" element={<Terms />} />
                <Route exact path="*" element={<Nopage />} />
                <Route element={<CookieAgreeLayOut />}>
                  {/* 메인 2023-02 JSG */}
                  <Route exact path="/" element={<MainPage />} />
                  {/* 사전예약페이지 */}
                  <Route exact path="/brand/byd" element={<BrandByd />} />
                  <Route exact path="/brand/gsg" element={<BrandGsg />} />
                  {/* 고객지원 */}
                  <Route exact path="/customer/notice" element={<Notice />} />
                  <Route exact path="/customer/notice/:bbsSeq" element={<NoticeDetail />} />
                  <Route exact path="/customer/event" element={<Event />} />
                  <Route exact path="/customer/event/:bbsSeq" element={<EventDetail />} />
                  <Route exact path="/customer/news" element={<News />} />
                  <Route exact path="/customer/news/:bbsSeq" element={<NewsDetail />} />
                  <Route exact path="/customer/sns" element={<Sns />} />
                  <Route exact path="/customer/sns/:bbsSeq" element={<SnsDetail />} />
                  <Route exact path="/customer/qna" element={<Qna />} />
                  <Route exact path="/customer/qna/:bbsSeq" element={<QnaDetail />} />
                  <Route exact path="/customer/qna/:bbsSeq/write" element={<QnaWrite />} />
                  <Route exact path="/customer/faq" element={<Faq />} />
                  <Route exact path="/customer/contact" element={<Contact />} />
                  <Route exact path="/customer/community" element={<Community />} />
                  <Route exact path="/customer/condition" element={<Condition />} />
                  <Route exact path="/customer/condition/:bbsSeq" element={<ConditionDetail />} />
                  <Route exact path="/customer/review" element={<Review />} />
                  <Route exact path="/customer/review/:bbsSeq" element={<ReviewDetail />} />
                  <Route exact path="/customer/review/:bbsSeq/write" element={<ReviewWrite />} />
                  <Route exact path="/customer/gallery" element={<Gallery />} />
                  <Route exact path="/customer/gallery/:bbsSeq" element={<GalleryDetail />} />
                  {/* 구매예약 */}
                  <Route exact path="/buy/reservation" element={<Reservation />} />
                  <Route exact path="/drive/reservation" element={<DriveReservation />} />
                  <Route exact path="/buy/tco" element={<Tco />} />
                  <Route exact path="/buy/subven" element={<Subven />} />

                  <Route exact path="/buy/estimate/select" element={<SelecEstimate />} />
                  <Route element={<BlockUrlAccess />}>
                    <Route exact path="/buy/estimate/model" element={<Model />} />
                    <Route exact path="/buy/estimate/color" element={<Color />} />
                    <Route exact path="/buy/estimate/option" element={<Option />} />
                    <Route exact path="/buy/estimate/detail" element={<Detail />} />
                    <Route exact path="/buy/document/doc" element={<Reservdoc />} />
                    <Route exact path="/buy/document/docreserv" element={<Docreserv />} />
                    <Route exact path="/buy/document/comp" element={<CompEstimate />} />
                  </Route>

                  <Route exact path="/buy/guide" element={<Buyguide />} />

                  {/* 모델 */}
                  <Route exact path="/model/price" element={<Price />} />
                  <Route exact path="/model/gallery" element={<ModelGallery />} />
                  <Route exact path="/model/performance" element={<Performance />} />
                  
                  {/* 서비스 */}
                  <Route exact path="/service/info" element={<ServiceInfo />} />
                  <Route exact path="/service/network" element={<Network />} />
                  <Route exact path="/service/part" element={<PartInfo />} />
                  <Route exact path="/service/guide" element={<Guide />} />
                  <Route exact path="/buy/document/" element={<Docreserv />} />
                  <Route exact path="/buy/estimate/select" element={<SelecEstimate />} />
                  <Route exact path="/buy/document/comp" element={<CompEstimate />} />
                  <Route exact path="/brand/byd" element={<BrandByd />} />
                  <Route exact path="/brand/gsg" element={<BrandGsg />} />
                </Route>
              </Route>
            </Routes>
          </Suspense>
        </AxiosInterceptor>
      </div>
      <ScrollTopBtn />
    </div>
  );
}

export default App;
