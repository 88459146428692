import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ax from '../../api/ax';

const initialState = {
  resultCode: 'loading',
};

export const postForm = createAsyncThunk('form/postForm', async (formData) => {
  const { form, reqUrl } = formData;

  const res = await ax.post(reqUrl, form);
  console.log(res);
  let resultCode = res.data.resultCode;
  return resultCode;
});

export const postFormSlice = createSlice({
  name: 'form',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(postForm.pending, (state) => {
      state.resultCode = 'loading';
    });
    builder
      .addCase(postForm.fulfilled, (state, { payload }) => {
        state.resultCode = payload;
      })
      .addCase(postForm.rejected, (state, { payload }) => {
        //보류
        state.resultCode = payload;
      });
  },
});

export default postFormSlice;
