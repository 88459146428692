import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  actShowDepth1LPopUp,
  actCb1LPopUp,
  actCb2LPopUp,
} from '../../../../utile/slice/layerPopUpSlice';
import { getCityList, actIsSelectMetro } from '../../../../utile/slice/citySlice';
import AlartPopUp from '../../../common/AlartPopUp';
import useVerifyMessageFormHook from '../../../../utile/hook/useVerifyMessageFormHook';
import requests from '../../../../api/requests';
import { autoHypen } from '../../../../utile/regExp';
import { actEffectToParentsAPopUp } from '../../../../utile/slice/alartPopUpSlice';

// 개인정보, 제3자동의 팝업
import PersonalInfoPop from '../../../common/popup/PersonalInfo';
import TermsPop from '../../../common/popup/Terms';

function Input() {
  const dispatch = useAppDispatch();

  //팝업창관련이벤트 시작//
  const alartInfo = {
    fail: {
      message: `구매상담 예약에 실패했습니다.<br>잠시 후 다시 시도해 주세요.`,
      cntBtn: 1,
    },
  };

  const { isShowAPopUp, isEffectToParentsAPopUp } = useAppSelector((state) => state.alartPopUp);

  const { isCb1LPopUp, isCb2LPopUp, depth1LPopUpName } = useAppSelector(
    (state) => state.layerPopUp,
  );

  const [syncIsCbAPopUp, setSyncIsCbAPopUp] = useState(isEffectToParentsAPopUp);

  useEffect(() => {
    setSyncIsCbAPopUp(true);
    if (syncIsCbAPopUp) {
      setSyncIsCbAPopUp(false);
      dispatch(actEffectToParentsAPopUp(false));
    }
  }, [isEffectToParentsAPopUp]);

  //팝업창관련이벤트 끝//

  //전화번호 하이픈 자동입력 이벤트시작//
  useEffect(() => {
    register('reserContactNum', {
      required: '신청 정보를 모두 입력해 주세요.',
      pattern: {
        value: /[0-9-]$/,
        message: '번호가 형식에 맞지 않습니다.',
      },
    });
    register('reserNm', {
      required: '신청 정보를 모두 입력해 주세요.',
      pattern: {
        value: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/,
        message: '이름이 형식에 맞지 않습니다.',
      },
    });
  }, []);

  const [AddHyphen, setAddHyphen] = useState('');

  useEffect(() => {
    setValue('reserContactNum', AddHyphen);
  }, [AddHyphen]);

  //전화번호 하이픈 자동입력 이벤트 끝//

  // 예약자이름 onChange 이벤트 시작
  const [reserName, setReserName] = useState('');

  useEffect(() => {
    setValue('reserNm', reserName);
  }, [reserName]);

  //예약자이름 onChange 이벤트 끝

  //거주지역 이벤트 시작//
  useEffect(() => {
    dispatch(getCityList());
  }, []);

  const { metroList, countryList } = useAppSelector((state) => state.city);

  const [resetCountyList, setResetCountyList] = useState(false);

  const getCountryList = (e) => {
    if (!e.target.value) {
      //2.셀렉초기값
      setResetCountyList(true);
      return;
    }
    //1.셀렉 메트로
    dispatch(getCityList(e.target.value));
    dispatch(actIsSelectMetro(true));
    setResetCountyList(false);
  };
  //거주지역 이벤트 끝//

  //폼관련 이벤트 시작//
  const { checkName, checkNumber } = useAppSelector((state) => state.verifyMessage);

  const { register, handleSubmit, onSubmit, onError, setValue, getValues, reset } =
    useVerifyMessageFormHook(alartInfo, requests.SEND_PURCHASE_FORM);

  const { resultCode } = useAppSelector((state) => state.form);

  useEffect(() => {
    if (resultCode === true) {
      reset();
      setAddHyphen('');
      setReserName('');
    } else return;
  }, [resultCode]);
  //폼관련 이벤트 끝//

  //약관이벤트 시작
  const onClickPersonalInfo = () => {
    let prsInfoCltAgrCont = getValues('prsInfoCltAgrCont');

    if (prsInfoCltAgrCont) {
      setValue('prsInfoCltAgrCont', false);
      dispatch(actCb1LPopUp('unChecked'));
    } else {
      dispatch(actCb1LPopUp('checked'));
      dispatch(actShowDepth1LPopUp('personal'));
    }
  };

  const onClickTerms = () => {
    let prsInfo3cProAgrCont = getValues('prsInfo3cProAgrCont');

    if (prsInfo3cProAgrCont) {
      setValue('prsInfo3cProAgrCont', false);
      dispatch(actCb2LPopUp('unChecked'));
    } else {
      dispatch(actCb2LPopUp('checked'));
      dispatch(actShowDepth1LPopUp('terms'));
    }
  };

  useEffect(() => {
    if (isCb1LPopUp === 'unChecked') {
      setValue('prsInfoCltAgrCont', false);
    } else if (isCb1LPopUp === 'checked') {
      setValue('prsInfoCltAgrCont', true);
    } else return;
  }, [isCb1LPopUp]);

  useEffect(() => {
    if (isCb2LPopUp === 'unChecked') {
      setValue('prsInfo3cProAgrCont', false);
    } else if (isCb2LPopUp === 'checked') {
      setValue('prsInfo3cProAgrCont', true);
    } else return;
  }, [isCb2LPopUp]);
  //약관이벤트 끝

  function inputFocus(input) {
    input.target.closest('.input_wrap').querySelector('.input_del').classList.add('focus');
  }
  function inputFocusOut(input) {
    setTimeout(() => {
      input.target.closest('.input_wrap').querySelector('.input_del').classList.remove('focus');
    }, 200);
  }
  function delTxt(input) {
    input.target.closest('.input_wrap').querySelector('input').value = '';
    input.target.closest('.input_wrap').querySelector('.input_del').classList.remove('focus');
  }

  return (
    <div className="reserv_input">
      {isShowAPopUp && <AlartPopUp />}
      {depth1LPopUpName === 'personal' && <PersonalInfoPop />}
      {depth1LPopUpName === 'terms' && <TermsPop />}
      {/* 차량추가시 주석만 풀어주면 백앤드서버로 선택차량 그대로 전송 됩니다. */}
      {/* <div className="img_wrap pc_view">
        <img src={Img} alt="" />
      </div> */}
      <form className="form" onSubmit={handleSubmit(onSubmit, onError)}>
        {/* <div className="input_radio_box">
          <div className="box_radio">
            <input
              type="radio"
              id="radio_box_1"
              value={document.querySelectorAll('.vehlNm')[0]?.innerHTML}
              name="item2"
              {...register('vehlNm', {
                required: '신청 정보를 모두 입력해 주세요.',
              })}
            />
            <label htmlFor="radio_box_1" className="input_radio vehlNm">
              T4K
            </label>
          </div>
          <div className="box_radio">
            <input
              type="radio"
              id="radio_box_2"
              value={document.querySelectorAll('.vehlNm')[1]?.innerHTML}
              name="item2"
              {...register('vehlNm', {
                required: '신청 정보를 모두 입력해 주세요.',
              })}
            />
            <label htmlFor="radio_box_2" className="input_radio vehlNm">
              T6K
            </label>
          </div>
          <div className="box_radio">
            <input
              type="radio"
              id="radio_box_3"
              value={document.querySelectorAll('.vehlNm')[2]?.innerHTML}
              name="item2"
              {...register('vehlNm', {
                required: '신청 정보를 모두 입력해 주세요.',
              })}
            />
            <label htmlFor="radio_box_3" className="input_radio vehlNm">
              T8K
            </label>
          </div>
        </div> */}
        <div className="form_wrap line_ty">
          <div className="input_wrap">
            <label htmlFor="input1" className="label">
              이름
            </label>
            <input
              id="input1"
              name="input1"
              type="text"
              placeholder="이름을 입력하세요."
              className="input_txt"
              onFocus={inputFocus}
              onBlur={inputFocusOut}
              maxLength={50}
              value={reserName}
              onChange={(e) => {
                setReserName(e.target.value);
              }}
            ></input>
            <button type="button" title="내용 지우기" className="input_del" onClick={delTxt}>
              <i className="ico_18_del"></i>
            </button>
          </div>
          <span className="err_msg">{checkName}</span>
        </div>
        <div className="form_wrap line_ty">
          <div className="input_wrap">
            <label htmlFor="input1" className="label">
              연락처
            </label>
            <input
              id="input1"
              name="input1"
              type="text"
              placeholder="010-1234-5678"
              className="input_txt"
              onFocus={inputFocus}
              onBlur={inputFocusOut}
              maxLength={13}
              value={autoHypen(AddHyphen)}
              onChange={(e) => {
                setAddHyphen(e.target.value);
              }}
            />
            <button type="button" title="내용 지우기" className="input_del" onClick={delTxt}>
              <i className="ico_18_del"></i>
            </button>
          </div>
          <span className="err_msg">{checkNumber}</span>
        </div>
        <div className="form_wrap line_ty">
          <div className="input_wrap">
            <label htmlFor="input3" className="label">
              거주지역
            </label>
            <div className="select_layout">
              <div className="select">
                <select
                  id="input1"
                  className="input_select"
                  {...register('sidoCd', {
                    onChange: (e) => getCountryList(e),
                    required: '신청 정보를 모두 입력해 주세요.',
                  })}
                >
                  <option value="">광역시/도</option>
                  {metroList?.map((metro) => (
                    <option key={metro.cd} value={metro.cd}>
                      {metro.addrName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="select">
                <select
                  id="input1"
                  className="input_select"
                  {...register('sigunguCd', {
                    required: '신청 정보를 모두 입력해 주세요.',
                  })}
                >
                  <option value="">시/군/구</option>
                  {resetCountyList
                    ? null
                    : countryList?.map((country) => (
                        <option key={country.cd} value={country.cd}>
                          {country.addrName}
                        </option>
                      ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="input_checkbox_basic">
          <div className="box_checkbox">
            <input
              type="checkbox"
              id="agree"
              name="item1"
              checked={getValues('prsInfoCltAgrCont') && isCb1LPopUp === 'checked' ? 'checked' : ''}
              onChange={() => {
                return;
              }}
            />
            <label
              className="input_checkbox"
              {...register('prsInfoCltAgrCont', {
                required: '약관에 동의하셔야 구매상담 신청이 가능합니다.',
              })}
              onClick={onClickPersonalInfo}
            >
              개인정보 수집과 이용에 동의합니다.
            </label>
          </div>
        </div>

        <div className="input_checkbox_basic">
          <div className="box_checkbox">
            <input
              type="checkbox"
              id="checkbox_basic_1"
              name="item1"
              checked={
                getValues('prsInfo3cProAgrCont') && isCb2LPopUp === 'checked' ? 'checked' : ''
              }
              onChange={() => {
                return;
              }}
            />
            <label
              className="input_checkbox"
              onClick={onClickTerms}
              {...register('prsInfo3cProAgrCont', {
                required: '약관에 동의하셔야 구매상담 신청이 가능합니다.',
              })}
            >
              개인정보 제3자 제공에 동의합니다.
            </label>
          </div>
        </div>
        <button className="btn_primary_l">
          <span>구매상담 신청</span>
        </button>
      </form>
    </div>
  );
}

export default React.memo(Input);
