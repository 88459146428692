import React, { useState } from 'react';
// sample image
import SampleImg from '../../assets/images/img/img_sample1.jpg';

function Common() {
  function inputFocus(input) {
    input.target.closest('.input_wrap').querySelector('.input_del').classList.add('focus');
  }
  function inputFocusOut(input) {
    setTimeout(() => {
      input.target.closest('.input_wrap').querySelector('.input_del').classList.remove('focus');
    }, 200);
  }
  function delTxt(input) {
    input.target.closest('.input_wrap').querySelector('input').value = '';
    input.target.closest('.input_wrap').querySelector('.input_del').classList.remove('focus');
  }

  return (
    <div className="wrap">
      <section className="common">
        {/* Typo */}
        <h1>Typo</h1>
        <div>
          <p className="typo_display1">typo_display1 / 700 / 80px / 125%</p>
          <p className="typo_header1">typo_header1 / 700 / 60px / 125%</p>
          <p className="typo_header2">typo_header2 / 700 / 48px / 120%</p>
          <p className="typo_header3">typo_header3 / 700 / 36px / 120%</p>
          <p className="typo_header4">typo_header4 / 700 / 28px / 120%</p>
          <p className="typo_title1">typo_title1 / 500 / 20px / 160%</p>
          <p className="typo_body1">typo_body1 / 400 / 16px / 150%</p>
          <p className="typo_body2">typo_body2 / 400 / 14px / 135%</p>
          <p className="typo_body3">typo_body3 / 400 / 12px / 135%</p>
        </div>

        {/* button */}
        <h1>Button</h1>
        <div>
          <button type="button" title="버튼" className="btn_primary_l">
            <span>button</span>
          </button>
          <button type="button" title="버튼" disabled className="btn_primary_l">
            <span>button</span>
          </button>
          <button type="button" title="버튼" className="btn_ghost_l">
            <span>button</span>
          </button>
          <button type="button" title="버튼" disabled className="btn_ghost_l">
            <span>button</span>
          </button>
          <button type="button" title="버튼" className="btn_primary_m">
            <span>button</span>
          </button>
          <button type="button" title="버튼" disabled className="btn_primary_m">
            <span>button</span>
          </button>
          <button type="button" title="버튼" className="btn_second_m">
            <span>button</span>
          </button>
          <button type="button" title="버튼" disabled className="btn_second_m">
            <span>button</span>
          </button>
          <button type="button" title="버튼" className="btn_ghost_m">
            <span>button</span>
          </button>
          <button type="button" title="버튼" disabled className="btn_ghost_m">
            <span>button</span>
          </button>
          <button type="button" title="버튼" className="btn_primary_s">
            <span>button</span>
          </button>
          <button type="button" title="버튼" disabled className="btn_primary_s">
            <span>button</span>
          </button>
          <button type="button" title="버튼" className="btn_second_s">
            <span>button</span>
          </button>
          <button type="button" title="버튼" disabled className="btn_second_s">
            <span>button</span>
          </button>
          <button type="button" title="버튼" className="btn_ghost_s">
            <span>button</span>
          </button>
          <button type="button" title="버튼" disabled className="btn_ghost_s">
            <span>button</span>
          </button>
          <button type="button" title="버튼" className="btn_radius active">
            버튼명
          </button>
          <button type="button" title="버튼" className="btn_radius">
            버튼명
          </button>
        </div>
        <div>
          <button type="button" title="버튼" className="btn_txt">
            <span>button</span>
          </button>
          <button type="button" title="버튼" className="btn_txt_bk">
            <span>button</span>
          </button>
        </div>

        {/* list */}
        <h1>List</h1>
        <div>
          <ul className="list_wrap1">
            <li>
              <button type="button" title="페이지 이동" className="list">
                <div className="tlt_wrap">
                  <strong className="tlt">
                    공지사항 게시물 타이틀 영역입니다.공지사항 게시물 타이틀 영역입니다.공지사항
                    게시물 타이틀 영역입니다.공지사항 게시물 타이틀 영역입니다.
                    <i className="ico_24_fix"></i>
                  </strong>
                  <span className="date">2022.12.01</span>
                </div>
                <p className="desc">
                  공지사항 게시글이 한줄 노출됩니다.공지사항 게시글이 한줄 노출됩니다.공지사항
                  게시글이 한줄 노출됩니다.공지사항 게시글이 한줄 노출됩니다.공지사항 게시글이 한줄
                  노출됩니다.공지사항 게시글이 한줄 노출됩니다.
                </p>
              </button>
            </li>
            <li>
              <button type="button" title="페이지 이동" className="list">
                <div className="tlt_wrap">
                  <strong className="tlt">
                    공지사항 게시물 타이틀 영역입니다.공지사항 게시물 타이틀 영역입니다.공지사항
                    게시물 타이틀 영역입니다.공지사항 게시물 타이틀 영역입니다.
                    <i className="ico_24_fix"></i>
                  </strong>
                  <span className="date">2022.12.01</span>
                </div>
                <p className="desc">
                  공지사항 게시글이 한줄 노출됩니다.공지사항 게시글이 한줄 노출됩니다.공지사항
                  게시글이 한줄 노출됩니다.공지사항 게시글이 한줄 노출됩니다.공지사항 게시글이 한줄
                  노출됩니다.공지사항 게시글이 한줄 노출됩니다.
                </p>
              </button>
            </li>
            <li>
              <button type="button" title="페이지 이동" className="list">
                <div className="tlt_wrap">
                  <strong className="tlt">
                    공지사항 게시물 타이틀 영역입니다.공지사항 게시물 타이틀 영역입니다.공지사항
                    게시물 타이틀 영역입니다.공지사항 게시물 타이틀 영역입니다.
                    <i className="ico_24_fix"></i>
                  </strong>
                  <span className="date">2022.12.01</span>
                </div>
                <p className="desc">
                  공지사항 게시글이 한줄 노출됩니다.공지사항 게시글이 한줄 노출됩니다.공지사항
                  게시글이 한줄 노출됩니다.공지사항 게시글이 한줄 노출됩니다.공지사항 게시글이 한줄
                  노출됩니다.공지사항 게시글이 한줄 노출됩니다.
                </p>
              </button>
            </li>
            <li>
              <button type="button" title="페이지 이동" className="list">
                <div className="tlt_wrap">
                  <strong className="tlt">
                    공지사항 게시물 타이틀 영역입니다.공지사항 게시물 타이틀 영역입니다.공지사항
                    게시물 타이틀 영역입니다.공지사항 게시물 타이틀 영역입니다.
                    <i className="ico_24_fix"></i>
                  </strong>
                  <span className="date">2022.12.01</span>
                </div>
                <p className="desc">
                  공지사항 게시글이 한줄 노출됩니다.공지사항 게시글이 한줄 노출됩니다.공지사항
                  게시글이 한줄 노출됩니다.공지사항 게시글이 한줄 노출됩니다.공지사항 게시글이 한줄
                  노출됩니다.공지사항 게시글이 한줄 노출됩니다.
                </p>
              </button>
            </li>
          </ul>
          <ul className="list_wrap4">
            <li className="list">
              <div className="tlt_wrap">
                <strong className="tlt">FAQ 타이틀이 들어갑니다.</strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">
                차량 내부와 트렁크를 청결히 하시고, 차량 외관도 깨끗하게 세차 합니다. 이 때, 차량
                외관의 작은 상처(기스)는 수리 후에 판매 하시는 것이 좋습니다. 또한 구매 후에
                장착하신 옵션 등의 부품은 메이커 순정품일 경우, 가치 평가를 받으 실 때 유리하게
                작용합니다. 이밖에 고객님이 차를 보유하시던 중에 특별히 신경써서 관리 하신 부분,
                예를 들어 소모품의 정기적 교환, 차계부 정리, 비흡연, 시내 출퇴근용으로만 사용 등의
                내 차만의 장점을 말씀해주시면 차량의 가치가 더욱 올라갑니다. 이상입니다. 차량 내부와
                트렁크를 청결히 하시고, 차량 외관도 깨끗하게 세차 합니다. 이 때, 차량 외관의 작은
                상처(기스)는 수리 후에 판매 하시는 것이 좋습니다. 또한 구매 후에 장착하신 옵션 등의
                부품은 메이커 순정품일 경우, 가치 평가를 받으 실 때 유리하게 작용합니다. 이밖에
                고객님이 차를 보유하시던 중에 특별히 신경써서 관리 하신 부분, 예를 들어 소모품의
                정기적 교환, 차계부 정리, 비흡연, 시내 출퇴근용으로만 사용 등의 내 차만의 장점을
                말씀해주시면 차량의 가치가 더욱 올라갑니다. 이상입니다.
              </div>
            </li>
            <li className="list">
              <div className="tlt_wrap">
                <strong className="tlt">
                  FAQ 타이틀이 들어갑니다. 파트너사가 아닌 다른 은행/금융기관을 통해 T4K 차량을
                  구매한 경우 납부 방법이 달라지는지 궁금합니다. FAQ 타이틀이 들어갑니다. 파트너사가
                  아닌 다른 은행/금융기관을 통해 T4K 차량을 구매한 경우 납부 방법이 달라지는지
                  궁금합니다.
                </strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">내용입니다.</div>
            </li>
            <li className="list">
              <div className="tlt_wrap">
                <strong className="tlt">FAQ 타이틀이 들어갑니다.</strong>
                <button type="button" title="내용 열기">
                  <i className="ico_32_accoup"></i>
                </button>
              </div>
              <div className="acco_cont">내용입니다.</div>
            </li>
          </ul>
        </div>

        {/* Sublist */}
        <h1>Sub List</h1>
        <div>
          <ul className="info">
            <li>
              - 선택품목을 포함한 최종가격은 개별소비세 최대인하금액에 따라 상이할 수
              있습니다.(최종판매가는견적서확인필요)
            </li>
            <li>
              - 모든선택품목가격은 개별소비세 감면 전 기준으로 표기하였으며 개별소비세 감면액이
              반영된 실제판매가는 견적 시 필히 확인바랍니다.{' '}
            </li>
            <li>
              - 본 가격표상의 품목(사양,컬러등) 및 가격은 차량성능개선이나 관련법규, 당사사정에 따라
              예고없이 변경될 수 있습니다.
            </li>
          </ul>
          <dl className="list_dl1">
            <dt>주소</dt>
            <dd>서울특별시 강남구 논현로 508 GS강남타워 GS글로벌 T4K 서비스센터</dd>
            <dt>전화번호</dt>
            <dd>042-582-1199</dd>
            <dt>운영 시간</dt>
            <dd>08:30~18:00 (평일/토요일근무)</dd>
            <dt>오시는 길</dt>
            <dd>· 오산IC: 1번국도 평택방면 (10분 소요) 송탄출장소 직전 우측 위치</dd>
            <dd>
              · 안성IC: 평택방면 좌회전 → 평택외곽산업도로(평택공단) → 1번국도 진입 → 송탄공단 →
              송탄출장소 지나 좌측 위치 (20분 소요)
            </dd>
            <dd>· 1번국도 송탄 출장소 건너 신한은행 옆 위치</dd>
          </dl>
          <dl className="list_dl2">
            <dt>파워트레인/성능</dt>
            <dd>
              EV 전용 플랫폼, 140kW 구동 모터, 82.02kWh 리튬인산철 블레이드배터리, 감속기,
              통합주행모드, 유압식 파워 스티어링, 회생제동 시스템, 배터리 프로텍터, 중량짐용 후륜
              현가장치, 고속 충전 포트 (멀티고속충전시스템) 배터리히팅시스템 기본 탑재, 중량짐용
              후륜 현가장치
            </dd>
            <dt>안전</dt>
            <dd>
              운전석 에어백, 차체자세 제어기능, 경사로 밀림방지 기능, 급제동 경보기능, 가상 엔진
              사운드(보행자 보호), 후진 경고음 발생장치, 타이어 응급처치 키트, TPMS
            </dd>
            <dt>외관</dt>
            <dd>
              195/70R15C-8PR 타이어, 헤드램프(안개등, 주간주행등 포함), 자외선 차단 유리(앞면), 고급
              범퍼, 아웃사이드 미러(전동접이, 열선, 전동미러조절), 적재함 브림커버, 충전구(DC1
              콤보타입), EV 전용 엠블럼(데칼
            </dd>
            <dt>공조</dt>
            <dd>풀오토 에어컨</dd>
            <dt>편의/내장</dt>
            <dd>
              실내 220V 전원장치 1 포트, USB 2 포트, EV 전용 클러스터 , 인조가죽시트, 운전석
              리클라이닝 & 슬라이딩 시트, 동승석 리클라이닝 시트, 도어맵 포켓, 센터 시트백 콘솔박스,
              운전석/조수석파워윈도우, 파워 아웃렛, 중앙집중식 도어잠금 장치, 트립컴퓨터, D&N
              룸미러, 고급코일보조매트, 후방유리 열선, 리놀륨 플로어매트, 간이정비용 오픈후드,
              쇽업쇼바, 운전석 열선시트, 운전석 통풍시트, 전동식 럼버서포트, 가죽 스티어링
              휠(열선포함), 운전석 선바이저 거울, 오토라이트 컨트롤, 트리플 턴 시그널, 전동식 파킹
              브레이크(오토홀드 포함), 버튼시동 & 스마트키, 후방 주차거리 경고, 시가잭라이터, 다이얼
              전자식 변속기어, 예약 충전 시스템
            </dd>
          </dl>
        </div>

        {/* form */}
        <h1>Form</h1>
        <div>
          <div className="form_wrap error line_ty">
            <div className="input_wrap">
              <label htmlFor="input1" className="label require">
                title
              </label>
              <input
                id="input1"
                name="input1"
                type="text"
                placeholder="placeholder"
                className="input_txt"
                onFocus={inputFocus}
                onBlur={inputFocusOut}
              ></input>
              <button type="button" title="내용 지우기" className="input_del" onClick={delTxt}>
                <i className="ico_18_del"></i>
              </button>
            </div>
            <span className="err_msg">Help or Error message area</span>
          </div>
          <div className="form_wrap">
            <label htmlFor="input2" className="label">
              title
            </label>
            <div className="input_wrap">
              <input
                id="input2"
                name="input2"
                type="text"
                placeholder="placeholder"
                className="input_txt"
              ></input>
              <button type="button" title="내용 지우기" className="input_del">
                <i className="ico_24_del"></i>
              </button>
            </div>
          </div>
          <div className="form_wrap">
            <div className="input_wrap">
              <input
                id="input3"
                name="input3"
                type="text"
                placeholder="placeholder"
                className="input_txt"
              ></input>
            </div>
            <button type="button" title="확인" className="btn_second_m">
              <span>Confirm</span>
            </button>
          </div>
          <div className="form_wrap">
            <div className="input_wrap">
              <input
                id="input2"
                name="input2"
                type="text"
                placeholder="disabled"
                disabled
                className="input_txt"
              ></input>
            </div>
          </div>
          <div className="form_wrap">
            <div className="input_wrap">
              <input
                id="input2"
                name="input2"
                type="text"
                placeholder="readonly"
                readOnly
                className="input_txt"
              ></input>
            </div>
          </div>
          <div className="form_wrap">
            <div className="input_wrap">
              <div className="select_default">
                <select id="input1" className="input_select">
                  <option value="">placeholder</option>
                  <option value="option1">옵션1</option>
                  <option value="option2">옵션2</option>
                  <option value="option3">옵션3</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form_wrap line_ty">
            <div className="input_wrap">
              <label htmlFor="input1" className="label require">
                title
              </label>
              <div className="select_layout">
                <div className="select">
                  <select id="input1" className="input_select">
                    <option value="">광역시/도</option>
                    <option value="option1">옵션1</option>
                    <option value="option2">옵션2</option>
                    <option value="option3">옵션3</option>
                  </select>
                </div>
                <div className="select">
                  <select id="input1" className="input_select">
                    <option value="">시/군/구</option>
                    <option value="option1">옵션1</option>
                    <option value="option2">옵션2</option>
                    <option value="option3">옵션3</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="form_wrap line_ty">
            <div className="input_wrap">
              <label htmlFor="input1" className="label">
                title
              </label>
              <input
                id="input3"
                name="input3"
                type="text"
                placeholder="placeholder"
                className="input_txt"
              ></input>
              <button type="button" title="확인" className="btn_second_m">
                <span>Confirm</span>
              </button>
            </div>
          </div>
        </div>

        {/* table */}
        <h1>Table</h1>
        <div>
          <div className="table_wrap">
            <div className="table_scroll_wrap">
              <table>
                <colgroup>
                  <col width="204px" />
                  <col width="205px" />
                  <col width="835px" />
                  <col width="284px" />
                </colgroup>
                <caption className="blind">테이블 제목</caption>
                <thead>
                  <tr>
                    <th>유지보수 주기</th>
                    <th>
                      판매가격 *VAT포함
                      <br />
                      (최대 혜택 적용 시)
                    </th>
                    <th>기본품목</th>
                    <th>선택품목</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>슈퍼캡 프리미엄</td>
                    <td className="txt_center">
                      43,690,000
                      <br />
                      (27,123,000)
                    </td>
                    <td>
                      <dl>
                        <dt>슈퍼캡 롱레인지 기본품목 및</dt>
                        <dd>
                          하이패스 룸미러 <br />
                          12.8인치 안드로이드 OS Pad
                        </dd>
                      </dl>
                    </td>
                    <td>
                      동승석 에어백 (200,000)
                      <br />
                      V2L실외 전원장치 (1,000,000)
                    </td>
                  </tr>
                  <tr>
                    <td>슈퍼캡 롱레인지</td>
                    <td className="txt_center">
                      45,250,000
                      <br />
                      (26,310,000)
                    </td>
                    <td>
                      <dl>
                        <dt>파워트레인/성능</dt>
                        <dd>
                          EV 전용 플랫폼, 140kW 구동 모터, 82.02kWh 리튬인산철 블레이드배터리,
                          감속기, 통합주행모드, 유압식 파워 스티어링, 회생제동 시스템, 배터리
                          프로텍터, 중량짐용 후륜 현가장치, 고속 충전 포트 (멀티고속충전시스템)
                          배터리히팅시스템 기본 탑재, 중량짐용 후륜 현가장치
                        </dd>
                        <dt>안전</dt>
                        <dd>
                          운전석 에어백, 차체자세 제어기능, 경사로 밀림방지 기능, 급제동 경보기능,
                          가상 엔진 사운드(보행자 보호), 후진 경고음 발생장치, 타이어 응급처치 키트,
                          TPMS
                        </dd>
                        <dt>외관</dt>
                        <dd>
                          195/70R15C-8PR 타이어, 헤드램프(안개등, 주간주행등 포함), 자외선 차단
                          유리(앞면), 고급 범퍼, 아웃사이드 미러(전동접이, 열선, 전동미러조절),
                          적재함 브림커버, 충전구(DC1 콤보타입), EV 전용 엠블럼(데칼)
                        </dd>
                        <dt>공조</dt>
                        <dd>풀오토 에어컨</dd>
                        <dt>편의/내장</dt>
                        <dd>
                          실내 220V 전원장치 1 포트, USB 2 포트, EV 전용 클러스터 , 인조가죽시트,
                          운전석 리클라이닝 & 슬라이딩 시트, 동승석 리클라이닝 시트, 도어맵 포켓,
                          센터 시트백 콘솔박스, 운전석/조수석파워윈도우, 파워 아웃렛, 중앙집중식
                          도어잠금 장치, 트립컴퓨터, D&N 룸미러, 고급코일보조매트, 후방유리 열선,
                          리놀륨 플로어매트, 간이정비용 오픈후드, 쇽업쇼바, 운전석 열선시트, 운전석
                          통풍시트, 전동식 럼버서포트, 가죽 스티어링 휠(열선포함), 운전석 선바이저
                          거울, 오토라이트 컨트롤, 트리플 턴 시그널, 전동식 파킹 브레이크(오토홀드
                          포함), 버튼시동 & 스마트키, 후방 주차거리 경고, 시가잭라이터, 다이얼
                          전자식 변속기어, 예약 충전 시스템
                        </dd>
                      </dl>
                    </td>
                    <td>
                      하이패스 룸미러 (200,000)
                      <br />
                      12.8인치 안드로이드 OS Pad (1,500,000)
                      <br />
                      동승석 에어백 (200,000)
                      <br />
                      V2L실외 전원장치 (1,000,000)
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="pagination">
              <button type="button" title="처음으로" className="page_ctrl">
                <i className="ico_24_first"></i>
              </button>
              <button type="button" title="이전으로" className="page_ctrl">
                <i className="ico_24_prev"></i>
              </button>
              <button type="button" title="페이지이동" className="page_item active">
                1
              </button>
              <button type="button" title="페이지이동" className="page_item">
                2
              </button>
              <button type="button" title="페이지이동" className="page_item">
                3
              </button>
              <button type="button" title="페이지이동" className="page_item">
                4
              </button>
              <button type="button" title="페이지이동" className="page_item">
                5
              </button>
              <button type="button" title="다음으로" className="page_ctrl">
                <i className="ico_24_next"></i>
              </button>
              <button type="button" title="끝으로" className="page_ctrl">
                <i className="ico_24_last"></i>
              </button>
            </div>
            <span className="info">2023년 1월 30일 기준</span>
            <ul className="info">
              <li className="sub">
                ※ 자동차관리법 제32조의2 1항의4에 따라 부품가격정보를 제공합니다.
              </li>
              <li className="sub">
                ※ 부품가격은 수시로 변동될 수 있으며, 실제 가격은 구매시점에 따라 다릅니다.
              </li>
            </ul>
          </div>
          <div className="table_wrap th_lf">
            <table>
              <colgroup>
                <col width="40%" />
                <col width="60%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>전장 (mm)</th>
                  <td>5,310</td>
                </tr>
                <tr>
                  <th>전폭 (mm)</th>
                  <td>1,770</td>
                </tr>
                <tr>
                  <th>전고 (mm)</th>
                  <td>2,100</td>
                </tr>
                <tr>
                  <th>축간거리 (mm)</th>
                  <td>2,850</td>
                </tr>
                <tr>
                  <th>공차 중량 (kg)</th>
                  <td>2,320</td>
                </tr>
                <tr>
                  <th>배터리 용량 (kWh)</th>
                  <td>86</td>
                </tr>
                <tr>
                  <th>타이어 (inch)</th>
                  <td>19.5</td>
                </tr>
                <tr>
                  <th>최대적재량 (kg)</th>
                  <td>1,000</td>
                </tr>
                <tr>
                  <th>최고출력 (ps/rpm)</th>
                  <td>190/4,200</td>
                </tr>
                <tr>
                  <th>에너지 소비효율(전비) (km/kWh)</th>
                  <td>도심 3.0 / 복합 2.6 / 고속도로 2.3
                    <small>*산자부 인증 기준</small></td>
                </tr>
                <tr>
                  <th>1회 충전 주행거리 (km)</th>
                  <td>상온주행 최대 246km 저온주행 최대 209km
                    <small>* 환경부 누리집 등재 기준</small></td>
                </tr>
                <tr>
                  <th>충전시간</th>
                  <td>5.310</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Radio button */}
        <h1>Radio button</h1>
        <div>
          <div className="input_radio_basic">
            <div className="box_radio">
              <input type="radio" id="radio_basic_1" value="" name="item1" />
              <label htmlFor="radio_basic_1" className="input_radio">
                Text Label
              </label>
            </div>
            <div className="box_radio">
              <input type="radio" id="radio_basic_2" value="" name="item1" checked />
              <label htmlFor="radio_basic_2" className="input_radio">
                Text Label
              </label>
            </div>
            <div className="box_radio">
              <input type="radio" id="radio_basic_3" value="" name="item1" disabled />
              <label htmlFor="radio_basic_3" className="input_radio">
                Text Label
              </label>
            </div>
          </div>

          <div className="input_radio_box">
            <div className="box_radio">
              <input type="radio" id="radio_box_1" value="" name="item2" />
              <label htmlFor="radio_box_1" className="input_radio">
                Label
              </label>
            </div>
            <div className="box_radio">
              <input type="radio" id="radio_box_2" value="" name="item2" checked />
              <label htmlFor="radio_box_2" className="input_radio">
                Label
              </label>
            </div>
            <div className="box_radio">
              <input type="radio" id="radio_box_3" value="" name="item2" disabled />
              <label htmlFor="radio_box_3" className="input_radio">
                Label
              </label>
            </div>
          </div>
        </div>

        {/* Checkbox */}
        <h1>Checkbox</h1>
        <div>
          <div className="input_checkbox_basic">
            <div className="box_checkbox">
              <input type="checkbox" id="checkbox_basic_1" value="" name="item1" />
              <label htmlFor="checkbox_basic_1" className="input_checkbox">
                Text Label
              </label>
            </div>
            <div className="box_checkbox">
              <input type="checkbox" id="checkbox_basic_2" value="" name="item1" checked />
              <label htmlFor="checkbox_basic_2" className="input_checkbox">
                Text Label
              </label>
            </div>
            <div className="box_checkbox">
              <input type="checkbox" id="checkbox_basic_3" value="" name="item1" disabled />
              <label htmlFor="checkbox_basic_3" className="input_checkbox">
                Text Label
              </label>
            </div>
          </div>

          <div className="input_checkbox_box">
            <div className="box_checkbox">
              <input type="checkbox" id="checkbox_box_1" value="" name="item2" />
              <label htmlFor="checkbox_box_1" className="input_checkbox">
                Text Label
              </label>
            </div>
            <div className="box_checkbox">
              <input type="checkbox" id="checkbox_box_2" value="" name="item2" checked />
              <label htmlFor="checkbox_box_2" className="input_checkbox">
                Text Label
              </label>
            </div>
            <div className="box_checkbox">
              <input type="checkbox" id="checkbox_box_3" value="" name="item2" disabled />
              <label htmlFor="checkbox_box_3" className="input_checkbox">
                Text Label
              </label>
            </div>
          </div>
        </div>

        {/* pagination */}
        <h1>Pagination</h1>
        <div>
          <div className="pagination">
            <button type="button" title="처음으로" className="page_ctrl">
              <i className="ico_24_first"></i>
            </button>
            <button type="button" title="이전으로" className="page_ctrl">
              <i className="ico_24_prev"></i>
            </button>
            <button type="button" title="페이지이동" className="page_item active">
              1
            </button>
            <button type="button" title="페이지이동" className="page_item">
              2
            </button>
            <button type="button" title="페이지이동" className="page_item">
              3
            </button>
            <button type="button" title="페이지이동" className="page_item">
              4
            </button>
            <button type="button" title="페이지이동" className="page_item">
              5
            </button>
            <button type="button" title="다음으로" className="page_ctrl">
              <i className="ico_24_next"></i>
            </button>
            <button type="button" title="끝으로" className="page_ctrl">
              <i className="ico_24_last"></i>
            </button>
          </div>
        </div>

        {/* card content */}
        <h1>Card Content</h1>
        <div>
          <a href="/" title="페이지 이동" className="card_cont1">
            <div className="img_wrap">
              <img src={SampleImg} alt="" />
            </div>
            <strong className="tlt">이벤트 게시물 타이틀 영역입니다.</strong>
            <span className="date">2022.12.01 ~ 2022.12.31</span>
          </a>
          <div className="card_cont2">
            <div className="img_wrap">
              <img src={SampleImg} alt="" />
            </div>
            <div className="info_wrap">
              <strong className="tlt">김푸른하늘</strong>
              <dl>
                <dt>전화번호</dt>
                <dd>
                  <a href="tel:0222424363">02-2242-4363</a>
                </dd>
                <dt>이메일</dt>
                <dd>
                  <a href="mailto:aaaaa@bbbbb.com">aaaaa@bbbbb.com</a>
                </dd>
              </dl>
            </div>
          </div>
          <div className="card_cont3">
            <div className="cont_l">
              <strong className="tlt">개요</strong>
              <p className="desc">최초 주행 정보</p>
            </div>
            <a href="/" className="ico_60_down_pdf" title="파일 다운로드"></a>
          </div>
        </div>

        {/* visual */}
        <h1>Visual</h1>
        <div>
          <div className="visual">
            <h2 className="tlt">판매/서비스 네트워크</h2>
            <div className="btn_layout">
              <button type="button" className="btn_radius active">
                판매센터
              </button>
              <button type="button" className="btn_radius">
                서비스센터
              </button>
              <button type="button" className="btn_radius">
                부품센터
              </button>
            </div>
            <div className="input_layout">
              <div className="form_wrap">
                <div className="input_wrap">
                  <div className="select_default">
                    <select id="input1" className="input_select">
                      <option value="">모델명</option>
                      <option value="option1">옵션1</option>
                      <option value="option2">옵션2</option>
                      <option value="option3">옵션3</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form_wrap">
                <div className="input_wrap">
                  <div className="select_default">
                    <select id="input1" className="input_select">
                      <option value="">부품명칭</option>
                      <option value="option1">옵션1</option>
                      <option value="option2">옵션2</option>
                      <option value="option3">옵션3</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form_wrap">
                <div className="input_wrap">
                  <input
                    id="input4"
                    name="input4"
                    type="text"
                    placeholder="부품명을 입력하세요."
                    className="input_txt"
                  ></input>
                </div>
                <button type="button" className="btn_second_m">
                  검색
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* popup */}
        {/* <div className="popup_wrap"> */}
        {/* <div className="dim"></div>
          <div className="conf_inner notice">
            <div className="conf_cont">
              <img src={SampleImg} alt="" />
            </div>
            <div className="conf_footer">
              <label className="close_ck">
                <input type="checkbox" />
                다시 보지 않기
              </label>
              <button type="button" className="btn_txt_bk">
                <span>닫기</span>
              </button>
            </div>
          </div> */}
        {/* <div className="conf_inner">
            <div className="conf_header">
              <button type="button" title="팝업 닫기" className="ico_22_pop_close"></button>
            </div>
            <div className="conf_cont">
              <div className="sub_visual"></div>
              <strong className="tlt">다이알로그 안내 메시지입니다.</strong>
              <p className="desc">다이알로그 본문영역입니다. 자세한 내용은 가이드를 확인하세요.</p>
            </div>
            <div className="conf_footer">
              <div className="btn_layout">
                <button type="button" className="btn_ghost_m">
                  <span>취소</span>
                </button>
                <button type="button" className="btn_primary_m">
                  <span>확인</span>
                </button>
              </div>
            </div>
          </div> */}
        {/* <div className="pop_l_inner">
            <div className="pop_header">
              <strong className="tlt">팝업 타이틀 영역</strong>
              <button type="button" title="팝업 닫기" className="ico_22_pop_close"></button>
            </div>
            <div className="pop_cont">
              머티리얼 디자인은 인쇄 디자인 방법(타이포그래피, 그리드, 공간, 크기, 색상 및 이미지)을
              사용하여 계층, 의미 및 초점을 만들어 보는 사람이 경험에 몰입할 수 있도록 합니다.
              머티리얼 디자인은 인쇄 디자인 방법(타이포그래피, 그리드, 공간, 크기, 색상 및 이미지)을
              사용하여 계층, 의미 및 초점을 만들어 보는 사람이 경험에 몰입할 수 있도록 합니다.
              머티리얼 디자인은 인쇄 디자인 방법(타이포그래피, 그리드, 공간, 크기, 색상 및 이미지)을
              사용하여 계층, 의미 및 초점을 만들어 보는 사람이 경험에 몰입할 수 있도록 합니다.
              머티리얼 디자인은 인쇄 디자인 방법(타이포그래피, 그리드, 공간, 크기, 색상 및 이미지)을
              사용하여 계층, 의미 및 초점을 만들어 보는 사람이 경험에 몰입할 수 있도록 합니다.
              머티리얼 디자인은 인쇄 디자인 방법(타이포그래피, 그리드, 공간, 크기, 색상 및 이미지)을
              사용하여 계층, 의미 및 초점을 만들어 보는 사람이 경험에 몰입할 수 있도록 합니다.
              머티리얼 디자인은 인쇄 디자인 방법(타이포그래피, 그리드, 공간, 크기, 색상 및 이미지)을
              사용하여 계층, 의미 및 초점을 만들어 보는 사람이 경험에 몰입할 수 있도록 합니다.
            </div>
            <div className="pop_footer">
              <div className="btn_layout">
                <button type="button" className="btn_ghost_m">
                  <span>취소</span>
                </button>
                <button type="button" className="btn_primary_m">
                  <span>확인</span>
                </button>
              </div>
            </div>
          </div> */}
        {/* </div> */}
      </section>
    </div>
  );
}

export default Common;
