import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getConditionList } from '../../../../utile/slice/boardSlice';
import { actIsLoading } from '../../../../utile/slice/loadingSlice';
import PaginationList from '../../../common/pagination/BoardPagination';

function ConditionList() {
  const dispatch = useAppDispatch();

  const conditionList = useAppSelector((state) => state.board.conditionList);
  const Page = useAppSelector((state) => state.board.paginationData);
  const { state } = useLocation();
  const [reqParams, setReqParams] = useState(
    state
      ? state
      : {
          currentPageNo: 1,
          tab: 'y',
        },
  );
  
  useEffect(() => {
    dispatch(getConditionList(reqParams));
  }, [reqParams]);

  const onChangePageNo = (pageNum) => {
    setReqParams({ ...reqParams, currentPageNo: pageNum });
  };

  const onClickList = () => {
    dispatch(actIsLoading(true));
    setTimeout(() => {
      dispatch(actIsLoading(false));
    }, 2000);
  };

  return (
    <>
      <div className="visual inner">
        <h2 className="tlt">이달의 판매조건</h2>
      </div>
      <div className="inner condition_inner">
        <ul className="list_wrap2">
          {conditionList.map((condition) => (
            <li key={condition.bbsSeq}>
              <Link
                to={'/customer/condition/' + condition.bbsSeq}
                title="페이지 이동"
                className="card_cont1"
                state={reqParams}
                onClick={onClickList}
              >
                <div className="img_wrap">
                  <img src={condition.fileFullPath} alt="" />
                </div>
                <strong className="tlt">{condition.bbsTitNm}</strong>
                <span className="date">
                  {condition.eventStartDt} ~ {condition.eventEndDt}
                </span>
              </Link>
            </li>
          ))}
        </ul>
        <PaginationList paginationData={Page} currentNum={reqParams.currentPageNo} setCurrentNum={onChangePageNo} />
      </div>
    </>
  );
}

export default React.memo(ConditionList);
