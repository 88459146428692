import React from 'react';
import RelationPage from '../../../common/RelationPage';
import { Packages } from '../../../../components/pages/buy/estimate/part3/Index';
import { staticAutoComma } from '../../../../utile/regExp';

// import T4KCatalogPDF from '../../../../assets/pdfs/T4K_2023_catalog.pdf';

function Price() {
  function tab(classfi) {
    const category = document.querySelectorAll('[data-category]');
    const contain = document.querySelectorAll('[data-contain]');

    category.forEach((e) => {
      e.classList.remove('active');
      classfi.target.classList.add('active');
    });
    contain.forEach((e) => {
      e.setAttribute('style', 'display:none');

      if (classfi.target.dataset.category === e.dataset.contain) {
        e.setAttribute('style', 'display:block');
      }
    });
  }

  function sort1(menu) {
    const table = document.querySelectorAll('[data-table1]');
    const mu = document.querySelectorAll('[data-name1]');

    mu.forEach((e) => {
      e.classList.remove('active');
      menu.target.classList.add('active');
    });
    table.forEach((e) => {
      e.setAttribute('style', 'display:none');

      if (menu.target.dataset.name1 === e.dataset.table1) {
        e.setAttribute('style', 'display:block');
      }
    });

    //console.log();
  }

  function sort2(menu) {
    const table = document.querySelectorAll('[data-table2]');
    const mu = document.querySelectorAll('[data-name2]');

    mu.forEach((e) => {
      e.classList.remove('active');
      menu.target.classList.add('active');
    });
    table.forEach((e) => {
      e.setAttribute('style', 'display:none');

      if (menu.target.dataset.name2 === e.dataset.table2) {
        e.setAttribute('style', 'display:block');
      }
    });

    //console.log();
  }

  function accOpen(e) {
    const btn = e.target.closest('.tlt');
    const cont = e.target.closest('.tlt').nextSibling;

    btn.classList.toggle('active');
    cont.classList.toggle('active');
  }

  return (
    <div className="default_price">
      <div className="inner">
        <div className="visual">
          <h2 className="tlt">가격</h2>

          <div className="price_infor">
            {/* <div className="tab-head mo_view">
              <div className="tab-inner">
                <button type="button" className="active" onClick={tab} data-category="cargo">
                  T4K 카고
                </button>
                <button type="button" onClick={tab} data-category="special">
                  T4K 특장
                </button>
              </div>
            </div> */}

            <span className="info">2023년 4월 7일 기준 (단위 : 원)</span>
            {/* <a href={T4KCatalogPDF} target="_blank" className="btn_second_m">
              <span>카탈로그 가격표</span>
            </a> */}
          </div>
          <div className="table_wrap pc_view">
            <table data-table1="premium">
              <colgroup>
                <col width="20%" />
                <col width="50%" />
                <col width="30%" />
              </colgroup>
              <caption className="blind">가격표 테이블</caption>
              <thead>
                <tr>
                  <th>트림/가격</th>
                  <th>기본품목</th>
                  <th>풀옵션 패키지</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong className="tlt">슈퍼캡 롱레인지 프리미엄</strong>
                    <p className="option">판매가격</p>
                    <p className="price">
                      46,690,000
                      <span>* 패키지 할인 적용 가격</span>
                      <span>* Roland Silver 컬러 선택 시 15만원 추가 비용이 발생합니다.</span>
                    </p>
                  </td>
                  <td>
                    <dl className="list_dl2">
                      <dt>파워트레인/성능</dt>
                      <dd>
                        EV전용 플랫폼 · 140kW 구동 모터 · 82.02kWh 리튬인산철 블레이드배터리 ·
                        감속기 · 통합주행모드 · 유압식 파워 스티어링 · 회생제동 시스템 · 배터리
                        프로텍터 · 중량짐용 후륜 현가장치 · 고속 충전 포트 (멀티고속충전시스템) ·
                        배터리히팅시스템 기본 탑재 · 중량짐용 후륜 현가장치
                      </dd>
                    </dl>
                    <dl className="list_dl2">
                      <dt>안전</dt>
                      <dd>
                        운전석 에어백 · 차체자세 제어기능 · 경사로 밀림방지 기능 · 급제동 경보기능 ·
                        가상 엔진 사운드(보행자 보호) · 후진 경고음 발생장치 · 타이어 응급처치 키트
                        · TPMS
                      </dd>
                    </dl>
                    <dl className="list_dl2">
                      <dt>외관</dt>
                      <dd>
                        195/70R15C-8PR 타이어 · 헤드램프(안개등 · 주간주행등 포함) · 자외선 차단
                        유리(앞면) · 고급 범퍼 · 아웃사이드 미러(전동접이, 열선, 전동미러조절) ·
                        적재함 브림커버 · 충전구(DC1콤보타입) · EV 전용 엠블럼(데칼)
                      </dd>
                    </dl>
                    <dl className="list_dl2">
                      <dt>공조</dt>
                      <dd>풀오토 에어컨</dd>
                    </dl>
                    <dl className="list_dl2">
                      <dt>편의/내장</dt>
                      <dd>
                        실내 220V 전원장치 1 포트 · USB 2포트 · EV 전용 클러스터 · 인조가죽시트 ·
                        운전석 리클라이닝 & 슬라이딩 시트 · 동승석 리클라이닝 시트 · 도어맵 포켓 ·
                        센터 시트백 콘솔박스 · 운전석/조수석파워윈도우 · 파워 아웃렛 · 중앙집중식
                        도어잠금 장치 · 트립컴퓨터 · D&N 룸미러 · 고급코일보조매트 · 후방유리 열선 ·
                        리놀륨 플로어매트 · 간이정비용 오픈후드 · 쇽업쇼바 · 운전석 열선시트 ·
                        운전석 통풍시트 · 전동식 럼버서포트 · 가죽 스티어링 휠(열선포함) · 운전석
                        선바이저 거울 · 오토라이트 컨트롤 · 원터치 트리플 턴 시그널 · 전동식 파킹
                        브레이크(오토홀드 포함) · 버튼시동 & 스마트키 · 후방 주차거리 경고 ·
                        시가잭라이터 · 다이얼 전자식 변속기어 · 예약 충전 시스템
                      </dd>
                    </dl>
                  </td>
                  <td>
                    <ul class="packages">
                      {Packages.map((option) => {
                        return (
                          <li>
                            <figure></figure>
                            <span>{option.labelTemp || option.subtit}</span>
                            <b>{staticAutoComma(option.price)}</b>
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* mo */}
          <div className="cont mo_view">
            <div className="tit_wrap">
              <strong className="tlt">슈퍼캡 롱레인지 프리미엄</strong>
              <p className="option">판매가격</p>
              <p className="price">
                46,690,000
                <span>* 패키지 할인 적용 가격</span>
                <span>* Roland Silver 컬러 선택 시 15만원 추가 비용이 발생합니다.</span>
              </p>
            </div>
            <strong className="sub_tlt">기본품목</strong>
            <dl className="list_dl2">
              <dt>파워트레인/성능</dt>
              <dd>
                EV전용 플랫폼 · 140kW 구동 모터 · 82.02kWh 리튬인산철 블레이드배터리 · 감속기 ·
                통합주행모드 · 유압식 파워 스티어링 · 회생제동 시스템 · 배터리 프로텍터 · 중량짐용
                후륜 현가장치 · 고속 충전 포트 (멀티고속충전시스템) · 배터리히팅시스템 기본 탑재 ·
                중량짐용 후륜 현가장치
              </dd>
            </dl>
            <dl className="list_dl2">
              <dt>안전</dt>
              <dd>
                운전석 에어백 · 차체자세 제어기능 · 경사로 밀림방지 기능 · 급제동 경보기능 · 가상
                엔진 사운드(보행자 보호) · 후진 경고음 발생장치 · 타이어 응급처치 키트 · TPMS
              </dd>
            </dl>
            <dl className="list_dl2">
              <dt>외관</dt>
              <dd>
                195/70R15C-8PR 타이어 · 헤드램프(안개등 · 주간주행등 포함) · 자외선 차단 유리(앞면)
                · 고급 범퍼 · 아웃사이드 미러(전동접이, 열선, 전동미러조절) · 적재함 브림커버 ·
                충전구(DC1콤보타입) · EV 전용 엠블럼(데칼)
              </dd>
            </dl>
            <dl className="list_dl2">
              <dt>공조</dt>
              <dd>풀오토 에어컨</dd>
            </dl>
            <dl className="list_dl2">
              <dt>편의/내장</dt>
              <dd>
                실내 220V 전원장치 1 포트 · USB 2포트 · EV 전용 클러스터 · 인조가죽시트 · 운전석
                리클라이닝 & 슬라이딩 시트 · 동승석 리클라이닝 시트 · 도어맵 포켓 · 센터 시트백
                콘솔박스 · 운전석/조수석파워윈도우 · 파워 아웃렛 · 중앙집중식 도어잠금 장치 ·
                트립컴퓨터 · D&N 룸미러 · 고급코일보조매트 · 후방유리 열선 · 리놀륨 플로어매트 ·
                간이정비용 오픈후드 · 쇽업쇼바 · 운전석 열선시트 · 운전석 통풍시트 · 전동식
                럼버서포트 · 가죽 스티어링 휠(열선포함) · 운전석 선바이저 거울 · 오토라이트 컨트롤 ·
                원터치 트리플 턴 시그널 · 전동식 파킹 브레이크(오토홀드 포함) · 버튼시동 & 스마트키
                · 후방 주차거리 경고 · 시가잭라이터 · 다이얼 전자식 변속기어 · 예약 충전 시스템
              </dd>
            </dl>
            <div className="sub_tlt_wrap">
              <strong className="sub_tlt">풀옵션 패키지</strong>
            </div>
            <ul class="packages">
              {Packages.map((option) => {
                return (
                  <li>
                    <figure></figure>
                    <span>{option.labelTemp || option.subtit}</span>
                    <b>{staticAutoComma(option.price)}</b>
                  </li>
                );
              })}
            </ul>
          </div>

          <ul className="info">
            <li className="sub">
              - 선택품목을 포함한 최종가격은 개별소비세 최대인하금액에 따라 상이할 수
              있습니다.(최종판매가는견적서확인필요)
            </li>
            <li className="sub">
              - 모든선택품목가격은 개별소비세 감면 전 기준으로 표기하였으며 개별소비세 감면액이
              반영된 실제판매가는 견적 시 필히 확인바랍니다.
            </li>
            <li className="sub">
              - 본 가격표상의 품목(사양,컬러등) 및 가격은 차량성능개선이나 관련법규, 당사사정에 따라
              예고없이 변경될 수 있습니다.
            </li>
          </ul>
        </div>
      </div>
      <RelationPage />
    </div>
  );
}

export default Price;
