import React from 'react';

function SystemFix() {
  return (
    <div className="replace_page system_fix">
      <div className="txt_wrap">
        <strong className="tlt">시스템 점검 중입니다.</strong>
        <p className="desc">
          더 나은 서비스 제공을 위해 시스템을 점검하고 있습니다.
          <br />
          점검 시간에는 서비스 이용이 제한됩니다. <br className="mo_view" />
          점검 시간 이후 접속해주세요.
        </p>
        <dl className="info_box">
          <dt>점검기간</dt>
          <dd>2023.01.01 12:00 ~ 2023.01.02 04:00</dd>
          <dt>점검사유</dt>
          <dd>시스템 임시 점검</dd>
        </dl>
      </div>
    </div>
  );
}

export default SystemFix;
