import { createSlice } from '@reduxjs/toolkit';
import { onlyNum } from '../../../../../utile/regExp';

const initialState = {
  monthPrice: '0', // 월 납입금액
  interestPrice: '0', // 할부이자
  buyPrice: '0', // 차량 구매 가격(차랑가격 - 구매보조금)
  advancePrice: '', // 선수금
  figuresPrice: {
    shareVal: '0', // 할부원금
    restVal: 12, // 할부기간
  },
  figuresInterestPrice: {
    principalVal: 0,
    interestVal: 0,
  },
  figuresAdvancePrice: {
    buyVal: 0,
    advanceVal: '',
  },
};

let result = initialState.figuresPrice.shareVal;
const checkChar = onlyNum.test(result.substr(-1));
const removeCharValue = result.slice(0, result - 1);

const planPriceSlice = createSlice({
  name: 'planPrice',
  initialState,
  reducers: {
    resultBuyPrice: (state, { payload }) => {
      state.buyPrice = payload;
    },
    resultShare: (state, { payload }) => {
      state.figuresPrice.shareVal = payload;
    },
    resultRest: (state, { payload }) => {
      state.figuresPrice.restVal = payload;
    },
    resultMonthPrice: (state, { payload }) => {
      state.monthPrice = payload;
    },
    resultPrincipal: (state, { payload }) => {
      state.figuresInterestPrice.principalVal = payload;
    },
    resultInterest: (state, { payload }) => {
      state.figuresInterestPrice.interestVal = payload;
    },
    resultInterestPrice: (state, { payload }) => {
      state.interestPrice = payload;
    },
    resultAdvancePrice: (state, { payload }) => {
      state.advancePrice = payload;
    },
    actionToReset: (state) => {
      void (
        (state.monthPrice = 0),
        (state.interestPrice = 0),
        (state.buyPrice = 0),
        (state.advancePrice = 0),
        (state.figuresPrice.shareVal = 0),
        (state.figuresPrice.restVal = 12),
        (state.figuresInterestPrice.principalVal = 0),
        (state.figuresInterestPrice.interestVal = 0)
      );
    },
  },
});

export const {
  resultBuyPrice,
  resultShare,
  resultRest,
  resultMonthPrice,
  resultPrincipal,
  resultInterest,
  resultInterestPrice,
  resultAdvancePrice,
  actionToReset,
} = planPriceSlice.actions;
export default planPriceSlice;
