import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../api/ax';
import requests from '../../../../api/requests';

const initialState = {
    markerlist: [],
    centerDetail: {},
    salesManList: [],
    totalPageCount: '',
    isAdd: false,
    effectSync: false
};

export const getMarkerList = createAsyncThunk('serviceNetwork/getMarkerList', async params => {
    if(params.search){
        const res = await axios.get(requests.SERVICE_NETWORK_LIST +
            '?currentPageNo=' + params.currentPage + 
            '&limit=' + params.limit + 
            '&srchSvcNwTypeCd=' + params.selectSorting +
            '&srchRepairTypeCd=' + params.selectRepairType +
            '&srchSidoCd=' + params.searchSido +
            '&srchSigunguCd=' + params.searchSigungu +
            '&srchText=' + params.searchText
            );
        let markerListData = res.data.data;
        return markerListData;
    }
    else {
        const res = await axios.get(requests.SERVICE_NETWORK_LIST + '?currentPageNo=' + params.currentPage + '&limit=' + params.limit + '&srchSvcNwTypeCd=' + params.selectSorting);
        let markerListData = res.data.data;
        return markerListData;
    }
    
});

export const getCenterDetail = createAsyncThunk('serviceNetwork/getCenterDetail', async seqNum => {
    const res = await axios.get(requests.SERVICE_NETWORK_DETAIL + '?srchSvcNwSeq=' + seqNum);
    let centerDetailData = res.data.data;
    return centerDetailData;
});

export const getSalesManList = createAsyncThunk('serviceNetwork/getSalesManList', async seqNum => {
    const res = await axios.get(requests.SALES_MAN_LIST + '?srchSvcNwSeq=' + seqNum);
    let salesManListData = res.data.data;
    return salesManListData;
});

const mapSlice = createSlice({
    name: 'serviceNetwork',
    initialState,
    reducers: {
        actIsAdd: (state, { payload }) => {
            state.isAdd = payload;
        },
        actSync: (state) => {
            state.effectSync = true;
        },
    },
    extraReducers: builder => {
        builder.addCase(getMarkerList.fulfilled, (state, { payload }) => {
            state.markerlist = state.isAdd ? [...state.markerlist, ...payload.serviceNetworkList] : payload.serviceNetworkList;
            state.totalPageCount = payload.paginationInfo;
        });
        builder.addCase(getCenterDetail.fulfilled, (state, { payload }) => {
            state.centerDetail = payload.serviceNetworkDetail;
        });
        builder.addCase(getSalesManList.fulfilled, (state, { payload }) => {
            state.salesManList = payload.salesManList;
        });
    },
});

export const { actIsAdd, actSync } = mapSlice.actions;
export default mapSlice;
