import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { actAddInputEstimateForm } from '../../../../../utile/slice/tempFormSlice';
import { staticAutoComma } from '../../../../../utile/regExp';
import CountUp from 'react-countup';
import _ from 'lodash';
import EAlartPopUp from './EAlartPopUp';

function EstimateBar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function barOpen(e) {
    const detail = document.querySelector('.detail');
    const btn = e.target.closest('button');
    btn.classList.toggle('active');
    detail.classList.toggle('active');
  }

  const [sumOptionPrice, setSumOptionPrice] = useState(0);

  const { estimateForm } = useAppSelector((state) => state.tempForm);
  const { model_price, color_price, option_package_checked_list, OPTION_TYPE } = estimateForm;

  useEffect(() => {
    const sumOptionPrice = _.sum(
      option_package_checked_list?.map((option) => Number(option.optionPrice)),
    );
    setSumOptionPrice(sumOptionPrice);
  }, [option_package_checked_list]);

  // TODO: 초기 옵션 타입이 패키지로 설정이 안 됨.
  useEffect(() => {
    let totalPrice = Number(model_price) + Number(color_price);

    const isPremiumPackage = OPTION_TYPE === 'PACKAGE';
    const premiumPackagePrice = totalPrice + Number(estimateForm.primium_package_price) - 250000;

    totalPrice = isPremiumPackage ? premiumPackagePrice : (totalPrice += sumOptionPrice);

    dispatch(actAddInputEstimateForm({ ...estimateForm, totalPrice: totalPrice }));
  }, [model_price, color_price, sumOptionPrice, OPTION_TYPE]);

  const goOption = () => navigate('/buy/estimate/option');
  const backModel = () => navigate('/buy/estimate/model');
  const backColor = () => navigate('/buy/estimate/color');

  // not used 옵션 제거 후
  const [openAlartPopUp, setOpenAlartPopUp] = useState(false);
  const goDetail = () => {
    if (OPTION_TYPE === 'OPTION') {
      setOpenAlartPopUp(true);
      return;
    }
    navigate('/buy/estimate/detail');
  };

  console.log('OPTION_TYPE', OPTION_TYPE);

  // useCountUp({
  //   ref: 'counter',
  //   start: 0,
  //   end: 1,
  //   duration: 0.5,
  // });

  // totalPrice를 받아오면 countup 미동작...
  // 리덕스로 상태 관리를 할 필요가 없음. 자동차 목업 데이터를 참조.
  // const INITIAL_PREV_COUNT = estimateForm.totalPrice;
  const INITIAL_PREV_COUNT = 43690000;
  const [prevCount, setPrevCount] = useState(INITIAL_PREV_COUNT);

  console.log('totalPrice', estimateForm.totalPrice, estimateForm);
  return (
    <div className="estimatebar">
      {openAlartPopUp && <EAlartPopUp setOpenAlartPopUp={setOpenAlartPopUp} />}
      {/* <button type="button" title="펼치기" className="bar_open active" onClick={barOpen}></button> */}
      <ul className="detail">
        <li>
          <strong className="de_tlt">모델</strong>
          <span
            className="de_name"
            dangerouslySetInnerHTML={{
              __html: `${estimateForm.mldNm}<br>${estimateForm.trimNm}`,
            }}
          ></span>
          <span className="de_price">
            <span>{staticAutoComma(estimateForm.model_price)}</span>원
          </span>
        </li>
        <li>
          <strong className="de_tlt">컬러</strong>
          <span className="de_name">{estimateForm.colorNm}</span>
          <span className="de_price">
            <span>
              {estimateForm.color_price ? staticAutoComma(Number(estimateForm.color_price)) : 0}
            </span>
            원
          </span>
        </li>
        {/* {estimateForm.OPTION_TYPE && (
          <li>
            <strong className="de_tlt">옵션 & 패키지</strong>
            <span className="de_name">{estimateForm.packageNm}</span>
            <span className="de_price">
              <span className="discount">
                {estimateForm.packageNm === '풀옵션 패키지' ? `패키지 할인 (-250,000원)` : null}
              </span>
              <span>
                {estimateForm.packageNm === '풀옵션 패키지'
                  ? staticAutoComma(estimateForm.primium_package_price - 250000)
                  : staticAutoComma(sumOptionPrice)}
              </span>
              원
            </span>
            {estimateForm.OPTION_TYPE === 'PACKAGE' ||
            (estimateForm.option_package_checked_list &&
              estimateForm.option_package_checked_list.length > 0) ? (
              <ul className="info">
                {estimateForm.packageNm === '풀옵션 패키지' ? (
                  <>
                    <li>- 순정 무선 휴대폰 충전패드</li>
                    <li>
                      - 프리미엄 팩 (ECM 하이패스 룸미러, 틸트 스티어링, 전동 접이식 아웃사이드
                      미러)
                    </li>
                    <li>- 동승석 에어백</li>
                    <li>- V2L 패키지(전용커넥터 포함)</li>
                    <li>- 스마트 팩 (12.8인치 스마트패드+ 후방카메라+ EV 스페셜 소프트웨어)</li>
                  </>
                ) : (
                  estimateForm.option_package_checked_list?.map((option, i) => (
                    <li key={i}>-{option.optionNm}</li>
                  ))
                )}
                {estimateForm.packageNm === '풀옵션 패키지'
                  ? estimateForm.package_data.optionPackageData?.map((option, i) => (
                      <li key={i}>-{option.optionNm}</li>
                    ))
                  : estimateForm.option_package_checked_list?.map((option, i) => (
                      <li key={i}>-{option.optionNm}</li>
                    ))}
              </ul>
            ) : null}
          </li>
        )} */}
      </ul>
      {/* <span className="discount">
        {estimateForm.packageNm === '풀옵션 패키지' ? `패키지 할인 (-250,000원)` : null}
      </span>
      <span className="sub_tlt">예상 가격(VAT 포함)</span> */}
      {/* {estimateForm.OPTION_TYPE === 'PACKAGE' && estimateForm.packageNm === '풀옵션 패키지' && (
          <span className="discount">패키지 할인 (-250,000원)</span>
        )} */}
      {/* <em className="price">
        <CountUp
          start={prevCount}
          end={estimateForm.totalPrice}
          onEnd={() => setPrevCount(estimateForm.totalPrice)}
          formattingFn={(num) => staticAutoComma(num || estimateForm.totalPrice)}
          duration={0.25}
        />
        원
      </em> */}
      <div className="btn_layout" style={{ margin: '0' }}>
        <button
          type="button"
          className="btn_ghost_l"
          onClick={window.location.pathname === '/buy/estimate/option' ? backColor : backModel}
        >
          <i className="ico_24_prev_bk"></i>
          <span>
            {window.location.pathname === '/buy/estimate/option' ? '컬러 선택' : '모델 선택 '}
          </span>
        </button>

        <button
          type="button"
          className="btn_primary_l"
          onClick={window.location.pathname === '/buy/estimate/option' ? goDetail : goOption}
        >
          <span>
            {window.location.pathname === '/buy/estimate/option' ? '견적내기' : '옵션 선택 '}
          </span>
          <i className="ico_24_next_wh"></i>
        </button>
      </div>
    </div>
  );
}

export default EstimateBar;
