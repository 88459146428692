import React from 'react';
import ConditionList from './List';

function Condition() {
  return (
    <div className="condition">
      <ConditionList />
    </div>
  );
}

export default Condition;
