import React, { useEffect, useState } from 'react';
import Img1 from '../../../../assets/images/img/img_performance1.png';
import Img2 from '../../../../assets/images/img/img_performance2.png';
import Img3 from '../../../../assets/images/img/img_performance3.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay]);

function Slide() {
  return (
    <div className="img_slide_wrap">
      <div className="mo_view">
        <Swiper slidesPerView={1} scrollbar={{ draggable: true }} navigation>
          <SwiperSlide>
            <img src={Img1} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Img2} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Img3} alt="" />
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="pc_view">
        <ul className="slide_wrap">
          <li className="slide">
            <img src={Img1} alt="" />
          </li>
          <li className="slide">
            <img src={Img2} alt="" />
          </li>
          <li className="slide">
            <img src={Img3} alt="" />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Slide;
