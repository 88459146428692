import React from 'react';
import RelationPage from '../../../common/RelationPage';
import EventList from './List';

function Event() {
  return (
    <div className="event">
      <EventList />
      <RelationPage />
    </div>
  );
}

export default Event;
