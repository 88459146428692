import React, { useState, useEffect } from 'react';
import ProgressBar from '../common/ProgressBar';
import EstimateBar from '../common/EstimateBar';
import { actAddInputEstimateForm } from '../../../../../utile/slice/tempFormSlice';
import { useAppSelector, useAppDispatch } from '../../../../../store';
import { actShowDepth1LPopUp } from '../../../../../utile/slice/layerPopUpSlice';
import ModelDetail1 from '../common/PopupDetail1';
import ModelDetail2 from '../common/PopupDetail2';
import ModelDetail3 from '../common/PopupDetail3';
import ModelDetail4 from '../common/PopupDetail4';
import ModelDetail5 from '../common/PopupDetail5';
import { staticAutoComma } from '../../../../../utile/regExp';
import AlartPopUp from '../../../../common/AlartPopUp';
import cargoWhImg from '../../../../../assets/images/img/img_model_type1_wh.png';
import cargoSbImg from '../../../../../assets/images/img/img_model_type1_sb.png';
import teugjangWhImg from '../../../../../assets/images/img/img_model_type2_wh.png';
import teugjangSbImg from '../../../../../assets/images/img/img_model_type2_sb.png';

function Color() {
  const dispatch = useAppDispatch();
  const { estimateForm } = useAppSelector((state) => state.tempForm);

  const COLOR_TYPE = {
    WHITE: {
      ID: 'WHITE',
      NM: 'Iceberg White',
      PRICE: 0,
    },
    ROLANDSILVER: {
      ID: 'ROLANDSILVER',
      NM: 'Roland Silver',
      PRICE: 150000,
    },
  };
  const [colorType, setColorType] = useState(estimateForm.COLOR_TYPE || COLOR_TYPE.WHITE.ID);

  useEffect(() => {
    dispatch(
      actAddInputEstimateForm({
        ...estimateForm,
        COLOR_TYPE: colorType,
        colorNm: COLOR_TYPE[colorType].NM,
        color_price: COLOR_TYPE[colorType].PRICE,
      }),
    );
  }, [colorType]);

  function colorChange(e) {
    let value = e.target.value.toUpperCase();
    setColorType(value);
  }

  const { depth1LPopUpName } = useAppSelector((state) => state.layerPopUp);
  const { isShowAPopUp } = useAppSelector((state) => state.alartPopUp);

  const openDetailedItem = (mldNm, trimNm) => {
    dispatch(actShowDepth1LPopUp(mldNm.replace(/ /g, '') + trimNm.replace(/ /g, '')));
  };
  return (
    <div className="estimate">
      <ProgressBar />
      {isShowAPopUp && <AlartPopUp />}
      <div className="color_cho">
        {/* 360도 이미지 */}
        <div className="car_detail">
          <div className="wrap_img">
            {estimateForm.type === 'T4K 카고' ? (
              <img src={estimateForm.COLOR_TYPE === 'WHITE' ? cargoWhImg : cargoSbImg} alt="" />
            ) : (
              <img
                src={estimateForm.COLOR_TYPE === 'WHITE' ? teugjangWhImg : teugjangSbImg}
                alt=""
              />
            )}
          </div>
          <span className="info">이미지는 참고용이며 실제 차량과 다를 수 있습니다.</span>
        </div>

        <div className="detail_info">
          <div className="car_name">
            <strong className="tlt">{estimateForm.mldNm}</strong>
            <p className="sub_tlt">{estimateForm.trimNm}</p>
            <button
              type="button"
              className="btn_txt"
              onClick={() => openDetailedItem(estimateForm.mldNm, estimateForm.trimNm)}
            >
              <span>상세품목</span>
              <i className="ico_20_next"></i>
            </button>
          </div>
          <div className="color">
            <strong className="tlt">컬러를 선택하세요.</strong>
            <div className="cho_option">
              <div className="option">
                <input
                  type="radio"
                  id="color1"
                  name="color"
                  value="WHITE"
                  onChange={colorChange}
                  checked={colorType === COLOR_TYPE.WHITE.ID}
                />
              </div>
              <div className="option">
                <input
                  type="radio"
                  id="color2"
                  name="color"
                  value="rolandsilver"
                  onChange={colorChange}
                  checked={colorType === COLOR_TYPE.ROLANDSILVER.ID}
                />
              </div>
              <div className="name_wrap">
                <span className="color_name">{COLOR_TYPE[colorType].NM}</span>
                <span className="color_price">
                  <span>{staticAutoComma(COLOR_TYPE[colorType].PRICE)}</span>원
                </span>
              </div>
            </div>
          </div>
        </div>
        <EstimateBar />
      </div>
      {depth1LPopUpName === 'T4K내장탑차슈퍼캡롱레인지프리미엄' && <ModelDetail1 />}
      {depth1LPopUpName === 'T4K냉동탑차슈퍼캡롱레인지프리미엄' && <ModelDetail2 />}
      {depth1LPopUpName === 'T4K윙바디슈퍼캡롱레인지프리미엄' && <ModelDetail3 />}
      {depth1LPopUpName === 'T4K카고슈퍼캡롱레인지프리미엄' && <ModelDetail4 />}
      {depth1LPopUpName === 'T4K카고슈퍼캡롱레인지' && <ModelDetail5 />}
    </div>
  );
}

export default Color;
