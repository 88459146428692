import React from 'react';
import ModelImg1 from '../../../../../assets/images/img/img_t4k_sample.png';
import ModelImg2 from '../../../../../assets/images/img/img_t4k_sample2.png';
// import ModelImg1Hover from '../../../../../assets/images/img/img_t4k_samplehover.png';
// import ModelImg2Hover from '../../../../../assets/images/img/img_t4k_sample2hover.png';
import RelationPage from '../../../../common/RelationPage';
import { useNavigate } from 'react-router';
import { actAddInputEstimateForm } from '../../../../../utile/slice/tempFormSlice';
import { useAppDispatch } from '../../../../../store';

function SelecEstimate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goModel = (type) => {
    navigate('/buy/estimate/model');
    dispatch(actAddInputEstimateForm({ type }));
  };

  const goIntro = () => {
    navigate('/model/introduce');
  };

  return (
    <div className="estimate">
      <div className="inner">
        <div className="selectestimate">
          <h2 className="tit">견적</h2>
          <div className="wrap_contain">
            <div className="box_model">
              <div className="img">
                <img src={ModelImg1} alt="" className="first-img"></img>
                {/* <img src={ModelImg1Hover} alt="" className="hover-img"></img> */}
                {/* 23.03.08 고객사 요청으로 주석처리 */}
              </div>
              <div className="name type">
                T4K 카고
                {/* <span className="price">43,690,000 원~</span> */}
              </div>
              <div className="btn_layout" data-toggle="true">
                <button type="button" className="btn_ghost_m" onClick={goIntro}>
                  <span>자세히 보기</span>
                </button>
                <button
                  type="button"
                  className="btn_primary_m"
                  onClick={() =>
                    goModel(document.querySelectorAll('.type')[0].innerHTML.split('<')[0])
                  }
                >
                  <span>견적내기</span>
                </button>
              </div>
            </div>
            <div className="box_model disable">
              <div className="img">
                <img src={ModelImg2} alt="" className="first-img"></img>
                {/* <img src={ModelImg2Hover} alt="" className="hover-img"></img> */}
              </div>
              <div className="name type">
                T4K 특장
                {/* <span className="price">00,000,000 원~</span> */}
              </div>
              <div className="btn_layout" data-toggle="true">
                <button
                  disabled="disabled"
                  type="button"
                  className="btn_primary_m"
                  onClick={() =>
                    goModel(document.querySelectorAll('.type')[1].innerHTML.split('<')[0])
                  }
                >
                  <span>견적내기</span>
                </button>
              </div>
            </div>
          </div>

          <div className="box_information">
            <dl className="info">
              <dt>
                <i className="ico_24_noti_msg"></i>확인해 주세요.
              </dt>
              <dd>- 본 견적 서비스의 차량 이미지는 실제 차량과 차이가 있을 수 있습니다.</dd>
              <dd>
                - 실제 계약 내용은 고객께서 계약하실 시점의 판매조건이나 대출 조건에 따라 본 견적
                내용과 달라질 수 있으니, 자세한 사항은 반드시 판매센터에 문의하시기 바랍니다.
              </dd>
              <dd>
                - 면세 적용 여부에 따른 자세한 견적 금액은 반드시 판매센터에 확인하시기 바랍니다.
              </dd>
              <dd>- 본 견적 내용은 계약 시 변경될 수 있으며, 법적 구속력은 없습니다.</dd>
            </dl>
          </div>
        </div>
      </div>
      <RelationPage />
    </div>
  );
}

export default SelecEstimate;
