import React from 'react';
import { useEffect, useState  } from 'react';
import { useLocation } from 'react-router-dom';
import PaginationList from '../../../common/pagination/BoardPagination';
import RelationPage from '../../../common/RelationPage';
import NoticeList from './notice/List';
import NewsList from './news/List';
import SnsList from './sns/List';

function Notice() {
  const [defaultCategory, setDefaultCategory] = useState("notice");
  const [category, setCategory] = useState("notice");
  const location = useLocation();
  
  function tab(classfi) {
    const selected = classfi.target.dataset.category;
    setCategory(selected);

    const categories = document.querySelectorAll('.main-tab[data-category]');
    const contains = document.querySelectorAll('.main-tab[data-contain]');
    categories.forEach((e) => {
      e.classList.remove('active');
      classfi.target.classList.add('active');
    });
    contains.forEach((e) => {
      if (selected === e.dataset.contain) {
        e.setAttribute('style', 'display:block');
      }else {
        e.setAttribute('style', 'display:none');
      }
    });
  }

  useEffect(() => {
    if(location.pathname==="/customer/notice") 
      setDefaultCategory("notice");
    else if(location.pathname==="/customer/news") 
      setDefaultCategory("news");
    else if(location.pathname==="/customer/sns") 
      setDefaultCategory("sns");

    document.querySelector('[data-category='+defaultCategory+']').click();
  }, [defaultCategory]);

  return (
    <div className="notice">
      <div className="visual inner">
        <h2 className="tlt">소식</h2>
      </div>
      <div className="tab-head outinner">
        <div className="tab-inner">
          <button type="button" className="main-tab" onClick={tab} data-category="notice">
            공지사항
          </button>
          <button type="button" className="main-tab" onClick={tab} data-category="news">
            뉴스
          </button>
          <button type="button" className="main-tab" onClick={tab} data-category="sns">
            SNS
          </button>
        </div>
      </div>
      <div className="inner">
        <div data-contain="notice" className="main-tab active">
          <NoticeList category={category}/>
        </div>
        <div data-contain="news" className="main-tab active">
          <NewsList category={category}/>
        </div>
        <div data-contain="sns" className="main-tab active">
          <SnsList category={category}/>
        </div>
      </div>
      <RelationPage />
    </div>
  );
}

export default Notice;
