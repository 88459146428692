import React from 'react';
import { useEffect, useState  } from 'react';
import { useLocation } from 'react-router-dom';
import PaginationList from '../../../common/pagination/BoardPagination';
import RelationPage from '../../../common/RelationPage';
import GalleryList from './gallery/List';
import ReviewList from './review/List';

// function Community() {
//   const [defaultCategory, setDefaultCategory] = useState("review");
//   const [category, setCategory] = useState("review");
//   const location = useLocation();

//   function tab(classfi) {
//     const selected = classfi.target.dataset.category;
//     setCategory(selected);

//     const categories = document.querySelectorAll('.main-tab[data-category]');
//     const contains = document.querySelectorAll('.main-tab[data-contain]');
//     categories.forEach((e) => {
//       e.classList.remove('active');
//       classfi.target.classList.add('active');
//     });
//     contains.forEach((e) => {
//       if (selected === e.dataset.contain) {
//         e.setAttribute('style', 'display:block');
//       }else {
//         e.setAttribute('style', 'display:none');
//       }
//     });
//   }

//   useEffect(() => {
//     if(location.pathname==="/customer/review") 
//       setDefaultCategory("review");
//     else if(location.pathname==="/customer/news") 
//       setDefaultCategory("news");

//     document.querySelector('[data-category='+defaultCategory+']').click();
//   }, [defaultCategory]);

//   return (
//     <div className="community">
//       <div className="visual inner">
//         <h2 className="tlt">커뮤니티</h2>
//       </div>
//       <div className="tab-head outinner">
//         <div className="tab-inner">
//           <button type="button" className="main-tab" onClick={tab} data-category="review">
//             출고 후기
//           </button>
//           <button type="button" className="main-tab" onClick={tab} data-category="gallery">
//             갤러리
//           </button>
//         </div>
//       </div>
//       <div className="inner">
//         <div data-contain="review" className="main-tab active">
//           <ReviewList category={category}/>
//         </div>
//         <div data-contain="gallery" className="main-tab active">
//           <GalleryList category={category}/>
//         </div>
//       </div>
//       <RelationPage />
//     </div>
//   );
// }


function ComGallery() {
  return (
    <div className="community">
      <div className="visual inner">
         <h2 className="tlt">T4K 특장</h2>
       </div>
       <div className="inner">
      <GalleryList category="gallery" />
      </div>
      <RelationPage />
    </div>
  );
}

export default ComGallery;
