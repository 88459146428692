import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ax from '../../api/ax';

const initialState = {
  resultCode: 'loading',
};

export const postBoardForm = createAsyncThunk('form/postForm', async (formData) => {
  const { form, reqUrl } = formData;

  const res = await ax.post(reqUrl, form);
  let resultCode = res.data.resultCode;
  
  if(resultCode){
    resultCode = res.data.data.resultCode;
  }
  return resultCode;
});

export const postBoardFormSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
      // resultCode 상태를 초기화하는 액션
      resetResultCode(state) {
          state.resultCode = '';
      },
  },
  extraReducers: (builder) => {
    builder.addCase(postBoardForm.pending, (state) => {
      state.resultCode = 'loading';
    });
    builder
      .addCase(postBoardForm.fulfilled, (state, { payload }) => {
        state.resultCode = payload;
      })
      .addCase(postBoardForm.rejected, (state, { payload }) => {
        //보류
        state.resultCode = payload;
      });
  },
});

export const { resetResultCode } = postBoardFormSlice.actions;

export default postBoardFormSlice;
