import React from 'react';
import Page from './Board';

function ConditionDetail() {
  return (
    <div className="condition_detail inner">
      <Page />
      {/* <PaginationBoard /> */}
    </div>
  );
}

export default ConditionDetail;
