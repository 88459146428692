import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Outlet } from 'react-router-dom';
import Loading from './components/common/Loading';
import Cookie from './components/common/popup/Cookie';
import { useAppDispatch, useAppSelector } from './store';
import { actIsCookieAgreeLPopUp } from './utile/slice/layerPopUpSlice';

function CookieAgreeLayOut() {
  const dispatch = useAppDispatch();
  const [appCookies, setAppCookies] = useCookies();
  const { isCookieAgreeLPopUp } = useAppSelector((state) => state.layerPopUp);
  const { loading } = useAppSelector((state) => state.loading);

  useEffect(() => {
    if (!appCookies.cookieAgree) {
      //초기렌더링
      setAppCookies('cookieAgree', false);
      dispatch(actIsCookieAgreeLPopUp(true));
    } else if (appCookies.cookieAgree === 'false') {
      //수집동의전
      dispatch(actIsCookieAgreeLPopUp(true));
    } else if (appCookies.cookieAgree === 'true') {
      //수집동의완료
      dispatch(actIsCookieAgreeLPopUp(false));
    }
  }, []);

  return (
    <>
      {loading ? <Loading /> : null}
      {/* {isCookieAgreeLPopUp ? <Cookie /> : null} */}
      <Outlet />
    </>
  );
}

export default CookieAgreeLayOut;
