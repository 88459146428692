import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getSnsList } from '../../../../../utile/slice/boardSlice';
import { actIsLoading } from '../../../../../utile/slice/loadingSlice';
import PaginationList from '../../../../common/pagination/BoardPagination';

import EmptyImage from '../../../../../assets/images/list_empty.png';

function SnsList({category}) {
  const THIS_CATE = "sns";
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const snsList = useAppSelector(state => state.board.snsList);
  const Page = useAppSelector(state => state.board.paginationData);
  const [reqParams, setReqParams] = useState(
    state ? state : {currentPageNo: 1, cateCd: ''}
  );

  useEffect(() => {
    if(category===THIS_CATE){
      dispatch(getSnsList({...reqParams, currentPageNo: 1}));
      setReqParams({...reqParams, currentPageNo: 1});
    }
  }, [category]);

  useEffect(() => {
    if(category===THIS_CATE){
      dispatch(getSnsList(reqParams));
    }
  }, [reqParams]);

  const onChangePageNo = (pageNum) => {
    setReqParams({...reqParams, currentPageNo: pageNum});
  }

  const onClickList = () => {
    dispatch(actIsLoading(true));
    setTimeout(() => {
      dispatch(actIsLoading(false));
    }, 2000);
  }
  return (
    <>
      <ul className="list_wrap2">
      {snsList.map((sns) => (
        <li key={sns.bbsSeq}>
          <Link
            to={'/customer/sns/' + sns.bbsSeq}
            title="페이지 이동"
            className="card_cont1"
            onClick={onClickList}
          >
            <div className="img_wrap">
              {sns.fileFullPath && <img src={sns.fileFullPath} alt="" />}
            </div>
            <strong className="tlt">
              {sns.bbsTitNm}
            </strong>
            <span className="date">{sns.createDate}</span>
          </Link>
        </li>
      ))}
      </ul>
      <PaginationList paginationData={Page} currentNum={reqParams.currentPageNo} setCurrentNum={onChangePageNo} />
    </>
  );
}

export default SnsList;
