import React from 'react';

function createMarkup() {
  return { __html: '<iframe src="pagelist.html" width="100%" height="100%"></iframe>' };
}
function PageList() {
  return <div className="pagelist" dangerouslySetInnerHTML={createMarkup()} />;
}

export default PageList;
