import React from 'react';
import Page from './Board';

function QnaDetail() {
  return (
    <div className="qna_detail inner">
      <Page />
    </div>
  );
}

export default QnaDetail;
