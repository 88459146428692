import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function RelationPage() {
  const location = useLocation();

  let pathname = location.pathname;

  const [link, setLink] = useState({
    toOne: '/model/introduce',
    titOne: 'T4K 소개',
    classOne: 'relationBg1',
    toTwo: '/service/network',
    titTwo: '판매/서비스 네트워크',
    classTwo: 'relationBg2',
    toThree: '/customer/contact',
    titThree: '문의하기',
    classThree: 'relationBg3',
  });

  useEffect(() => {
    if (pathname === '/service/network') {
      setLink({
        ...link,
        toOne: '/buy/reservation',
        titOne: '구매상담 신청',
        classOne: 'relationBg4',
        toTwo: '/service/guide',
        titTwo: '차량관리 가이드',
        classTwo: 'relationBg5',
        toThree: '/service/part',
        titThree: '부품정보 안내',
        classThree: 'relationBg7',
      });
    } else if (pathname === '/customer/contact') {
      setLink({
        ...link,
        toOne: '/model/introduce',
        titOne: 'T4K 소개',
        classOne: 'relationBg1',
        toTwo: '/service/network',
        titTwo: '판매/서비스 네트워크',
        classTwo: 'relationBg2',
        toThree: '/customer/faq',
        titThree: 'FAQ',
        classThree: 'relationBg6',
      });
    } else if (
      pathname === '/service/info' ||
      pathname === '/service/part' ||
      pathname === '/buy/guide'
    ) {
      setLink({
        ...link,
        toOne: '/service/network',
        titOne: '판매/서비스 네트워크',
        classOne: 'relationBg2',
        toTwo: '/service/guide',
        titTwo: '차량관리 가이드',
        classTwo: 'relationBg5',
        toThree: '/customer/contact',
        titThree: '문의하기',
        classThree: 'relationBg3',
      });
    } else if (pathname === '/service/guide') {
      setLink({
        ...link,
        toOne: '/service/network',
        titOne: '판매/서비스 네트워크',
        classOne: 'relationBg2',
        toTwo: '/service/part',
        titTwo: '부품정보 안내',
        classTwo: 'relationBg7',
        toThree: '/customer/contact',
        titThree: '문의하기',
        classThree: 'relationBg3',
      });
    } else if (
      pathname === '/model/gallery' ||
      pathname === '/model/performance' ||
      pathname === '/model/price'
    ) {
      setLink({
        ...link,
        toOne: '/model/introduce',
        titOne: 'T4K 소개',
        classOne: 'relationBg1',
        toTwo: '/service/network',
        titTwo: '판매/서비스 네트워크',
        classTwo: 'relationBg2',
        toThree: '/buy/reservation',
        titThree: '구매상담 신청',
        classThree: 'relationBg4',
      });
    } else if (pathname === '/model/introduce') {
      setLink({
        ...link,
        // toOne: '/buy/reservation',
        toOne: '/buy/reservation',
        titOne: '구매상담 신청',
        classOne: 'relationBg4',
        toTwo: '/service/network',
        titTwo: '판매/서비스 네트워크',
        classTwo: 'relationBg2',
        toThree: '/customer/contact',
        titThree: '문의하기',
        classThree: 'relationBg3',
      });
    }
  }, []);

  return (
    <div className="relation">
      <ul className="relation_wrap inner">
        <li className={link.classOne}>
          <Link to={link.toOne} title="페이지 이동" className="page">
            {link.titOne}
          </Link>
        </li>
        <li className={link.classTwo}>
          <Link to={link.toTwo} title="페이지 이동" className="page">
            {link.titTwo}
          </Link>
        </li>
        <li className={link.classThree}>
          <Link to={link.toThree} title="페이지 이동" className="page">
            {link.titThree}
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default RelationPage;
