import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  getTaxFreeTypeList,
  getPurcRateList,
  actSetRegFeeTotal,
  actSetRegTax,
  actSetBondTax,
} from './regFeeSlice';
import { autoComma, staticAutoComma } from '../../../../../utile/regExp';

function RegFee({ accOnOff }) {
  const dispatch = useAppDispatch();
  const BOND_TYPE = {
    PURC: 'purc',
    DISC: 'disc',
  };
  const TAX_FREE_TYPE = {
    GENERAL: 'C2501',
    DISABILITY: 'C2504',
    SALES: 'C2508',
  };
  const cateList = useAppSelector((state) => state.regFee.cateList); // 시/도 리스트
  const taxFreeDivList = useAppSelector((state) => state.regFee.taxFreeDivList); // 면세 구분 리스트
  const regFeeTotal = useAppSelector((state) => state.regFee.regFeeTotal); // 등록비용
  const regTax = useAppSelector((state) => state.regFee.regTax); // 취득세
  const bondTax = useAppSelector((state) => state.regFee.bondTax); // 공채
  const localTax = useAppSelector((state) => state.regFee.localTax); // 증지대
  const plateFee = useAppSelector((state) => state.regFee.plateFee); // 차량번호판
  const charge = useAppSelector((state) => state.regFee.charge); // 대행수수료
  const [isChildrenDisabled, setIsChildrenDisabled] = useState(false);
  const [params, setParams] = useState({
    selectSido: '', // 시/도
    purcRate: 0, // 시/도 매입율
    reducPrice: 0, // 시/도 감면금액
    discRate: 0, // 시/도 할인율
    taxFreeDiv: TAX_FREE_TYPE.GENERAL, // 면세구분
    isChildrenYn: false, // 다자녀여부
    bondType: BOND_TYPE.PURC, // 공채유형(disc:할인, purc:매입)
  });
  const elecTaxFreeAmt = 1400000; // 전기차 감면한도
  const childrenTaxFreeAmt = 300000; // 다자녀 감면한도
  const consignmentCharge = useAppSelector((state) => state.consignment.consignmentCharge); // 탁송비
  const { estimateForm } = useAppSelector((state) => state.tempForm);

  useEffect(() => {
    dispatch(getTaxFreeTypeList());
    dispatch(getPurcRateList(params.bondType));
  }, []);

  useEffect(() => {
    calculateRegFee();
  }, [params, consignmentCharge, estimateForm.totalPrice]);

  const onChangeTaxFreeDiv = (e) => {
    let value = e.target.value;
    if (value === TAX_FREE_TYPE.GENERAL || value === TAX_FREE_TYPE.DISABILITY) {
      setIsChildrenDisabled(false);
    } else {
      setIsChildrenDisabled(true);
    }
    setParams({ ...params, taxFreeDiv: value, isChildrenYn: false });
  };

  const onChangeSelectSido = (e) => {
    let selectedIndex = e.target.selectedIndex;
    let purcRate = selectedIndex ? e.target[selectedIndex].dataset.rate : 0;
    let reducPrice = selectedIndex ? e.target[selectedIndex].dataset.price : 0;
    let discRate = selectedIndex ? e.target[selectedIndex].dataset.disc : 0;
    setParams({
      ...params,
      selectSido: e.target.value,
      purcRate: purcRate,
      reducPrice: reducPrice,
      discRate: discRate,
    });
  };

  const onChangeIsChildrenYn = (e) => {
    setParams({ ...params, isChildrenYn: !params.isChildrenYn });
  };

  const onChangeBondType = (e) => {
    setParams({ ...params, bondType: e.target.value });
  };

  const calculateRegFee = () => {
    // 취득세 계산 : 공급가액 * 취득세율 - 감면금액
    const vehlPrice = Number(estimateForm.totalPrice);
    const supVehlPrice = Number(vehlPrice) / 1.1; // 공급가액
    let rate = 0.05; // 취득세율
    let regTax = 0;
    if (params.taxFreeDiv === TAX_FREE_TYPE.SALES) {
      rate = 0.04; // 영업용인 경우 4%
    }
    if (
      params.taxFreeDiv === TAX_FREE_TYPE.GENERAL ||
      params.taxFreeDiv === TAX_FREE_TYPE.DISABILITY ||
      params.taxFreeDiv === TAX_FREE_TYPE.SALES
    ) {
      regTax = Math.trunc(supVehlPrice * rate - elecTaxFreeAmt);
      if (!params.isChildrenDisabled && params.isChildrenYn) {
        regTax -= childrenTaxFreeAmt; // 다자녀감면
      }
    }
    // 공채 계산 : (공급가액 + 탁송비) * 매입율 + 감면금액
    let bondTax =
      ((supVehlPrice + Number(consignmentCharge)) * Number(params.purcRate)) / 100 +
      Number(params.reducPrice);
    if (params.bondType === BOND_TYPE.PURC) {
      // 공채 매입
      bondTax = Math.trunc(bondTax);
    } else {
      // 공채 할인 : 공채매입금 * 할인율
      bondTax = Math.trunc((bondTax * params.discRate) / 100);
    }
    dispatch(actSetBondTax(bondTax.toString()));

    // 등록 비용 합산
    let regFeeTotal =
      Number(regTax) + Number(bondTax) + Number(localTax) + Number(plateFee) + Number(charge);
    dispatch(actSetRegTax(regTax.toString()));
    dispatch(actSetRegFeeTotal(regFeeTotal.toString()));
  };

  return (
    <>
      <li className="section">
        <div className="tlt_wrap" onClick={accOnOff}>
          <strong className="tlt">등록비용</strong>
          <span className="price">
            <span className="num">{autoComma(regFeeTotal)}</span>원
            <button type="button" title="항목 닫기">
              <i className="ico_32_accoup"></i>
            </button>
          </span>
        </div>
        <div className="sec_cont">
          <div className="form_wrap line_ty">
            <div className="input_wrap">
              <label htmlFor="taxFreeDiv" className="label">
                면세구분
              </label>
              <div className="select">
                <select
                  id="taxFreeDiv"
                  className="input_select"
                  name="taxFreeDiv"
                  value={params.taxFreeDiv}
                  onChange={onChangeTaxFreeDiv}
                >
                  {taxFreeDivList.map((type) => (
                    <option value={type.cd} key={type.cd}>
                      {type.cdNm}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input_checkbox_basic">
              <div className="box_checkbox">
                <input
                  type="checkbox"
                  id="isChildrenYn"
                  name="isChildrenYn"
                  value={params.isChildrenYn}
                  checked={params.isChildrenYn}
                  disabled={isChildrenDisabled}
                  onChange={onChangeIsChildrenYn}
                />
                <label htmlFor="isChildrenYn" className="input_checkbox">
                  다자녀 (18세미만 3인이상)
                </label>
              </div>
            </div>
          </div>
          <div className="form_wrap line_ty">
            <div className="input_wrap">
              <label htmlFor="input1" className="label">
                공채선택
              </label>
              <div className="select_layout">
                <div className="select">
                  <select
                    id="selectSido"
                    className="input_select"
                    name="selectSido"
                    value={params.selectSido}
                    onChange={onChangeSelectSido}
                  >
                    <option value="" key="">
                      지역선택
                    </option>
                    {cateList.map((city) => (
                      <option
                        value={city.cateSeq}
                        key={city.cateSeq}
                        data-rate={city.purcRate}
                        data-price={city.reducPrice}
                        data-disc={city.discountRate}
                      >
                        {city.cateNm}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <div className="select">
									<select id="selectSigungu" className="input_select" name="selectSigungu" value={params.selectSigungu} onChange={onChangeSelectSigungu}>
										<option value="">시/군/구 선택</option>
									</select>
								</div> */}
              </div>
            </div>
            <div className="input_radio_basic">
              <div className="box_radio">
                <input
                  type="radio"
                  id="bondTypeDisCount"
                  name="bondType"
                  value={BOND_TYPE.DISC}
                  checked={params.bondType === BOND_TYPE.DISC}
                  onChange={onChangeBondType}
                />
                <label htmlFor="bondTypeDisCount" className="input_radio">
                  할인
                </label>
              </div>
              <div className="box_radio">
                <input
                  type="radio"
                  id="bondTypePurchase"
                  name="bondType"
                  value={BOND_TYPE.PURC}
                  checked={params.bondType === BOND_TYPE.PURC}
                  onChange={onChangeBondType}
                />
                <label htmlFor="bondTypePurchase" className="input_radio">
                  매입
                </label>
              </div>
            </div>
          </div>
          <dl className="acco_desc active">
            <dt>취득세</dt>
            <dd>
              <span>{autoComma(regTax)}</span>원
            </dd>
            <dt>공채</dt>
            <dd>
              <span>{autoComma(bondTax)}</span>원
            </dd>
            <dt>증지대</dt>
            <dd>
              <span>{autoComma(localTax)}</span>원
            </dd>
            <dt>차량번호판</dt>
            <dd>
              <span>{autoComma(plateFee)}</span>원
            </dd>
            <dt>등록 대행 수수료</dt>
            <dd>
              <span>{autoComma(charge)}</span>원
            </dd>
          </dl>
        </div>
      </li>
    </>
  );
}

export default RegFee;
