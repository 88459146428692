//하이픈
export const autoHypen = (phoneNum) => {
  return phoneNum?.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
};

//양방향 값 가격
export const autoComma = (priceNum) => {
  const priceCheck = priceNum?.replace(/[^0-9]/g, '');

  if (!priceCheck && priceNum) return '';
  else {
    const unCommaPriceNum = priceCheck?.replaceAll(',', ''); // 잠시 콤마를 때주고
    return (priceNum = unCommaPriceNum?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')); // 받은 값에 3자리수마다 콤마를 추가
  }
};

//단반향 값 가격
export const staticAutoComma = (priceNum) => {
  return priceNum?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const unComma = (priceNum) => {
  return priceNum?.replace(/,/g, '');
};

/**
 * 소수인지 확인
 * 
 * 
  //   1.소수점 다음에 숫자가 3개면 초기화(소수점 둘째자리까지만 입력가능)

  // 2.숫자랑 "."(소수점)이 아닌것 입력불가

  // 3.연속으로던, 떨어져있던 "."(소수점) 두개 이상 입력불가 (하나만 입력가능)

  // 4.숫자가아닌 "."(소수점)으로 시작불가

 * @param {num or .} decimal
 * @returns
 */
export const isDecimal = (decimal) => {
  const pattern = /(^\d+$)|(^\d{1,}.\d{0,100}$)/;

  if (!pattern.test(decimal)) {
    return decimal.substring(0, decimal.length - 1);
  } else {
    return decimal;
  }

  // const decimalCheck = decimal.replace(/[^0-9.]/g, '');
  // if (!decimalCheck && decimal) return '';
  // else return decimalCheck;
};

// 입력값이 숫자면 true를 리턴
export const isNum = (value) => {
  const pattern = /[0-9]/g;

  if (pattern.test(value)) {
    return true;
  } else return false;
};

//숫자만있는지 확인하는 정규식
export const onlyNum = /^[0-9]+$/;
