import React from 'react';
import Page from './Board';
import PaginationBoard from '../../../../../common/PaginationBoard';

function GalleryDetail() {
  return (
    <div className="gallery_detail inner">
      <Page />
      {/* <PaginationBoard /> */}
    </div>
  );
}

export default GalleryDetail;
