import React from 'react';

function BuyguideFund() {
  return (
    <div className="buyguide-fund">
      <strong className="tlt">공채 안내</strong>
      <div className="cont_box">
        <strong className="sub_tlt">공채매입</strong>
        <ul className="desc_list">
          <li className="list_item">
            공채는 자동차 등록 시 매입 해야 하는 지방채로 지자체별 채권의 종류, 매입액 등에 따라
            상이합니다.
          </li>
          <li className="list_item">
            채권은 차량 등록지에 따라 도시철도 채권 이나 지역개발채권으로 구분하여 매입합니다.
          </li>
          <li className="list_item">
            채권 매입금액은 등록할 차량의 차종과 해당 지역 공채매입 요율에 의해서 채권 매입액이
            산출됩니다.
          </li>
        </ul>
        <p className="desc_box">공채 매입액 = 과세 기준액 x 등록지역 공채 요율</p>
      </div>

      <div className="cont_box">
        <strong className="sub_tlt">공채할인</strong>
        <p className="sub">
          자동차 구입 초기 비용 부담을 덜기 위해 채권 매입 후 금융사에 일정 할인율을 적용해서 즉시
          매도하는 것으로, 차량등록사업소 내 은행 출장소에서 할인비용만 지급하여 처리가 가능합니다.
        </p>
        <p className="desc_box">
          할인비용=공채 매입금액 x 할인율
          <span>(채권별로 매일 변동되며 등록지역 차량등록사업소에서 확인 가능)</span>
        </p>
      </div>

      <div className="cont_box">
        <strong className="sub_tlt">친환경차(취득세 산출금액에서 감면)</strong>
        <div className="table_wrap">
          <div className="table_scroll_wrap">
            <table className="txt_center">
              <colgroup>
                <col width="20%" />
                <col width="20%" />
                <col width="30%" />
                <col width="30%" />
              </colgroup>
              <caption className="blind">친환경차 취득세 감면 안내표</caption>
              <thead>
                <tr>
                  <th>종류</th>
                  <th>지역</th>
                  <th>월급/이 상환</th>
                  <th>선택품목</th>
                </tr>
              </thead>
              <tbody className="txt_center">
                <tr>
                  <td rowSpan={2}>
                    도시철도채권
                    <br />
                    (지하철 공채)
                  </td>
                  <td>서울</td>
                  <td>7년 거치 후 일시 상환</td>
                  <td>
                    연 2.0% 5년간 1년 단위로 복리 <br /> 나머지 2년은 단리로 계산
                  </td>
                </tr>
                <tr>
                  <td>부산, 대구</td>
                  <td>5년 거치 후 일시 상환</td>
                  <td>연 2.0% 1년 단위 복리</td>
                </tr>
                <tr>
                  <td>지역개발채권</td>
                  <td>기타지역</td>
                  <td>5년 거치 후 일시 상환</td>
                  <td>연 2.0% 1년 단위 복리</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="cont_box">
        <div className="tlt_wrap">
          <strong className="sub_tlt">비사업용 차량 채권 매입금액 요율 </strong>
          <span className="sub">서울(도시철도채권) 신규등록기준</span>
        </div>
        <div className="table_wrap">
          <div className="table_scroll_wrap">
            <table className="txt_center">
              <colgroup>
                <col width="20%" />
                <col width="60%" />
                <col width="20%" />
              </colgroup>
              <caption className="blind">친환경차 취득세 감면 안내표</caption>
              <thead>
                <tr>
                  <th>종류</th>
                  <th>월급/이 상환</th>
                  <th>도시철도채권(예: 서울)</th>
                </tr>
              </thead>
              <tbody className="txt_center">
                <tr>
                  <td rowSpan={6}>승용차</td>
                  <td>1000cc 미만</td>
                  <td>0%</td>
                </tr>
                <tr>
                  <td>
                    소형
                    <br /> (1000cc~1600cc 로 길이 4.7m, 너비 1.7m, 높이 2m 이하인 것)
                  </td>
                  <td>9%</td>
                </tr>
                <tr>
                  <td>
                    중형
                    <br /> (1600cc~2000cc 미만이거나 길이,너비,높이중 어느 하나라도 소형을 초과하는
                    것)
                  </td>
                  <td>12%</td>
                </tr>
                <tr>
                  <td>
                    대형
                    <br /> (2000cc이상이거나 길이,너비,높이 모두 소형을 초과 하는 것)
                  </td>
                  <td>12%</td>
                </tr>
                <tr>
                  <td>다목적 형</td>
                  <td>12%</td>
                </tr>
                <tr>
                  <td>7-10 인승</td>
                  <td>39만원정책</td>
                </tr>
                <tr>
                  <td>승합차</td>
                  <td>
                    소형
                    <br /> (승차정원 11명 이상 15명 이하인 승합 자동차로서 길이 4.7미터, 너비 1.7
                    미터, 높이 2.0미터 이하인 것)
                  </td>
                  <td>39만원정책</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyguideFund;
