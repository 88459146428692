import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getEventList } from '../../../../utile/slice/boardSlice';
import { actIsLoading } from '../../../../utile/slice/loadingSlice';
import PaginationList from '../../../common/pagination/BoardPagination';

function EventList() {
  const dispatch = useAppDispatch();

  const eventList = useAppSelector((state) => state.board.eventList);
  const Page = useAppSelector((state) => state.board.paginationData);
  const { state } = useLocation();
  const [reqParams, setReqParams] = useState(
    state
      ? state
      : {
          currentPageNo: 1,
          tab: 'y',
        },
  );
  
  useEffect(() => {
    dispatch(getEventList(reqParams));
  }, [reqParams]);

  const onClickBtn = (e) => {
    setReqParams({ currentPageNo: 1, tab: e.target.value });
  };

  const onChangePageNo = (pageNum) => {
    setReqParams({ ...reqParams, currentPageNo: pageNum });
  };

  const onClickList = () => {
    dispatch(actIsLoading(true));
    setTimeout(() => {
      dispatch(actIsLoading(false));
    }, 2000);
  };

  return (
    <>
      <div className="visual inner">
        <h2 className="tlt">제휴이벤트</h2>

        {/* <div className="btn_layout">
          <button type="button" className={reqParams.tab == 'y' ? "btn_radius active" : "btn_radius"} value="y" onClick={onClickBtn}>
            진행중인 이벤트
          </button>
          <button type="button" className={reqParams.tab == 'n' ? "btn_radius active" : "btn_radius"} value="n" onClick={onClickBtn}>
            종료된 이벤트
          </button>
        </div> */}
      </div>
      <div className="tab-head event_tab">
        <div className="tab-inner">
          <button
            type="button"
            className={reqParams.tab == 'y' ? 'active' : ''}
            value="y"
            onClick={onClickBtn}
          >
            진행중인 이벤트
          </button>
          <button
            type="button"
            className={reqParams.tab == 'n' ? 'active' : ''}
            value="n"
            onClick={onClickBtn}
          >
            종료된 이벤트
          </button>
        </div>
      </div>

      <div className="inner event_inner">
        <ul className="list_wrap2">
          {eventList.map((event) => (
            <li key={event.bbsSeq}>
              <Link
                to={'/customer/event/' + event.bbsSeq}
                title="페이지 이동"
                className={reqParams.tab == 'y' ? 'card_cont1' : 'card_cont1 add_txt'}
                state={reqParams}
                onClick={onClickList}
              >
                <div className="img_wrap">
                  <img src={event.fileFullPath} alt="" />
                </div>
                <strong className="tlt">{event.bbsTitNm}</strong>
                <span className="date">
                  {event.eventStartDt} ~ {event.eventEndDt}
                </span>
              </Link>
            </li>
          ))}
        </ul>
        {/* 2023-03-01 12:31:38 페이징 inner 안으로 이동 */}
        <PaginationList paginationData={Page} currentNum={reqParams.currentPageNo} setCurrentNum={onChangePageNo} />
      </div>
    </>
  );
}

export default React.memo(EventList);
