import { createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store';

const initialState = {
  scroll: 0,
};

export const scrollSlice = createSlice({
  name: 'scroll',
  initialState,
  reducers: {
    setScroll: (state, { payload }) => {
      state.scroll = payload;
    },
  },
});

export const { setScroll } = scrollSlice.actions;

export default function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { scroll } = useAppSelector((state) => state.scroll);

  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history;

    if (pathname === '/buy/guide') {
      window.history.scrollRestoration = 'auto';
      window.scrollTo(0, scroll);
    } else {
      if (canControlScrollRestoration) {
        window.history.scrollRestoration = 'manual';
      }
      window.scrollTo(0, 0);
    }

    if (
      pathname !== '/model/introduce' &&
      pathname !== '/buy/estimate/select' &&
      pathname !== '/service/network' &&
      pathname !== '/buy/guide'
    ) {
      dispatch(setScroll(0));
    }
  }, [pathname]);

  return children;
}
