import React, { useEffect, useState } from 'react';
import { actSetCurrentNo } from 'utile/slice/searchBoardSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import useSearchFormHook from '../../../../utile/hook/useSearchFormHook';
import { actSaveSearchForm } from '../../../../utile/slice/tempFormSlice';

function SearchBar() {
  const dispatch = useAppDispatch();

  const { onError, onSearch, register, handleSubmit, getValues } = useSearchFormHook();
  const { searchForm } = useAppSelector((state) => state.tempForm);

  useEffect(() => {
    onSearch(searchForm)
    //dispatch(actSetCurrentNo(1));
  }, [searchForm]);

  function hideModal(e) {
    const inputWrap = e.target.closest('.input_layout');
    inputWrap.classList.remove('active');
  }

  const [placeholder, setPlaceholder] = useState('부품명을 입력하세요.');

  const onChangePart = (e) => {
    if (e.target.value === 'partNum') {
      setPlaceholder('부품번호를 입력하세요.');
    } else {
      setPlaceholder('부품명을 입력하세요.');
    }
  };

  const getSrchModelData = (e) => {
    const srchModelData = !e.target.value
      ? { currentPageNo: 1 }
      : { form: { srchModel: e.target.value, srchType: '', srchCont: '' }, currentPageNo: 1 };
    dispatch(actSetCurrentNo(1));
    dispatch(actSaveSearchForm({ srchModel: e.target.value, srchType: '', srchCont: '' }));
    //dispatch(getPartsInfoList(srchModelData));
    document.getElementById('input4').value = '';
  };

  const removeMsrchBar = () => {
    document.querySelector('form').classList.remove('active');
    dispatch(actSetCurrentNo(1));
    dispatch(actSaveSearchForm({ srchModel: getValues('srchModel'), srchType: getValues('srchType'), srchCont: getValues('srchCont') }));
  };

  const onSearchTwo = () => {
    if (!getValues('srchCont')) {
      dispatch(actSetCurrentNo(1));
    }
    dispatch(actSaveSearchForm({ srchModel: getValues('srchModel'), srchType: getValues('srchType'), srchCont: getValues('srchCont') }));
  }

  return (
    <>
      <form className="input_layout" onSubmit={handleSubmit(onSearchTwo, onError)}>
        <strong className="tlt mo_view">부품정보 검색</strong>
        <button
          type="button"
          title="팝업 닫기"
          className="close_btn ico_22_pop_close mo_view"
          onClick={hideModal}
        ></button>
        <div className="form_wrap">
          <div className="input_wrap">
            <div className="select_default">
              <select
                id="input1"
                className="input_select"
                {...register('srchModel')}
                onChange={getSrchModelData}
              >
                <option value="">모델명</option>
                <option value="T4K">T4K</option>
              </select>
            </div>
          </div>
        </div>
        <div className="form_wrap">
          <div className="input_wrap">
            <div className="select_default">
              <select
                id="input1"
                className="input_select"
                {...register('srchType')}
                onChange={onChangePart}
              >
                <option value="partName">부품명</option>
                <option value="partNum">부품번호</option>
              </select>
            </div>
          </div>
        </div>
        <div className="form_wrap">
          <div className="input_wrap">
            <input
              id="input4"
              name="input4"
              type="text"
              placeholder={placeholder}
              className="input_txt"
              {...register('srchCont')}
            ></input>
          </div>
          <button type="button" title="검색" className="btn_primary_m" onClick={removeMsrchBar}>
            <span>검색</span>
          </button>
        </div>
      </form>
    </>
  );
}

export default React.memo(SearchBar);
