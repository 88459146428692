import React, { useEffect, useState } from 'react';
import ProgressBar from '../common/ProgressBar';
import { useAppSelector, useAppDispatch } from '../../../../../store';
import { staticAutoComma } from '../../../../../utile/regExp';
import { useNavigate } from 'react-router-dom';

import {
  actShowDepth1LPopUp,
  actCb1LPopUp,
  actCb2LPopUp,
} from '../../../../../utile/slice/layerPopUpSlice';
import { getCityList, actIsSelectMetro } from '../../../../../utile/slice/citySlice';
import AlartPopUp from '../../../../common/AlartPopUp';
import useVerifyMessageFormHook from '../../../../../utile/hook/useVerifyMessageFormHook';
import requests from '../../../../../api/requests';
import { autoHypen } from '../../../../../utile/regExp';
import { actEffectToParentsAPopUp } from '../../../../../utile/slice/alartPopUpSlice';

// 개인정보, 제3자동의 팝업
import PersonalInfoPop from '../../../../common/popup/PersonalInfo';
import TermsPop from '../../../../common/popup/Terms';

function Docreserv() {
  function accOnOff(e) {
    const accCont = e.target.closest('.section_mychoice');

    accCont.classList.toggle('active');
  }

  function inputFocus(input) {
    input.target.closest('.input_wrap').querySelector('.input_del').classList.add('focus');
  }
  function inputFocusOut(input) {
    setTimeout(() => {
      input.target.closest('.input_wrap').querySelector('.input_del').classList.remove('focus');
    }, 200);
  }
  function delTxt(input) {
    input.target.closest('.input_wrap').querySelector('input').value = '';
    input.target.closest('.input_wrap').querySelector('.input_del').classList.remove('focus');
  }

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { estimateForm } = useAppSelector((state) => state.tempForm);

  //팝업창관련이벤트 시작//
  const alartInfo = {
    fail: {
      message: `구매상담 예약에 실패했습니다.<br>잠시 후 다시 시도해 주세요.`,
      cntBtn: 1,
    },
  };

  const { isShowAPopUp, isEffectToParentsAPopUp } = useAppSelector((state) => state.alartPopUp);

  const { isCb1LPopUp, isCb2LPopUp, depth1LPopUpName } = useAppSelector(
    (state) => state.layerPopUp,
  );

  const [syncIsCbAPopUp, setSyncIsCbAPopUp] = useState(isEffectToParentsAPopUp);

  useEffect(() => {
    setSyncIsCbAPopUp(true);
    if (syncIsCbAPopUp) {
      setSyncIsCbAPopUp(false);
      dispatch(actEffectToParentsAPopUp(false));
    }
  }, [isEffectToParentsAPopUp]);

  //팝업창관련이벤트 끝//

  //전화번호 하이픈 자동입력 이벤트시작//
  useEffect(() => {
    register('reserContactNum', {
      required: '신청 정보를 모두 입력해 주세요.',
      pattern: {
        value: /[0-9-]$/,
        message: '번호가 형식에 맞지 않습니다.',
      },
    });
    register('reserNm', {
      required: '신청 정보를 모두 입력해 주세요.',
      pattern: {
        value: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/,
        message: '이름이 형식에 맞지 않습니다.',
      },
    });
  }, []);

  const [AddHyphen, setAddHyphen] = useState('');

  useEffect(() => {
    setValue('reserContactNum', AddHyphen);
  }, [AddHyphen]);

  //전화번호 하이픈 자동입력 이벤트 끝//

  // 예약자이름 onChange 이벤트 시작
  const [reserName, setReserName] = useState('');

  useEffect(() => {
    setValue('reserNm', reserName);
  }, [reserName]);

  //예약자이름 onChange 이벤트 끝

  //거주지역 이벤트 시작//
  useEffect(() => {
    dispatch(getCityList());
  }, []);

  const { metroList, countryList } = useAppSelector((state) => state.city);

  const [resetCountyList, setResetCountyList] = useState(false);

  const getCountryList = (e) => {
    if (!e.target.value) {
      //2.셀렉초기값
      setResetCountyList(true);
      return;
    }
    //1.셀렉 메트로
    dispatch(getCityList(e.target.value));
    dispatch(actIsSelectMetro(true));
    setResetCountyList(false);
  };
  //거주지역 이벤트 끝//

  //폼관련 이벤트 시작//
  const { checkName, checkNumber } = useAppSelector((state) => state.verifyMessage);

  const { register, handleSubmit, onSubmit, onError, setValue, getValues, reset } =
    useVerifyMessageFormHook(alartInfo, requests.SEND_ESTIMATE_FORM);

  const { resultCode } = useAppSelector((state) => state.form);

  useEffect(() => {
    if (resultCode === true) {
      reset();
      setAddHyphen('');
      setReserName('');
    } else return;
  }, [resultCode]);
  //폼관련 이벤트 끝//

  //약관이벤트 시작
  const onClickPersonalInfo = () => {
    let prsInfoCltAgrCont = getValues('prsInfoCltAgrCont');

    if (prsInfoCltAgrCont) {
      setValue('prsInfoCltAgrCont', false);
      dispatch(actCb1LPopUp('unChecked'));
    } else {
      dispatch(actCb1LPopUp('checked'));
      dispatch(actShowDepth1LPopUp('personal'));
    }
  };

  const onClickTerms = () => {
    let prsInfo3cProAgrCont = getValues('prsInfo3cProAgrCont');

    if (prsInfo3cProAgrCont) {
      setValue('prsInfo3cProAgrCont', false);
      dispatch(actCb2LPopUp('unChecked'));
    } else {
      dispatch(actCb2LPopUp('checked'));
      dispatch(actShowDepth1LPopUp('terms'));
    }
  };

  useEffect(() => {
    if (isCb1LPopUp === 'unChecked') {
      setValue('prsInfoCltAgrCont', false);
    } else if (isCb1LPopUp === 'checked') {
      setValue('prsInfoCltAgrCont', true);
    } else return;
  }, [isCb1LPopUp]);

  useEffect(() => {
    if (isCb2LPopUp === 'unChecked') {
      setValue('prsInfo3cProAgrCont', false);
    } else if (isCb2LPopUp === 'checked') {
      setValue('prsInfo3cProAgrCont', true);
    } else return;
  }, [isCb2LPopUp]);
  //약관이벤트 끝

  const goDetail = () => {
    navigate('/buy/estimate/detail');
  };

  return (
    <div className="docreserv">
      {isShowAPopUp && <AlartPopUp />}
      {depth1LPopUpName === 'personal' && <PersonalInfoPop />}
      {depth1LPopUpName === 'terms' && <TermsPop />}
      <ProgressBar />
      <div className="inner">
        <p className="tit_head">
          <strong>구매 예약을 신청하세요.</strong>
          <br />
          차량 구매 상담을 위해 정보를 입력하세요.
        </p>

        <div className="section_mychoice">
          <div className="head_list" onClick={accOnOff}>
            <div className="option_label">{}</div>
            <div className="option_name">
              {`${estimateForm.mldNm}
               ${estimateForm.trimNm}`}
              <button type="button" title="항목 닫기">
                <i className="ico_32_accoup"></i>
              </button>
            </div>
          </div>
          <div className="body_list">
            <div className="section_detail1">
              <dl className="list_option">
                <dt>모델</dt>
                <dd className="name">
                  {`${estimateForm.mldNm}
               ${estimateForm.trimNm}`}
                </dd>
                <dd>
                  <span className="condition1">(VAT 포함) </span>
                  <strong>{staticAutoComma(estimateForm.model_price)}</strong>
                </dd>
              </dl>
              <dl className="list_option">
                <dt>컬러</dt>
                <dd className="name">{estimateForm.colorNm}</dd>
                <dd>
                  <strong>{staticAutoComma(estimateForm.color_price)}원</strong>
                </dd>
              </dl>
              <dl className="list_option package">
                <dt>옵션&패키지</dt>
                <dd className="name">
                  {estimateForm.packageNm}
                  <ul>
                    {estimateForm.packageNm === '풀옵션 패키지'
                      ? estimateForm.package_data.optionPackageData?.map((option, i) => (
                          <li key={i}>{option.optionNm}</li>
                        ))
                      : estimateForm.option_package_checked_list?.map((option, i) => (
                          <li key={i}>{option.optionNm}</li>
                        ))}
                  </ul>
                </dd>
                <dd>
                  <span className="condition2">패키지 할인 (-)250,000원 </span>
                  <strong>{staticAutoComma(estimateForm.primium_package_price - 250000)}원</strong>
                </dd>
              </dl>
            </div>
            <div className="section_detail2">
              <dl className="list_option price">
                <dt>차량가격</dt>
                <dd>{staticAutoComma(estimateForm.totalPrice)}원</dd>
              </dl>
              <dl className="list_option">
                <dt>보조금 (국비 및 지방비)</dt>
                <dd>
                  <span className="subsidy">
                    (-)
                    {staticAutoComma(estimateForm.subsidyPrice)}원
                  </span>
                </dd>
              </dl>
              <dl className="list_option">
                <dt>탁송</dt>
                <dd>{staticAutoComma(estimateForm.consignmentPrice)}원</dd>
              </dl>
              <dl className="list_option">
                <dt>등록비용</dt>
                <dd>{staticAutoComma(estimateForm.reg_fee_total)}원</dd>
              </dl>
              <dl className="list_option">
                <dt>임시운행 의무보험료</dt>
                <dd>{staticAutoComma(estimateForm.mandInsurPrice)}원</dd>
              </dl>
              <dl className="list_option total">
                <dt>총 견적 합계</dt>
                <dd>
                  {staticAutoComma(
                    estimateForm.totalPrice +
                      estimateForm.consignmentPrice -
                      estimateForm.subsidyPrice +
                      estimateForm.mandInsurPrice,
                  )}
                  원
                </dd>
              </dl>
            </div>
          </div>
        </div>

        <div className="section_infor">
          <form className="form" onSubmit={handleSubmit(onSubmit, onError)}>
            <p className="tit_user">신청자 정보입력</p>
            <div className="conf_cont">
              <div className="desc">
                {/* error case error 클래스 추가 */}
                <div className="form_wrap line_ty">
                  <div className="input_wrap">
                    <label htmlFor="input1" className="label">
                      이름
                    </label>
                    <input
                      id="input1"
                      name="input1"
                      type="text"
                      placeholder="예약자 이름을 입력하세요"
                      className="input_txt"
                      onFocus={inputFocus}
                      onBlur={inputFocusOut}
                      maxLength={50}
                      value={reserName}
                      onChange={(e) => {
                        setReserName(e.target.value);
                      }}
                    />
                    <button
                      type="button"
                      title="내용 지우기"
                      className="input_del"
                      onClick={delTxt}
                    >
                      <i className="ico_18_del"></i>
                    </button>
                  </div>
                  <span className="err_msg">{checkName}</span>
                </div>
                <div className="form_wrap line_ty">
                  <div className="input_wrap">
                    <label htmlFor="input1" className="label">
                      연락처
                    </label>
                    <input
                      id="input1"
                      name="input1"
                      type="text"
                      placeholder="숫자만 입력하세요"
                      className="input_txt"
                      onFocus={inputFocus}
                      onBlur={inputFocusOut}
                      maxLength={13}
                      value={autoHypen(AddHyphen)}
                      onChange={(e) => {
                        setAddHyphen(e.target.value);
                      }}
                    />
                    <button
                      type="button"
                      title="내용 지우기"
                      className="input_del"
                      onClick={delTxt}
                    >
                      <i className="ico_18_del"></i>
                    </button>
                  </div>
                  <span className="err_msg">{checkNumber}</span>
                </div>
                <div className="form_wrap line_ty">
                  <div className="input_wrap">
                    <label htmlFor="input1" className="label">
                      거주지역
                    </label>
                    <div className="select_layout">
                      <div className="select">
                        <select
                          className="input_select"
                          {...register('sidoCd', {
                            onChange: (e) => getCountryList(e),
                            required: '신청 정보를 모두 입력해 주세요.',
                          })}
                        >
                          <option value="">광역시/도</option>
                          {metroList?.map((metro) => (
                            <option key={metro.cd} value={metro.cd}>
                              {metro.addrName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="select">
                        <select
                          className="input_select"
                          id="input1"
                          {...register('sigunguCd', {
                            required: '신청 정보를 모두 입력해 주세요.',
                          })}
                        >
                          <option value="">시/군/구</option>
                          {resetCountyList
                            ? null
                            : countryList?.map((country) => (
                                <option key={country.cd} value={country.cd}>
                                  {country.addrName}
                                </option>
                              ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="input_checkbox_basic">
                <div className="box_checkbox">
                  <input
                    type="checkbox"
                    id="agree"
                    name="item1"
                    checked={
                      getValues('prsInfoCltAgrCont') && isCb1LPopUp === 'checked' ? 'checked' : ''
                    }
                    onChange={() => {
                      return;
                    }}
                  />
                  <label
                    className="input_checkbox"
                    {...register('prsInfoCltAgrCont', {
                      required: '약관에 동의하셔야 구매상담 신청이 가능합니다.',
                    })}
                    onClick={onClickPersonalInfo}
                  >
                    개인정보 수집과 이용에 동의합니다.
                  </label>
                </div>
              </div>

              <div className="input_checkbox_basic">
                <div className="box_checkbox">
                  <input
                    type="checkbox"
                    id="offer"
                    name="item1"
                    checked={
                      getValues('prsInfo3cProAgrCont') && isCb2LPopUp === 'checked' ? 'checked' : ''
                    }
                    onChange={() => {
                      return;
                    }}
                  />
                  <label
                    className="input_checkbox"
                    onClick={onClickTerms}
                    {...register('prsInfo3cProAgrCont', {
                      required: '약관에 동의하셔야 구매상담 신청이 가능합니다.',
                    })}
                  >
                    개인정보 제 3자 제공에 동의합니다.
                  </label>
                </div>
              </div>
            </div>
            <div className="conf_footer">
              <button type="button" title="버튼" className="btn_ghost_l" onClick={goDetail}>
                <span>이전</span>
              </button>
              <button title="버튼" className="btn_primary_l">
                <span>구매상담 신청</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Docreserv;
