import React from 'react';
import { useNavigate } from 'react-router-dom';

function Nopage() {
  const navigate = useNavigate();

  const goToMain = () => {
    navigate('/');
  };

  return (
    <div className="replace_page no_page">
      <div className="txt_wrap">
        <strong className="tlt">
          원하시는 페이지를 <br className="mo_view" />
          찾을 수 없어요!
        </strong>
        <p className="desc">
          찾으려는 웹페이지의 주소가 잘못 입력되었거나, <br className="mo_view" />
          주소의 변경 또는 삭제로 인해 사용하실 수 없습니다.
          <br />
          입력하신 링크정보, 접근 권한 등을 다시 한번 확인해 주세요.
        </p>
        <div
          style={{ display: 'inline-block', cursor: 'pointer' }}
          className="btn_primary_l"
          onClick={goToMain}
        >
          <span>T4K 홈으로 가기</span>
        </div>
      </div>
    </div>
  );
}

export default Nopage;
