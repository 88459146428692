/**
 * 메인 페이지 내 공통 버튼
 * @param { children } children 버튼 텍스트
 * @param { linkUrl } linkUrl 링크 Url
 * @param { className } className 버튼 클래스명
 * @returns
 */
function ButtonMain({ children, linkUrl, className }) {
  return (
    <a href={linkUrl ? linkUrl : '#'} className={className ? `btn-main ${className}` : 'btn-main'}>
      <i className="btn-line right" />
      <i className="btn-line top" />
      <i className="btn-line left" />
      <i className="btn-line full" />
      {children}
    </a>
  );
}
export default ButtonMain;
