import React from 'react';
import { Link } from 'react-router-dom';
import RelationPage from '../../../common/RelationPage';
import Visual from '../../../common/Visual';

function ServiceInfo() {
  const onClickEmail = (e) => {
    const copyTxt = e.target.innerText;
    window.navigator.clipboard.writeText(copyTxt).then(() => {});
  };

  return (
    <div className="service_info">
      <Visual tlt="서비스 안내" sub={'GS글로벌에서 제공하는 \n 서비스에 대해 확인 해보세요.'} />
      <div className="inner">
        <div className="cont">
          <div className="bg_gray"></div>
          <div className="tlt_wrap">
            <strong className="tlt">보증 기간 기준을 확인해 보세요.</strong>
            <p className="desc">
              보증수리 기간은 신차 판매일로부터 적용되며, 기간 및 주행거리 중 선 도래 한 항목이
              보증기간 만료의 기준이 됩니다.
            </p>
          </div>
          <div className="card_wrap">
            <ul className="card">
              <li className="item">
                <span className="category">고전압 배터리</span>
                <strong className="name">8년/120,000km</strong>
              </li>
              <li className="item">
                <span className="category">기타 고전압 부품</span>
                <strong className="name">8년/120,000km</strong>
                <span className="sub_de">단, 충전포트는 2년/40,000km</span>
              </li>
              <li className="item">
                <span className="category">일반/차체 보증기간</span>
                <strong className="name">2년/40,000km</strong>
                <span className="sub_de">공조시스템, 바디, 트림, 저전압시스템 포함</span>
              </li>
              <li className="item">
                <span className="category">샤시 시스템 보증기간</span>
                <strong className="name">3년/60,000km</strong>
                <span className="sub_de">단, 프레임은 2년/40,000km</span>
              </li>
            </ul>
            <ul className="info">
              <li>
                ※ 기타 고전압 부품 : BMS, BCC, DC-DC BOOSTER, 올인원 고전압 제어기, VCU, OBC, MSD,
                HV HARNESS
              </li>
              <li>※ 공조시스템의 냉·난방장치 보증기간의 경우 1년 이내는 주행거리와 무관하게 보증수리 가능</li>
            </ul>
          </div>
          {/* 2023-03-01 11:24:33 #117 버튼 삭제
          <Link to="/service/network" title="서비스 네트워크 조회" className="btn_primary_l">
            <span>서비스 네트워크 조회</span>
          </Link> */}
        </div>
        <div className="cont">
          <div className="tlt_wrap">
            <strong className="tlt">사후관리에 대해 알고가세요!</strong>
          </div>
          <ul className="list">
            <li>
              <strong className="name">리콜제도 정보</strong>
              <p className="desc">
                우리나라는 자동차 관리법에 따라 리콜제도를 실시합니다. &nbsp;
                <span className="desktop_br" />
                자동차 리콜센터(
                <a
                  href="http://car.go.kr"
                  target="_blank"
                  className="link new_window"
                  rel="noreferrer"
                  title="페이지 이동"
                >
                  http://car.go.kr
                </a>
                )에서 확인하세요.
              </p>
            </li>
            <li>
              <strong className="name">사후관리</strong>
              <p className="desc">
                GS글로벌은 BYD의 공식 수입원으로서 대한민국의 법규에 맞게
                <span className="desktop_br" />
                &nbsp;제작된 BYD의 차량을 수입하여 판매/사후관리를 제공합니다.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <RelationPage />
    </div>
  );
}

export default ServiceInfo;
