import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { autoComma, staticAutoComma } from '../../../../../utile/regExp';
import {
  resultAdvancePrice,
  resultBuyPrice,
  resultInterest,
  resultInterestPrice,
  resultMonthPrice,
  resultRest,
  resultShare,
} from './planPriceSlice';
const notPattern = /[`~!@#$%^&*()_|+\-=?;:'"<>\,\.\{\}\[\]\\\/\|ㄱ-ㅎ|ㅏ-ㅣ-ㅢ|가-힣|a-z|A-Z]/g;
const notPatternEx = /[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\[\]\\\/\|ㄱ-ㅎ|ㅏ-ㅣ-ㅢ|가-힣|a-z|A-Z]/g;
const pattern = /(^\d*[.]\d{3}$)|([^0-9.])|(^\d*[.]{2})/;
function PlanPopup({ popupClose, onClickApply }) {
  const minRestVal = 12;
  const maxRestVal = 60;
  const { estimateForm } = useAppSelector((state) => state.tempForm);

  const planPrice = useSelector((state) => state.planPrice);
  let buyPrice = planPrice.buyPrice; // 총구매금액
  let advancePrice = planPrice.advancePrice; // 선수금
  let shareVal = planPrice.figuresPrice.shareVal; // 할부원금
  let restVal = planPrice.figuresPrice.restVal; // 할부기간
  let interestVal = planPrice.figuresInterestPrice.interestVal; // 금리
  let interestPrice = planPrice.interestPrice; // 총할부이자
  let monthPrice = planPrice.monthPrice; // 월납입금
  const dispatch = useAppDispatch();

  useEffect(() => {
    let buyP = Number(estimateForm.totalPrice) - Number(estimateForm.subsidyPrice);
    // 할부원금이 구매가격 초과 시
    if (shareVal > buyP) {
      dispatch(resultShare(buyP));
    }
    // 총구매금액
    dispatch(resultBuyPrice(buyP));
  }, [estimateForm.totalPrice, estimateForm.subsidyPrice]);

  useEffect(() => {
    // 선수금 (총구매금액 - 할부원금)
    let advanceP = Number(buyPrice) - Number(shareVal);
    dispatch(resultAdvancePrice(advanceP));
    // 할부이자 (할부원금 * 금리/100 * (할부기간 + 1) / 2 / 12)
    let interestP = Math.round(
      (((Number(shareVal) * Number(interestVal)) / 100) * (restVal + 1)) / 2 / 12,
    );
    dispatch(resultInterestPrice(interestP));
    // 월 납입금 ((할부원금 + 총할부이자) / 할부기간)
    let monthP =
      restVal !== 0 ? Math.round((Number(shareVal) + Number(interestP)) / Number(restVal)) : 0;
    dispatch(resultMonthPrice(monthP));
  }, [buyPrice, shareVal, interestVal, restVal]);

  const showValue = (e) => {
    let value = e.target.value || '0';
    // 특수문자 제거
    let numVal = Number(value.toString().replace(notPattern, ''));
    // 할부원금
    if (e.target.name === 'inputPrice' || e.target.name === 'rangePrice') {
      // 구매 가격 초과 시
      if (numVal > Number(buyPrice)) {
        numVal = buyPrice;
      }
      dispatch(resultShare(numVal));
    }
    // 할부기간
    if (e.target.name === 'inputMonth' || e.target.name === 'rangeMonth') {
      // 할부 기간 초과 시
      if (numVal > maxRestVal) {
        numVal = maxRestVal;
      }
      dispatch(resultRest(numVal));
    }
  };

  const onChangeNumber = (e) => {
    let value = e.target.value || '0';
    let replacedVal = value.toString().replace(notPatternEx, ''); // .제외 특수문자 제거
    // 소수점 두자리까지 허용
    if (replacedVal.includes('.')) {
      if (pattern.test(replacedVal)) return false;
    } else {
      replacedVal = Number(replacedVal);
    }
    if (Number(replacedVal) > 100) {
      replacedVal = 100;
    }
    dispatch(resultInterest(replacedVal));
  };

  return (
    <div className="popup_wrap">
      <div className="dim" onClick={popupClose}></div>
      <div className="pop_m_inner">
        <div className="pop_header">
          <strong className="tlt">할부 선택</strong>
          <button
            type="button"
            title="팝업 닫기"
            className="ico_22_pop_close"
            onClick={popupClose}
          ></button>
        </div>
        <div className="pop_cont plan">
          <div className="tlt_wrap">
            <strong className="tlt">
              차량 구매 금액
              <span>(차량가격 - 구매보조금)</span>
            </strong>
            <em className="price">
              <span>
                {staticAutoComma(
                  Number(estimateForm.totalPrice) - Number(estimateForm.subsidyPrice),
                )}
              </span>
              원
            </em>
          </div>
          <ul className="plan_wrap">
            <li>
              <strong className="tlt">할부 원금</strong>
              <div className="form_wrap">
                <div className="input_wrap">
                  <input
                    id="inputPrice"
                    name="inputPrice"
                    min="0"
                    max={buyPrice}
                    placeholder="readonly"
                    // readOnly
                    className="input_txt"
                    value={staticAutoComma(shareVal)}
                    onChange={showValue}
                  ></input>
                  <span className="txt_unit">원</span>
                </div>
              </div>
              <div className="input_wrap">
                {/* <span
                  style={{
                    width: `${(shareVal / estimateForm.totalPrice) * 100}%`,
                    background: '#4955C1',
                    position: 'absolute',
                    left: '0',
                    top: '-3px',
                    height: '12px',
                    borderRadius: '6px',
                    zIndex: '2',
                  }}
                ></span> */}
                <input
                  id="rangePrice"
                  name="rangePrice"
                  type="range"
                  min="0"
                  max={buyPrice}
                  step="10000"
                  className="input_range"
                  onChange={showValue}
                  value={shareVal}
                ></input>
                <span className="ran_val">{staticAutoComma(shareVal)}</span>
                <span className="range_min">0</span>
                <span className="range_max">{staticAutoComma(buyPrice)}</span>
              </div>
            </li>
            <li className="before">
              <strong className="tlt">선수금</strong>
              <em className="price">
                <span>{staticAutoComma(advancePrice)}</span>원
              </em>
            </li>
            <li>
              <strong className="tlt">할부 기간</strong>
              <div className="form_wrap">
                <div className="input_wrap">
                  <input
                    id="inputMonth"
                    name="inputMonth"
                    min={minRestVal}
                    max={maxRestVal}
                    placeholder="readonly"
                    readOnly
                    className="input_txt"
                    value={restVal}
                    onChange={showValue}
                  ></input>
                  <span className="txt_unit">개월</span>
                </div>
              </div>
              <div className="input_wrap">
                <input
                  id="rangeMonth"
                  name="rangeMonth"
                  type="range"
                  min={minRestVal}
                  max={maxRestVal}
                  step="1"
                  className="input_range"
                  value={restVal}
                  onChange={showValue}
                ></input>
                <span className="ran_val">{restVal}</span>
                <span className="range_min">{minRestVal}</span>
                <span className="range_max">{maxRestVal}</span>
              </div>
            </li>
            <li>
              <strong className="tlt">금리</strong>
              <div className="form_wrap">
                <div className="input_wrap">
                  <input
                    id="input2"
                    name="input2"
                    inputMode="decimal"
                    className="input_txt"
                    value={interestVal}
                    onChange={onChangeNumber}
                  />
                  <span className="txt_unit">%</span>
                </div>
              </div>
            </li>
          </ul>
          <div className="total_wrap">
            <strong className="total">
              <span>총 할부이자</span>
              <span>
                <span>{autoComma(Number(interestPrice).toFixed())}</span>원
              </span>
            </strong>
            <em className="month">
              월 납입금
              <span>
                {autoComma(Number(monthPrice).toFixed())}
                <span>원</span>
              </span>
            </em>
          </div>
          <dl className="info">
            <dt>
              <i className="ico_24_noti_msg"></i>확인해 주세요.
            </dt>
            <dd>- 할부 원금은 취득세 및 부대비용을 제외한 금액입니다.</dd>
            <dd>
              - 할부 기간, 선수금 여부에 따라 월 할부금, 이자율은 변동될 수 있으며, 실제 차량 구매
              시 계산되는 금액과 다를 수 있습니다.
            </dd>
          </dl>
        </div>
        <div className="pop_footer">
          <button type="button" className="btn_primary_m" onClick={onClickApply}>
            <span>적용하기</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default React.memo(PlanPopup);
