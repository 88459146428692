import React from 'react';

function PaginationBoard({ detailData, goDetail, goList }) {
  let prevSeq;
  let nextSeq;

  prevSeq = detailData.prevBbsSeq;
  nextSeq = detailData.nextBbsSeq;

  const onClickPrev = () => {
    goDetail(prevSeq);
  };

  const onClickNext = () => {
    goDetail(nextSeq);
  };

  const onClickList = () => {
    goList();
  };

  return (
    <div className="detail_pagination">
      {prevSeq != null ? (
        <button type="button" title="버튼" className="btn_txt_bk prev" onClick={onClickPrev}>
          <i className="ico_20_prev_bk" />
          <span>이전 글</span>
        </button>
      ) : (
        <button type="button" title="버튼" className="btn_txt_bk prev disable">
          <i className="ico_20_prev_bk" />
          <span>이전 글</span>
        </button>
      )}
      <button title="버튼" className="btn_txt_bk" onClick={onClickList}>
        <i className="ico_20_board_list" />
        <span>목록으로</span>
      </button>
      {nextSeq != null ? (
        <button type="button" title="버튼" className="btn_txt_bk next" onClick={onClickNext}>
          <span>다음 글</span>
          <i className="ico_20_next_bk" />
        </button>
      ) : (
        <button type="button" title="버튼" className="btn_txt_bk next disable">
          <span>다음 글</span>
          <i className="ico_20_next_bk" />
        </button>
      )}
    </div>
  );
}

export default PaginationBoard;
