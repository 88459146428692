import { useAppSelector } from '../../../../store';
import { staticAutoComma } from '../../../../utile/regExp';

function PartList() {
  const { searchBoardList, currentPageNo, totalRecordCount } = useAppSelector(
    (state) => state.searchBoard,
  );

  return (
    <div className="table_scroll_wrap">
      <table>
        <colgroup>
          <col width="80px" />
          <col width="80px" />
          <col width="160px" />
          <col width="calc (50% - 320px)" />
          <col width="calc (50% - 240px)" />
          <col width="140px" />
          <col width="140px" />
        </colgroup>
        <caption className="blind">부품 정보</caption>
        <thead>
          <tr>
            <th>번호</th>
            <th>모델</th>
            <th>부품번호</th>
            <th>한글명</th>
            <th>영문명</th>
            <th>가격</th>
            <th>비고</th>
          </tr>
        </thead>
        <tbody>
          {searchBoardList.length ? (
            searchBoardList?.map((partInfo, i) => (
              <tr key={i}>
                <td style={{ textAlign: 'center' }}>
                  {totalRecordCount - ((currentPageNo - 1) * 10 + i)}
                </td>
                <td className="txt_center">{partInfo.mdlNm}</td>
                <td className="txt_center">{partInfo.partNum}</td>
                <td className="txt_center">{partInfo.partNm}</td>
                <td className="txt_center">{partInfo.partEnNm}</td>
                <td className="txt_center">
                  {partInfo.price < 0 ? '추후 업데이트 예정' : staticAutoComma(partInfo.price)}
                </td>
                <td className="txt_center">{partInfo.note}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="txt_center" colSpan="6">
                검색된 결과가 없습니다. 검색조건을 확인해주세요.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default PartList;
