import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  actIsFixLPopUp,
  actShowDepth1LPopUp,
  actChContLPopUp,
  actCb1LPopUp,
  actCb2LPopUp,
} from '../../../utile/slice/layerPopUpSlice';
import { actHideVerify } from '../../../utile/slice/verifyMessageSlice';
import { getCityList, actIsSelectMetro } from '../../../utile/slice/citySlice';
import AlartPopUp from '../../common/AlartPopUp';
import useVerifyMessageFormHook from '../../../utile/hook/useVerifyMessageFormHook';
import requests from '../../../api/requests';
import { autoHypen } from '../../../utile/regExp';

// 개인정보, 제3자동의 팝업
import PersonalInfoPop from '../../common/popup/PersonalInfo';
import TermsPop from '../../common/popup/Terms';

function ReservePopup() {
  const dispatch = useAppDispatch();

  //팝업창관련이벤트 시작//
  const alartInfo = {
    fail: {
      message: `사전예약 상담 예약에 실패했습니다.<br>잠시 후 다시 시도해 주세요.`,
      cntBtn: 1,
    },
  };

  const { isShowAPopUp } = useAppSelector((state) => state.alartPopUp);

  const closeLayerPopUp = () => {
    dispatch(actIsFixLPopUp(false));
    dispatch(actHideVerify());
    dispatch(actCb1LPopUp(false));
    dispatch(actCb2LPopUp(false));
    dispatch(actChContLPopUp(false));
  };
  //폼관련 이벤트 시작//
  const { checkName, checkNumber } = useAppSelector((state) => state.verifyMessage);

  const { register, handleSubmit, onSubmit, onError, setValue, getValues } =
    useVerifyMessageFormHook(alartInfo, requests.SEND_ADVRESER_FORM);
  //폼관련 이벤트 끝//

  const { isChContLPopUp, depth1LPopUpName, isCb1LPopUp, isCb2LPopUp } = useAppSelector(
    (state) => state.layerPopUp,
  );

  const onClickPersonalInfo = () => {
    let prsInfoCltAgrCont = getValues('prsInfoCltAgrCont');

    if (prsInfoCltAgrCont) {
      setValue('prsInfoCltAgrCont', false);
      dispatch(actCb1LPopUp('unChecked'));
    } else {
      dispatch(actCb1LPopUp('checked'));
      dispatch(actShowDepth1LPopUp('personal'));
    }
  };

  const onClickTerms = () => {
    let prsInfo3cProAgrCont = getValues('prsInfo3cProAgrCont');

    if (prsInfo3cProAgrCont) {
      setValue('prsInfo3cProAgrCont', false);
      dispatch(actCb2LPopUp('unChecked'));
    } else {
      dispatch(actCb2LPopUp('checked'));
      dispatch(actShowDepth1LPopUp('terms'));
    }
  };

  useEffect(() => {
    if (isCb1LPopUp === 'unChecked') {
      setValue('prsInfoCltAgrCont', false);
    } else if (isCb1LPopUp === 'checked') {
      setValue('prsInfoCltAgrCont', true);
    } else return;
  }, [isCb1LPopUp]);

  useEffect(() => {
    if (isCb2LPopUp === 'unChecked') {
      setValue('prsInfo3cProAgrCont', false);
    } else if (isCb2LPopUp === 'checked') {
      setValue('prsInfo3cProAgrCont', true);
    } else return;
  }, [isCb2LPopUp]);
  //약관이벤트 끝

  //전화번호 하이픈 자동입력 이벤트시작//
  useEffect(() => {
    register('reserContactNum', {
      required: '신청 정보를 모두 입력해 주세요.',
      pattern: {
        value: /[0-9-]$/,
        message: '번호가 형식에 맞지 않습니다.',
      },
    });
    register('reserNm', {
      required: '신청 정보를 모두 입력해 주세요.',
      pattern: {
        value: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/,
        message: '이름이 형식에 맞지 않습니다.',
      },
    });
  }, []);

  const [AddHyphen, setAddHyphen] = useState('');

  useEffect(() => {
    setValue('reserContactNum', AddHyphen);
  }, [AddHyphen]);
  //전화번호 하이픈 자동입력 이벤트 끝//

  // 예약자이름 onChange 이벤트 시작
  const [reserName, setReserName] = useState('');

  useEffect(() => {
    setValue('reserNm', reserName);
  }, [reserName]);

  //예약자이름 onChange 이벤트 끝

  //거주지역 이벤트 시작//
  useEffect(() => {
    dispatch(getCityList());
  }, []);

  const { metroList, countryList } = useAppSelector((state) => state.city);

  const [resetCountyList, setResetCountyList] = useState(false);

  const getCountryList = (e) => {
    if (!e.target.value) {
      //2.셀렉초기값
      setResetCountyList(true);
      return;
    }

    //1.셀렉 메트로
    dispatch(getCityList(e.target.value));
    dispatch(actIsSelectMetro(true));
    setResetCountyList(false);
  };
  //거주지역 이벤트 끝//

  function inputFocus(input) {
    input.target.closest('.input_wrap').querySelector('.input_del').classList.add('focus');
  }
  function inputFocusOut(input) {
    setTimeout(() => {
      input.target.closest('.input_wrap').querySelector('.input_del').classList.remove('focus');
    }, 200);
  }
  function delTxt(input) {
    input.target.closest('.input_wrap').querySelector('input').value = '';
    input.target.closest('.input_wrap').querySelector('.input_del').classList.remove('focus');
  }

  return (
    <div>
      <div className="popup_wrap reserve">
        <div className="dim"></div>
        <div className="conf_inner">
          <div className="conf_header">
            <h1 className="logo mo_view">
              <span className="blind">t4k</span>
            </h1>
            <button
              type="button"
              title="팝업 닫기"
              className="ico_24_pop_close2"
              onClick={closeLayerPopUp}
            ></button>
          </div>
          {isChContLPopUp ? (
            <div>
              <div className="logo_wrap">
                <h4 className="logo">
                  <span className="blind">t4k</span>
                </h4>
              </div>
              <div className="conf_cont complete">
                <strong className="tlt">
                  구매상담 신청이 <br />
                  완료되었습니다.
                </strong>
                <p className="desc">
                  신청 완료 문자가 발송되며,
                  <br />
                  영업담당자가 빠르게 연락드립니다!
                </p>
                <div className="conf_footer">
                  <button
                    type="button"
                    title="버튼"
                    className="btn_primary_l"
                    onClick={closeLayerPopUp}
                  >
                    <span>확인</span>
                  </button>
                  {/* <div className="logo_wrap">
                    <h4 className="logo">
                      <span className="blind">GS Global</span>
                    </h4>
                    <h4 className="logo">
                      <span className="blind">BYD</span>
                    </h4>
                  </div> */}
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="logo_wrap">
                <h4 className="logo">
                  <span className="blind">t4k</span>
                </h4>
              </div>
              <div className="conf_cont">
                <strong className="tlt">
                  지금, 사전예약 상담을
                  <br />
                  신청하세요!
                </strong>
                <div className="desc">
                  {/* error case error 클래스 추가 */}
                  <div className="form_wrap line_ty">
                    <div className="input_wrap">
                      <label htmlFor="input1" className="label">
                        이름
                      </label>
                      <input
                        id="input1"
                        name="input1"
                        type="text"
                        placeholder="예약자 이름을 입력하세요"
                        className="input_txt"
                        onFocus={inputFocus}
                        onBlur={inputFocusOut}
                        maxLength={50}
                        value={reserName}
                        onChange={(e) => {
                          setReserName(e.target.value);
                        }}
                      ></input>
                      <button
                        type="button"
                        title="내용 지우기"
                        className="input_del"
                        onClick={delTxt}
                      >
                        <i className="ico_18_del"></i>
                      </button>
                    </div>
                    <span className="err_msg">{checkName}</span>
                  </div>
                  <div className="form_wrap line_ty">
                    <div className="input_wrap">
                      <label htmlFor="input1" className="label">
                        연락처
                      </label>
                      <input
                        id="input1"
                        name="input1"
                        type="text"
                        placeholder="숫자만 입력하세요"
                        className="input_txt"
                        onFocus={inputFocus}
                        onBlur={inputFocusOut}
                        maxLength={13}
                        value={autoHypen(AddHyphen)}
                        onChange={(e) => {
                          setAddHyphen(e.target.value);
                        }}
                      />
                      <button
                        type="button"
                        title="내용 지우기"
                        className="input_del"
                        onClick={delTxt}
                      >
                        <i className="ico_18_del"></i>
                      </button>
                    </div>
                    <span className="err_msg">{checkNumber}</span>
                  </div>
                  <div className="form_wrap line_ty">
                    <div className="input_wrap">
                      <label htmlFor="input1" className="label">
                        거주지역
                      </label>
                      <div className="select_layout">
                        <div className="select">
                          <select
                            id="input1"
                            className="input_select"
                            type="text"
                            {...register('sidoCd', {
                              onChange: (e) => getCountryList(e),
                              required: '신청 정보를 모두 입력해 주세요.',
                            })}
                          >
                            <option value="">광역시/도</option>
                            {metroList?.map((metro) => (
                              <option key={metro.cd} value={metro.cd}>
                                {metro.addrName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="select">
                          <select
                            id="coutryList"
                            className="input_select"
                            {...register('sigunguCd', {
                              required: '신청 정보를 모두 입력해 주세요.',
                            })}
                          >
                            <option value="">시/군/구</option>
                            {resetCountyList
                              ? null
                              : countryList?.map((country) => (
                                  <option key={country.cd} value={country.cd}>
                                    {country.addrName}
                                  </option>
                                ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input_checkbox_basic">
                  <div className="box_checkbox">
                    <input
                      type="checkbox"
                      id="agree"
                      name="item1"
                      checked={
                        getValues('prsInfoCltAgrCont') && isCb1LPopUp === 'checked' ? 'checked' : ''
                      }
                      onChange={() => {
                        return;
                      }}
                    />
                    <label
                      className="input_checkbox"
                      onClick={onClickPersonalInfo}
                      {...register('prsInfoCltAgrCont', {
                        required: '약관에 동의하셔야 사전예약 상담신청이 가능합니다.',
                      })}
                    >
                      개인정보 수집과 이용에 동의합니다.
                      {/* 개인정보 <button type="button">수집과 이용</button>에 동의합니다. */}
                    </label>
                  </div>
                </div>

                <div className="input_checkbox_basic">
                  <div className="box_checkbox">
                    <input
                      type="checkbox"
                      id="offer"
                      name="item1"
                      checked={
                        getValues('prsInfo3cProAgrCont') && isCb2LPopUp === 'checked'
                          ? 'checked'
                          : ''
                      }
                      onChange={() => {
                        return;
                      }}
                    />
                    <label
                      className="input_checkbox"
                      onClick={onClickTerms}
                      {...register('prsInfo3cProAgrCont', {
                        required: '약관에 동의하셔야 사전예약 상담신청이 가능합니다.',
                      })}
                    >
                      {/* 개인정보 <button type="button">제 3자 제공</button>에 동의합니다. */}
                      개인정보 제 3자 제공에 동의합니다.
                    </label>
                  </div>
                </div>
              </div>
              <div className="conf_footer">
                <button title="" className="btn_primary_l">
                  <span>구매상담 신청</span>
                </button>
              </div>
            </form>
          )}
        </div>
      </div>

      {isShowAPopUp && <AlartPopUp />}
      {depth1LPopUpName === 'personal' && <PersonalInfoPop />}
      {depth1LPopUpName === 'terms' && <TermsPop />}
    </div>
  );
}

export default React.memo(ReservePopup);
