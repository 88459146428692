import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../store';
import { actIsLoading } from '../utile/slice/loadingSlice';

// axios instance 생성
const ax = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
});

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //비동기 싱크 맞춰주기 위함.
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    const pending = (config) => {
      dispatch(actIsLoading(true));
      return config;
    };

    const fulfilled = (response) => {
      dispatch(actIsLoading(false));
      //200
      return response;
    };

    const rejected = (error) => {
      dispatch(actIsLoading(false));
      //500 error
      if (error.response.status.toString().startsWith('5', 0)) {
        navigate('/access-error');
      }
      return Promise.reject(error);
    };

    const reqInterceptor = ax.interceptors.request.use(pending);
    const resInterceptor = ax.interceptors.response.use(fulfilled, rejected);
    setIsSet(true);

    return () => {
      ax.interceptors.request.eject(reqInterceptor);
      ax.interceptors.response.eject(resInterceptor);
    };
  }, []);

  return isSet && children;
};

export default ax;
export { AxiosInterceptor };
