import React, { useRef, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../../store';
import ProgressBar from '../common/ProgressBar';
import cargoWhImg from '../../../../../assets/images/img/img_model_type1_wh.png';
import cargoSbImg from '../../../../../assets/images/img/img_model_type1_sb.png';
import teugjangWhImg from '../../../../../assets/images/img/img_model_type2_wh.png';
import teugjangSbImg from '../../../../../assets/images/img/img_model_type2_sb.png';
import { CircularProgressbar } from 'react-circular-progressbar';
import { staticAutoComma } from '../../../../../utile/regExp';
import PDF from '../../../../pages/buy/estimate/document/Document';
import '../../../../pages/buy/estimate/document/document.css';
import ReactToPrint from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import { actResetEstimateForm } from '../../../../../utile/slice/tempFormSlice';

function CompEstimate() {
  const [state, setState] = useState({
    complete: 0,
  });
  setTimeout(() => {
    const circle = document.querySelector('.circle');

    setState({ ...state, complete: 100 });
    circle.classList.add('finish');
  }, 300);

  const ref = useRef();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { estimateForm } = useAppSelector((state) => state.tempForm);

  const goHome = () => {
    navigate('/');
    dispatch(actResetEstimateForm({}));
  };

  return (
    <div className="estimate">
      <ProgressBar />
      <div className="inner complet">
        <div className="compl_msg">
          <div className="circle">
            <CircularProgressbar value={state.complete} maxValue={100} />
          </div>
          <strong className="tlt">구매상담이 예약되었습니다.</strong>
          <p className="sub_tlt">담당자가 빠르게 연락드리겠습니다.</p>
        </div>

        <div className="summary">
          {estimateForm.type === 'T4K 카고' ? (
            <img
              src={estimateForm.COLOR_TYPE === 'WHITE' ? cargoWhImg : cargoSbImg}
              alt=""
              className="img"
            />
          ) : (
            <img
              src={estimateForm.COLOR_TYPE === 'WHITE' ? teugjangWhImg : teugjangSbImg}
              alt=""
              className="img"
            />
          )}
          <div className="total">
            <div className="model">
              <strong>{estimateForm.mldNm}</strong>
              {estimateForm.trimNm}
            </div>
            <div className="price">
              총 견적 합계
              <strong>
                {staticAutoComma(
                  estimateForm.totalPrice +
                    estimateForm.consignmentPrice -
                    estimateForm.subsidyPrice +
                    estimateForm.mandInsurPrice,
                )}
                원
              </strong>
            </div>
          </div>
        </div>
        <div className="section_detail1">
          <dl className="list_option">
            <dt>모델</dt>
            <dd className="name">
              {/* <div className="selectcolor mo_view"></div> */}
              {`${estimateForm.mldNm} ${estimateForm.trimNm}`}
            </dd>
            <dd>
              <span className="condition1">(VAT 포함) </span>
              <strong>{staticAutoComma(estimateForm.model_price)}원</strong>
            </dd>
          </dl>
          <dl className="list_option">
            <dt>컬러</dt>
            <dd className="name">
              {/* <div
                className={
                  estimateForm.COLOR_TYPE === 'WHITE'
                    ? 'selectcolorW mo_view'
                    : 'selectcolorR mo_view'
                }
              ></div> */}
              {estimateForm.colorNm}
            </dd>
            <dd>
              <strong>{staticAutoComma(estimateForm.color_price)}원</strong>
            </dd>
          </dl>
          <dl className="list_option package">
            <dt>옵션&패키지</dt>
            <dd className="name">
              {/* <div className="optionimg mo_view"></div> {estimateForm.packageNm} */}
              <ul>
                {estimateForm.packageNm === '풀옵션 패키지'
                  ? estimateForm.package_data.optionPackageData?.map((option, i) => (
                      <li key={i}>{option.optionNm}</li>
                    ))
                  : estimateForm.option_package_checked_list?.map((option, i) => (
                      <li key={i}>{option.optionNm}</li>
                    ))}
              </ul>
            </dd>
            <dd>
              <span className="condition2">패키지 할인 (-) 250,000원</span>
              <strong>{staticAutoComma(estimateForm.primium_package_price - 250000)}원</strong>
            </dd>
          </dl>
        </div>
        <div className="section_detail2">
          <dl className="list_option price">
            <dt>차량가격</dt>
            <dd>{staticAutoComma(estimateForm.totalPrice)}원</dd>
          </dl>
          <dl className="list_option">
            <dt>보조금 (국비 및 지방비)</dt>
            <dd>
              <span className="subsidy">(-) {staticAutoComma(estimateForm.subsidyPrice)}원</span>
            </dd>
          </dl>
          <dl className="list_option">
            <dt>탁송</dt>
            <dd>{staticAutoComma(estimateForm.consignmentPrice)}원</dd>
          </dl>
          {/* <dl className="list_option">
            <dt>등록비용</dt>
            <dd>000,000원</dd>
          </dl> */}
          <dl className="list_option">
            <dt>임시운행 의무보험료</dt>
            <dd>{staticAutoComma(estimateForm.mandInsurPrice)}원</dd>
          </dl>
          <dl className="list_option total">
            <dt>총 견적 합계</dt>
            <dd>
              {staticAutoComma(
                estimateForm.totalPrice +
                  estimateForm.consignmentPrice -
                  estimateForm.subsidyPrice +
                  estimateForm.mandInsurPrice,
              )}
              원
            </dd>
          </dl>
        </div>
        <div className="conf_footer">
          <span download type="button" className="btn_ghost_l">
            <i className="ico_24_download_bk"></i>
            <ReactToPrint
              trigger={() => <button>견적서 다운로드</button>}
              content={() => ref.current}
            />
            {/* <span>견적서 다운로드</span> */}
          </span>
          <span
            type="button"
            className="btn_primary_l"
            onClick={goHome}
            style={{ cursor: 'pointer' }}
          >
            <span>홈으로</span>
          </span>
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <div ref={ref}>
          <PDF />
        </div>
      </div>
    </div>
  );
}

export default CompEstimate;
