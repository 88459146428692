import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  checkName: '',
  checkNumber: '',
  checkEmail: '',
  checkTitNm: '', 
  checkUserNm: '', 
  checkPassword: '', 
  checkCont: ''
};

export const verifyMessageSlice = createSlice({
  name: 'verifyMessage',
  initialState,
  reducers: {
    actShowVerify: (state, { payload }) => {
      state.checkName = payload.reserNm;
      state.checkNumber = payload.reserContactNum;
      state.checkEmail = payload.reserContactMail;
    },
    actHideVerify: (state) => {
      state.checkName = '';
      state.checkNumber = '';
      state.checkEmail = '';
    },
    actShowVerifyBoard: (state, { payload }) => {
      state.checkTitNm = payload.titNm;
      state.checkUserNm = payload.userNm;
      state.checkPassword = payload.password;
      state.checkCont = payload.cont;
    },
    actHideVerifyBoard: (state) => {
      state.checkTitNm = '';
      state.checkUserNm = '';
      state.checkPassword = '';
      state.checkCont = '';
    },
  },
});

export const { actShowVerify, actHideVerify, actShowVerifyBoard, actHideVerifyBoard } = verifyMessageSlice.actions;

export default verifyMessageSlice;
