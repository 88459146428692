import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import ModelImg1 from '../../../../../assets/images/img/img_t4k_sampledrop.png';
import ModelImg2 from '../../../../../assets/images/img/img_t4k_sample2drop.png';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  actCb1APopUp,
  actCb2APopUp,
  actEffectToParentsAPopUp,
  actShowAPopUp,
} from '../../../../../utile/slice/alartPopUpSlice';
import {
  actAddInputEstimateForm,
  actResetEstimateForm,
} from '../../../../../utile/slice/tempFormSlice';
import AlartPopUp from '../../../../common/AlartPopUp';
import ChAlartPopUp from './ChAlartPopUp';
import ResetAlartPopUp from './ResetAlartPopUp';

function ProgressBar() {
  const location = useLocation();

  useEffect(() => {
    const per = document.querySelector('.per');
    const tlt = document.querySelectorAll('.item');
    const resetBtn = document.querySelector('[data-reset]');

    resetBtn.setAttribute('style', 'display:none');
    tlt.forEach((tlt) => {
      tlt.classList.remove('active');
    });
    // progress state
    if (location.pathname.includes('model')) {
      per.classList.add('part1');
      tlt[0].classList.add('active');
    } else if (location.pathname.includes('color')) {
      per.classList.add('part2');
      tlt[1].classList.add('active');
    } else if (location.pathname.includes('option')) {
      per.classList.add('part3');
      tlt[2].classList.add('active');
    } else if (location.pathname.includes('detail')) {
      const list = document.querySelector('.state_wrap');
      list.setAttribute('style', 'display:none');
      resetBtn.setAttribute('style', 'display:inline-block');
      per.classList.add('part4');
    } else if (location.pathname.includes('document')) {
      const list = document.querySelector('.state_wrap');
      list.setAttribute('style', 'display:none');
      resetBtn.setAttribute('style', 'display:inline-block');
    }
  }, [location]);

  function chooseOpen(e) {
    const btn = e.target.closest('.open_choose');
    const menu = document.querySelector('.choose');
    btn.classList.toggle('active');
    menu.classList.toggle('active');
  }

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { estimateForm } = useAppSelector((state) => state.tempForm);
  const linkModel = useRef();
  const linkColor = useRef();
  const linkOption = useRef();

  const { isShowAPopUp, isEffectToParentsAPopUp, isCb1APopUp, isCb2APopUp } = useAppSelector(
    (state) => state.alartPopUp,
  );

  const goModel = (e) => {
    navigate('/buy/estimate/model');
  };

  const goColor = (e) => {
    if (linkColor.current.style.cursor === 'pointer') {
      navigate('/buy/estimate/color');
    }
  };

  const goOption = (e) => {
    if (linkOption.current.style.cursor === 'pointer') {
      navigate('/buy/estimate/option');
    }
  };

  // const backSelect = () => {
  //   navigate('/buy/estimate/select');
  //   dispatch(actResetEstimateForm({}));
  // };

  useEffect(() => {
    linkModel.current.style.cursor = 'pointer';
    // 컬러 선택되어 있을 시 링크 활성화
    if (estimateForm.COLOR_TYPE) {
      linkColor.current.style.cursor = 'pointer';
    }
    // 옵션 선택되어 있을 시 링크 활성화
    if (estimateForm.OPTION_TYPE) {
      linkOption.current.style.cursor = 'pointer';
    }
  }, []);

  const endAlartInfo = {
    message: '견적내기를 종료하시겠습니까?',
    cntBtn: 2,
    btnName: { first: '아니요', second: '예' },
  };

  const estimateEnd = () => {
    dispatch(actShowAPopUp(endAlartInfo));
  };

  let type;

  useEffect(() => {
    if (isEffectToParentsAPopUp) {
      //견적종료
      navigate('/');
      dispatch(actResetEstimateForm({}));
    }

    if (isCb1APopUp) {
      //견적체인지 :예
      type = estimateForm.type === 'T4K 카고' ? 'T4K 특장' : 'T4K 카고';
      const menu = document.querySelector('.choose');
      menu.classList.remove('active');
      dispatch(actAddInputEstimateForm({ type }));

      if (window.location.pathname !== '/buy/estimate/model') {
        dispatch(actResetEstimateForm({ type }));
        navigate('/buy/estimate/model');
      }
    }

    if (isCb2APopUp) {
      //견적체인지 :아니요
      type = estimateForm.type === 'T4K 카고' ? 'T4K 특장' : 'T4K 카고';
      const menu = document.querySelector('.choose');
      menu.classList.remove('active');
    }

    dispatch(actCb1APopUp(false));
    dispatch(actCb2APopUp(false));
    dispatch(actEffectToParentsAPopUp(false));
  }, [isEffectToParentsAPopUp, isCb1APopUp, isCb2APopUp]);

  const [isChangeAlartPopUp, setIsChangeAlartPopUp] = useState(false);

  const changeEquipmentType = (e) => {
    if (estimateForm.type !== e.target.dataset.name) {
      setIsChangeAlartPopUp(true);
    }
  };

  const [isResetAlartPopUp, setIsResetAlartPopUp] = useState(false);

  const resetEstimateForm = () => {
    setIsResetAlartPopUp(true);
  };

  const onClickTit = () => {
    navigate('/');
    dispatch(actResetEstimateForm({}));
  };

  return (
    <div className="estimate_prog_header">
      {isShowAPopUp && <AlartPopUp />}
      {isChangeAlartPopUp && <ChAlartPopUp setIsChangeAlartPopUp={setIsChangeAlartPopUp} />}
      {isResetAlartPopUp && <ResetAlartPopUp setIsResetAlartPopUp={setIsResetAlartPopUp} />}
      <div className="tit">
        <button className="tit_home_logo" onClick={onClickTit}></button>
        <button type="button" className="open_choose" title="메뉴열기">
          {estimateForm.type === 'T4K 카고' ? 'T4K 카고' : 'T4K 특장'}
          {/* <i className="ico_24_down_arrow"></i> */}
        </button>
      </div>
      <ul className="state_wrap">
        <li className="item active" ref={linkModel} onClick={goModel}>
          <span>01.</span>모델
        </li>
        <li className="item" ref={linkColor} onClick={goColor}>
          <span>02.</span>컬러
        </li>
        <li className="item" ref={linkOption} onClick={goOption}>
          <span>03.</span>옵션
        </li>
      </ul>
      <div className="link_wrap">
        {/* <a href="/" download className="download_ca btn_txt_wh">
          <span>카달로그</span>
          <i className="ico_18_download"></i>
        </a> */}
        <button type="button" className="btn_txt_wh" data-reset onClick={resetEstimateForm}>
          <span className="pc_view">견적 다시내기</span>
          <i className="ico_22_reset"></i>
        </button>
        <button type="button" className="btn_txt_wh" onClick={estimateEnd}>
          <span className="pc_view">견적종료</span>
          <i className="ico_22_progress_close"></i>
        </button>
      </div>
      <div className="choose">
        <button
          type="button"
          className="item"
          data-name={estimateForm.type === 'T4K 카고' ? 'T4K 특장' : 'T4K 카고'}
          onClick={changeEquipmentType}
        >
          <span
            className="name"
            data-name={estimateForm.type === 'T4K 카고' ? 'T4K 특장' : 'T4K 카고'}
            onClick={changeEquipmentType}
          >
            {estimateForm.type === 'T4K 카고' ? 'T4K 특장' : 'T4K 카고'}
          </span>
          <span
            className="model"
            data-name={estimateForm.type === 'T4K 카고' ? 'T4K 특장' : 'T4K 카고'}
            onClick={changeEquipmentType}
          >
            <img
              src={ModelImg1}
              alt=""
              className="img"
              data-name={estimateForm.type === 'T4K 카고' ? 'T4K 특장' : 'T4K 카고'}
              onClick={changeEquipmentType}
            />
          </span>
        </button>
        <button
          type="button"
          className="item on"
          data-name={estimateForm.type !== 'T4K 카고' ? 'T4K 특장' : 'T4K 카고'}
          onClick={changeEquipmentType}
          style={{ cursor: 'default' }}
        >
          <span
            className="name"
            data-name={estimateForm.type !== 'T4K 카고' ? 'T4K 특장' : 'T4K 카고'}
            onClick={changeEquipmentType}
          >
            {estimateForm.type !== 'T4K 카고' ? 'T4K 특장' : 'T4K 카고'}
          </span>
          <span
            className="model"
            data-name={estimateForm.type !== 'T4K 카고' ? 'T4K 특장' : 'T4K 카고'}
            onClick={changeEquipmentType}
          >
            <img
              src={ModelImg2}
              alt=""
              className="img"
              data-name={estimateForm.type !== 'T4K 카고' ? 'T4K 특장' : 'T4K 카고'}
              onClick={changeEquipmentType}
            />
          </span>
        </button>
      </div>
      <div className="per"></div>
    </div>
  );
}

export default React.memo(ProgressBar);
