import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../api/ax';
import requests from '../../../../api/requests';

const initialState = {
    sortinglist: []
};

export const getSortingList = createAsyncThunk('sorting/getSortingList', async grpCd => {
    const res = await axios.get(requests.COMMON_CODE + '?grpCd=' + grpCd);
    let sortingListData = res.data.data;
    return sortingListData;
});

const sortingSlice = createSlice({
    name: 'sorting',
    initialState,
    extraReducers: builder => {
        builder.addCase(getSortingList.fulfilled, (state, { payload }) => {
            state.sortinglist = payload.list;
        });
    },
});

export default sortingSlice;