import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../../api/ax';
import requests from '../../../../../api/requests';

const initialState = {
  cateList: [], // 시/도 매입비율 리스트
  taxFreeDivList: [], // 면세 구분 리스트
  regTax: '0', // 취득세
  bondTax: '0', // 공채
  localTax: '2600', // 증지대
  plateFee: '25000', // 차량번호판
  charge: '55000', // 대행수수료
  regFeeTotal: '0', // 등록비용
};

export const getTaxFreeTypeList = createAsyncThunk('regFee/getTaxFreeTypeList', async () => {
  const res = await axios.get(requests.COMMON_CODE + '?grpCd=C2500');
  return res.data.data;
});

export const getPurcRateList = createAsyncThunk('regFee/getPurcRateList', async () => {
  const res = await axios.get(requests.PURC_RATE_LIST);
  let cateList = res.data.data;
  return cateList;
});

const regFeeSlice = createSlice({
  name: 'regFeeSlice',
  initialState,
  reducers: {
    actSetRegFeeTotal: (state, { payload }) => {
      state.regFeeTotal = payload;
    },
    actSetRegTax: (state, { payload }) => {
      state.regTax = payload;
    },
    actSetBondTax: (state, { payload }) => {
      state.bondTax = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTaxFreeTypeList.fulfilled, (state, { payload }) => {
      state.taxFreeDivList = payload.list;
    });
    builder.addCase(getPurcRateList.fulfilled, (state, { payload }) => {
      state.cateList = payload.cateList;
    });
  },
});

export const { actSetRegFeeTotal, actSetRegTax, actSetBondTax } = regFeeSlice.actions;
export default regFeeSlice;
