import { Outlet } from 'react-router-dom';

import Header from '../header/Header';
import Footer from '../footer/Footer';

function WideLayout() {
  return (
    <>
      <Header />
      <Outlet />
      {/* {main} */}
      <Footer />
    </>
  );
}

export default WideLayout;
