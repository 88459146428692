export const baseUrl = process.env.REACT_APP_API_ROOT;

const requests = {
  MAIN: '/api/mian',
  TEST: '',
  COMMON_CODE: '/api/commonCode',

  //메인
  MAIN_BANNER: '/api/mainBanner',
  MAIN_VISUAL: '/api/mainVisual',
  POPUP: '/api/popup',

  //소식
  NOTICE: '/api/notice',
  NEWS: '/api/news',
  SNS: '/api/sns',

  //게시판 비밀번호 확인
  CHECK_PASSWORD : '/verify-access',

  //고객게시판
  CUSTOMER: '/api/customer',

  //커뮤니티
  REVIEW: '/api/review',
  GALLERY: '/api/gallery',

  //이달의 판매조건
  SALES_TERMS: '/api/sales-terms',

  //이벤트
  EVENT: '/api/alliance-event',

  //T4K 특장
  MODEL_GALLERY : '/api/forte',

  //사전예약
  CITY_LIST: '/api/getCityList',
  SEND_ADVRESER_FORM: '/api/reqAdvReserConsult',
  //구매예약
  SEND_PURCHASE_FORM: '/api/reqPurReservation',
  //견적예약
  SEND_ESTIMATE_FORM: '/api/reqEstReservation',
  //시승 예약
  SEND_DRIVE_FORM: '/api/reqDriReservation',

  // 판매/서비스 네트워크
  SERVICE_NETWORK_LIST: '/api/getServiceNetworkList',
  SERVICE_NETWORK_DETAIL: '/api/getServiceNetworkDetail',
  SALES_MAN_LIST: '/api/getSalesManList',

  //서비스/부품정보
  SERVICE_PART: '/api/getPartsInfoList',

  // 견적
  subsidyPrice_LIST: '/api/getSubsidyPriceList',
  CONSIGNMENT_CHARGE_LIST: '/api/getConsignmentChargeList',
  PURC_RATE_LIST: '/api/getPurcRateList',
};

export default requests;
