import React, { useState } from 'react';
import Visual from '../../../common/Visual';
import RelationPage from '../../../common/RelationPage';

import PDF from '../../../../assets/pdfs/service_check.pdf';

import PDF01 from '../../../../assets/pdfs/guide01_2024.pdf';
import PDF02 from '../../../../assets/pdfs/guide02_2024.pdf';
import PDF03 from '../../../../assets/pdfs/guide03_2024.pdf';
import PDF04 from '../../../../assets/pdfs/guide04_2024.pdf';
import PDF05 from '../../../../assets/pdfs/guide05_2024.pdf';
import PDF06 from '../../../../assets/pdfs/guide06_2024.pdf';
import PDF07 from '../../../../assets/pdfs/guide07_2024.pdf';
import PDF08 from '../../../../assets/pdfs/guide08_2024.pdf';
import PDF09 from '../../../../assets/pdfs/guide09_2024.pdf';
import PDF10 from '../../../../assets/pdfs/guide10_2024.pdf';
import PDF11 from '../../../../assets/pdfs/guide11_2024.pdf';

// const PDF_PATH = '../../../../assets/pdfs/';
const guides = [
  {
    title: '소개, 목차, 사진색인',
    description: '취급설명서 안내 및 차량 내/외부 장치 위치 안내',
    href: PDF01,
  },
  {
    title: '안전',
    description: '안전벨트, 에어백 등 안전장치 사용 방법 및 주의사항',
    href: PDF02,
  },
  {
    title: '계기판',
    description: '계기판 및 표시등, 경고등',
    href: PDF03,
  },
  {
    title: '컨트롤러 작동',
    description: '도어와 키, 시트, 스티어링 휠, 스위치',
    href: PDF04,
  },
  {
    title: '사용 및 운전',
    description: '충전/전력, 배터리 사용 요령 및 주의사항',
    href: PDF05,
  },
  {
    title: '차내 장치',
    description: '차내 각종 장치 사용 방법 및 주의사항',
    href: PDF06,
  },
  {
    title: '정비 및 유지보수',
    description: '정기/자체 유지보수 항목과 차량관리 요령 및 주의사항',
    href: PDF07,
  },
  {
    title: '고장 발생 시',
    description: '차량 고장 발생시 응급 조치 방법',
    href: PDF08,
  },
  {
    title: '차량 사양',
    description: '차량 제원 및 경고표시',
    href: PDF09,
  },
  {
    title: '약어',
    description: '사용 약어의 영어/한국어 명칭',
    href: PDF10,
  },
  {
    title: '색인',
    description: '찾아보기',
    href: PDF11,
  },
];
const guides2 = [];

function Guide() {
  // react hook 문법
  // const [isOpen, setIsOpen] = useState(false);
  // const handleTabClick = () => setIsOpen(true);

  function sort(e) {
    const contName = e.target.dataset.btn;
    const btn = document.querySelectorAll('[data-btn]');
    const cont = document.querySelectorAll('[data-cont]');

    btn.forEach((list) => {
      list.classList.remove('active');
    });
    e.target.classList.add('active');
    cont.forEach((con) => {
      con.classList.remove('active');
      if (con.dataset.cont === contName) {
        con.classList.add('active');
      }
    });
  }

  return (
    <div className="guide">
      <Visual
        tlt="차량관리 가이드"
        sub={'차량 관리에 필요한 설명 가이드를\n확인하고 차량을 관리해 보세요.'}
      />
      {/* {isOpen && (
        <div class="AlartPopUp_wrap popup_wrap" style={{ display: 'flex', alignItems: 'center' }}>
          <div class="dim"></div>
          <div class="conf_inner">
            <div class="conf_header">
              <button
                type="button"
                title="팝업 닫기"
                class="ico_22_pop_close"
                onClick={() => setIsOpen(false)}
              ></button>
            </div>
            <div class="conf_cont">
              <strong class="tlt" style={{ fontSize: '18px' }}>
                서비스 준비 중입니다
              </strong>
              <p class="desc" style={{ fontSize: '15px', lineHeight: '150%' }}>
                조금만 기다려 주세요.
                <br />
                빠른 시일 내에 준비하겠습니다.
              </p>
            </div>
            <div class="conf_footer">
              <div class="btn_layout">
                <button type="button" class="btn_primary_m" onClick={() => setIsOpen(false)}>
                  <span>예</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}

      <div className="tab-head outinner">
        <div className="tab-inner">
          <button type="button" className="active" data-btn="inspection" onClick={sort}>
            차량점검주기
          </button>
          <button type="button" data-btn="instruction" onClick={sort}>
            차량취급설명서
          </button>
        </div>
      </div>

      <div className="inner">
        {/* 2023-03-01 11:56:35 차량관리 가이드 모델 선텍 셀렉트 박스 이동
        <div className="top_cont">
          <div className="form_wrap">
            <div className="input_wrap">
              <div className="select_default">
                <select id="input1" className="input_select">
                  <option value="">T4K</option>
                </select>
              </div>
            </div>
          </div>
        </div> */}
        <div className="active" data-cont="inspection">
          <div className="table_wrap pc_view">
            {/* 2023-03-01 12:06:47 #120 안내문구 및 다운로드 버튼 추가 */}
            <dl className="info_guide">
              <dt>
                안전한 운행을 위하여 주기적인 점검 또는 정비가 필요합니다. <br />
                서비스 네트워크에서 아래 표의 기준에 맞춰 점검/정비를 진행 하십시오.
              </dt>
              <dd>
                <a href={PDF} target="_blank" className="btn_primary_m">
                  <span>차량점검주기표 다운로드</span>
                </a>
              </dd>
            </dl>
            <ul className="com">
              <li className="tlt">정비계획</li>
              <li className="co">
                <i className="ico_18_comi"></i>점검
              </li>
              <li className="co">
                <i className="ico_18_comc"></i>클리닝
              </li>
              <li className="co">
                <i className="ico_18_comr"></i>교체
              </li>
              <li className="co">
                <i className="ico_18_comt"></i>조임
              </li>
              {/* 2023-03-01 11:59:43 다운로드 삭제
              <li className="down">
                <a href="/" download>
                  <span>PDF 다운로드</span>
                  <i className="ico_20_download_pp"></i>
                </a>
              </li> */}
            </ul>
            <div className="table_scroll_wrap">
              <table>
                <colgroup>
                  <col width="200px" />
                  <col width="736px" />
                  <col width="116px" />
                  <col width="119px" />
                  <col width="119px" />
                  <col width="119px" />
                  <col width="119px" />
                </colgroup>
                <caption className="blind">차량점검주기 테이블</caption>
                <thead>
                  <tr>
                    <th className="diagonal_layout" rowSpan="2">
                      <svg xmlns="http://www.w3.org/2000/svg">
                        <line x1="0" y1="0" x2="100%" y2="100%" stroke="#dedede" />
                      </svg>
                      <span className="b">유지보수 항목</span>
                      <span className="t">유지보수 주기</span>
                    </th>
                    <th>거리 (x1,000km) | 거리 (x1,000miles)</th>
                    <th rowSpan="2">주행거리 첫 정비 &lt; 5,000km</th>
                    <th>5 | 3</th>
                    <th>15 | 9</th>
                    <th>30 | 18</th>
                    <th>60 | 36</th>
                  </tr>
                  <tr>
                    <th>교체주기 (월)</th>
                    <th>1개월마다</th>
                    <th>3개월마다</th>
                    <th>6개월마다</th>
                    <th>12개월마다</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan="2">완성차</td>
                    <td>섀시 볼트류 고정상태 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>완성차 윤활부 점검 및 그리스 주입 (유지보수 필수)</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comr"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="2">섀시 방청</td>
                    <td>
                      고정장치(클램프, 클립, 브라켓, 조인트, 등) 및 배선 및 호스, 파이프류 부식여부
                      점검
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>프레임 앞뒤축 및 서스펜션 등 부품을 점검</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan="2">프레임</td>
                    <td>프레임 서스펜션이 장착된 위치와 배터리팩 장착위치를 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>프레임의 조임부 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="3">적재함</td>
                    <td>적재함의 조임부 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>적재함의 잠금 메커니즘(잠금 고리, 잠금 레버), 힌지부위를 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>적재함 전체 (바닥, 앞면, 뒷면, 측면) 변형 및 용접부 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="6">브레이크 시스템</td>
                    <td>시스템 기능이 정상 작동하는지 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      브레이크 호스 및 파이프의 손상, 부싱 및 오일씰 등 변형 및 경화 여부 점검
                    </td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>브레이크 호스 및 파이프, 진공호스 누유 및 누기 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>★브레이크 오일 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td colSpan="2" className="txt_center">
                      첫 교체주기는 18개월이고 6만km 혹은 24개월마다 교체와 클리닝을 진행
                    </td>
                  </tr>
                  <tr>
                    <td>브레이크 캘리퍼 작동 점검 (고착, 누유 등)</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>브레이크의 핀 및 부싱의 마모 상태, 윤활위치의 윤활 상태 점검</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="1">브레이크</td>
                    <td>브레이크 작동 및 제동력 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="5">스티어링 시스템</td>
                    <td>스티어링 시스템의 각종 볼트/너트류 조임상태 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>스티어링 관련 부품 및 호스류 손상 및 누유 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>스티어링 펌프 작동 상태, 보호 커버 파손 여부 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>스티어링 컬럼샤프트 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>스티어링 오일 교환 및 오일탱크 세척</td>
                    <td></td>
                    <td colSpan="4" className="txt_center">
                      12만km 혹은 24개월마다 교체와 클리닝을 진행
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan="3">냉각 시스템</td>
                    <td>전자 팬, 전동 펌프의 정상 작동 여부 점검; 라디에이터를 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>냉각시스템 호스 및 파이프 연결을 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>★냉각액 교환</td>
                    <td></td>
                    <td colSpan="3" className="txt_center">
                      24만km 혹은 48개월마다 교체
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="12">주행 시스템</td>
                    <td>쇼크 업소버 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>서스펜션 부싱 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>강판 스프링 점검(판 스프링)</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>휠얼라인먼트 점검 (토인 및 조향각)</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>휠 및 타이어 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>전후축 및 휠 트레드 클리닝</td>
                    <td className="txt_center">
                      <i className="ico_18_comc"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comc"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>조향링크와 볼 헤드 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>타이어 위치 교환</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comr"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>메인 핀과 조향 부싱 간극 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      프론트 휠 해체, 조향 고리,메인 핀, 베어링(유지보수가 필요한 타이어 휠 구조)을
                      클리닝
                    </td>
                    <td></td>
                    <td></td>

                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comc"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>타이어 휠 부품 점검</td>
                    <td></td>
                    <td></td>

                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      타이어 휠의 윤활 그리스를 교체하고 베어링의 간극을 조정(유지보수가 필요한
                      타이어 휠 구조)
                    </td>
                    <td></td>
                    <td></td>

                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comr"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="7">구동축</td>
                    <td>배선점검(고/저전압)</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>누유, 갈라짐, 이상 변형 상황을 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>조임부를 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>기어 오일 교환, 에어 밸브 청소</td>
                    <td className="txt_center layout">
                      <i className="ico_18_comr"></i>
                      <i className="ico_18_comc"></i>
                    </td>
                    <td colSpan="4" className="txt_center">
                      1만km 마다 점검하고 추가작업 진행, 2만km마다 교체 유지보수
                    </td>
                  </tr>
                  <tr>
                    <td>타이어 휠,베어링,오일 실 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td colSpan="2" className="txt_center">
                      1만km 마다 점검하고 추가작업 진행, 2만km마다 교체 유지보수
                    </td>
                  </tr>
                  <tr>
                    <td>타이어 휠 부품 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      타이어 휠 윤활 그리스 교체,베어링 간극 조정(유지보수가 필요한 타이어 휠 구조)
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comr"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="1">고전압 차단 커넥터(MSD)</td>
                    <td>고전압 차단 커넥터의 스위치를 점검</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan="1">누전 센서</td>
                    <td>호스 및 배선 고정장치(클램프, 브라켓, 조인트) 고정상태 및 부식여부점검</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan="2">고전압 배터리 시스템</td>
                    <td>고전압 배터리팩과 프레임 사이의 조임상태 점검</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comt"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>배터리팩 및 관련 고전압선, 커넥터 및 접지선 고정 상태 점검</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan="2">충전포트</td>
                    <td>충전포트 및 커버 상태 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>충전포트 배선 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="2">저압 배선</td>
                    <td>구동축 모터의 접지선 점검구동축 모터의 접지선 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>구동축 주변부 내에 저압선 배선 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="2">12V 배터리</td>
                    <td>점검,고정 상태,클리닝 상태</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>★외관 및 고정 상태 점검, 클리닝 또는 교체</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="1">경음기</td>
                    <td>경음기 기능 점검</td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="1">퓨즈박스</td>
                    <td>배전함과 케이블 연결 위치 점검</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="1">데이터 기록 모듈</td>
                    <td>제품 경고등 및 지시등, 계기판 알림 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="3">램프/조명</td>
                    <td>램프/조명 기능 점검</td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>완성차 램프/조명 기능 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>완성차 램프/조명 외관 손상 점검 (배선 포함)</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="6">에어컨 시스템 장치</td>
                    <td>에어컨 냉매의 충전량 점검(시스템 압력 점검)</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>파이프 용접 부위, 브라켓, 볼트 조임상태 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>케이블 및 커넥터 연결 상태 확인</td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>에어컨의 공기 필터 및 콘덴서 클리닝 상태 점검</td>
                    <td className="txt_center">
                      <i className="ico_18_comc"></i>
                    </td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comc"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>공조시스템 배관 연결 상태 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>에어컨 냉매</td>
                    <td colSpan="2" className="txt_center">
                      10만km 혹은 36개월마다 교체
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="1">배터리 열 관리 시스템(통합식)</td>
                    <td>★냉각액 교환</td>
                    <td colSpan="2" className="txt_center">
                      24만km 혹은 48개월마다 교체
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="1">김서림 방지 장치</td>
                    <td>실외 환기 필터 교체 또는 세척</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comr"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="1">운전자 시트</td>
                    <td>시트 고정 장치,조절 기능,부품 상태 점검</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan="1">안전벨트</td>
                    <td>안전벨트 고정상태 및 작동 점검</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan="1">캐빈 도어</td>
                    <td>도어 시스템 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="2">프론트 캡/패널</td>
                    <td>프론트 캡/패널 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>앞 오버행 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="1">와이퍼</td>
                    <td>와이퍼 및 세척기 시스템 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="1">아웃사이드 미러</td>
                    <td>미러 상태와 안전성 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="1">유리</td>
                    <td>유리 파손 여부 및 주변부 점검</td>
                    <td></td>
                    <td></td>
                    <td className="txt_center">
                      <i className="ico_18_comi"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mo_view">
            <a
              class="card_cont3"
              href="/static/media/service_check.453e78602db0b387b907.pdf"
              target="_blank"
            >
              <div class="cont_l">
                <strong class="tlt">차량점검주기표</strong>
                <p class="desc">안전한 운행을 위하여 표의 기준에 맞춰 점검/정비를 진행 하세요.</p>
              </div>
              <span class="ico_60_down_pdf" title="파일 다운로드"></span>
            </a>
          </div>
        </div>

        <div className="" data-cont="instruction">
          <div className="guide_wrap">
            {guides.map((item, index) => {
              return (
                <a className="card_cont3" key={index} href={item.href} target="_blank">
                  <div className="cont_l">
                    <b className="tlt">{item.title}</b>
                    <p className="desc">{item.description}</p>
                  </div>
                  <span className="ico_60_down_pdf" title="파일 다운로드" />
                </a>
              );
            })}
          </div>
          {/* 2023-03-01 11:57:00 차량관리 가이드 모델 선택 설렉트 오픈 시 숨김처리 필요 */}
          {/* <div className="top_cont">
            <div className="form_wrap">
              <div className="input_wrap">
                <div className="select_default">
                  <select id="input1" className="input_select">
                    <option value="">T4K</option>
                  </select>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="dummy_con_box">
            <strong>
              서비스 준비중입니다
              <span>
                조금만 기다려 주세요.
                <br />
                빠른 시일 내에 준비하겠습니다.
              </span>
            </strong>
          </div> */}
        </div>
      </div>
      <RelationPage />
    </div>
  );
}

export default Guide;
