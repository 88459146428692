import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ax from '../../api/ax';
import requests from '../../api/requests';

const initialState = {
  metroList: [],
  countryList: [],
  isSelectMetro: false,
};

export const getCityList = createAsyncThunk('city/getCityList ', async (cd) => {
  if (cd) {
    //셀렉트박스 선택시
    const res = await ax.get(requests.CITY_LIST + '?cd=' + cd);
    let data = res.data.data.cityList;
    return data;
  } else {
    //초기렌더링
    const res = await ax.get(requests.CITY_LIST);
    let data = res.data.data.cityList;
    return data;
  }
});

const citySlice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    actIsSelectMetro: (state, { payload }) => {
      state.isSelectMetro = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCityList.fulfilled, (state, { payload }) => {
      if (state.isSelectMetro) {
        state.countryList = payload;
        state.metroList = [...state.metroList];
      } else {
        //초기
        state.metroList = payload;
        state.countryList = [];
      }
    });
  },
});

export const { actIsSelectMetro } = citySlice.actions;

export default citySlice;
