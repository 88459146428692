import React from 'react';
import { useAppDispatch } from '../../../../../store';
import { actHideDepth1LPopUp } from '../../../../../utile/slice/layerPopUpSlice';

function ModelDetail3() {
  const dispatch = useAppDispatch();
  const closeLayerPopUp = () => {
    dispatch(actHideDepth1LPopUp());
  };

  return (
    <div className="popup_wrap" popup-cont="detail">
      <div className="dim"></div>
      <div className="pop_l_inner">
        <div className="pop_header">
          <strong className="tlt"></strong>
          <button
            type="button"
            title="팝업 닫기"
            className="ico_22_pop_close"
            onClick={closeLayerPopUp}
          ></button>
        </div>
        <div className="pop_cont nofoot">
          {/* top */}
          <div className="info_tlt">
            <strong className="tlt">슈퍼캡 롱레인지 프리미엄3</strong>
            {/* <p className="price">
              <span>(VAT포함)</span>
              <em className="won">
                43,690,000
                <span>원 </span>
              </em>
            </p> */}
          </div>

          {/* desc */}
          <div className="cont">
            <strong className="sub_tlt">기본품목</strong>
            <dl className="list_dl2">
              <dt>파워트레인/성능</dt>
              <dd>
                EV 전용 플랫폼, 140kW 구동 모터, 82.02kWh 리튬인산철 블레이드배터리, 감속기,
                통합주행모드, 유압식 파워 스티어링, 회생제동 시스템, 배터리 프로텍터, 중량짐용 후륜
                현가장치, 고속 충전 포트 (멀티고속충전시스템) 배터리히팅시스템 기본 탑재, 중량짐용
                후륜 현가장치
              </dd>
            </dl>
            <dl className="list_dl2">
              <dt>안전</dt>
              <dd>
                운전석 에어백, 차체자세 제어기능, 경사로 밀림방지 기능, 급제동 경보기능, 가상 엔진
                사운드(보행자 보호), 후진 경고음 발생장치, 타이어 응급처치 키트, TPMS
              </dd>
            </dl>
            <dl className="list_dl2">
              <dt>외관</dt>
              <dd>
                195/70R15C-8PR 타이어, 헤드램프(안개등, 주간주행등 포함), 자외선 차단 유리(앞면),
                고급 범퍼, 아웃사이드 미러(전동접이, 열선, 전동미러조절), 적재함 브림커버,
                충전구(DC1 콤보타입), EV 전용 엠블럼(데칼
              </dd>
            </dl>
            <dl className="list_dl2">
              <dt>공조</dt>
              <dd>풀오토 에어컨</dd>
            </dl>
            <dl className="list_dl2">
              <dt>편의/내장</dt>
              <dd>
                실내 220V 전원장치 1 포트, USB 2 포트, EV 전용 클러스터 , 인조가죽시트, 운전석
                리클라이닝 & 슬라이딩 시트, 동승석 리클라이닝 시트, 도어맵 포켓, 센터 시트백
                콘솔박스, 운전석/조수석파워윈도우, 파워 아웃렛, 중앙집중식 도어잠금 장치,
                트립컴퓨터, D&N 룸미러, 고급코일보조매트, 후방유리 열선, 리놀륨 플로어매트,
                간이정비용 오픈후드, 쇽업쇼바, 운전석 열선시트, 운전석 통풍시트, 전동식 럼버서포트,
                가죽 스티어링 휠(열선포함), 운전석 선바이저 거울, 오토라이트 컨트롤, 트리플 턴
                시그널, 전동식 파킹 브레이크(오토홀드 포함), 버튼시동 & 스마트키, 후방 주차거리
                경고, 시가잭라이터, 다이얼 전자식 변속기어, 예약 충전 시스템
              </dd>
            </dl>
            <div className="sub_tlt_wrap">
              <strong className="sub_tlt">선택품목</strong>
              <p className="txt_notice">
                <i className="ico_24_notice"></i> 선택 품목이 포함된 모델입니다.
              </p>
            </div>
            <ul className="my_list">
              <li>순정 무선 휴대폰 충전패드</li>
              <li>프리미엄 팩 (ECM 하이패스 룸미러, 틸트 스티어링, 전동 접이식 아웃사이드 미러)</li>
              <li>동승석 에어백</li>
              <li>V2L 패키지 (전용커넥터 포함)</li>
              {/* <li>3kW 보조 충전케이블</li> */}
              <li>
                스마트 팩 (12.8인치 스마트패드+후방카메라+EV 스페셜 소프트웨어(티맵모빌리티 EV전용
                네비게이션+NUGU 음성인식시템))
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModelDetail3;
