import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { getSnsDetail } from '../../../../../../utile/slice/boardSlice';
import PaginationBoard from '../../../../../common/PaginationBoard';

function Page() {
  const navigate = useNavigate();
  const seqNum = useParams();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const snsDetail = useAppSelector(state => state.board.snsDetail);

  useEffect(() => {
    dispatch(getSnsDetail(seqNum.bbsSeq));
  }, [seqNum]);

  const goDetail = (seq) => {
    navigate(`/customer/sns/${seq}`);
  }

  const goList = () => {
    navigate('/customer/sns', {state: snsDetail.currentPageNo});
  }

  return (
    <>
      <div className="board" key={snsDetail.bbsSeq}>
        <span className="date">{snsDetail.createDate}</span>
        <strong className="tlt">
          {snsDetail.cateCd==="youtube" ? "[유튜브] " : 
            snsDetail.cateCd==="instagram" ? "[인스타그램] " : 
            snsDetail.cateCd==="blog" ? "[블로그] " : ""}
            {snsDetail.bbsTitNm}
        </strong>
        <div className="cont">
          <p className="desc" dangerouslySetInnerHTML={{ __html: snsDetail.bbsCont }} />
        </div>
      </div>
      <PaginationBoard detailData={snsDetail} goDetail={goDetail} goList={goList} />
    </>
  );
}

export default Page;
