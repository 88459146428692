import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

function BuyguideRegist() {
  return (
    <div className="buyguide-regist">
      <strong className="tlt">자동차 신규 등록 절차</strong>
      <Swiper className="list_stage pc_ta_view" slidesPerView="auto">
        <SwiperSlide className="item_stage">
          <div className="item_img step1"></div>
          <strong className="tlt">01. 검토</strong>
        </SwiperSlide>
        <SwiperSlide className="item_stage">
          <div className="item_img step2"></div>
          <strong className="tlt">02. 등록번호 부여</strong>
        </SwiperSlide>
        <SwiperSlide className="item_stage">
          <div className="item_img step3"></div>
          <strong className="tlt">03. 취・등록세 납부</strong>
        </SwiperSlide>
        <SwiperSlide className="item_stage">
          <div className="item_img step4"></div>
          <strong className="tlt">04. 자동차등록증 수령</strong>
        </SwiperSlide>
        <SwiperSlide className="item_stage">
          <div className="item_img step5"></div>
          <strong className="tlt">05. 번호판 및 봉인 부착</strong>
        </SwiperSlide>
      </Swiper>
      <div className="scroll_wrap mo_view">
        <div className="list_stage">
          <div className="item_stage">
            <div className="item_img step1"></div>
            <strong className="tlt">01. 검토</strong>
          </div>
          <div className="item_stage">
            <div className="item_img step2"></div>
            <strong className="tlt">02. 등록번호 부여</strong>
          </div>
          <div className="item_stage">
            <div className="item_img step3"></div>
            <strong className="tlt">03. 취・등록세 납부</strong>
          </div>
          <div className="item_stage">
            <div className="item_img step4"></div>
            <strong className="tlt">04. 자동차등록증 수령</strong>
          </div>
          <div className="item_stage">
            <div className="item_img step5"></div>
            <strong className="tlt">05. 번호판 및 봉인 부착</strong>
          </div>
        </div>
      </div>

      <div className="cont_regist">
        <strong className="tit">등록 시 필요한 서류 안내</strong>
        <div className="table_wrap">
          <div className="table_scroll_wrap">
            <table className="txt_center">
              <colgroup>
                <col width="25%" />
                <col width="25%" />
                <col width="25%" />
                <col width="25%" />
              </colgroup>
              <caption className="blind">자동차 신규 등록시 필요서류 안내표</caption>
              <thead>
                <tr>
                  <th>관할관청 제공</th>
                  <th>자동차 회사 제공</th>
                  <th>개인/법인 구비서류</th>
                  <th>과태료 부과 대상 시, 대리인 위임 시</th>
                </tr>
              </thead>
              <tbody className="txt_center">
                <tr>
                  <td>
                    신규등록신청서
                    <br />
                    등록세 영수필 통지서(은행 납입 확인서)
                    <br /> 공채매입필증 (면제 대상자는 면제 신청서)
                    <br />
                    번호판 영수증 <br />
                    과태료 납입통지서 (임시운행 허가기간 경과 시)
                  </td>
                  <td>
                    자동차 제작 증<br />
                    세금계산서
                    <br />
                    자동차 임시운행 허가증 및 임시번호판 (앞, 뒤)
                  </td>
                  <td>
                    책임 보험 영수증 또는 가입 증명서
                    <br />
                    개인 : 주민등록등본
                    <br />
                    법인 : 법인등기부 등본, 사업자등록증 사본
                  </td>
                  <td>자동차 소유자 인감증명서</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="box_information">
          <dl className="info">
            <dt>
              <i className="ico_24_info"> </i>
              유의사항
            </dt>
            <dd>- 모든 영업용 차량은 종합보험 또는 공제 조합 가입 증명서를 첨부해야 합니다.</dd>
            <dd>
              - 취득세는 신규 등록 또는 이전 등록 시 취득일로부터 2%를 관할관청 세무 2과 자진
              납부하여야 하며 기간 경과 시 20%의 가산금을 납부해야 합니다.
            </dd>
            <dd>
              - 임시 운행허가증 상의 출고일자와 세금 계산서 상의 출고일자는 동일해야 합니다. (3일간
              유효)
            </dd>
            <dd>- 세금 계산서와 등록 주소지를 증빙하는 서류상의 주소지는 일치해야 합니다.</dd>
            <dd>
              - 자가용 승용차에 한하여 홀·짝수를 지정할 수 있으며 신청 시 신청서의 우측 상단에 이를
              표기합니다.
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default BuyguideRegist;
