import React from 'react';
import Page from './Board';

function EventDetail() {
  return (
    <div className="event_detail inner">
      <Page />
      {/* <PaginationBoard /> */}
    </div>
  );
}

export default EventDetail;
