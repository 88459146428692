import React, { useEffect, useLayoutEffect } from 'react';
import RelationPage from '../../common/RelationPage';
import GlobalMap from '../../../assets/images/img/img_globalmap.png';
import GlobalMapMo from '../../../assets/images/img/img_brand_gsg_map.png';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function Gsg() {
  const handleGsap = () => {
    const target = document.querySelectorAll('[data-trigger]');

    ScrollTrigger.matchMedia({
      scroller: '[data-snap]',
      // desktop
      '(min-width: 769px)': function () {
        target.forEach((el) => {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: el,
                start: 'bottom bottom',
                end: 'center 55%',
                // markers: true,
              },
            })
            .to(el, {
              duration: 1.4,
              opacity: 1,
              y: 0,
            });
        });
      },

      //   mobile
      '(max-width: 769px)': function () {
        target.forEach((el) => {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: el,
                start: 'center bottom',
                end: 'center 55%',
                // scrub: 1,
                // markers: true,
              },
            })
            .to(el, {
              duration: 0.8,
              opacity: 1,
              y: 0,
            });
        });
      },
    });
  };

  useLayoutEffect(() => {
    handleGsap();
  }, []);

  return (
    <div className="gsg">
      <section className="contain-gsg1">
        <p className="txt-slogun" data-trigger>
          VALUE NO.1 <br className="tablet_view" />
          SOLUTION <br className="tablet_view" />
          PROVIDER
        </p>
        <p className="txt-desc" data-trigger>
          GS글로벌은 고객의 입장에서 <br className="tablet_view" />
          최적의 솔루션과 최상의 가치를 <br className="tablet_view" />
          제공하기 위해 노력하고 있습니다.
        </p>
      </section>
      <section className="contain-gsg2">
        <p className="txt-desc" data-trigger>
          수십 년간 축적된 사업 경험과 30여개의 해외 네트워크를 기반으로 <br className="pc_view" />
          전 세계 고객의 다양한 니즈에 맞는 최적의 솔루션을 제공하기 위해 끊임없이 노력하겠습니다.{' '}
        </p>
        <div className="box-infor">
          <div className="article-rate" data-trigger>
            <span className="txt-head" data-trigger>
              Value
            </span>
            <span className="txt-cont" data-trigger>
              No.<strong>1</strong>
            </span>
          </div>
          <div className="article-rate" data-trigger>
            <span className="txt-head" data-trigger>
              Establishment
            </span>
            <span className="txt-cont" data-trigger>
              <strong>1954</strong>
            </span>
          </div>
          <div className="article-rate" data-trigger>
            <span className="txt-head" data-trigger>
              World Wide Network
            </span>
            <span className="txt-cont" data-trigger>
              <strong>30</strong>+
            </span>
          </div>
        </div>
      </section>
      <section className="contain-gsg3">
        <div className="box-slogun">
          <p className="txt-slogun" data-trigger>
            글로벌 사회의 발전에 <br className="mo_view" />
            기여할 수 있는 <br className="pc_view" />
            최고의 <br className="mo_view" />
            가치를 창출할 것을 <br className="mo_view" />
            약속 드립니다.
          </p>
        </div>
        <div className="box-category">
          <div className="item">
            <div className="img" data-trigger></div>
            <div className="desc">
              <h3 className="tit" data-trigger>
                Trading
              </h3>
              <p className="txt-desc" data-trigger>
                GS글로벌은 해외 네트워크를 기반으로 철강, 석유&화학, 산업재 등의 다양한 Trading
                사업을 확대 하고 있습니다.
              </p>
              <ul className="detail">
                <li data-trigger>
                  <span className="tag">철강</span>
                  열연강판, 냉도금강판, 컬러강판, 선재, 스테인리스 등
                </li>
                <li data-trigger>
                  <span className="tag">석유&amp;화학</span>
                  벙커링 / 합성수지 / 윤활유 / Base Oil / PBAT 등
                </li>
                <li data-trigger>
                  <span className="tag">산업재</span>
                  석탄, 2차전지 소재 / 시멘트, 클링커 및 부자재 / 소형엔진, 전기차 충전 사업
                </li>
              </ul>
            </div>
          </div>
          <div className="item">
            <div className="img" data-trigger></div>
            <div className="desc">
              <h3 className="tit" data-trigger>
                Mobility
              </h3>
              <p className="txt-desc" data-trigger>
                Global EV제조사인 “BYD”와의 파트너십을 바탕으로 2020년부터 국내에서 EV상용차 중심
                판매사업을 운영하고 있습니다.
              </p>
              <ul className="detail">
                <li data-trigger>
                  <span className="tag">주요품목</span>
                  BYD 버스 : 대형 (11m), 소형 (9m)
                  <br />
                  BYD 트럭 : T4K(1톤 트럭)
                </li>
              </ul>
            </div>
          </div>
          <div className="item">
            <div className="img" data-trigger></div>
            <div className="desc">
              <h3 className="tit" data-trigger>
                Green
              </h3>
              <p className="txt-desc" data-trigger>
                신재생 에너지, 헬스케어, Recycling 등 친환경 산업과 연관된 제품을 발굴하여 국내·외
                고객사들에 공급하고 있습니다.
              </p>
              <ul className="detail">
                <li data-trigger>
                  <span className="tag">주요품목</span>Bio Energy : 우드팰릿, PKS(Palm Kernel
                  Shell), Palm Oil 등<br />
                  Solar Energy : 태양광 모듈, 인버터 등의 태양광 기자재
                  <br />
                  Healthcare : 심전도 패치, 휴대용 x-ray, 필러 등<br />
                  Recycling : 폐배터리, 폐플라스틱 등
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="contain-gsg4">
        <p className="txt-slogun" data-trigger>
          고객과 함께하는 <br className="mo_view" />
          내일을 그립니다
        </p>
        <div className="box-feature">
          <div className="box-items1">
            <span className="item" data-trigger>
              Competitive
            </span>
            <span className="space" data-trigger></span>
            <span className="item" data-trigger>
              Balance
            </span>
            <span className="space" data-trigger></span>
            <span className="item" data-trigger>
              Culture
            </span>
          </div>
          <div className="box-items2">
            <div className="item" data-trigger>
              <div className="img-item img1"></div>
              <span className="txt-item">
                <strong>경쟁력</strong>
                기존 사업의 고도화
              </span>
            </div>
            <div className="item" data-trigger>
              <div className="img-item img2"></div>
              <span className="txt-item">
                <strong>균형</strong>
                신사업 확대 및 수익창출
              </span>
            </div>
            <div className="item" data-trigger>
              <div className="img-item img3"></div>
              <span className="txt-item">
                <strong>문화</strong>
                실행 중심의 조직문화 정착
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="contain-gsg5">
        <h2 className="tit" data-trigger>
          Global Network
        </h2>
        <p className="txt-desc" data-trigger>
          GS글로벌은 17개국 23개 거점을 기반으로 한 World Wide Network를 구축하여 수출, 수입, 삼국간
          및 내수거래, 해외투자사업, 수입자동차 물류서비스사업 등의 Global Business를 전개하고
          있습니다.
        </p>
        <div className="box-office">
          <div className="box-map pc_view">
            <img src={GlobalMap} alt="" className="globalmap" />
          </div>
          <div className="box-map mo_view">
            <img src={GlobalMapMo} alt="" className="globalmap" />
          </div>
          {/* S : 사업소별 묶음 */}
          <div className="item america" data-trigger>
            <div className="box-pop">
              <h3 className="tit">North America</h3>
              <ul className="list">
                <li>- 미국 법인 (LA)</li>
              </ul>
            </div>
          </div>
          <div className="item europecis" data-trigger>
            <div className="box-pop">
              <h3 className="tit">Europe/CIS</h3>
              <ul className="list">
                <li>- 독일 법인</li>
                <li>- 모스크바 지사</li>
              </ul>
            </div>
          </div>
          <div className="item middleeast" data-trigger>
            <div className="box-pop">
              <h3 className="tit">Middle East</h3>
              <ul className="list">
                <li>- 리야드 지사</li>
                <li>- 두바이 지사</li>
                <li>- 이스탄불 지사</li>
              </ul>
            </div>
          </div>
          <div className="item china" data-trigger>
            <div className="box-pop">
              <h3 className="tit">China</h3>
              <ul className="list">
                <li>- 상해 법인/지사</li>
                <li>- 북경 지사</li>
                <li>- 대련 지사</li>
                <li>- 대만 지사</li>
              </ul>
            </div>
          </div>
          <div className="item japan" data-trigger>
            <div className="box-pop">
              <h3 className="tit">Japan</h3>
              <ul className="list">
                <li>- 동경 법인</li>
                <li>- 오사카 지사</li>
              </ul>
            </div>
          </div>
          <div className="item southwestasia" data-trigger>
            <div className="box-pop">
              <h3 className="tit">Southwest Asia</h3>
              <ul className="list">
                <li>- 뉴델리 지사</li>
                <li>- 푸네 지사</li>
                <li>- 첸나이 지사</li>
              </ul>
            </div>
          </div>
          <div className="item southeastasia" data-trigger>
            <div className="box-pop">
              <h3 className="tit">Southeast Asia</h3>
              <ul className="list">
                <li>- 인도네시아 법인</li>
                <li>- KL 지사</li>
                <li>- 방콕 지사</li>
                <li>- 호치민 지사</li>
                <li>- 하노이 지사</li>
                <li>- 싱가포르 법인</li>
              </ul>
            </div>
          </div>
          <div className="item oceania" data-trigger>
            <div className="box-pop">
              <h3 className="tit">Oceania</h3>
              <ul className="list">
                <li>- 호주 법인</li>
              </ul>
            </div>
          </div>
          {/* E : 사업소별 묶음 */}
        </div>
      </section>
      <RelationPage />
    </div>
  );
}

export default Gsg;
