import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { staticAutoComma } from '../../../../../utile/regExp';
import { actAddInputEstimateForm } from '../../../../../utile/slice/tempFormSlice';
import {
  actSetSido,
  actSetSigungu,
  actSetSubsidyPrice,
  getCityList,
  getSubsidyPrice,
} from './subsidyPriceSlice';

function SubsidyPrice({ accOnOff }) {
  const dispatch = useAppDispatch();
  const cityList = useAppSelector((state) => state.subsidyPrice.cityList); // 시/도 리스트
  const townList = useAppSelector((state) => state.subsidyPrice.townList); // 시/군/구 리스트
  const selectSido = useAppSelector((state) => state.subsidyPrice.selectSido); // 시/도
  const selectSigungu = useAppSelector((state) => state.subsidyPrice.selectSigungu); // 시/군/구
  const subsidyPrice = useAppSelector((state) => state.subsidyPrice.subsidyPrice); // 구매보조금

  const { estimateForm } = useAppSelector((state) => state.tempForm);

  useEffect(() => {
    dispatch(
      actAddInputEstimateForm({
        ...estimateForm,
        subsidyPrice: Number(subsidyPrice),
        subsidyAddrCdL: selectSigungu,
      }),
    );
  }, [subsidyPrice, selectSigungu]);

  useEffect(() => {
    dispatch(actSetSido(''));
    dispatch(actSetSigungu(''));
    dispatch(actSetSubsidyPrice('0'));
    dispatch(getCityList());
    dispatch(
      actAddInputEstimateForm({
        ...estimateForm,
        subsidyPrice: 0,
        subsidyAddrCdL: '',
      }),
    );
    dispatch(getSubsidyPrice('11'));
  }, []);

  useEffect(() => {
    console.log(townList)
    if(selectSido === "99"){
      dispatch(actSetSubsidyPrice(12000000));
    } else {
      if (townList.length > 0) {
        dispatch(actSetSubsidyPrice(townList[0].cateVal));
      } else {
        dispatch(actSetSubsidyPrice('0'));
      }
    }
  }, [townList]);

  const onChangeSelectSido = (e) => {
    let value = e.target.value;
    if(value === "99"){
      dispatch(actSetSubsidyPrice(12000000));
    }
    dispatch(actSetSido(value));
    dispatch(actSetSigungu(''));
    dispatch(getSubsidyPrice(value));
  };

  const onChangeSelectSigungu = (e) => {
    let value = e.target.value;
    let dataVal = e.target[e.target.selectedIndex].dataset.price;
    dispatch(actSetSigungu(value));
    if (e.target.selectedIndex) {
      dispatch(actSetSubsidyPrice(dataVal));
    } else {
      dispatch(actSetSubsidyPrice(dataVal));
    }
  };

  return (
    <>
      <li className="section active">
        <div className="tlt_wrap" onClick={accOnOff}>
          <strong className="tlt">
            구매 보조금
            <a
              href="https://www.ev.or.kr/portal/localInfo"
              target="_blank"
              rel="noreferrer"
              title="페이지 이동"
              className="btn_txt_bk"
            >
              <span>구매보조금 지급현황</span>
              <i className="ico_20_next_bk"></i>
            </a>
          </strong>
          <span className="price">
            <span className="num">
              <span>(-) </span>
              {staticAutoComma(subsidyPrice)}
            </span>
            원
            <button type="button" title="항목 닫기">
              <i className="ico_32_accoup"></i>
            </button>
          </span>
        </div>
        <div className="sec_cont">
          <div className="form_wrap line_ty">
            <div className="input_wrap">
              <label htmlFor="input1" className="label">
                지역조회
              </label>
              <div className="select_layout">
                <div className="select">
                  <select
                    id="selectSido"
                    className="input_select"
                    onChange={onChangeSelectSido}
                    value={selectSido}
                  >
                    {cityList.map((city) => (
                      <option value={city.cd} key={city.cd}>
                        {city.addrName}
                      </option>
                    ))}
                    <option value="99" key="99">공단</option>
                  </select>
                </div>
                <div className="select">
                  <select
                    id="selectSigungu"
                    className="input_select"
                    onChange={onChangeSelectSigungu}
                    value={selectSigungu}
                  >
                    {townList.map((city) => (
                      <option value={city.cateCd} key={city.cateCd} data-price={city.cateVal}>
                        {city.cateNm}
                      </option>
                    ))}
                    {selectSido === "99" &&
                      <option value="99999" key="99" data-price={12000000}>한국환경공단</option>
                    }
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </>
  );
}

export default React.memo(SubsidyPrice);
