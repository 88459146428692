import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../api/ax';
import requests from '../../../../api/requests';

const initialState = {
    repairTypeList: []
};

export const getRepairTypeList = createAsyncThunk('repairType/getRepairTypeList', async grpCd => {
    const res = await axios.get(requests.COMMON_CODE + '?grpCd=' + grpCd);
    let repairTypeListData = res.data.data;
    return repairTypeListData;
});

const repairTypeSlice = createSlice({
    name: 'repairType',
    initialState,
    extraReducers: builder => {
        builder.addCase(getRepairTypeList.fulfilled, (state, { payload }) => {
            state.repairTypeList = payload.list;
        });
    },
});

export default repairTypeSlice;