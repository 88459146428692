import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from './store';
import NoPage from './components/common/replacementPage/NoPage';

const BlockUrlAccess = () => {
  const { estimateForm } = useAppSelector((state) => state.tempForm);

  return <>{estimateForm.type ? <Outlet /> : <NoPage />}</>;
};

export default BlockUrlAccess;
