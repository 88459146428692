import React from 'react';
import RelationPage from '../../../common/RelationPage';
import Map from './Map';

function Network() {
  return (
    <div className="newwork">
      <div className="inner">
        <Map />
      </div>
      <RelationPage />
    </div>
  );
}

export default Network;
