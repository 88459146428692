import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../../api/ax';
import requests from '../../../../../api/requests';

const initialState = {
  cityList: [], // 시/도 리스트
  townList: [], // 시/군/구 리스트
  subsidyPrice: '0', // 구매보조금
  selectSido: '', // 시/도
  selectSigungu: '', // 시/군/구
};

export const getCityList = createAsyncThunk('subsidyPrice/getCityList', async () => {
  const res = await axios.get(requests.CITY_LIST);
  let cityListData = res.data.data;
  return cityListData;
});

export const getSubsidyPrice = createAsyncThunk(
  'subsidyPrice/getSubsidyPriceList',
  async (addrCd) => {
    const res = await axios.get(requests.subsidyPrice_LIST + '?addrCd=' + addrCd);
    return res.data.data;
  },
);

const subsidyPriceSlice = createSlice({
  name: 'subsidyPrice',
  initialState,
  reducers: {
    actSetSido: (state, { payload }) => {
      state.selectSido = payload;
    },
    actSetSigungu: (state, { payload }) => {
      state.selectSigungu = payload;
    },
    actSetSubsidyPrice: (state, { payload }) => {
      state.subsidyPrice = payload;
      console.log(payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCityList.fulfilled, (state, { payload }) => {
      state.cityList = payload.cityList;
    });
    builder.addCase(getSubsidyPrice.fulfilled, (state, { payload }) => {
      state.townList = payload.cateList;
    });
  },
});

export const { actSetSido, actSetSigungu, actSetSubsidyPrice } = subsidyPriceSlice.actions;
export default subsidyPriceSlice;
