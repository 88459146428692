import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store';
import AlartPopUp from '../../../common/AlartPopUp';
import useVerifyMessageBoardFormHook from '../../../../utile/hook/useVerifyMessageBoardFormHook';
import requests from '../../../../api/requests';
import { getCustomerDetail } from '../../../../utile/slice/boardSlice';
import { isEmpty } from 'lodash';


function QnaWrite() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const [reqParams, setReqParams] = useState(
    state ? state : {bbsSeq: 0, password: ''}
  );
  const [bbsSeq, setBbsSeq] = useState(0);
  const [mode, setMode] = useState('insert');
  const [titNm, setTitNm] = useState('');
  const [userNm, setUserNm] = useState('');
  const [password, setPassword] = useState('');
  const [cont, setCont] = useState('');
  const [secretYn, setSecretYn] = useState(false);
  
  const dispatch = useAppDispatch();
  const qnaDetail = useAppSelector(state => state.board.customerDetail);

  // 페이지 로드 또는 state 변경 시 실행
  useEffect(() => {
    // 수정 모드 판별
    if (state?.bbsSeq > 0) {
      setMode('update');
      setBbsSeq(state.bbsSeq);
      dispatch(getCustomerDetail({ bbsSeq: state.bbsSeq }));
    } else {
      // 등록 모드 초기화
      setMode('insert');
      resetForm();
    }
  }, [state, dispatch]);

  // 상세 정보가 로드되었을 때 폼 데이터 설정
  useEffect(() => {
    if (mode === 'update' && !isEmpty(qnaDetail)) {
      setTitNm(qnaDetail.bbsTitNm);
      setUserNm(qnaDetail.bbsUserNm);
      setPassword(''); // 비밀번호는 비공개 정보이므로 초기화에 포함되지 않음
      setCont(qnaDetail.bbsCont);
      setSecretYn(qnaDetail.secretYn);
    }
  }, [qnaDetail, mode]);

  // 폼 초기화 함수
  const resetForm = () => {
    setBbsSeq(0); // bbsSeq 초기화
    setTitNm('');
    setUserNm('');
    setPassword('');
    setCont('');
    setSecretYn(false);
  };

  const alartInfo = {
    fail: {
      message: `게시글 등록에 실패했습니다.<br>잠시 후 다시 시도해 주세요.`,
      cntBtn: 1,
    },
  };

  const { isShowAPopUp } = useAppSelector((state) => state.alartPopUp);
  useEffect(() => {
    register('bbsTitNm', {
      required: '신청 정보를 모두 입력해 주세요.',
    });
    register('bbsUserNm', {
      required: '신청 정보를 모두 입력해 주세요.',
    });
    register('bbsCont', {
      required: '신청 정보를 모두 입력해 주세요.',
    });
    register('password', {
      required: '신청 정보를 모두 입력해 주세요.',
    });
  }, []);
  

  useEffect(() => {
    setValue('bbsTitNm', titNm);
  }, [titNm]);

  useEffect(() => {
    setValue('bbsUserNm', userNm);
  }, [userNm]);

  useEffect(() => {
    setValue('password', password);
  }, [password]);

  useEffect(() => {
    setValue('bbsCont', cont);
  }, [cont]);

  useEffect(() => {
    setValue('secretYn', secretYn);
  }, [secretYn]);

  const { checkTitNm, checkUserNm, checkPassword, checkCont } = useAppSelector((state) => state.verifyMessage);

  const { register, handleSubmit, onSubmit, onError, setValue, reset } =
    useVerifyMessageBoardFormHook(alartInfo, requests.CUSTOMER);

  const { resultCode } = useAppSelector((state) => state.boardForm);

  useEffect(() => {
    
    if (resultCode === "00") {
      alert("등록되었습니다.");
    }else if (resultCode === "02") {
      alert("비밀번호가 일치하지 않습니다.");
    }else if (resultCode === "03") {
      alert("존재하지 않는 게시글 입니다.");
    }

    if(resultCode === "00" || resultCode === "03") {
      reset();
      
      setTitNm('');
      setUserNm('');
      setPassword('');
      setCont('');
      setSecretYn(false);
      navigate('/customer/qna');
    }
    
  }, [resultCode, reset, dispatch, setTitNm, setUserNm, setPassword, setCont, navigate]);

  const goDelete = () => {
    if(password === ""){
      alert("비밀번호를 입력해주세요.");
      return;
    }
    fetch(requests.CUSTOMER, {
      method: 'delete',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          bbsSeq: bbsSeq,
          password: password
      })
    })
    .then(res => res.json())
    .then(res => {
      if (res.resultCode) {
        if(res.data.resultCode==="00"){
          alert("삭제가 완료되었습니다.");
          navigate('/customer/qna');
        } else if(res.data.resultCode==="01"){
          alert("비밀번호를 입력해주세요.");
        } else if(res.data.resultCode==="02"){
          alert("비밀번호가 일치하지 않습니다.");
        } else if(res.data.resultCode==="03"){
          alert("존재하지 않는 게시글입니다.");
        }
      } else{
        alert("정상적으로 처리되지 않았습니다.");
      }
    });
  };

  return (
    <div className="qna_write inner">
      {isShowAPopUp && <AlartPopUp />}
      <div className="visual">
        <h2 className="tlt">고객게시판</h2>
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="input_checkbox_basic">
          <div className="box_checkbox">
            <input
              type="checkbox"
              id="secret"
              name="secretYn"
              value="Y"
              checked={secretYn === 'Y'} // 'checked' 문자열 대신 불리언 값 사용
              onChange={(e) => {
                setSecretYn(e.target.checked ? 'Y' : 'N'); // 체크박스 변경 시 secretYn 상태 업데이트
              }}
            />
            <label htmlFor="secret">
              비밀글
            </label>
          </div>
        </div>  
        <div className="form_wrap line_ty">
          <div className="input_wrap">
            <label htmlFor="input1" className="label3">
              제목
            </label>
            <input
              id="input1"
              name="bbsTitNm"
              type="text"
              placeholder="제목을 입력하세요."
              className="input_txt2"
              value={titNm}
              onChange={(e) => {
                setTitNm(e.target.value);
              }}
            ></input>
          </div>
          <span className="err_msg">{checkTitNm}</span>
        </div>
        <div className="form_wrap line_ty">
          <div className="input_wrap">
            <label htmlFor="input1" className="label3">
              작성자
            </label>
            <input
              id="input1"
              name="bbsUserNm"
              type="text"
              placeholder="이름을 입력하세요."
              className="input_txt2"
              value={userNm}
              onChange={(e) => {
                setUserNm(e.target.value);
              }}
            ></input>
          </div>
          <span className="err_msg">{checkUserNm}</span>
        </div>
        <div className="form_wrap line_ty">
          <div className="input_wrap">
            <label htmlFor="input1" className="label3">
              비밀번호 {mode === "modify" ? "확인" : "등록"}
            </label>
            <input
              id="input1"
              name="password"
              type="password"
              placeholder="비밀번호를 입력하세요."
              className="input_txt2"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            ></input>
          </div>
          <span className="err_msg">{checkPassword}</span>
        </div>
        <div className="form_wrap line_ty typeTop">
          <div className="input_wrap textarea_wrap">
            <label htmlFor="input1" className="label3">
              내용
            </label>
            <textarea
              id="input1"
              name="bbsCont"
              type="text"
              placeholder="내용을 입력하세요."
              className="textarea_txt"
              value={cont}
              onChange={(e) => {
                setCont(e.target.value);
              }}
            ></textarea>
          </div>
          <span className="err_msg">{checkCont}</span>
        </div>
        {bbsSeq !== 0 ? 
          <div className='btn_layout right'>
            <input type='button' className="btn_ghost_m" onClick={goDelete} value="삭제하기" />
            <button className="btn_primary_m" ><span>수정하기</span></button>
          </div>
        :
        <div className='btn_layout right'>
          <button className="btn_primary_m"><span>등록하기</span></button>
        </div>}
      </form>
    </div>
  );
}

export default QnaWrite;
