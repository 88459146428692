import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  estimateOptionForm: [],
  estimateForm: {},
  searchForm: {},
  customerForm: {}
};

export const tempFormSlice = createSlice({
  name: 'tempForm',
  initialState,
  reducers: {
    actSaveSearchForm: (state, { payload }) => {
      state.searchForm = payload;
    },
    actAddInputEstimateForm: (state, { payload }) => {
      state.estimateForm = { ...state.estimateForm, ...payload };
    },
    actResetEstimateForm: (state, { payload }) => {
      state.estimateForm = payload;
    },
    actAddInputEstimateOptionForm: (state, { payload }) => {
      state.estimateOptionForm = [...state.estimateOptionForm, ...payload];
    },
    actSaveCustomerForm: (state, { payload }) => {
      state.customerForm = [...state.customerForm, ...payload];
    },
  },
});

export const {
  actAddInputEstimateForm,
  actSaveSearchForm,
  actResetEstimateForm,
  actAddInputEstimateOptionForm,
  actSaveCustomerForm,
} = tempFormSlice.actions;

export default tempFormSlice;
