import React from 'react';

function AccessError() {
  return (
    <div className="replace_page access">
      <div className="txt_wrap">
        <strong className="tlt">
          접속이 원활하지 <br className="mo_view" />
          않습니다.
        </strong>
        <p className="desc">
          일시적인 오류로 인하여 <br className="mo_view" />
          시스템 접속이 원활하지 않습니다.
          <br />
          잠시 후 다시 접속해 주세요.
        </p>
        <a href="/" className="btn_primary_l">
          <span>T4K 홈으로 가기</span>
        </a>
      </div>
    </div>
  );
}

export default AccessError;
