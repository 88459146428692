import React, { useEffect, useState } from 'react';
import ProgressBar from '../common/ProgressBar';
import EstimateBar from '../common/EstimateBar';
import { actAddInputEstimateForm } from '../../../../../utile/slice/tempFormSlice';
import { useAppSelector, useAppDispatch } from '../../../../../store';
import { actShowDepth1LPopUp } from '../../../../../utile/slice/layerPopUpSlice';
import ModelDetail1 from '../common/PopupDetail1';
import ModelDetail2 from '../common/PopupDetail2';
import ModelDetail3 from '../common/PopupDetail3';
import ModelDetail4 from '../common/PopupDetail4';
import ModelDetail5 from '../common/PopupDetail5';
import { staticAutoComma } from '../../../../../utile/regExp';
import AlartPopUp from '../../../../common/AlartPopUp';
import cargoWhImg from '../../../../../assets/images/img/img_model_type1_wh.png';
import cargoSbImg from '../../../../../assets/images/img/img_model_type1_sb.png';
import teugjangWhImg from '../../../../../assets/images/img/img_model_type2_wh.png';
import teugjangSbImg from '../../../../../assets/images/img/img_model_type2_sb.png';

const OPTION_TYPE = {
  OPTION: {
    ID: 'OPTION',
    NM: '옵션',
  },
  PACKAGE: {
    ID: 'PACKAGE',
    NM: '풀옵션 패키지',
    PRICE: 3250000, // todo 계산된 값으로 사용...
  },
};
export const Packages = [
  {
    value: '1',
    label: '순정 무선 휴대폰 충전패드',
    subtit: '순정 무선 휴대폰 충전패드',
    price: 350000,
    tooltip: false,
    tooltipInfo: '',
  },
  {
    value: '2',
    label: '프리미엄팩',
    subtit: '프리미엄팩',
    labelTemp: '프리미엄팩(ECM 하이패스 룸미러, 틸트 스티어링, 전동접이식 아웃사이드 미러)',
    price: 300000,
    tooltip: true,
    tooltipInfo: 'ECM  하이패스 룸미러, 틸트 스티어링, 전동접이식 아웃사이드 미러',
  },
  {
    value: '3',
    label: '동승석 에어백',
    subtit: '동승석 에어백',
    price: 200000,
    tooltip: false,
    tooltipInfo: '',
  },
  {
    value: '4',
    label: 'V2L 패키지 (전용커넥터 포함)',
    subtit: 'V2L 패키지 (전용커넥터 포함)',
    price: 1200000,
    tooltip: false,
    tooltipInfo: '',
  },
  // {
  //   value: '5',
  //   label: '3kW 보조 충전케이블',
  //   price: 200000,
  //   tooltip: false,
  //   tooltipInfo: '',
  // },
  {
    value: '5',
    label: '스마트팩',
    subtit:
      '12.8인치 스마트패드 + 후방카메라 + EV 스페셜 소프트웨어(티맵 모빌리티 EV 전용 네비게이션 + NUGU 음성인식시스템)',
    price: 1200000,
    tooltip: true,
    tooltipInfo:
      '12.8인치 스마트패드 + 후방카메라 + EV 스페셜 소프트웨어(티맵 모빌리티 EV 전용 네비게이션 + NUGU 음성인식시스템)',
  },
];

function Detail() {
  const dispatch = useAppDispatch();
  const { estimateForm } = useAppSelector((state) => state.tempForm);
  const { depth1LPopUpName } = useAppSelector((state) => state.layerPopUp);

  const { isShowAPopUp } = useAppSelector((state) => state.alartPopUp);

  const openDetailedItem = (mldNm, trimNm) => {
    dispatch(actShowDepth1LPopUp(mldNm.replace(/ /g, '') + trimNm.replace(/ /g, '')));
  };

  useEffect(() => {
    if (!estimateForm.OPTION_TYPE) {
      let optionPackageData = Array.from(document.querySelectorAll('.list input')).map((option) => {
        return { optionNm: option.name, optionPrice: option.dataset.name };
      });

      let packageDtlNm = Array.from(document.querySelectorAll('.list input')).map((option) => {
        return option.name;
      });

      dispatch(
        actAddInputEstimateForm({
          ...estimateForm,
          OPTION_TYPE: OPTION_TYPE.PACKAGE.ID,
          packageNm: OPTION_TYPE.PACKAGE.NM,
          primium_package_price: OPTION_TYPE.PACKAGE.PRICE,
          package_data: {
            optionPackageData,
          },
          packageDtlNm,
        }),
      );
    }
  }, []);

  const selectPackage = (e) => {
    dispatch(
      actAddInputEstimateForm({
        ...estimateForm,
        OPTION_TYPE: e.target.dataset.id,
        packageNm: e.target.value,
        option_package_checked_list: [],
      }),
    );
  };

  const checkOption = () => {
    const checkedList = Array.from(document.querySelectorAll('.list input'))?.filter((input) => {
      return input;
    });

    const option_package_checked_list = checkedList.map((option) => {
      return {
        optionValue: option.value,
        optionNm: option.name,
        optionPrice: option.dataset.name,
      };
    });

    dispatch(
      actAddInputEstimateForm({
        ...estimateForm,
        option_package_checked_list: [...option_package_checked_list],
      }),
    );
  };

  return (
    <div className="estimate">
      <ProgressBar />
      {isShowAPopUp && <AlartPopUp />}
      <div className="color_cho">
        {/* 360도 이미지 */}
        <div className="car_detail">
          <div className="wrap_img">
            {estimateForm.type === 'T4K 카고' ? (
              <img src={estimateForm.COLOR_TYPE === 'WHITE' ? cargoWhImg : cargoSbImg} alt="" />
            ) : (
              <img
                src={estimateForm.COLOR_TYPE === 'WHITE' ? teugjangWhImg : teugjangSbImg}
                alt=""
              />
            )}
          </div>
          <span className="info">이미지는 참고용이며 실제 차량과 다를 수 있습니다.</span>
        </div>
        <div className="detail_info">
          <div className="car_name">
            <strong className="tlt">{estimateForm.mldNm}</strong>
            <p className="sub_tlt">{estimateForm.trimNm}</p>
            <button
              type="button"
              className="btn_txt"
              onClick={() => openDetailedItem(estimateForm.mldNm, estimateForm.trimNm)}
            >
              <span>상세품목</span>
              <i className="ico_20_next"></i>
            </button>
          </div>
          <div className="color">
            {/* <strong className="tlt">옵션&패키지를 선택하세요.</strong> */}
            <div className="package_cho">
              {/* <div className="input_radio_basic">
                <div className="box_radio">
                  <input
                    type="radio"
                    id="radio_option"
                    value={OPTION_TYPE.OPTION.NM}
                    name="itme1"
                    onChange={selectPackage}
                    data-id={OPTION_TYPE.OPTION.ID}
                    checked={estimateForm.OPTION_TYPE === OPTION_TYPE.OPTION.ID}
                  />
                  <label htmlFor="radio_option" className="input_radio">
                    옵션
                  </label>
                </div>
                <span className="price">
                // <span>3,000,000</span>원
                </span>
              </div> */}
              <div className="input_radio_basic" style={{ marginTop: '0' }}>
                <div className="box_radio">
                  <input
                    type="radio"
                    id="radio_basic"
                    value={OPTION_TYPE.PACKAGE.NM}
                    name="itme1"
                    onChange={selectPackage}
                    data-id={OPTION_TYPE.PACKAGE.ID}
                    checked={estimateForm.OPTION_TYPE === OPTION_TYPE.PACKAGE.ID}
                  />
                  <label htmlFor="radio_basic" className="input_radio">
                    풀옵션 패키지
                  </label>
                </div>
                <span className="price">
                  <span>3,000,000</span>원
                </span>
                <span className="info">
                  ※ 2023년은 제조사의 사정으로 '풀옵션 패키지'만 주문이 가능합니다.
                </span>
              </div>
              <div className="check_list">
                {Packages.map((item, i) => {
                  const isOption = estimateForm.OPTION_TYPE === OPTION_TYPE.OPTION.ID;

                  return (
                    <div className="list" key={i}>
                      <div>
                        <input
                          type="checkbox"
                          name={item.label}
                          id={item.value}
                          value={item.value}
                          // disabled={!isOption ? 'disabled' : ''}
                          data-name={item.price}
                          onChange={checkOption}
                          checked={
                            isOption &&
                            estimateForm.option_package_checked_list.findIndex(
                              (e) => e.optionValue === item.value,
                            ) > -1
                          }
                        />
                      </div>
                      <label htmlFor={item.value} className="tlt">
                        {item.label}
                        {item.tooltip && (
                          <div className="tooltip">
                            <button type="button">
                              <i className="ico_24_tooltip"></i>
                            </button>
                            <div className="tool_desc">{item.tooltipInfo}</div>
                          </div>
                        )}
                      </label>
                      <span className="price">{staticAutoComma(item.price) + '원'}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <EstimateBar />
      </div>
      {depth1LPopUpName === 'T4K내장탑차슈퍼캡롱레인지프리미엄' && <ModelDetail1 />}
      {depth1LPopUpName === 'T4K냉동탑차슈퍼캡롱레인지프리미엄' && <ModelDetail2 />}
      {depth1LPopUpName === 'T4K윙바디슈퍼캡롱레인지프리미엄' && <ModelDetail3 />}
      {depth1LPopUpName === 'T4K카고슈퍼캡롱레인지프리미엄' && <ModelDetail4 />}
      {depth1LPopUpName === 'T4K카고슈퍼캡롱레인지' && <ModelDetail5 />}
    </div>
  );
}

export default Detail;
