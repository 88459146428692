import React from 'react';
import Page from './Board';
import PaginationBoard from '../../../../../common/PaginationBoard';

function ReviewDetail() {
  return (
    <div className="review_detail inner">
      <Page />
      {/* <PaginationBoard /> */}
    </div>
  );
}

export default ReviewDetail;
