import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store';
import { getCenterDetail, getSalesManList } from './mapSlice';

const PopUp = ({ sepNum, hideModal }) => {
  function tabEvent(e) {
    const contName = e.target.dataset.list;
    const list = document.querySelectorAll('[data-list]');
    const cont = document.querySelectorAll('[data-cont]');

    list.forEach((el) => {
      el.classList.remove('active');
    });
    e.target.classList.add('active');
    cont.forEach((el) => {
      el.classList.remove('active');
      if (el.dataset.cont === contName) {
        el.classList.add('active');
      }
    });

    if (e.target.value == 'person') {
      dispatch(getSalesManList(sepNum));
    }
  }

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCenterDetail(sepNum));
  }, [sepNum]);

  const centerDetail = useSelector((state) => state.serviceNetwork.centerDetail);
  const salesManList = useSelector((state) => state.serviceNetwork.salesManList);

  return (
    <div className="popup_wrap">
      <div className="dim"></div>
      {/* START : 타이틀 + 메세지 + 버튼 (S, M, L) */}
      {/* .pop_s_inner(width: 440px), .pop_m_inner(width: 600px), .pop_l_inner(width:900px) */}
      <div className="pop_l_inner">
        <div className="pop_header">
          <strong className="tlt">{centerDetail.svcNwNm}</strong>
          <button
            type="button"
            title="팝업 닫기"
            className="ico_22_pop_close"
            onClick={hideModal}
          ></button>
        </div>
        <div className="pop_cont">
          {centerDetail.svcNwTypeCd === 'C1201' && (
            <ul className="tab_list">
              <li>
                <button
                  type="button"
                  className="btn_radius active"
                  data-list="detail"
                  onClick={tabEvent}
                >
                  상세정보
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="btn_radius"
                  data-list="person"
                  value="person"
                  onClick={tabEvent}
                >
                  영업담당자
                </button>
              </li>
            </ul>
          )}
          <div className="tab_cont">
            <div className="cont active" data-cont="detail">
              <div className="img_wrap">
                <img src={centerDetail.svcNwImg} alt="" />
              </div>
              <div className="detail_info">
                <strong className="tlt">{centerDetail.svcNwNm}</strong>
                {centerDetail.svcNwDescCont !== '' && (
                  <p className="sub_tlt">{centerDetail.svcNwDescCont}</p>
                )}
                <dl className="list_dl1">
                  <dt>주소</dt>
                  <dd>{centerDetail.basicAddr + centerDetail.dtlAddr}</dd>
                  <dt>전화번호</dt>
                  <dd>{centerDetail.repContactNum}</dd>
                  {centerDetail.phoneNum !== '' && <dd>{centerDetail.phoneNum}</dd>}
                  <dt>운영 시간</dt>
                  <dd>
                    {centerDetail.operTimeDirecInpYn == 'Y'
                      ? centerDetail.operTimeDirecInpCont
                      : centerDetail.operStartTime + '~' + centerDetail.operEndTime}
                  </dd>
                  <dt>오시는 길</dt>
                  <dd>· {centerDetail.wayComCont}</dd>
                </dl>
              </div>
            </div>
            <ul className="cont" data-cont="person">
              {salesManList.map((salesMan) => (
                <li className="card_cont2" key={salesMan.salesManagerSeq}>
                  <div className="img_wrap">
                    <img src={salesMan.salesManagerImg} alt="" />
                  </div>
                  <div className="info_wrap">
                    <strong className="tlt">{salesMan.salesManagerNm}</strong>
                    <dl>
                      <dt>전화번호</dt>
                      <dd>{salesMan.contactNum}</dd>
                      <dt>이메일</dt>
                      <dd>{salesMan.emailAddr}</dd>
                    </dl>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {/* END : 타이틀 + 메세지 + 버튼 (S, M, L) */}
    </div>
  );
};

export default PopUp;
