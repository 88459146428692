import React from 'react';
import { Link } from 'react-router-dom';

function ReserBtn(props) {
  const handleClick = () => {
    props.closeHeader();
    props.setIsOpen && props.setIsOpen(false);
  };
  return (
    <Link to="/buy/reservation" className={props.type === 'wh' ? 'btn_radius wh' : 'btn_radius'}>
      <button onClick={handleClick}>지금, 구매상담 신청하세요!</button>
    </Link>
  );
}

export default ReserBtn;
